import { useCallback } from 'react';
import { FormatterProps } from 'react-data-grid';
import {
  GridPaginationCellExpanded,
  GridPaginationHeader
} from '../GridPagination';
import useSummaryGridDetail from './useSummaryGridDetail';
import { SummaryRow, TypesRow } from './types';
import {
  formatterNumber,
  formatterPercent,
  formatterCurrency,
  formatterDaysExhibition
} from '../../helpers/Formatters';
import useFilters from '../Filters/useFilters';
import { PlanningRow } from '../../store/modules/Planning/Planning.types';
import {
  calcTRP,
  calcCPP,
  calcCPM,
  calcAvgDiscount,
  calcTotalImpacts
} from '../../helpers/CalcsSimulation';
import { GridColumn } from '../GridPagination/types';
import SummaryGridDetail from './SummaryGridDetail';
import usePlanningRedux from '../Planning/usePlanningRedux';
import { classMaskOrUnmask } from '../../helpers/Utils';

export default function useSummaryGrid() {
  const { getColumns } = useSummaryGridDetail();
  const planning = usePlanningRedux().currentPlanning;
  const { getCurrentFilters } = useFilters();

  const filterPerChannel = (channel: string) => (program: PlanningRow) =>
    program.channel.toUpperCase() === channel.toUpperCase() &&
    program.insertions > 0;

  const calcTrpAndImpacts = (
    insertions: number,
    avgAudiencePercent: number,
    univTarget: number
  ) => {
    const trp = calcTRP(insertions, avgAudiencePercent);

    const impacts = calcTotalImpacts(trp, univTarget);

    return { trp, impacts };
  };

  const calcMetrics = (
    trp: number,
    negotiatedValue: number,
    totalImpacts: number
  ) => {
    const cpp = calcCPP(negotiatedValue, trp);

    const cpm = calcCPM(negotiatedValue, totalImpacts);

    return { cpp, cpm };
  };

  const getDataPerChannels = useCallback(
    (channel: string, _target: string) => {
      return planning.planningPrograms
        .filter(filterPerChannel(channel))
        .map((program) => {
          const metricsPerTarget = program.metricsPerTarget.find(
            (t) => t.target === _target
          );

          const { trp, impacts } = calcTrpAndImpacts(
            program.insertions,
            metricsPerTarget?.avgAudiencePercent || 0,
            metricsPerTarget?.univTarget || 0
          );

          const metrics = calcMetrics(trp, program.negotiatedValue, impacts);

          return {
            ...program,
            programTime: program.avgProgramStartTime
              .concat(' - ')
              .concat(program.avgProgramEndTime),
            daysExhibition: formatterDaysExhibition(program.daysExhibition),
            totalInserts: program.insertions,
            totalAvgDiscount: program.discount,
            totalInvestment: program.amount,
            totalNegotiated: program.negotiatedValue,
            totalImpacts: impacts,
            trp,
            ...metrics
          };
        });
    },
    [planning.planningPrograms]
  );

  const getRowsFromPlanning = useCallback(
    (_target: string) => {
      const currentFilter = getCurrentFilters();
      const channelFromFilter = currentFilter?.data?.channels || [];

      const programs = channelFromFilter.map((channel) => {
        const programsPerChannel = planning.planningPrograms.filter(
          filterPerChannel(channel.description)
        );

        const { totalInserts, totalAmount, totalNegotiated } =
          programsPerChannel.reduce(
            (acc, item) => ({
              totalInserts: acc.totalInserts + Number(item.insertions),
              totalAmount: acc.totalAmount + Number(item.amount),
              totalNegotiated:
                acc.totalNegotiated + Number(item.negotiatedValue)
            }),
            {
              totalInserts: 0,
              totalAmount: 0,
              totalNegotiated: 0
            }
          );

        const totalAvgDiscount = calcAvgDiscount(totalAmount, totalNegotiated);

        const allMetrics = programsPerChannel.reduce(
          (acc, program) => {
            const metricsPerTarget = program.metricsPerTarget.find(
              (t) => t.target === _target
            );

            const { trp, impacts } = calcTrpAndImpacts(
              program.insertions,
              metricsPerTarget?.avgAudiencePercent || 0,
              metricsPerTarget?.univTarget || 0
            );

            return {
              trp: acc.trp + trp,
              totalImpacts: acc.totalImpacts + impacts
            };
          },
          { trp: 0, totalImpacts: 0 }
        );

        return {
          ...allMetrics,
          cpp: calcCPP(totalNegotiated, allMetrics.trp),
          cpm: calcCPM(totalNegotiated, allMetrics.totalImpacts),
          channel,
          totalInserts,
          totalAvgDiscount,
          totalNegotiated,
          totalInvestment: totalAmount,
          programs: getDataPerChannels(channel.description, _target)
        };
      });

      return programs || [];
    },
    [planning.planningPrograms, getCurrentFilters, getDataPerChannels]
  );

  const getColumnsMaster = (
    target: string,
    classTagManagerExpanded: string
  ): readonly GridColumn<SummaryRow>[] => {
    return [
      {
        key: 'expanded',
        name: 'Canal',
        exportedField: 'channel.description',
        width: 700,
        minWidth: 30,
        colSpan(args) {
          return args.type === 'ROW' && args.row.type === TypesRow.DETAIL
            ? 9
            : undefined;
        },
        cellClass(row) {
          return `${classMaskOrUnmask(true)} ${
            row.type === TypesRow.DETAIL
              ? 'grid-col__shadow--none pa-3'
              : 'grid-col__shadow--none'
          }`;
        },
        headerCellClass: `${classMaskOrUnmask(false)} grid-col__shadow--none`,
        formatter({ row, onRowChange, isCellSelected }) {
          if (row.type === TypesRow.DETAIL) {
            return (
              <SummaryGridDetail
                columns={getColumns(target)}
                isCellSelected={isCellSelected}
                rows={row.programs}
              />
            );
          }

          return (
            <GridPaginationCellExpanded
              classes={{ tagManager: classTagManagerExpanded }}
              disabled={row.totalInserts === 0}
              expanded={row.expanded}
              onCellExpandClick={() => {
                onRowChange({ ...row, expanded: !row.expanded });
              }}
              text={row.channel.description.toUpperCase()}
            />
          );
        }
      },
      {
        key: 'totalInserts',
        name: 'Total Inserções',
        sortable: true,
        headerRenderer: (props) => {
          return GridPaginationHeader({
            alignment: 'right',
            title: 'Total',
            subtitle: 'inserções',
            ...props
          });
        },
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>{row.type === TypesRow.MASTER && row.totalInserts}</>
        ),
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none'
      },
      {
        key: 'trp',
        name: `GRP/TRP (${target})`,
        width: 150,
        headerRenderer: (props) => {
          return GridPaginationHeader({
            alignment: 'right',
            title: 'GRP/TRP',
            subtitle: target,
            dataMaskSubtitle: true,
            ...props
          });
        },
        sortable: true,
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none',
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER &&
              formatterNumber(row.trp, { fractionDigits: 5 })}
          </>
        )
      },
      {
        key: 'totalImpacts',
        name: 'Total Impactos',
        width: 150,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Total',
            subtitle: 'impactos',
            ...props
          }),
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER && formatterNumber(row.totalImpacts)}
          </>
        ),
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none',
        sortable: true
      },
      {
        key: 'cpm',
        name: 'CPM Impactos',
        width: 150,
        sortable: true,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'CPM',
            subtitle: 'impactos',
            ...props
          }),
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER &&
              formatterNumber(row.cpm, { fractionDigits: 1 })}
          </>
        ),
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none'
      },
      {
        key: 'cpp',
        name: `CPP (${target})`,
        sortable: true,
        headerRenderer: (props) => {
          return GridPaginationHeader({
            alignment: 'right',
            title: 'CPP',
            subtitle: target,
            dataMaskSubtitle: true,
            ...props
          });
        },
        width: 150,
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none',
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER &&
              formatterNumber(row.cpp, { fractionDigits: 1 })}
          </>
        )
      },
      {
        key: 'totalInvestment',
        name: 'Total Investimento Inicial',
        width: 150,
        sortable: true,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Total',
            subtitle: 'invest. inicial',
            ...props
          }),
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER &&
              formatterCurrency(row.totalInvestment, { fractionDigits: 1 })}
          </>
        ),
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none'
      },
      {
        key: 'totalAvgDiscount',
        name: 'Total Desconto Médio',
        width: 150,
        exportXlsxFormat: '0.000%',
        sortable: true,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Total',
            subtitle: 'desconto médio',
            ...props
          }),
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER &&
              formatterPercent(row.totalAvgDiscount, { fractionDigits: 3 })}
          </>
        ),
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none'
      },
      {
        key: 'totalNegotiated',
        name: 'Total Investimento Negociado',
        width: 150,
        sortable: true,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Total',
            subtitle: 'invest. negociado',
            ...props
          }),
        formatter: ({ row }: FormatterProps<SummaryRow>) => (
          <>
            {row.type === TypesRow.MASTER &&
              formatterCurrency(row.totalNegotiated, { fractionDigits: 1 })}
          </>
        ),
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none grid-col__shadow--none text-right`,
        headerCellClass: 'grid-col__separate--none grid-col__shadow--none'
      }
    ];
  };

  return { getColumnsMaster, getRowsFromPlanning };
}
