/* eslint-disable @typescript-eslint/no-unused-vars */
import '../AudiencePayTvChannelsFilters.scss';
import React, { useRef, useEffect, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import { checkboxClasses, Grid, Typography } from '@mui/material';

import ButtonApp from '../../../../../ButtonApp';
import FormContainer from '../../../../../Form/FormContainer';
import { OptionsList } from '../../../../../Form/types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';
import { listboxSigleProps } from '../../../../../Competitive/CompetitiveFilter/constants';
import classesGTM from '../../../../../../config/classesGTM';
import { IAudiencebyChannelsContainerProps } from '../types';
import LoadAudienceFilterTargetsRequest from '../../../../../../store/modules/AudienceModule/PayTv/Targets/Audience.targets.actions';
import LoadAudienceFilterTimeRangesRequest from '../../../../../../store/modules/AudienceModule/PayTv/TimeRanges/Audience.timeRanges.actions';
import LoadAudienceFilterWeekDaysRequest from '../../../../../../store/modules/AudienceModule/PayTv/WeekDays/Audience.weekDays.actions';
import {
  ClearAudienceProgrammingGendersData,
  LoadPayTvAudienceFilterProgrammingGendersRequest
} from '../../../../../../store/modules/AudienceModule/PayTv/ProgrammingGenders/Audience.programmingGenders.actions';
import { LoadAudienceFilterChannelsRequest } from '../../../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.actions';

const AudiencebyPayTvChannelsVerticalFiltersRadiography: React.FC<
  IAudiencebyChannelsContainerProps
> = ({
  target,
  setTarget,
  timeRange,
  setTimeRange,
  weekDays,
  setWeekDays,
  programmingGenders,
  setProgrammingGenders,
  newFilter,
  setNewFilter
}) => {
  const formRef = useRef<FormHandles>(null);
  const inputsConfig = {
    target: {
      name: 'target',
      defaultValue: target?.value ?? 'Total Domicílios',
      state: target
    },
    timeRange: {
      name: 'timeRange',
      defaultValue: timeRange?.value ?? 'Total dia (06h-30H)',
      state: timeRange
    },
    weekDays: {
      name: 'weekDays',
      defaultValue: weekDays?.value ?? 'TODOS',
      state: weekDays
    },
    programmingGender: {
      name: 'programmingGender',
      defaultValue: programmingGenders?.value ?? 'TODOS',
      state: programmingGenders
    }
  };
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    dispatch(LoadAudienceFilterTargetsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoadAudienceFilterTimeRangesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoadAudienceFilterWeekDaysRequest());
  }, [dispatch]);

  useEffect(() => {
    if (
      target &&
      timeRange &&
      weekDays &&
      !audience?.payTv?.programmingGenders.error &&
      !audience?.payTv?.programmingGenders.loading &&
      audience?.payTv?.programmingGenders.count === null
    ) {
      dispatch(
        LoadPayTvAudienceFilterProgrammingGendersRequest({
          target,
          timeRange,
          weekDays
        })
      );
    }
  }, [
    dispatch,
    target,
    timeRange,
    weekDays,
    audience?.payTv?.programmingGenders
  ]);

  const targetsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.targets.count) {
      return audience?.payTv?.targets.data
        .filter((targetOption) => targetOption.name)
        .map((targetOption) => ({
          label: targetOption.name,
          value: targetOption.name
        }));
    }

    return [];
  }, [audience?.payTv?.targets.data, audience?.payTv?.targets.count]);

  useEffect(() => {
    if (!target) {
      setTarget(
        targetsOptions.find(
          (option) => option.value === inputsConfig.target.defaultValue
        )
      );
    }
  }, [inputsConfig.target, targetsOptions, target, setTarget]);

  const timeRangesOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.timeRanges.count) {
      const order = [
        'Manhã',
        'Tarde',
        'Noite',
        'Madrugada',
        'Total dia (06h-30H)',
        'Total dia (07h-24H)'
      ];

      return audience?.payTv?.timeRanges.data
        .filter((timeRangeOption) => timeRangeOption.name)
        .map((timeRangeOption) => ({
          label: timeRangeOption.name,
          value: timeRangeOption.name
        }))
        .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
    }

    return [];
  }, [audience?.payTv?.timeRanges.data, audience?.payTv?.timeRanges.count]);

  useEffect(() => {
    if (!timeRange && setTimeRange) {
      setTimeRange(
        timeRangesOptions.find(
          (option) => option.value === inputsConfig.timeRange.defaultValue
        )
      );
    }
  }, [inputsConfig.timeRange, timeRangesOptions, timeRange, setTimeRange]);

  const weekDaysOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.weekDays.count) {
      const order = [
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
        'Domingo'
      ];

      return audience?.payTv?.weekDays.data
        .filter((weekDayOption) => weekDayOption.name)
        .map((weekDayOption) => ({
          label: weekDayOption.name,
          value: weekDayOption.name
        }))
        .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
    }

    return [];
  }, [audience?.payTv?.weekDays.data, audience?.payTv?.weekDays.count]);

  useEffect(() => {
    if (!weekDays) {
      setWeekDays(
        weekDaysOptions.find(
          (option) => option.value === inputsConfig.weekDays.defaultValue
        )
      );
    }
  }, [weekDaysOptions, weekDays, setWeekDays, inputsConfig.weekDays]);

  const programmingGendersOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.programmingGenders.count) {
      return audience?.payTv?.programmingGenders.data
        .filter((programmingGenderOption) => programmingGenderOption.name)
        .map((programmingGenderOption) => ({
          label: programmingGenderOption.name,
          value: programmingGenderOption.name
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    }

    return [];
  }, [
    audience?.payTv?.programmingGenders.data,
    audience?.payTv?.programmingGenders.count
  ]);

  useEffect(() => {
    if (!programmingGenders) {
      setProgrammingGenders(
        programmingGendersOptions.find(
          (option) =>
            option.value === inputsConfig.programmingGender.defaultValue
        )
      );
    }
  }, [
    programmingGendersOptions,
    programmingGenders,
    setProgrammingGenders,
    inputsConfig.programmingGender
  ]);

  useEffect(() => {
    if (target && timeRange && weekDays && programmingGenders && !ready) {
      if (newFilter !== undefined && setNewFilter) {
        setNewFilter(true);
      }

      dispatch(
        LoadAudienceFilterChannelsRequest({
          target,
          timeRange,
          weekDays,
          programmingGenders
        })
      );
      setReady(true);
    }
  }, [
    dispatch,
    target,
    timeRange,
    weekDays,
    programmingGenders,
    ready,
    newFilter,
    setNewFilter
  ]);

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  const handleTargetChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([inputsConfig.programmingGender?.name]);

    setTarget(option);
  };

  const handleTimeRangeChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([inputsConfig.programmingGender?.name]);

    if (setTimeRange) setTimeRange(option);
  };

  const handleWeekDaysChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([inputsConfig.programmingGender?.name]);

    setWeekDays(option);
  };

  const handleProgrammingGenderChange = (option: OptionsList) => {
    setProgrammingGenders(option);
  };

  const handleSendClick = () => {
    if (newFilter !== undefined && setNewFilter) {
      setNewFilter(true);
    }

    if (target && timeRange && weekDays && programmingGenders) {
      dispatch(
        LoadAudienceFilterChannelsRequest({
          target,
          timeRange,
          weekDays,
          programmingGenders
        })
      );
    }
  };

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
      sx={{
        width: '100%',
        paddingLeft: '1%',
        paddingRight: '1%',
        marginBottom: '-70px'
      }}
    >
      <Typography variant="h6">Filtros</Typography>
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Target"
          name={inputsConfig.target?.name}
          data-testid="target"
          disabled={audience?.payTv?.targets.loading}
          loading={audience?.payTv?.targets.loading}
          ListboxProps={listboxSigleProps}
          options={targetsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={targetsOptions.find(
            (option) => option.value === inputsConfig.target.defaultValue
          )}
          onChange={handleTargetChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Faixa Horária"
          name={inputsConfig.timeRange?.name}
          data-testid="timeRange"
          disabled={audience?.payTv?.timeRanges.loading}
          loading={audience?.payTv?.timeRanges.loading}
          ListboxProps={listboxSigleProps}
          options={timeRangesOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={timeRangesOptions.find(
            (option) => option.value === inputsConfig.timeRange.defaultValue
          )}
          onChange={handleTimeRangeChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Dias da Semana"
          name={inputsConfig.weekDays?.name}
          data-testid="weekDay"
          disabled={audience?.payTv?.weekDays.loading}
          loading={audience?.payTv?.weekDays.loading}
          ListboxProps={listboxSigleProps}
          options={weekDaysOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={weekDaysOptions.find(
            (option) => option.value === inputsConfig.weekDays.defaultValue
          )}
          onChange={handleWeekDaysChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Gênero de Programação"
          name={inputsConfig.programmingGender?.name}
          data-testid="programmingGender"
          disabled={audience?.payTv?.programmingGenders.loading}
          loading={audience?.payTv?.programmingGenders.loading}
          ListboxProps={listboxSigleProps}
          options={programmingGendersOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={programmingGendersOptions.find(
            (option) =>
              option.value === inputsConfig.programmingGender.defaultValue
          )}
          onChange={handleProgrammingGenderChange}
          sx={{
            width: '20%'
          }}
        />
        {/* direction row */}
        <Grid
          item
          display="flex"
          marginTop={1}
          sx={{
            marginLeft: 'auto'
          }}
        >
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--primary',
              loading: 'competitive-filter__buttons--loading',
              tagManager: classesGTM.audience.filter
            }}
            loading={false}
            onClick={handleSendClick}
            title="Filtrar"
          />
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--secondary',
              tagManager: classesGTM.audience.clearFilter
            }}
            disabled={false}
            onClick={() => formRef.current?.reset() || setReady(false)}
            title="Limpar Filtros"
          />
        </Grid>
      </FormContainer>
    </Grid>
  );
};

export default AudiencebyPayTvChannelsVerticalFiltersRadiography;
