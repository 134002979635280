import axios from 'axios';
import { SimulationData } from '../../store/modules/Simulation/Simulation.types';

export default async function getJson(
  url: string,
  onDownloadProgress: (event: ProgressEvent) => void
): Promise<SimulationData> {
  const { data } = await axios.get<SimulationData>(url, {
    onDownloadProgress
  });

  return data;
}
