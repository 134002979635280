import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AudienceByChannelsComparative from './Comparative/AudienceOpenTvRegularPlazasChannelsComparativePanel';
import AudiencebyChannelsContainer from './AudienceOpenTvRegularPlazasChannelsContainer';
import AudienceByChannelsRadiography from './Radiography/AudienceOpenTvRegularPlazasChannelsRadiographyPanel';
import { OptionsList } from '../../../../Form/types';

const AudiencebyChannels: React.FC = () => {
  const [target, setTarget] = React.useState<OptionsList | undefined>();
  const [timeRange, setTimeRange] = React.useState<OptionsList | undefined>();
  const [weekDays, setWeekDays] = React.useState<OptionsList | undefined>();
  const [programmingGenders, setProgrammingGenders] = React.useState<
    OptionsList | undefined
  >();
  const [newFilter, setNewFilter] = React.useState<boolean>(false);

  const params = useParams() as {
    tab: 'channels';
    subtab: 'comparative' | 'radiography';
  };

  const subtab = useMemo(() => {
    if (params.tab === 'channels') {
      return params.subtab || 'comparative';
    }

    return params.subtab;
  }, [params.tab, params.subtab]);

  const globalProps = {
    target,
    timeRange,
    weekDays,
    programmingGenders,
    newFilter,
    setNewFilter
  };

  const additionalProps = {
    ...globalProps,
    setTarget,
    setTimeRange,
    setWeekDays,
    setProgrammingGenders
  };

  return (
    <AudiencebyChannelsContainer {...additionalProps}>
      {subtab === 'radiography' ? (
        <AudienceByChannelsRadiography {...globalProps} />
      ) : (
        <AudienceByChannelsComparative {...globalProps} />
      )}
    </AudiencebyChannelsContainer>
  );
};

export default AudiencebyChannels;
