import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function PanelContainer({ className, children }: Props) {
  return <div className={`panel-container ${className}`}>{children}</div>;
}

PanelContainer.defaultProps = {
  className: 'panel-container__gray'
};
