import './StyledTabs.scss';
import React from 'react';
import Tabs, { TabsProps, tabsClasses } from '@mui/material/Tabs';

const StyledTabs: React.FC<TabsProps> = ({ classes, ...rest }) => (
  <Tabs
    {...rest}
    classes={{
      scroller: 'styled-tabs',
      flexContainer: 'styled-tabs__button',
      indicator: 'styled-tabs__indicator',
      ...classes
    }}
    sx={{
      ...rest.sx,
      [`& .${tabsClasses.scrollButtons}`]: {
        '&.Mui-disabled': { opacity: 0.3 }
      }
    }}
    TabIndicatorProps={{
      children: <span className="styled-tabs__indicator--span" />
    }}
  />
);

export default StyledTabs;
