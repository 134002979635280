import { RefObject } from 'react';
import { FormHandles } from '@unform/core';
import { Channel } from '../Channels/Channels.types';
import { TypeTV } from '../../../services/shared/Api.service';
import { Market } from '../Markets/Markets.types';
import { YupError } from '../../../helpers/HandleErrors';

export enum FiltersTypes {
  SET_FILTERS_FORMREF = '@filters/SET_FILTERS_FORMREF',
  SAVE_PARTIAL_FILTERS_FORM_DATA = '@filters/SAVE_PARTIAL_FILTERS_FORM_DATA',
  SAVE_FILTERS_FORM_DATA = '@filters/SAVE_FILTERS_FORM_DATA',
  SAVE_FILTERS_FORM_DATA_ERROR = '@filters/SAVE_FILTERS_FORM_DATA_ERROR',
  CLEAR_FILTERS_FORM_DATA = '@filters/CLEAR_FILTERS_FORM_DATA',
  REMOVE_FILTERS_SCENERY = '@filters/REMOVE_FILTERS_SCENERY',
  REMOVE_FILTERS_FORM_ERROR = '@filters/REMOVE_FILTERS_FORM_ERROR',
  RESET_FILTERS = '@filters/RESET_FILTERS'
}

export enum ClosedPeriodTypesEnum {
  MONTH = 'month',
  QUARTER = 'quarter',
  SEMESTER = 'semester',
  CLOSED_YEAR = 'closedYear'
}

export type ClosedPeriodTypes =
  | ClosedPeriodTypesEnum.MONTH
  | ClosedPeriodTypesEnum.QUARTER
  | ClosedPeriodTypesEnum.SEMESTER
  | ClosedPeriodTypesEnum.CLOSED_YEAR;

export type ClosedPeriodValue = {
  label: string;
  value: string;
};

export type ClosedPeriod = {
  year: Date | string;
  type: ClosedPeriodTypes;
  value?: ClosedPeriodValue;

  /** @deprecated use value field instead */
  month?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  quarter?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  semester?: ClosedPeriodValue;
  /** @deprecated use value field instead */
  closedYear?: ClosedPeriodValue;
};

export type FiltersChannel = Omit<Channel, 'channelGlobo'> &
  Partial<Pick<Channel, 'channelGlobo'>>;
export interface FiltersData {
  targets: string[];
  dates: {
    campaign: {
      to: Date | string;
      from: Date | string;
    };
    audience: {
      to: Date | string;
      from: Date | string;
      closedPeriod: ClosedPeriod;
    };
    buy: Date | string;
    display: Date | string;
  };
  market: Market;
  channels: Channel[];
  typeTV: TypeTV.OPEN | TypeTV.CLOSED;
}

export interface Filter {
  ref?: RefObject<FormHandles>;
  data?: FiltersData;
  erros?: YupError;
  partialData?: Partial<FiltersData>;
  sceneryId: string;
  error?: boolean;
}

export interface FiltersState {
  readonly forms: Filter[];
}

export interface FilterAction {
  type: FiltersTypes;
  payload: Filter;
}
