import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import has from 'lodash/has';
import StyledTabPanel from '../../StyledTabs/StyledTabPanel';
import paths from '../../../routes/paths';
import ConsolidatedMetrics from './ConsolidatedMetrics';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Loading from '../../Loading';
import ComparativeMetrics from './ComparativeMetrics';
import ChangeTab from '../../../store/modules/Competitive/Navigation/Competitive.navigation.actions';

const { competitive } = paths.home.childrens;
const defaultTab = competitive.childrens.consolidated.tab;

export default function CompetitivePanel() {
  const dispatch = useAppDispatch();
  const { consolidatedMetrics, comparativeMetrics, competitiveNav } =
    useAppSelector((state) => state);
  const params = useParams() as { tab: 'consolidated' | 'comparative' };

  useEffect(() => {
    const panel = competitive.childrens[params.tab];
    const tab = panel ? panel.tab : defaultTab;
    dispatch(ChangeTab(tab));
  }, [params, dispatch]);

  return has(competitive.childrens, params.tab) ? (
    <>
      <StyledTabPanel index={0} value={competitiveNav.tab}>
        <ConsolidatedMetrics />
      </StyledTabPanel>
      <StyledTabPanel index={1} value={competitiveNav.tab}>
        <ComparativeMetrics />
      </StyledTabPanel>
      {consolidatedMetrics.loading || comparativeMetrics.loading ? (
        <Loading />
      ) : null}
    </>
  ) : null;
}
