import { TypeTV, apiV1 } from '../shared/Api.service';
import { ChannelsResponse } from '../../store/modules/Channels/Channels.types';

const path = '/channels';

export default async function getChannels(
  typeTV: TypeTV
): Promise<ChannelsResponse> {
  const { data } = await apiV1.get<ChannelsResponse>(path, {
    params: { typeTV }
  });
  return data;
}
