import { Reducer } from 'redux';
import {
  AudienceFilterProgramsAction,
  AudienceFilterProgramsState,
  AudienceFiltersProgramsType
} from './Audience.programs.types';

const programInitialState: AudienceFilterProgramsState = {
  error: false,
  loading: false,
  data: [],
  count: null,
  requests: 0
};

const reducer: Reducer<
  AudienceFilterProgramsState,
  AudienceFilterProgramsAction
> = (state = programInitialState, action): AudienceFilterProgramsState => {
  switch (action.type) {
    case AudienceFiltersProgramsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count,
        requests: state.requests + 1
      };
    }

    case AudienceFiltersProgramsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null,
        requests: state.requests + 1
      };
    }

    case AudienceFiltersProgramsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersProgramsType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
