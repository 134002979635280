import { toast } from 'react-toastify';
import security from '../../../config/keycloak';
import { ApiErrorResponse } from '../../../services/shared/Api.service';
import { AuthTypes, AuthProfile, AuthAction } from './Auth.types';

import {
  authenticate,
  authenticateWithKeycloak,
  getUserInfo,
  login,
  logout,
  logoutKeycloak
} from '../../../services/MediaSimulator/Auth.service';
import { AppThunk } from '../..';
import loginLocalStorage from '../../../components/Auth/LoginWrapper/Login.LocalStorage';

const loginStore = loginLocalStorage();

const UserSignInSuccess = (userInfo: AuthProfile): AuthAction => ({
  type: AuthTypes.USER_SIGN_IN_SUCESS,
  payload: { userInfo }
});

const UserSignInRequest = () => ({
  type: AuthTypes.USER_SIGN_IN_REQUEST
});

const UserSignInFailure = () => ({
  type: AuthTypes.USER_SIGN_IN_FAILURE
});

const AuthenticateRequest = () => ({
  type: AuthTypes.AUTHENTICATE_REQUEST
});

const AuthenticateSuccess = () => ({
  type: AuthTypes.AUTHENTICATE_SUCCESS
});

const AuthenticateFailure = () => ({
  type: AuthTypes.AUTHENTICATE_FAILURE
});

const SignOutRequest = () => ({ type: AuthTypes.SIGN_OUT_REQUEST });
const SignOutFailure = () => ({ type: AuthTypes.SIGN_OUT_FAILURE });

const RedirectToLoginRequest = () => ({
  type: AuthTypes.REDIRECT_TO_LOGIN
});

export const ResetProfile = () => ({
  type: AuthTypes.RESET_PROFILE
});

export const AuthenticateWithKeycloak = (): AppThunk => async (dispatch) => {
  try {
    dispatch(RedirectToLoginRequest());
    loginStore.codeVerifier.remove();

    // @ts-ignore
    if (!security?.didInitialize) {
      await security
        .init({ onLoad: 'login-required', responseMode: 'query' })
        .then(async (authenticated: boolean): Promise<void> => {
          if (authenticated) {
            if (
              !security.tokenParsed?.resource_access?.plim?.roles?.includes(
                'acesso-plim'
              )
            ) {
              throw new Error('Usuário sem permissão');
            }

            if (!security.token) {
              throw new Error('Token não encontrado');
            }

            dispatch(AuthenticateRequest());
            const response = await authenticateWithKeycloak({
              keycloakToken: security?.token,
              refreshToken: security?.refreshToken
            });

            if (response.status === 200) {
              dispatch(AuthenticateSuccess());

              const routePath = `${window.location.origin}/#${
                loginStore.lastRoute.get() || '/'
              }`;
              loginStore.authenticateMethod.set('keycloak');
              loginStore.expires.set(response.data.expires);
              loginStore.authenticated.set(true);
              loginStore.lastRoute.remove();
              window.location.href = routePath;
            } else {
              throw new Error(response.msg);
            }
          }
        });
    }
  } catch (err) {
    window.history.pushState(null, '', window.location.pathname);
    dispatch(AuthenticateFailure());
    loginStore.authenticated.set(false);
  } finally {
    loginStore.codeVerifier.remove();
  }
};

export const RedirectToLogin = (): AppThunk => async (dispatch) => {
  try {
    dispatch(RedirectToLoginRequest());

    const { data } = await login();

    if (data?.codeVerifier) loginStore.codeVerifier.set(data.codeVerifier);

    window.location.href = data.url;
  } catch (err) {
    toast.error('Ocorreu um erro ao se comunicar com o servidor');
    dispatch(UserSignInFailure());
  }
};

export const GetProfile = (): AppThunk => async (dispatch) => {
  try {
    dispatch(UserSignInRequest());
    const response = await getUserInfo();
    if (response.status !== 200) {
      throw new Error(response.msg);
    }

    loginStore.lastRoute.remove();
    dispatch(UserSignInSuccess(response.data));
  } catch (error) {
    loginStore.authenticated.set(false);
    if (error instanceof Error) {
      toast.error(error.message);
    }
    const err = error as ApiErrorResponse;

    if (err?.status !== 401) {
      dispatch(UserSignInFailure());
      if (err?.msg) toast.error(err?.msg);
    }
  }
};

export const Authenticate =
  (state: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(AuthenticateRequest());
      const response = await authenticate(
        state.concat(`&codeVerifier=${loginStore.codeVerifier.get()}`)
      );

      if (response.status === 200) {
        dispatch(AuthenticateSuccess());

        const routePath = `${window.location.origin}/#${
          loginStore.lastRoute.get() || '/'
        }`;

        window.location.href = routePath;
        loginStore.authenticated.set(true);
        loginStore.lastRoute.remove();
        loginStore.expires.set(response.data.expires);
        loginStore.authenticateMethod.set('backstage');
      } else {
        throw new Error(response.msg);
      }
    } catch (err) {
      window.history.pushState(null, '', window.location.pathname);
      dispatch(AuthenticateFailure());
      loginStore.authenticated.set(false);
    } finally {
      loginStore.codeVerifier.remove();
    }
  };

export const UserSignOut = (): AppThunk => async (dispatch) => {
  try {
    dispatch(SignOutRequest());
    const loginMethod = loginStore.authenticateMethod.get();
    const response = await (loginMethod === 'keycloak'
      ? logoutKeycloak()
      : logout());
    if (response.status !== 200) {
      throw new Error(response.msg);
    }

    loginStore.authenticated.set(false);
    loginStore.expires.remove();
    window.location.href = response.data.url;
  } catch (error) {
    dispatch(SignOutFailure());
    console.error(error);
  }
};

export const UpdateProfile =
  (newProfile: any): AppThunk =>
  (dispatch) => {
    dispatch({
      type: AuthTypes.SET_PROFILE,
      payload: { userInfo: newProfile }
    });
  };
