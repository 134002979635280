import { Reducer } from 'redux';
import {
  AudienceFilterRangeAvaragesAction,
  AudienceFilterRangeAvaragesState,
  AudienceFiltersRangeAvaragesType
} from './Audience.rangeAvarages.types';

const audienceAvarageInitialState: AudienceFilterRangeAvaragesState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterRangeAvaragesState,
  AudienceFilterRangeAvaragesAction
> = (
  state = audienceAvarageInitialState,
  action
): AudienceFilterRangeAvaragesState => {
  switch (action.type) {
    case AudienceFiltersRangeAvaragesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersRangeAvaragesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersRangeAvaragesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersRangeAvaragesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
