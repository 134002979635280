import { configureStore } from '@reduxjs/toolkit';
import rootReducers from './modules/Root.reducer';

export default () => {
  return configureStore({
    reducer: rootReducers(),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            '@filters/SET_FILTERS_FORMREF',
            '@planning/SET_PLANNING_PROGRAMS',
            '@planning/ADD_PLANNING_PROGRAM',
            '@filters/SAVE_FILTERS_FORM_DATA',
            '@filters/SAVE_PARTIAL_FILTERS_FORM_DATA'
          ],
          ignoredPaths: [
            'filters.forms',
            'baseAudience.openedTv.dateMax',
            'baseAudience.openedTv.dateMin',
            'baseAudience.closedTv.dateMax',
            'baseAudience.closedTv.dateMin',
            'planning'
          ]
        },
        immutableCheck: {
          ignoredPaths: ['filters.forms', 'payload.planningPrograms']
        }
      })
  });
};
