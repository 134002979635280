import { Reducer } from 'redux';
import {
  AudienceFilterAudienceEvolutionsAction,
  AudienceFilterAudienceEvolutionsState,
  AudienceFiltersAudienceEvolutionsType
} from './Audience.audienceEvolutions.types';

const audienceEvolutionInitialState: AudienceFilterAudienceEvolutionsState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterAudienceEvolutionsState,
  AudienceFilterAudienceEvolutionsAction
> = (
  state = audienceEvolutionInitialState,
  action
): AudienceFilterAudienceEvolutionsState => {
  switch (action.type) {
    case AudienceFiltersAudienceEvolutionsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAudienceEvolutionsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAudienceEvolutionsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAudienceEvolutionsType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
