import { Reducer } from 'redux';
import {
  AudienceNavAction,
  AudienceNavState,
  AudienceNavTypes
} from './Audience.navigation.types';

const targetInitialState: AudienceNavState = {
  tab: undefined,
  filters: undefined
};

const reducer: Reducer<AudienceNavState, AudienceNavAction> = (
  state = targetInitialState,
  action
): AudienceNavState => {
  switch (action.type) {
    case AudienceNavTypes.AUDIENCE_CHANGE_TAB: {
      return {
        ...state,
        tab: action.payload?.tab || 0
      };
    }
    case AudienceNavTypes.AUDIENCE_SAVE_FILTERS_TAB: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
