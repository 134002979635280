import React, { memo, useEffect, useRef } from 'react';
import { DataGridHandle } from 'react-data-grid';
import { GridColumn } from '../GridPagination/types';
import GridPagination from '../GridPagination/index';
import { SummaryDetailProps } from './types';

interface Props {
  columns: readonly GridColumn<SummaryDetailProps>[];
  isCellSelected: boolean;
  rows: SummaryDetailProps[];
}

const SummaryGridDetail: React.FC<Props> = ({
  columns,
  isCellSelected,
  rows
}) => {
  const gridRef = useRef<DataGridHandle>(null);

  useEffect(() => {
    if (!isCellSelected) return;

    gridRef.current?.element
      ?.querySelector<HTMLDivElement>('[tabindex="0"]')
      ?.focus({ preventScroll: true });
  }, [isCellSelected]);

  return (
    <GridPagination
      className="summary__grid--detail"
      columns={columns}
      gridRef={gridRef}
      hasSearch={false}
      headerRowHeight={40}
      rows={rows}
    />
  );
};

export default memo(SummaryGridDetail);
