export interface RangeAvarage {
  pc_alcance: number;
  nm_emissora: string;
}
export interface AudienceFilterRangeAvaragesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: RangeAvarage[];
  readonly count: number | null;
}

export interface RangeAvarageResponse {
  data: {
    rows: RangeAvarage[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersRangeAvaragesType {
  REQUEST = '@audience/payTv/byChannels/rangeAvarages/load',
  SUCESS = '@audience/payTv/byChannels/rangeAvarages/success',
  ERROR = '@audience/payTv/byChannels/rangeAvarages/error',
  CLEAR = '@audience/payTv/byChannels/rangeAvarages/clear'
}

export interface AudienceFilterRangeAvaragesAction {
  type: AudienceFiltersRangeAvaragesType;
  payload: Omit<RangeAvarageResponse, 'status'>;
}
