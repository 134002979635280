/* eslint-disable @typescript-eslint/no-unused-vars */
import '../AudiencePayTvChannelsFilters.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Grid } from '@mui/material';

import FormContainer from '../../../../../Form/FormContainer';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';
import {
  checkboxClasses,
  chipProps,
  listboxProps,
  listboxSigleProps
} from '../../../../../Competitive/CompetitiveFilter/constants';
import { IAudienceByChannelsPayTvComparativeFilterProps } from '../types';
import { OptionsList } from '../../../../../Form/types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { ClearAudienceChannelGeneresData } from '../../../../../../store/modules/AudienceModule/PayTv/ChannelGeneres/Audience.channelGeneres.actions';
import { ClearAudienceChannelsData } from '../../../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.actions';
import { ClearAudienceYearsData } from '../../../../../../store/modules/AudienceModule/PayTv/Years/Audience.years.actions';
import useAudiencePayTvChannelsComparativeFilterLoadData from './hooks/useAudiencePayTvChannelsComparativeFilterLoadData';
import useAudiencePayTvChannelsComparativeFilterAutoSelect from './hooks/useAudiencePayTvChannelsComparativeFilterAutoSelect';

const AudiencebyPayTvChannelsComparativeFilter: React.FC<
  IAudienceByChannelsPayTvComparativeFilterProps
> = ({
  programmers,
  channelGeneres,
  channels,
  year,
  setProgrammers,
  setChannelGeneres,
  setChannels,
  setYear,
  newFilter,
  setNewFilter,
  target,
  timeRanges,
  weekDays,
  programmingGenders
}) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const FIXED_VALUE = useMemo(
    () => ({
      label: 'Todos',
      value: 'all'
    }),
    []
  );
  const inputsConfig = useMemo(
    () => ({
      programmers: {
        name: 'programmers',
        defaultValue: [FIXED_VALUE]
      },
      channelGeneres: {
        name: 'channelGenres',
        defaultValue: [FIXED_VALUE]
      },
      channels: {
        name: 'channels',
        defaultValue: [FIXED_VALUE]
      },
      year: {
        name: 'year',
        defaultValue: '2024'
      }
    }),
    [FIXED_VALUE]
  );
  const {
    programersOptions,
    channelGeneresOptions,
    channelsOptions,
    yearsOptions
  } = useAudiencePayTvChannelsComparativeFilterLoadData({
    programmers,
    channelGeneres,
    channels,
    target,
    timeRanges,
    weekDays,
    programmingGenders
  });

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  useAudiencePayTvChannelsComparativeFilterAutoSelect({
    programmers,
    channelGeneres,
    channels,
    year,
    setProgrammers,
    setChannelGeneres,
    setChannels,
    setYear,
    clearInputs,
    FIXED_VALUE,
    inputsConfig
  });

  const handleProgrammerChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceChannelGeneresData());
    clearInputs([
      inputsConfig.channelGeneres.name,
      inputsConfig.channels.name,
      inputsConfig.year.name
    ]);

    setProgrammers(option);
  };

  const handleChannelGenreChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceChannelsData());
    clearInputs([inputsConfig.channels.name, inputsConfig.year.name]);

    setChannelGeneres(option);
  };

  const handleChannelChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceYearsData());
    clearInputs([inputsConfig.year.name]);

    setChannels(option);
  };

  const handleYearChange = (option: OptionsList) => {
    setYear(option);
  };

  useEffect(() => {
    if (!year) {
      if (setYear)
        setYear(
          yearsOptions?.filter(
            (option) => option.value === inputsConfig.year.defaultValue
          )[0]
        );
    }
  }, [
    year,
    setYear,
    FIXED_VALUE,
    inputsConfig.year.name,
    inputsConfig.year.defaultValue,
    yearsOptions
  ]);

  const handleSendClick = () => {
    return;
  };

  useEffect(() => {
    if (newFilter) {
      clearInputs([
        inputsConfig.programmers.name,
        inputsConfig.channelGeneres.name,
        inputsConfig.channels.name,
        inputsConfig.year.name
      ]);
      setProgrammers(undefined);
      setChannelGeneres(undefined);
      setChannels(undefined);
      setYear(undefined);

      if (setNewFilter) setNewFilter(false);
    }
  }, [
    newFilter,
    setNewFilter,
    setProgrammers,
    setChannelGeneres,
    setChannels,
    setYear,
    inputsConfig
  ]);

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(programmers)}
          /* classes={} */
          data-testid={inputsConfig.programmers?.name}
          disableCloseOnSelect
          disabled={
            audience?.payTv?.programmers.loading ||
            audience?.payTv?.programmers.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Programadoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.payTv?.programmers.loading}
          multiple
          name={inputsConfig.programmers?.name}
          onChange={handleProgrammerChange}
          options={programersOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channelGeneres)}
          /* classes={} */
          data-testid={inputsConfig.channelGeneres?.name}
          disableCloseOnSelect
          disabled={
            audience?.payTv?.channelGeneres.loading ||
            audience?.payTv?.channelGeneres.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Gêneros das Emissoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.payTv?.channelGeneres.loading}
          multiple
          name={inputsConfig.channelGeneres?.name}
          onChange={handleChannelGenreChange}
          options={channelGeneresOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channels)}
          /* classes={} */
          data-testid="channels"
          disableCloseOnSelect
          disabled={
            audience?.payTv?.channels.loading ||
            audience?.payTv?.channels.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Emissoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.payTv?.channels.loading}
          multiple
          name={inputsConfig.channels?.name}
          onChange={handleChannelChange}
          options={channelsOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Ano"
          name={inputsConfig.year?.name}
          defaultValue={yearsOptions?.find(
            (option) => option.value === inputsConfig.year.defaultValue
          )}
          data-testid="year"
          disabled={
            audience?.payTv?.years.loading || audience?.payTv?.years.count === 0
          }
          loading={audience?.payTv?.years.loading}
          ListboxProps={listboxSigleProps}
          options={yearsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          onChange={handleYearChange}
          sx={{
            marginLeft: 'auto',
            width: '20%',
            marginRight: '-70px'
          }}
        />
      </FormContainer>
    </Grid>
  );
};

export default AudiencebyPayTvChannelsComparativeFilter;
