import { SortColumn } from 'react-data-grid';
import { UsersTypes, UsersResponse, UsersFindAll, User } from './Users.types';
import {
  getAllUsers,
  saveUser,
  updateUser,
  deleteUser
} from '../../../services/MediaSimulator/Users.service';
import { AppThunk } from '../..';
import { UpdateProfile } from '../Auth/Auth.actions';

export const LoadUsersSuccess = (userResponse: UsersResponse) => {
  const payload = {
    payload: {
      users: userResponse.data.rows,
      count: userResponse.data.count,
      message: ''
    },
    type: UsersTypes.LOAD_USERS_SUCCESS
  };
  return payload;
};
export const LoadUsersFailure = (error: string) => ({
  type: UsersTypes.LOAD_USERS_FAILURE,
  payload: { message: error }
});

const LoadingRequest = () => ({
  type: UsersTypes.LOAD_USERS_REQUEST
});

export const LoadUsersRequest =
  (userFind: UsersFindAll): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingRequest());
      const result = await getAllUsers(userFind);
      if (result.status !== 200) {
        throw new Error(result.msg);
      }
      dispatch(LoadUsersSuccess(result));
    } catch (error) {
      dispatch(LoadUsersFailure(error instanceof Error ? error.message : ''));
    }
  };

export const SaveOrUpdateUserFailure = (error: string) => {
  return {
    type: UsersTypes.SAVE_OR_UPDATE_USER_FAILURE,
    payload: { message: error }
  };
};

export const SaveUserSuccess = (message: string) => {
  return {
    type: UsersTypes.SAVE_USER_SUCCESS,
    payload: { message }
  };
};

export const SaveUserRequest =
  (userRequest: User): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingRequest());
      const response = await saveUser(userRequest);
      if (response.status !== 200) {
        throw new Error(response.msg);
      }

      dispatch(SaveUserSuccess(response.msg));
    } catch (error) {
      dispatch(
        SaveOrUpdateUserFailure(error instanceof Error ? error.message : '')
      );
    }
  };

export const UpdateSuccess = (message: string) => ({
  type: UsersTypes.UPDATE_USER_SUCCESS,
  payload: {
    message
  }
});

export const DeleteSuccess = (message: string) => ({
  type: UsersTypes.DELETE_USERS_SUCCESS,
  payload: {
    message
  }
});

export const DeleteFailure = (message: string) => {
  return {
    type: UsersTypes.DELETE_USERS_FAILURE,
    payload: {
      message
    }
  };
};

export const DeleteUserRequest =
  (id: number): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingRequest());
      const response = await deleteUser(id);
      if (response.status !== 200) {
        throw new Error(response.msg);
      }
      dispatch(DeleteSuccess(response.msg));
    } catch (error) {
      dispatch(DeleteFailure(error instanceof Error ? error.message : ''));
    }
  };

export const UpdateUser =
  (userRequest: User): AppThunk =>
  async (dispatch, getState) => {
    const { auth } = getState();
    try {
      dispatch(LoadingRequest());
      const response = await updateUser(userRequest);
      if (response.status !== 200) {
        throw new Error(response.msg);
      }
      if (response.data.id === auth?.profile?.id) {
        dispatch(UpdateProfile({ ...auth.profile, ...response.data }));
      }
      dispatch(UpdateSuccess(response.msg));
    } catch (error) {
      dispatch(
        SaveOrUpdateUserFailure(error instanceof Error ? error.message : '')
      );
    }
  };

export const SetUsersPage = (newPage: number) => ({
  type: UsersTypes.SET_USERS_PAGE,
  payload: {
    page: newPage
  }
});

export const SetUsersRowsPerPage = (newRows: number) => ({
  type: UsersTypes.SET_USERS_ROWS_PER_PAGE,
  payload: {
    rowsPerPage: newRows
  }
});

export const SetUsersSortColumns = (columns: SortColumn[]) => ({
  type: UsersTypes.SET_USERS_SORT_COLUMNS,
  payload: {
    sortColumns: columns
  }
});

export const SetUsersSearch = (search: string) => ({
  type: UsersTypes.SET_USERS_SEARCH,
  payload: {
    search
  }
});
