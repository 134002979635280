// import { User } from '../Users/Users.types';
import { SortColumn } from 'react-data-grid';
import { ApiResponse } from '../../../services/shared/Api.service';

export enum SimulationsTypes {
  SIMULATIONS_REQUEST = '@simulations/SIMULATIONS_REQUEST',
  LOAD_SIMULATIONS_SUCCESS = '@simulations/LOAD_SIMULATIONS_SUCCESS',
  LOAD_SIMULATIONS_FAILURE = '@simulations/LOAD_SIMULATIONS_FAILURE',
  SAVE_SIMULATION_REQUEST = '@simulations/SAVE_OR_UPDATE_SIMULATION_REQUEST',
  SAVE_SIMULATION_SUCCESS = '@simulations/SAVE_OR_UPDATE_SIMULATION_SUCCESS',
  SAVE_OR_UPDATE_SIMULATION_FAILURE = '@simulations/SAVE_OR_UPDATE_SIMULATION_FAILURE',
  UPDATE_SIMULATION_REQUEST = '@simulations/UPDATE_SIMULATION_REQUEST',
  UPDATE_SIMULATION_SUCCESS = '@simulations/UPDATE_SIMULATION_SUCCESS',
  DELETE_SIMULATION_SUCCESS = '@simulations/DELETE_SIMULATION_SUCCESS',
  DELETE_SIMULATION_FAILURE = '@simulations/DELETE_SIMULATION_FAILURE',
  SET_SIMULATIONS_PAGE = '@simulations/SET_SIMULATIONS_PAGE',
  SET_SIMULATIONS_ROWS_PER_PAGE = '@simulations/SET_SIMULATIONS_ROWS_PER_PAGE',
  SET_SIMULATIONS_SORT_COLUMNS = '@simulations/SET_SIMULATIONS_SORT_COLUMNS',
  SET_SIMULATIONS_SEARCH = '@simulations/SET_SIMULATIONS_SEARCH'
}

export interface Simulation {
  id: number | null;
  uuid: string;
  name: string;
  dataUrl: string;
  fileName: string;
  draftUrl: string;
  draftFileName: string;
  userCreatedId: number;
  // userCreated: Pick<User, 'id' | 'name' | 'email' | 'area' | 'central'>;
  userUpdatedId: number;
  // userUpdated: Pick<User, 'id' | 'name' | 'email' | 'area' | 'central'>;
  createdAt: Date | string;
  updatedAt: Date | string;
  isDraft?: boolean;
}

export interface SimulationsAction {
  type: SimulationsTypes;
  payload: {
    simulations?: Simulation[];
    count?: number;
    message?: string;
    search?: string;
    loading?: boolean;
    mustBeLoading?: boolean;
    page?: number;
    rowsPerPage?: number;
    sortColumns?: SortColumn[];
  };
}
export interface SimulationsQueryParams {
  limit?: number;
  offset?: number;
  orderDir?: string;
  orderBy?: string;
  search?: string;
}

export interface SimulationsState {
  readonly data: Simulation[];
  readonly loading: boolean;
  readonly mustBeLoading: boolean;
  readonly error: boolean;
  readonly reload: boolean;
  readonly message: string;
  readonly count: number;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly sortColumns: SortColumn[];
  readonly search: string;
}

export interface SimulationsResponse {
  data: {
    rows: Simulation[];
    count: number;
  };
  status: number;
  msg: string;
}

export type SimulationResponse = ApiResponse<Simulation>;

export interface GetAllSimulationsQueryParams
  extends Omit<SimulationsQueryParams, 'orderDir' | 'orderBy'> {
  attributes?: string;
  order?: string;
}
