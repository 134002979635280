import './SubHeaderSimulation.scss';
import React, { useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { eachMonthOfInterval, isSameMonth } from 'date-fns/esm';
import snakeCase from 'lodash/snakeCase';
import edit from '../../assets/images/edit.svg';
import Breadcrumb from '../Breadcrumb';
import usePlanningPvi from '../Planning/usePlanningPvi';
import Scenarios from '../Scenarios';
import paths from '../../routes/paths';
import ButtonApp, { ButtonIcon } from '../ButtonApp';
import { exportToMapMedia, exportToPvi } from '../../helpers/Export';
import { useAppSelector } from '../../hooks';
import useFilters from '../Filters/useFilters';
import { formatBrLocale, stringToDate } from '../../helpers/Dates';
import getModelXlsx from '../../xlsx/MediaMap/MediaMap.model';
import { ExportMapMediaDataRow } from '../../xlsx/MediaMap/MediaMap.types';
import { mapColsXlsx } from '../../xlsx/MediaMap/MediaMap.constants';
import {
  isChannelGlobo,
  attributeMaskOrUnmask,
  removeSpecialChars
} from '../../helpers/Utils';
import usePlanningRedux from '../Planning/usePlanningRedux';
import { TypeTV } from '../../services/shared/Api.service';
import { useFiltersRedux } from '../Filters';
import classesGTM from '../../config';

interface SubHeaderSimulationProps {
  title: string;
  onClickEdit(): void;
}

const SubHeaderSimulation: React.FC<SubHeaderSimulationProps> = ({
  title,
  onClickEdit
}) => {
  const location = useLocation();
  const { simulation, auth } = useAppSelector((state) => state);
  const { transformPlanningToJson } = usePlanningPvi();
  const planning = usePlanningRedux().currentPlanning;
  const filters = useFilters().getCurrentFilters();
  const { currentChannels } = useFiltersRedux(
    filters?.data?.typeTV || TypeTV.OPEN
  );

  const [loadingPi, setLoadingPi] = useState(false);

  const handleExportPvi = () => {
    const pvi = transformPlanningToJson(title);
    exportToPvi(removeSpecialChars(snakeCase(title)), pvi);
  };

  const maskCpfCnpj = (cpfOrCnpj: string) => {
    if (!cpfOrCnpj) return '';

    if (cpfOrCnpj?.length === 11) {
      const cpfWithMask = cpfOrCnpj?.replace(
        /(\d{3})(\d{3})(\d{3})(\d{2})/,
        '$1.$2.$3-$4'
      );
      return cpfWithMask;
    }

    const cnpjWithMask = cpfOrCnpj?.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );

    return cnpjWithMask;
  };

  const channelsGlobo = useMemo(
    () =>
      currentChannels.data
        .filter((curChannel) =>
          filters?.data?.channels.some(
            (ch) => ch.description === curChannel.description
          )
        )
        .filter(isChannelGlobo) || [],
    [filters, currentChannels.data]
  );

  const handleExportPi = () => {
    if (filters?.data?.channels) {
      setLoadingPi(true);

      channelsGlobo.forEach((channel) => {
        const dataByChannel = planning.planningPrograms.filter(
          (program) =>
            program.channel.toLowerCase() === channel.description.toLowerCase()
        );

        let intervalMonths: Date[] = [];

        if (filters.data?.dates.campaign) {
          intervalMonths = eachMonthOfInterval({
            start: new Date(filters.data.dates.campaign.from),
            end: new Date(filters.data.dates.campaign.to)
          });
        }

        const dataSheets = intervalMonths.map((date) => {
          const dataByMonth = dataByChannel.reduce<ExportMapMediaDataRow[]>(
            (acc, data) => {
              const priceOfCompetency = data.pricesByDate.find((priceByDate) =>
                isSameMonth(stringToDate(priceByDate.competency), date)
              );

              return [
                ...acc,
                {
                  initials: data.initials || '',
                  name: data.name,
                  duration: Number(data.secondary.replace('s', '')),
                  price: priceOfCompetency ? priceOfCompetency.price : 0,
                  insertionsByDay: data.insertionsByDay.filter((insertions) =>
                    isSameMonth(new Date(insertions.day), date)
                  ),
                  discount: data.discount
                }
              ];
            },
            []
          );

          const modelAllCells = getModelXlsx({
            userName: auth.profile?.name || '',
            month: date.getMonth(),
            year: date.getFullYear(),
            channel: channel.description,
            market: filters.data?.market,
            dateBuy: filters.data?.dates
              ? new Date(filters.data.dates.buy)
              : new Date(),
            rowsDayOfMonth: dataByMonth
          });

          return {
            modelAllCells,
            sheetName: formatBrLocale(date, 'MMM-yyyy').toUpperCase()
          };
        });

        exportToMapMedia(
          dataSheets,
          mapColsXlsx,
          `Mapa de Midia - ${channel.description}.xlsx`
        );
      });

      setLoadingPi(false);
    }
  };

  return (
    <div className="flex-sb">
      {/* <div className="top-sub"> */}
      <div className="title-version">
        <div className="flex-wrap">
          <h2 className="subheader__title" {...attributeMaskOrUnmask(true)}>
            {title}
          </h2>
          <ButtonIcon
            className="ml-2"
            icon={edit}
            id="btn_edit_simulation"
            onClick={onClickEdit}
          />
        </div>
        {/* <div className="version">
            <span>Versão 1.2</span>
            <img alt="" src={arrowDown} />
          </div> */}
      </div>
      {simulation?.me_cliente && simulation?.cpf_cnpj_cliente && (
        <div>
          <span className="client">
            {simulation?.me_cliente} |{' '}
            {maskCpfCnpj(simulation?.cpf_cnpj_cliente)}
          </span>
        </div>
      )}
      <Breadcrumb
        links={[
          {
            id: 1,
            title: 'Simulações',
            path: paths.home.childrens.simulations
          },
          {
            id: 2,
            title,
            path: `${paths.home.childrens.simulation.path}/${simulation.currentScenery}/${paths.home.childrens.simulation.childrens.filters}`,
            dataClarityMask: true
          }
        ]}
      />
      <div className="top-sub">
        {/* <ButtonExportImport onClick={handleImportButtonClick}>
          importar
          <ButtonExportImport>
        <input
          ref={inputRef}
          accept=".pvi"
          className="top-sub__inputFile"
          onChange={e => handleFileSelected(e)}
          type="file"
        /> */}
        {location.pathname.search(
          paths.home.childrens.simulation.childrens.planning
        ) !== -1 && (
          <>
            <ButtonApp
              classes={{
                button: 'btn--export-import btn--square btn--gray',
                tagManager: classesGTM.planning.exportMapOfMedia
              }}
              disabled={!channelsGlobo.length}
              loading={loadingPi}
              onClick={handleExportPi}
              title="exportar plano"
              visible
            />
            <ButtonApp
              classes={{
                button: 'btn--export-import btn--square btn--gray',
                tagManager: classesGTM.planning.exportPVI
              }}
              onClick={handleExportPvi}
              title="exportar pvi"
            />
          </>
        )}
      </div>
      <Scenarios />
    </div>
  );
};

export default SubHeaderSimulation;
