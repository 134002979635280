import { Reducer } from 'redux';
import cloneDeep from 'lodash/cloneDeep';
import {
  FiltersState,
  FilterAction,
  FiltersTypes,
  Filter,
  FiltersData
} from './Filters.types';

const filtersInitialState: FiltersState = {
  forms: []
};

const reducer: Reducer<FiltersState, FilterAction> = (
  state = filtersInitialState,
  action
): FiltersState => {
  switch (action.type) {
    case FiltersTypes.RESET_FILTERS: {
      return filtersInitialState;
    }
    case FiltersTypes.SET_FILTERS_FORMREF: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      const sceneryForm = state.forms.find(
        (form) => form.sceneryId === action.payload.sceneryId
      );

      const form: Filter = {
        ...sceneryForm,
        ref: action.payload.ref,
        sceneryId: action.payload.sceneryId
      };

      return {
        ...state,
        forms: [...forms, form]
      };
    }
    case FiltersTypes.SAVE_FILTERS_FORM_DATA: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      const sceneryForm = state.forms.find(
        (form) => form.sceneryId === action.payload.sceneryId
      );

      const form: Filter = {
        ...sceneryForm,
        data: action.payload.data,
        error: false,
        erros: undefined,
        sceneryId: action.payload.sceneryId
      };

      return {
        ...state,
        forms: [...forms, form]
      };
    }
    case FiltersTypes.SAVE_PARTIAL_FILTERS_FORM_DATA: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      const sceneryForm = state.forms.find(
        (form) => form.sceneryId === action.payload.sceneryId
      );

      let partialData: Partial<FiltersData> | undefined;
      if (action.payload?.partialData) {
        partialData = action.payload.partialData;
      } else if (sceneryForm?.ref?.current && !sceneryForm?.data) {
        partialData = cloneDeep(sceneryForm?.ref?.current?.getData());
      } else if (sceneryForm?.data) {
        partialData = cloneDeep(sceneryForm.data);
      } else {
        return state;
      }

      const form: Filter = {
        ...sceneryForm,
        partialData,
        sceneryId: action.payload.sceneryId
      };

      return {
        ...state,
        forms: [...forms, form]
      };
    }
    case FiltersTypes.SAVE_FILTERS_FORM_DATA_ERROR: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      const sceneryForm = state.forms.find(
        (form) => form.sceneryId === action.payload.sceneryId
      );

      const form: Filter = {
        ...sceneryForm,
        data: undefined,
        error: true,
        erros: action.payload.erros,
        sceneryId: action.payload.sceneryId
      };

      return {
        ...state,
        forms: [...forms, form]
      };
    }
    case FiltersTypes.CLEAR_FILTERS_FORM_DATA: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      const sceneryForm = state.forms.find(
        (form) => form.sceneryId === action.payload.sceneryId
      );

      const form: Filter = {
        ...sceneryForm,
        data: undefined,
        error: false,
        sceneryId: action.payload.sceneryId
      };

      return {
        ...state,
        forms: [...forms, form]
      };
    }
    case FiltersTypes.REMOVE_FILTERS_SCENERY: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      return {
        ...state,
        forms
      };
    }
    case FiltersTypes.REMOVE_FILTERS_FORM_ERROR: {
      const forms = state.forms.filter(
        (form) => form.sceneryId !== action.payload.sceneryId
      );

      const sceneryForm = state.forms.find(
        (form) => form.sceneryId === action.payload.sceneryId
      );

      const form: Filter = {
        ...sceneryForm,
        error: false,
        erros: undefined,
        sceneryId: action.payload.sceneryId
      };

      return {
        ...state,
        forms: [...forms, form]
      };
    }
    default:
      return state;
  }
};

export default reducer;
