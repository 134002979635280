import { toast } from 'react-toastify';
import {
  CompetitiveFilterMarketsAction,
  CompetitiveFiltersMarketsType,
  MarketResponse
} from './Competitive.markets.types';
import { getMarkets } from '../../../../services/MediaSimulator/Competitive.service';
import { AppThunk } from '../../..';

const LoadCompetitiveFilterMarketsSuccess = (response: MarketResponse) => {
  const payload: CompetitiveFilterMarketsAction = {
    type: CompetitiveFiltersMarketsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadCompetitiveFilterMarketsError = (error: string) => {
  const payload: CompetitiveFilterMarketsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: CompetitiveFiltersMarketsType.ERROR
  };

  return payload;
};

const LoadingCompetitiveMarketsFilter = () => ({
  type: CompetitiveFiltersMarketsType.REQUEST,
  payload: {}
});

const LoadCompetitiveFilterMarketsRequest =
  (): AppThunk => async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveMarketsFilter());
      const response = await getMarkets();
      dispatch(LoadCompetitiveFilterMarketsSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveFilterMarketsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar as praças.');
    }
  };

export default LoadCompetitiveFilterMarketsRequest;
