export interface Program {
  name: string;
}
export interface AudienceFilterProgramsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Program[];
  readonly count: number | null;
}

export interface ProgramResponse {
  data: {
    rows: Program[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersProgramsType {
  REQUEST = '@audience/openTv/programs/load',
  SUCESS = '@audience/openTv/programs/success',
  ERROR = '@audience/openTv/programs/error',
  CLEAR = '@audience/openTv/programs/clear'
}

export interface AudienceFilterProgramsAction {
  type: AudienceFiltersProgramsType;
  payload: Omit<ProgramResponse, 'status'>;
}
