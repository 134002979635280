/* eslint-disable camelcase */
import { format } from 'date-fns';
import usePlanningRedux from './usePlanningRedux';
import useFilters from '../Filters/useFilters';
import { translateForPviTime } from '../../helpers/Dates';
import { PlanningRow } from '../../store/modules/Planning/Planning.types';
import { Channel } from '../../store/modules/Channels/Channels.types';

interface Months {
  month: number;
  year: number;
}

export interface PlanningProgram {
  name: string;
  mnemonic: string | null;
  unitPrice: number | undefined;
  totalInsertions: number;
  insertions: {
    day: string;
    quantity: number;
  }[];
  totalNegotiatedValue: number;
  discount: number;
  secondary: string;
}

interface PVIPlanning {
  id: string;
  month: number;
  year: number;
  channel: string;
  market: string | undefined;
  totalNegotiatedValue: number | undefined;
  totalDiscount: number | undefined;
  totalInsertions: number | undefined;
  totalValueWithouDiscount: number | undefined;
  discountPercentage: number | undefined;
  programs: PlanningProgram[];
  programsBySecondary: { [key: string]: PlanningProgram[] };
}

export interface PVIPlanningData {
  title: string;
  me_filial_agencia: string | undefined;
  nu_cgc_cpf_agencia: string | undefined;
  oi_agencia: string | undefined;
  oi_cliente: string | undefined;
  me_cliente: string | undefined;
  cpf_cnpj_cliente: string | undefined;
  data: PVIPlanning;
}

const usePlanningGAdsPvi = () => {
  const planning = usePlanningRedux().currentPlanning;
  const { getCurrentFilters } = useFilters();

  const filters = getCurrentFilters();

  /* 
  
  Essa função retornará um array de objetos que agrupam o planejamento por mês e por canal.

  Dentro de cada objeto, teremos as seguintes chaves:

  - month: Mês do planejamento
  - year: Ano do planejamento
  - channel: Canal do planejamento (mnemônico)
  - programs: Array de programas do planejamento
    -- name: Nome do programa
    -- mnemonic: Mnemônico do programa
    -- insertions: Array de inserções do programa
      -- day: Dia da inserção
      -- quantity: Quantidade de inserções
  
  */

  const getMonthsInterval = (from: Date, to: Date): Months[] => {
    const monthsArray = [];
    const currentDate = new Date(from);
    const toDate = new Date(to);

    while (currentDate <= toDate) {
      monthsArray.push({
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear()
      });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return monthsArray;
  };

  const generateDataArray = (
    planningPrograms: PlanningRow[],
    channels: Channel[] | undefined,
    months: Months[]
  ) => {
    const dataArray: {
      month: number;
      year: number;
      channel: string;
      programs: PlanningProgram[];
      programsBySecondary: { [key: string]: PlanningProgram[] };
    }[] = [];

    months.forEach((month: any) => {
      channels?.forEach((channel: any) => {
        const programs = planningPrograms
          .filter(
            (program) =>
              program.channel === channel.description &&
              program.insertionsByDay?.filter((insertion) => {
                const date = new Date(insertion.day);
                return (
                  date.getMonth() + 1 === month.month &&
                  date.getFullYear() === month.year &&
                  insertion.value > 0
                );
              }).length > 0
          )
          .map((program) => {
            const insertions = program.insertionsByDay
              .filter((insertion) => {
                const date = new Date(insertion.day);
                return (
                  date.getMonth() + 1 === month.month &&
                  date.getFullYear() === month.year &&
                  insertion.value > 0
                );
              })
              .map((insertion) => {
                return {
                  day: format(new Date(insertion.day), 'dd/MM/yyyy'),
                  quantity: insertion.value
                };
              });

            const totalInsertions = insertions.reduce(
              (acc, insertion) => acc + insertion.quantity,
              0
            );
            const unitPrice =
              program.pricesByDate.find((priceByDate) => {
                const [competencyYear, competencyMonth, competencyDay] =
                  priceByDate.competency.split('-');
                const date = new Date(
                  Number(competencyYear),
                  Number(competencyMonth) - 1,
                  Number(competencyDay)
                );
                return (
                  date.getMonth() + 1 === month.month &&
                  date.getFullYear() === month.year
                );
              }, 0)?.price || 0;

            return {
              name: program.name,
              mnemonic: program.initials,
              unitPrice: unitPrice * 100,
              totalInsertions,
              insertions,
              // TOTAL DE INSERÇÕES * PREÇO UNITÁRIO * DESCONTO (EM PORCENTAGEM) * 100 (PARA CONVERTER PARA CENTAVOS)
              totalNegotiatedValue:
                totalInsertions *
                unitPrice *
                ((100 - program.discount) / 100) *
                100,
              discount: program.discount,
              secondary: program.secondary
            };
          });

        const programBySecondary = programs.reduce(
          (acc: { [key: string]: any[] }, program) => {
            if (program.secondary) {
              if (acc[program.secondary]) {
                acc[program.secondary].push(program);
              } else {
                acc[program.secondary] = [program];
              }
            }
            return acc;
          },
          {}
        );

        if (programs.length > 0) {
          dataArray.push({
            month: month.month,
            year: month.year,
            channel: channel.description,
            programs,
            programsBySecondary: programBySecondary
          });
        }
      });
    });

    return dataArray;
  };

  const transformPlanning = (
    title: string,
    me_filial_agencia: string | undefined,
    nu_cgc_cpf_agencia: string | undefined,
    oi_agencia: string | undefined,
    oi_cliente: string | undefined,
    me_cliente: string | undefined,
    cpf_cnpj_cliente: string | undefined
  ): PVIPlanningData[] | undefined => {
    const currentFilter = getCurrentFilters();

    const { planningPrograms } = planning;
    const channels = filters?.data?.channels;
    const months = getMonthsInterval(
      currentFilter?.data?.dates.campaign.from as Date,
      currentFilter?.data?.dates.campaign.to as Date
    );

    const dataArray = generateDataArray(planningPrograms, channels, months);

    return dataArray.map((data) => {
      const { month, year, channel, programs, programsBySecondary } = data;

      const date = new Date(year, month - 1, 1)?.toString();

      return {
        title: `${title} - ${translateForPviTime(date)} - ${channel}`,
        me_filial_agencia,
        nu_cgc_cpf_agencia,
        oi_agencia,
        oi_cliente,
        me_cliente,
        cpf_cnpj_cliente,
        data: {
          id: planning?.sceneryId,
          month,
          year,
          channel,
          market: currentFilter?.data?.market?.signal,
          totalNegotiatedValue:
            programs.reduce(
              (acc, program) => acc + program.totalNegotiatedValue,
              0
            ) * 100,
          totalDiscount:
            programs.reduce((acc, program) => acc + program.discount, 0) * 100,
          totalInsertions:
            programs.reduce(
              (acc, program) => acc + program.totalInsertions,
              0
            ) * 100,
          totalValueWithouDiscount:
            programs.reduce(
              (acc, program) =>
                acc +
                program.totalInsertions *
                  (program.unitPrice ? program.unitPrice : 0),
              0
            ) * 100,
          discountPercentage:
            (programs.reduce((acc, program) => acc + program.discount, 0) /
              programs.reduce(
                (acc, program) =>
                  acc +
                  program.totalInsertions *
                    (program.unitPrice ? program.unitPrice : 0),
                0
              )) *
            100,
          programs,
          programsBySecondary
        }
      };
    });
  };

  return {
    transformPlanning
  };
};

export default usePlanningGAdsPvi;
