import './StyledTab.scss';
import React, { memo, useState } from 'react';
import Tab, { TabProps } from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import AppSVG from '../SVG/AppSVG';

export interface StyleTabsItemProps {
  id: string | number;
  icon?: string;
  title?: string;
  classes?: {
    tagManager?: string;
  };
  handleClick: (index: number) => void;
}

interface Props extends TabProps {
  index: number;
  tabOptions?: StyleTabsItemProps[];
  prefixId?: string;
}

const Icon = (
  <svg
    className="no-pointer-events"
    height="13"
    viewBox="0 0 3 13"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g data-name="Group 159" id="Group_159" transform="translate(-148 -182)">
      <circle
        cx="1.5"
        cy="1.5"
        data-name="Ellipse 2"
        fill="#92a3a7"
        id="Ellipse_2"
        r="1.5"
        transform="translate(148 182)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        data-name="Ellipse 3"
        fill="#92a3a7"
        id="Ellipse_3"
        r="1.5"
        transform="translate(148 187)"
      />
      <circle
        cx="1.5"
        cy="1.5"
        data-name="Ellipse 4"
        fill="#92a3a7"
        id="Ellipse_4"
        r="1.5"
        transform="translate(148 192)"
      />
    </g>
  </svg>
);

const StyledTab = ({ tabOptions, prefixId, index, ...rest }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (item: StyleTabsItemProps) => {
    handleClose();
    item.handleClick(index);
  };

  const a11yProps = (idx: number) => ({
    'aria-controls': `tabpanel-${idx}`
  });

  return (
    <div className="styled-tab">
      <Tab
        id={`${prefixId}-${index}`}
        {...rest}
        {...a11yProps(index)}
        {...attributeMaskOrUnmask(false)}
      />
      {tabOptions ? (
        <>
          <IconButton
            aria-controls={open ? 'basic-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            aria-label="options"
            className="styled-tab__button"
            onClick={handleOpenMenuClick}
            size="small"
          >
            {Icon}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            classes={{ paper: 'styled-tab__menu' }}
            className="styled-tab__menu"
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            onClose={handleClose}
            open={open}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            {tabOptions.map((item) => (
              <MenuItem
                key={item.id}
                classes={{ root: item.classes?.tagManager }}
                className="styled-tab__menu__item"
                dense
                disableRipple
                onClick={() => handleMenuItemClick(item)}
                {...attributeMaskOrUnmask(false)}
              >
                {item.icon ? (
                  <AppSVG
                    className="mr-3 no-pointer-events"
                    fill="url(#linear-gradient)"
                    loader={
                      <Skeleton className="mr-3" height={24} width={24} />
                    }
                    src={item.icon}
                  />
                ) : null}
                {item.title || null}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}
    </div>
  );
};

export default memo(StyledTab);

StyledTab.defaultProps = {
  prefixId: 'tab',
  tabOptions: undefined
};
