export enum NotificationsTypes {
  OPEN_NOTIFICATIONS_DRAWER = '@notification/OPEN_NOTIFICATIONS_DRAWER',
  CLOSE_NOTIFICATIONS_DRAWER = '@notification/CLOSE_NOTIFICATIONS_DRAWER'
}

export interface Notification {
  title: string;
  mensagem: string;
  icon: string;
}

export interface NotificationsState {
  readonly openDrawer: boolean;
}

export interface NotificationAction {
  type: NotificationsTypes;
}
