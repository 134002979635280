/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import {
  AudienceFilterAudienceAbsolutesAction,
  AudienceFiltersAudienceAbsolutesType,
  AudienceAbsoluteResponse
} from './Audience.audienceAbsolutes.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AppThunk } from '../../../../../..';
import getPayTvAudienceAbsolutesByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.audienceAbsolutes.service';

const LoadAudienceFilterAudienceAbsolutesSuccess = (
  response: AudienceAbsoluteResponse
) => {
  const payload: AudienceFilterAudienceAbsolutesAction = {
    type: AudienceFiltersAudienceAbsolutesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAudienceAbsolutesError = (error: string) => {
  const payload: AudienceFilterAudienceAbsolutesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAudienceAbsolutesType.ERROR
  };

  return payload;
};

const LoadingAudienceAudienceAbsolutesFilter = () => ({
  type: AudienceFiltersAudienceAbsolutesType.REQUEST,
  payload: {}
});

export const ClearAudienceAudienceAbsolutesData = () => ({
  type: AudienceFiltersAudienceAbsolutesType.CLEAR,
  payload: {}
});

export const LoadPayTvAudienceFilterAudienceAbsolutesRequestByChannels =
  ({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    programmers,
    channelGeneres,
    channels,
    channelsNotIn,
    year
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAudienceAbsolutesFilter());
      const response = await getPayTvAudienceAbsolutesByChannels({
        target,
        programmers,
        channelGeneres,
        channels,
        timeRanges,
        weekDays,
        programmingGenders,
        year,
        fields: ['vl_audiencia_abs', 'nm_emissora', 'ds_periodo'],
        table: 'mensal',
        channelsNotIn
      });

      dispatch(LoadAudienceFilterAudienceAbsolutesSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAudienceAbsolutesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de programação.'
      );
    }
  };
