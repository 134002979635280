export enum Events {
  LOGIN_RELOAD = 'loginReload'
}

type Emitter = {
  events: any;
  dispatch(event: Events, data?: any): void;
  subscribe(event: Events, callback: (data?: any) => any): void;
  unsubscribe(event: Events): void;
};

export const eventEmitter: Emitter = {
  events: {},
  dispatch(event: Events, data?: any) {
    if (!this.events[event]) return;
    this.events[event].forEach((callback: (data?: any) => any) =>
      callback(data)
    );
  },
  subscribe(event: Events, callback: (data?: any) => any) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  unsubscribe(event: Events) {
    if (!this.events[event]) return;
    delete this.events[event];
  }
};
