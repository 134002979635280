import { Reducer } from 'redux';
import {
  AudienceFilterAudienceAvaragesAction,
  AudienceFilterAudienceAvaragesState,
  AudienceFiltersAudienceAvaragesType
} from './Audience.audienceAvarages.types';

const audienceAvarageInitialState: AudienceFilterAudienceAvaragesState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterAudienceAvaragesState,
  AudienceFilterAudienceAvaragesAction
> = (
  state = audienceAvarageInitialState,
  action
): AudienceFilterAudienceAvaragesState => {
  switch (action.type) {
    case AudienceFiltersAudienceAvaragesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAudienceAvaragesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAudienceAvaragesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAudienceAvaragesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
