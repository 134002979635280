import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { LineChartData } from './types';
import MetricCard from '../MetricCard';

interface Props {
  data: LineChartData[];
  header: string;
  formatter?: (value: any) => string;
}

const customTooltip = ({ point }: PointTooltipProps, formatter?: any) => {
  const yValue = Number(point.data.y);
  return (
    <div className="collumn-chart__tooltip">
      <div className="collumn-chart__tooltip__title">{point.serieId}</div>
      <div className="collumn-chart__tooltip__text">{`${point.data.x}: ${
        formatter ? formatter(yValue) : yValue
      }`}</div>
    </div>
  );
};

export default function LineChart({ data, header, formatter }: Props) {
  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height="400px"
    >
      <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 60, left: 60 }}
        xScale={{ type: 'point' }}
        colors="#5D43FA"
        yFormat=">-.2%"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        axisLeft={{
          format: (value) => `${formatter ? formatter(value) : value}`
        }}
        tooltip={(point) => customTooltip(point, formatter)}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color', modifiers: [] }}
        pointLabel={(point) =>
          formatter ? formatter(point.data.y) : String(point.data.y)
        }
        pointLabelYOffset={-12}
        enableTouchCrosshair
        useMesh
        enableGridX={false}
        enablePointLabel
        enableArea
        areaBlendMode="linear-gradient"
        //  areaBaselineValue={20}
        crosshairType="bottom"
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 13,
                fontFamily: 'inherit',
                fontWeight: 600,
                fill: '#77808B'
              }
            }
          },
          grid: {
            line: {
              stroke: '#e0e0e0',
              strokeDasharray: '4 4'
            }
          }
        }}
        defs={[
          {
            id: 'gradientA',
            type: 'linearGradient',
            colors: [
              { offset: 50, color: '#5D43FA' },
              { offset: 100, color: '#FFFFFF' }
            ]
          }
        ]}
        fill={[{ match: '*', id: 'gradientA' }]}
      />
    </MetricCard>
  );
}

LineChart.defaultProps = {
  formatter: (value: number) =>
    Number(value).toFixed(1).toString().replace('.', ',')
};
