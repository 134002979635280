import { Reducer } from 'redux';
import {
  AudienceFilterAvarageAudienceByWeekdaysAction,
  AudienceFilterAvarageAudienceByWeekdaysState,
  AudienceFiltersAvarageAudienceByWeekdaysType
} from './Audience.avarageAudienceByWeekdays.types';

const avarageAudienceByWeekdayInitialState: AudienceFilterAvarageAudienceByWeekdaysState =
  {
    error: false,
    loading: false,
    data: [],
    count: 0
  };

const reducer: Reducer<
  AudienceFilterAvarageAudienceByWeekdaysState,
  AudienceFilterAvarageAudienceByWeekdaysAction
> = (
  state = avarageAudienceByWeekdayInitialState,
  action
): AudienceFilterAvarageAudienceByWeekdaysState => {
  switch (action.type) {
    case AudienceFiltersAvarageAudienceByWeekdaysType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageAudienceByWeekdaysType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageAudienceByWeekdaysType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageAudienceByWeekdaysType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
