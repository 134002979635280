import { toast } from 'react-toastify';
import { AppThunk } from '../../..';
import {
  CompetitiveChannelsResponse,
  CompetitiveChannelAction,
  CompetitiveChannelsTypes,
  CompetitiveChannelsQueryParams
} from './Competitive.channels.types';
import getCompetitiveChannels from '../../../../services/Competitive/Competitive.channels.service';

export const LoadCompetitiveChannelsSuccess = (
  response: CompetitiveChannelsResponse
) => {
  const payload: CompetitiveChannelAction = {
    type: CompetitiveChannelsTypes.LOAD_CHANNELS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg
    }
  };

  return payload;
};

const LoadCompetitiveChannelsError = (error: string) => {
  const payload: CompetitiveChannelAction = {
    type: CompetitiveChannelsTypes.LOAD_CHANNELS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingCompetitiveChannels = () => ({
  type: CompetitiveChannelsTypes.LOAD_CHANNELS_REQUEST
});

const LoadCompetitiveChannelsRequest =
  (params: CompetitiveChannelsQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveChannels());

      const response = await getCompetitiveChannels(params);

      dispatch(LoadCompetitiveChannelsSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveChannelsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar os canais.');
    }
  };

export default LoadCompetitiveChannelsRequest;
