export interface AudienceEvolution {
  pc_audiencia: number;
  nm_emissora: string;
  ds_periodo: string;
}
export interface AudienceFilterAudienceEvolutionsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AudienceEvolution[];
  readonly count: number | null;
}

export interface AudienceEvolutionResponse {
  data: {
    rows: AudienceEvolution[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAudienceEvolutionsType {
  REQUEST = '@audience/payTv/byPrograms/audienceEvolutions/load',
  SUCESS = '@audience/payTv/byPrograms/audienceEvolutions/success',
  ERROR = '@audience/payTv/byPrograms/audienceEvolutions/error',
  CLEAR = '@audience/payTv/byPrograms/audienceEvolutions/clear'
}

export interface AudienceFilterAudienceEvolutionsAction {
  type: AudienceFiltersAudienceEvolutionsType;
  payload: Omit<AudienceEvolutionResponse, 'status'>;
}
