import React, { HTMLAttributes } from 'react';
import { attributeMaskOrUnmask } from '../../../../helpers/Utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode | React.ReactNode[];
  dataClarityMask?: boolean;
}

export default function MetricCardHeader({
  dataClarityMask,
  children,
  className = '',
  ...rest
}: Props) {
  return (
    <div
      className={'metric-card__header'.concat(' ', className).trim()}
      {...attributeMaskOrUnmask(dataClarityMask)}
      {...rest}
    >
      {children}
    </div>
  );
}

MetricCardHeader.defaultProps = {
  dataClarityMask: false
};
