import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';

import { LoadPayTvAudienceFilterChannelGeneresRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/ChannelGeneres/Audience.channelGeneres.actions';
import { LoadAudienceFilterChannelsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.actions';
import { IAudienceByProgramsPayTvRadiographyFilterProps } from '../../types';
import { LoadAudienceFilterPeriodsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';
import { LoadPayTvAudienceFilterProgrammingGendersRequestByPrograms } from '../../../../../../../store/modules/AudienceModule/PayTv/byPrograms/ProgrammingGenders/Audience.programmingGenders.actions';

const useAudiencePayTvProgramsRankingFilterLoadData = ({
  programmers,
  channelGeneres,
  channels,
  target,
  timeRange,
  programmingGenders
}: Partial<IAudienceByProgramsPayTvRadiographyFilterProps>) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      audience?.payTv?.programmers?.count !== 0 &&
      audience?.payTv?.programmers?.count !== null &&
      audience?.payTv?.programmers?.loading === false &&
      target &&
      timeRange &&
      audience?.payTv?.channelGeneres?.count === null &&
      !audience?.payTv?.channelGeneres?.error &&
      !audience?.payTv?.channelGeneres?.loading
    ) {
      dispatch(
        LoadPayTvAudienceFilterChannelGeneresRequest({
          target,
          timeRange,
          programmers
        })
      );
    }
  }, [
    audience?.payTv?.programmers?.count,
    audience?.payTv?.programmers?.loading,
    audience?.payTv?.channelGeneres?.count,
    audience?.payTv?.channelGeneres?.error,
    audience?.payTv?.channelGeneres?.loading,
    dispatch,
    programmers,
    target,
    timeRange
  ]);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      channelGeneres?.length &&
      channelGeneres?.length > 0 &&
      audience?.payTv?.channelGeneres?.count !== 0 &&
      audience?.payTv?.channelGeneres?.count !== null &&
      audience?.payTv?.channelGeneres?.loading === false &&
      target &&
      timeRange &&
      audience?.payTv?.channels?.count === null &&
      !audience?.payTv?.channels?.error &&
      !audience?.payTv?.channels?.loading
    ) {
      dispatch(
        LoadAudienceFilterChannelsRequest({
          target,
          timeRange,
          programmers,
          channelGeneres
        })
      );
    }
  }, [
    channelGeneres,
    audience?.payTv?.channelGeneres?.count,
    audience?.payTv?.channelGeneres?.loading,
    audience?.payTv?.channels?.count,
    audience?.payTv?.channels?.error,
    audience?.payTv?.channels?.loading,
    dispatch,
    programmers,
    target,
    timeRange
  ]);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      channelGeneres?.length &&
      channelGeneres?.length > 0 &&
      channels?.length &&
      channels?.length > 0 &&
      audience?.payTv?.channels?.count !== 0 &&
      audience?.payTv?.channels?.count !== null &&
      audience?.payTv?.channels?.loading === false &&
      target &&
      timeRange &&
      audience?.payTv?.byPrograms?.programmingGenders?.count === null &&
      !audience?.payTv?.byPrograms?.programmingGenders?.error &&
      !audience?.payTv?.byPrograms?.programmingGenders?.loading
    ) {
      dispatch(
        LoadPayTvAudienceFilterProgrammingGendersRequestByPrograms({
          target,
          timeRange,
          programmers,
          channelGeneres,
          channels
        })
      );
    }
  }, [
    channels,
    audience?.payTv?.channels?.count,
    audience?.payTv?.channels?.loading,
    audience?.payTv?.byPrograms?.programmingGenders?.count,
    audience?.payTv?.byPrograms?.programmingGenders?.error,
    audience?.payTv?.byPrograms?.programmingGenders?.loading,
    channelGeneres,
    dispatch,
    programmers,
    target,
    timeRange
  ]);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      channelGeneres?.length &&
      channelGeneres?.length > 0 &&
      channels?.length &&
      channels?.length > 0 &&
      programmingGenders?.length &&
      programmingGenders?.length > 0 &&
      audience?.payTv?.byPrograms?.programmingGenders?.count !== 0 &&
      audience?.payTv?.byPrograms?.programmingGenders?.count !== null &&
      audience?.payTv?.byPrograms?.programmingGenders?.loading === false &&
      target &&
      timeRange &&
      audience?.payTv?.periods?.count === null &&
      !audience?.payTv?.periods?.error &&
      !audience?.payTv?.periods?.loading
    ) {
      dispatch(
        LoadAudienceFilterPeriodsRequest({
          target,
          timeRange,
          programmers,
          channelGeneres,
          channels,
          programmingGenders
        })
      );
    }
  }, [
    programmingGenders,
    audience?.payTv?.byPrograms?.programmingGenders?.count,
    audience?.payTv?.byPrograms?.programmingGenders?.loading,
    audience?.payTv?.periods?.count,
    audience?.payTv?.periods?.error,
    audience?.payTv?.periods?.loading,
    channels,
    channelGeneres,
    dispatch,
    programmers,
    target,
    timeRange
  ]);

  const programersOptions = useMemo(() => {
    if (
      audience?.payTv?.programmers?.count &&
      audience?.payTv?.programmers?.count > 0
    ) {
      const option = audience?.payTv?.programmers?.data
        ?.filter((programersOption) => programersOption?.nm_programadora)
        ?.map((programersOption) => {
          return {
            label: programersOption?.nm_programadora,
            value: programersOption?.nm_programadora
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [audience?.payTv?.programmers?.count, audience?.payTv?.programmers.data]);

  const channelGeneresOptions = useMemo(() => {
    if (
      audience?.payTv?.channelGeneres?.count &&
      audience?.payTv?.channelGeneres?.count > 0
    ) {
      const option = audience?.payTv?.channelGeneres?.data
        ?.filter(
          (channelGeneresOption) => channelGeneresOption?.nm_genero_paytv
        )
        ?.map((channelGeneresOption) => {
          return {
            label: channelGeneresOption?.nm_genero_paytv,
            value: channelGeneresOption?.nm_genero_paytv
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [
    audience?.payTv?.channelGeneres?.count,
    audience?.payTv?.channelGeneres.data
  ]);

  const channelsOptions = useMemo(() => {
    if (
      audience?.payTv?.channels?.count &&
      audience?.payTv?.channels?.count > 0
    ) {
      const option = audience?.payTv?.channels?.data
        ?.filter((channelsOption) => channelsOption?.nm_emissora)
        ?.map((channelsOption) => {
          return {
            label: channelsOption?.nm_emissora,
            value: channelsOption?.nm_emissora
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [audience?.payTv?.channels?.count, audience?.payTv?.channels.data]);

  const programmingGendersOptions = useMemo(() => {
    if (
      audience?.payTv?.byPrograms?.programmingGenders?.count &&
      audience?.payTv?.byPrograms?.programmingGenders?.count > 0
    ) {
      const option = audience?.payTv?.byPrograms?.programmingGenders?.data
        ?.filter(
          (programmingGendersOption) => programmingGendersOption?.nm_genero
        )
        ?.map((programmingGendersOption) => {
          return {
            label: programmingGendersOption?.nm_genero,
            value: programmingGendersOption?.nm_genero
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [
    audience?.payTv?.byPrograms?.programmingGenders?.count,
    audience?.payTv?.byPrograms?.programmingGenders.data
  ]);

  const periodOptions = useMemo(() => {
    if (audience?.payTv?.periods.count) {
      return audience?.payTv?.periods.data
        .filter((timeRangeOption) => timeRangeOption.ds_periodo)
        .map((timeRangeOption) => ({
          label: timeRangeOption.ds_periodo,
          value: timeRangeOption.ds_periodo,
          tp_dado: timeRangeOption.tp_dado
        }))
        .sort((a, b) => {
          if (a?.value?.includes('SEM') && !b?.value?.includes('SEM')) {
            return 1;
          }

          if (!a?.value?.includes('SEM') && b?.value?.includes('SEM')) {
            return -1;
          }

          if (a?.value?.includes('TRI') && !b?.value?.includes('TRI')) {
            return 1;
          }

          if (!a?.value?.includes('TRI') && b?.value?.includes('TRI')) {
            return -1;
          }

          return a?.value?.localeCompare(b?.value);
        });
    }

    return [];
  }, [audience?.payTv?.periods?.count, audience?.payTv?.periods.data]);

  return {
    programersOptions,
    channelGeneresOptions,
    channelsOptions,
    programmingGendersOptions,
    periodOptions
  };
};

export default useAudiencePayTvProgramsRankingFilterLoadData;
