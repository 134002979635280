import { toast } from 'react-toastify';
import {
  AudienceFilterTimeRangesAction,
  AudienceFiltersTimeRangesType,
  TimeRangeResponse
} from './Audience.timeRanges.types';
import getTimeRanges from '../../../../../services/Audience/OpenTv/RegularPlazas/Audience.openTv.regularPlazas.timeRanges.service';
import { AppThunk } from '../../../..';

const LoadAudienceFilterTimeRangesSuccess = (response: TimeRangeResponse) => {
  const payload: AudienceFilterTimeRangesAction = {
    type: AudienceFiltersTimeRangesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterTimeRangesError = (error: string) => {
  const payload: AudienceFilterTimeRangesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersTimeRangesType.ERROR
  };

  return payload;
};

const LoadingAudienceTimeRangesFilter = () => ({
  type: AudienceFiltersTimeRangesType.REQUEST,
  payload: {}
});

const LoadAudienceFilterTimeRangesRequest =
  (): AppThunk => async (dispatch) => {
    try {
      dispatch(LoadingAudienceTimeRangesFilter());
      const response = await getTimeRanges();

      dispatch(LoadAudienceFilterTimeRangesSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterTimeRangesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar as faixas horárias disponíveis'
      );
    }
  };

export default LoadAudienceFilterTimeRangesRequest;
