import { useMemo, useState, useCallback } from 'react';
import {
  getMonth,
  getYear,
  subMonths,
  lastDayOfMonth,
  isFirstDayOfMonth,
  addMonths,
  eachMonthOfInterval,
  isWithinInterval,
  lastDayOfYear,
  isLastDayOfMonth
} from 'date-fns';
import omitProps from 'lodash/omit';
import { getStrMonth } from '../../helpers/Dates';
import {
  ClosedPeriodTypes,
  ClosedPeriodTypesEnum
} from '../../store/modules/Filters/Filters.types';
import useFiltersRedux from '../Filters/useFiltersRedux';
import { TypeTV } from '../../services/shared/Api.service';

export default function useClosedPeriod(typeTV: TypeTV | undefined) {
  const [closedPeriodYear, setClosedPeriodYear] = useState<
    number | undefined
  >();
  const [closedPeriodType, setClosedPeriodType] = useState<ClosedPeriodTypes>();
  const [closedPeriodNumOfMonths, setClosedPeriodNumOfMonths] =
    useState<number>(1);

  const { currentBaseAudience } = useFiltersRedux(typeTV);

  const getClosedPeriodNumOfMonths = useCallback((value: ClosedPeriodTypes) => {
    if (value === ClosedPeriodTypesEnum.MONTH) return 1;
    if (value === ClosedPeriodTypesEnum.QUARTER) return 3;
    if (value === ClosedPeriodTypesEnum.SEMESTER) return 6;

    return 12;
  }, []);

  const getMaxYear = useMemo(() => {
    if (currentBaseAudience.dateMax) {
      if (getMonth(currentBaseAudience.dateMax) === 0) {
        const dateMaxTmp = subMonths(currentBaseAudience.dateMax, 1);
        return lastDayOfMonth(new Date(getYear(dateMaxTmp), 11, 1));
      }
      return lastDayOfMonth(
        new Date(getYear(currentBaseAudience.dateMax), 11, 1)
      );
    }
    return undefined;
  }, [currentBaseAudience]);

  const getMinYear = useMemo(() => {
    if (currentBaseAudience.dateMin) {
      if (getMonth(currentBaseAudience.dateMin) === 11) {
        const dateMinTmp = addMonths(currentBaseAudience.dateMin, 1);
        return new Date(getYear(dateMinTmp), 0, 1);
      }
      return new Date(getYear(currentBaseAudience.dateMin), 0, 1);
    }
    return undefined;
  }, [currentBaseAudience.dateMin]);

  const getMaxMonth = useMemo(() => {
    if (closedPeriodYear && currentBaseAudience.dateMax) {
      if (closedPeriodYear > getYear(currentBaseAudience.dateMax)) {
        return undefined;
      }
      const dateTmp = subMonths(currentBaseAudience.dateMax, 1);
      const maxYear = getYear(dateTmp);
      if (closedPeriodYear === maxYear) {
        const maxMonth = getMonth(dateTmp);
        return lastDayOfMonth(new Date(maxYear, maxMonth, 1));
      }

      return lastDayOfMonth(new Date(closedPeriodYear, 11, 1));
    }

    return undefined;
  }, [currentBaseAudience.dateMax, closedPeriodYear]);

  const getMinMonth = useMemo(() => {
    if (closedPeriodYear && currentBaseAudience.dateMin) {
      if (closedPeriodYear < getYear(currentBaseAudience.dateMin)) {
        return undefined;
      }

      let dateTmp = addMonths(currentBaseAudience.dateMin, 1);

      if (isFirstDayOfMonth(currentBaseAudience.dateMin)) {
        dateTmp = currentBaseAudience.dateMin;
      }

      const minYear = getYear(dateTmp);
      if (closedPeriodYear === minYear) {
        const minMonth = getMonth(dateTmp);
        return new Date(closedPeriodYear, minMonth, 1);
      }

      return new Date(closedPeriodYear, 0, 1);
    }

    return undefined;
  }, [currentBaseAudience.dateMin, closedPeriodYear]);

  const getOptionsClosedPeriodYear = (
    maxMonth: Date | null,
    minMonth: Date | null
  ) => {
    if (typeTV === TypeTV.CLOSED && maxMonth && maxMonth.getMonth() < 11) {
      return [];
    }

    if (minMonth && maxMonth) {
      return [
        {
          label: `De ${getStrMonth(minMonth)} a ${getStrMonth(maxMonth)}`,
          value: `${minMonth.toJSON()}|${maxMonth.toJSON()}`
        }
      ];
    }
    return [];
  };

  const getOptionsOthersClosedPeriods = (
    year: number,
    maxMonth: Date | null,
    minMonth: Date | null,
    type:
      | ClosedPeriodTypesEnum.MONTH
      | ClosedPeriodTypesEnum.QUARTER
      | ClosedPeriodTypesEnum.SEMESTER
  ) => {
    if (year && maxMonth && minMonth) {
      if (type === ClosedPeriodTypesEnum.MONTH && typeTV === TypeTV.CLOSED) {
        return [];
      }

      const months = eachMonthOfInterval({
        start: new Date(year, 0, 1),
        end: maxMonth
      });

      const numOfMonths = getClosedPeriodNumOfMonths(type);

      const closedPeriodMonths = [];
      let position = 1;
      for (let index = 0; index < months.length; index += numOfMonths) {
        const period = months.slice(index, numOfMonths + index);
        if (period.length === numOfMonths) {
          const dateFrom = period[0];
          const dateTo = lastDayOfMonth(period[period.length - 1]);
          const interval = { start: minMonth, end: maxMonth };

          const labelPosition =
            type !== ClosedPeriodTypesEnum.MONTH ? `${position}º - ` : '';

          const labelMonths = period.map((p) => getStrMonth(p)).join(', ');

          closedPeriodMonths.push({
            label: `${labelPosition}${labelMonths}`,
            value: `${dateFrom.toJSON()}|${dateTo.toJSON()}`,
            disabled: period.some((p) => !isWithinInterval(p, interval))
          });
          position += 1;
        }
      }

      return closedPeriodMonths
        .filter((p) => !p.disabled)
        .map((p) => omitProps(p, ['disabled']));
    }

    return [];
  };

  const getAllOptionsClosedPeriod = (year: number) => {
    const { dateMax, dateMin } = currentBaseAudience;
    let minDate: Date | null = new Date(year, 0, 1);
    let maxDate: Date | null = lastDayOfYear(new Date(year, 0, 1));

    if (year === dateMax?.getFullYear()) {
      const month = isLastDayOfMonth(dateMax)
        ? dateMax.getMonth()
        : dateMax.getMonth() - 1;
      maxDate = month >= 0 ? lastDayOfMonth(new Date(year, month, 1)) : null;
    }
    if (year === dateMin?.getFullYear()) {
      const month = isFirstDayOfMonth(dateMin)
        ? dateMin.getMonth()
        : dateMin.getMonth() + 1;
      minDate =
        month <= 11
          ? new Date(dateMin.getFullYear(), dateMin.getMonth(), 1)
          : null;
    }

    return {
      [ClosedPeriodTypesEnum.MONTH]: getOptionsOthersClosedPeriods(
        year,
        maxDate,
        minDate,
        ClosedPeriodTypesEnum.MONTH
      ),
      [ClosedPeriodTypesEnum.QUARTER]: getOptionsOthersClosedPeriods(
        year,
        maxDate,
        minDate,
        ClosedPeriodTypesEnum.QUARTER
      ),
      [ClosedPeriodTypesEnum.SEMESTER]: getOptionsOthersClosedPeriods(
        year,
        maxDate,
        minDate,
        ClosedPeriodTypesEnum.SEMESTER
      ),
      [ClosedPeriodTypesEnum.CLOSED_YEAR]: getOptionsClosedPeriodYear(
        maxDate,
        minDate
      )
    };
  };

  return {
    closedPeriodYear,
    closedPeriodType,
    closedPeriodNumOfMonths,
    setClosedPeriodYear,
    setClosedPeriodType,
    setClosedPeriodNumOfMonths,
    getMaxYear,
    getMinYear,
    getMaxMonth,
    getMinMonth,
    getClosedPeriodNumOfMonths,
    getAllOptionsClosedPeriod
  };
}
