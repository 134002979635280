import { Reducer } from 'redux';
import {
  CompetitiveComparativeTypes,
  ComparativeMetricsAction,
  ComparativeMetricsState
} from './Competitive.comparative.types';

const targetInitialState: ComparativeMetricsState = {
  data: undefined,
  loading: false,
  error: false,
  progress: 0
};

const reducer: Reducer<ComparativeMetricsState, ComparativeMetricsAction> = (
  state = targetInitialState,
  action
): ComparativeMetricsState => {
  switch (action.type) {
    case CompetitiveComparativeTypes.COMPARATIVE_METRICS_ERROR: {
      return {
        ...state,
        data: undefined,
        loading: false,
        error: true,
        progress: 0
      };
    }
    case CompetitiveComparativeTypes.CLEAR_COMPARATIVE_METRICS: {
      return {
        ...state,
        data: undefined,
        loading: false,
        error: false,
        progress: 0
      };
    }
    case CompetitiveComparativeTypes.RUN_COMPARATIVE_METRICS_REQUEST:
    case CompetitiveComparativeTypes.STATUS_COMPARATIVE_METRICS_REQUEST: {
      return {
        ...state,
        data: undefined,
        loading: true,
        error: false,
        progress: 0
      };
    }
    case CompetitiveComparativeTypes.STATUS_COMPARATIVE_METRICS_SUCCESS: {
      return {
        ...state,
        data: undefined,
        loading: true,
        error: false,
        progress: action.payload.progress
      };
    }
    case CompetitiveComparativeTypes.RESULT_COMPARATIVE_METRICS_REQUEST: {
      return {
        ...state
      };
    }
    case CompetitiveComparativeTypes.RESULT_COMPARATIVE_METRICS_SUCCESS: {
      return {
        ...state,
        data: action.payload.metrics?.data.row.metricsByChannels,
        loading: false,
        error: false,
        progress: action.payload.progress
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
