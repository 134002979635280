export const reverseStr = (str: string) => str.split('').reverse().join('');

export const applyMask = (
  val: string,
  decimalScale: number,
  mask = '##,###'
) => {
  const valArr = val.split('.');
  if (valArr.length > 1) {
    valArr.push(
      ...Array.from<string>({
        length: decimalScale - valArr[1].length
      }).fill('0')
    );
  }
  const newVal = valArr.length > 1 ? valArr.join('') : val;
  const valStr = reverseStr(newVal.replace(/[^\d]+/gi, ''));

  let result = '';

  for (let x = 0, y = 0; x < mask.length && y < valStr.length; x += 1) {
    if (mask.charAt(x) !== '#') {
      result += mask.charAt(x);
    } else {
      result += valStr.charAt(y);
      y += 1;
    }
  }
  return reverseStr(result);
};
