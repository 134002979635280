import { isArray } from 'lodash';

import { apiV1 } from '../../shared/Api.service';
import { YearResponse } from '../../../store/modules/AudienceModule/PayTv/Years/Audience.years.types';
import { IAudienceByChannelsPayTvComparativeFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const path = '/audience/pay-tv/data';

export default async function getpayTvYears({
  channelGeneres,
  programmers,
  channels,
  programmingGenders,
  target,
  timeRange,
  timeRanges,
  weekDays
}: IAudienceByChannelsPayTvComparativeFilterParams): Promise<YearResponse> {
  const { data } = await apiV1.get<YearResponse>(path, {
    params: {
      fields: ['nr_ano'],
      table: 'anual',
      targets: target?.value ? [target?.value] : undefined,
      timeRanges: timeRange?.value
        ? [timeRange?.value]
        : timeRanges?.map((timeRangeOption) => timeRangeOption.value),
      weekDays: weekDays?.value ? [weekDays?.value] : ['TODOS'],
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : [programmingGenders?.value ?? 'TODOS'],
      channelGenres: channelGeneres?.map((genere) => genere.value),
      channelCompanies: programmers?.map((prog) => prog.value),
      channels: channels?.map((channel) => channel.value),
      programs: ['TODOS']
    }
  });
  return data;
}
