import { PVIPlanningData } from '../../components/Planning/usePlanningGAdsPvi';
import { apiV1 } from '../shared/Api.service';

const path = '/export';

export default async function postExport(data: {
  pvi: PVIPlanningData[] | undefined;
  title: string;
}) {
  const { data: response } = await apiV1.post(path, data);
  return response;
}
