import React from 'react';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  className?: string;
  message: string | React.ReactNode;
  icon?: string;
  dataClarityMask?: boolean;
  visible?: boolean;
}

const InformationWithoutIcon = ({
  message,
  className = '',
  dataClarityMask = false,
  visible = true
}: Props) => {
  return visible ? (
    <span
      className={`text-info ${className}`}
      {...attributeMaskOrUnmask(dataClarityMask)}
    >
      {message}
    </span>
  ) : null;
};

InformationWithoutIcon.defaultProps = {
  className: undefined,
  dataClarityMask: undefined,
  icon: undefined,
  visible: true
};

export default InformationWithoutIcon;
