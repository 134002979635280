import { apiV1 } from '../../shared/Api.service';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AvarageAudienceByFinancialClasseResponse } from '../../../store/modules/AudienceModule/PayTv/byChannels/Radiography/AvarageAudienceByFinancialClasses/Audience.avarageAudienceByFinancialClasses.types';

const path = '/audience/pay-tv/data';

interface IProps extends IAudienceByChannelsRadiographyRequestParams {
  fields: string[];
  table: string;
}

export default async function getPayTvAvarageAudienceByFinancialClassesByChannels({
  targets,
  timeRange,
  weekDays,
  programmingGenders,
  channel,
  channelsNotIn,
  period,
  fields,
  table
}: IProps): Promise<AvarageAudienceByFinancialClasseResponse> {
  const { data } = await apiV1.get<AvarageAudienceByFinancialClasseResponse>(
    path,
    {
      params: {
        fields,
        table,
        targets,
        timeRanges: [timeRange] ?? ['Total dia (06h-30H)'],
        weekDays: weekDays ?? ['TODOS'],
        programmingGenders: programmingGenders ?? ['TODOS'],
        channels: [channel],
        periods: [period],
        programs: ['TODOS'],
        channelsNotIn
      }
    }
  );
  return data;
}
