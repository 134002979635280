import { AppThunk } from '../..';
import getClients from '../../../services/MediaSimulator/Clients.service';
import { ClientsAction, ClientsResponse, ClientsTypes } from './Clients.types';

const LoadClientsSuccess = (response: ClientsResponse): ClientsAction => {
  const payload: ClientsAction = {
    type: ClientsTypes.LOAD_CLIENTS_SUCCESS,
    payload: {
      count: response?.data?.count,
      rows: {
        uniques: response?.data?.rows?.uniques || [],
        all: response?.data?.rows?.all || []
      },
      message: response?.msg
    }
  };

  return payload;
};

const LoadClientsError = (error: string): ClientsAction => {
  const payload: ClientsAction = {
    type: ClientsTypes.LOAD_CLIENTS_ERROR,
    payload: {
      count: 0,
      rows: {
        uniques: [],
        all: []
      },
      message: error
    }
  };

  return payload;
};

const LoadingClients = () => ({
  type: ClientsTypes.LOAD_CLIENTS_REQUEST,
  payload: {}
});

const LoadClientsRequest =
  (nuCgcCpf: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingClients());
      const response = await getClients(nuCgcCpf);
      dispatch(LoadClientsSuccess(response));
    } catch (error: any) {
      dispatch(
        LoadClientsError(
          error instanceof Error ? error.message : error?.msg ?? ''
        )
      );
    }
  };

export default LoadClientsRequest;
