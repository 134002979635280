import LocalStorage, { LocalStorageProps } from '../../helpers/LocalStorage';
import { SimulationScenery } from '../../store/modules/Simulation/Simulation.types';

export interface ScenariosStore {
  currentScenery: LocalStorageProps<number>;
  currentSceneryId: LocalStorageProps<string>;
  scenarios: LocalStorageProps<Pick<SimulationScenery, 'name' | 'uuid'>[]>;
}

export default function scenariosLocalStorage(): ScenariosStore {
  return {
    currentScenery: LocalStorage<number>(`currentScenery`),
    currentSceneryId: LocalStorage<string>(`currentSceneryId`),
    scenarios:
      LocalStorage<Pick<SimulationScenery, 'name' | 'uuid'>[]>(`scenarios`)
  };
}
