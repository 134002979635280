import { AudienceData } from '../Shared/Audience.shared.types';

export enum AudienceNavTypes {
  AUDIENCE_CHANGE_TAB = '@audience/openTv/audience_nav/change_tab',
  AUDIENCE_SAVE_FILTERS_TAB = '@audience/openTv/audience_nav/save_filters_tab'
}

export interface AudienceNavState {
  readonly tab: number | undefined;
  readonly filters: AudienceData | undefined;
}
export interface AudienceNavAction {
  type: AudienceNavTypes;
  payload: {
    tab?: number;
    filters?: AudienceData;
  };
}
