import { Reducer } from 'redux';
import { calcPage } from '../../../helpers/Utils';
import { UsersState, UserAction, UsersTypes } from './Users.types';

const userInitialState: UsersState = {
  data: [],
  loading: false,
  error: false,
  reload: true,
  count: 0,
  message: '',
  page: 0,
  rowsPerPage: 10,
  search: '',
  sortColumns: []
};

const reducer: Reducer<UsersState, UserAction> = (
  state = userInitialState,
  action
): UsersState => {
  switch (action.type) {
    case UsersTypes.LOAD_USERS_SUCCESS: {
      return {
        ...state,
        data: action.payload.users,
        count: action.payload.count || 0,
        message: action.payload.message,
        error: false,
        loading: false,
        reload: false
      };
    }
    case UsersTypes.DELETE_USERS_SUCCESS: {
      const newCount = state.count - 1;
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.message,
        count: state.count - 1,
        page: calcPage(newCount, state.page, state.rowsPerPage),
        reload: true
      };
    }
    case UsersTypes.SAVE_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.message,
        count: state.count + 1,
        reload: true
      };
    }
    case UsersTypes.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        message: action.payload.message,
        loading: false,
        error: false,
        reload: true
      };
    }
    case UsersTypes.LOAD_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
        message: ''
      };
    }
    case UsersTypes.DELETE_USERS_FAILURE:
    case UsersTypes.SAVE_OR_UPDATE_USER_FAILURE:
    case UsersTypes.LOAD_USERS_FAILURE: {
      return {
        ...state,
        error: true,
        message: action.payload.message,
        loading: false,
        reload: false
      };
    }
    case UsersTypes.SET_USERS_PAGE: {
      return {
        ...state,
        page: action.payload.page || 0,
        reload: true
      };
    }
    case UsersTypes.SET_USERS_ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage || 10,
        reload: true
      };
    }
    case UsersTypes.SET_USERS_SORT_COLUMNS: {
      return {
        ...state,
        sortColumns: action.payload.sortColumns || [],
        reload: true
      };
    }
    case UsersTypes.SET_USERS_SEARCH: {
      return {
        ...state,
        search: action.payload.search || '',
        page: 0,
        reload: true
      };
    }
    default:
      return state;
  }
};

export default reducer;
