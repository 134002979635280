import React from 'react';
import './SubHeaderSimulations.scss';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import ButtonApp from '../ButtonApp';
import classesGTM from '../../config';

interface SubHeaderSimulationsProps {
  onClick(): void;
}

const SubHeaderSimulations: React.FC<SubHeaderSimulationsProps> = ({
  onClick
}) => {
  return (
    <div {...attributeMaskOrUnmask(false)}>
      <h2 className="subheader__title">Simulações</h2>
      <ButtonApp
        classes={{ button: `btn--med ${classesGTM.home.newSimulation}` }}
        onClick={onClick}
        title="nova simulação"
      />
    </div>
  );
};

export default SubHeaderSimulations;
