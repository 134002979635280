import './Audience.scss';
import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Audience: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }} className="box">
      <Outlet />
    </Box>
  );
};

export default Audience;
