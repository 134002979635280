export interface WeekDay {
  name: string;
}
export interface AudienceFilterWeekDaysState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: WeekDay[];
  readonly count: number;
}

export interface WeekDayResponse {
  data: {
    rows: WeekDay[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersWeekDaysType {
  REQUEST = '@audience/openTv/weekDays/load',
  SUCESS = '@audience/openTv/weekDays/success',
  ERROR = '@audience/openTv/weekDays/error'
}

export interface AudienceFilterWeekDaysAction {
  type: AudienceFiltersWeekDaysType;
  payload: Omit<WeekDayResponse, 'status'>;
}
