import './Simulations.scss';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SortColumn } from 'react-data-grid';
import Skeleton from '@mui/material/Skeleton';
import SubHeader from '../../components/SubHeader';
import SubHeaderSimulations from '../../components/SubHeaderSimulations';
import { ResetFilters } from '../../store/modules/Filters/Filters.actions';
import paths from '../../routes/paths';
import { ResetSimulation } from '../../store/modules/Simulation/Simulation.actions';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  simualtionLocalStorage,
  ModalSimulation,
  ModalLoadSimulation
} from '../../components/Simulation';
import { filtersLocalStorage } from '../../components/Filters';
import { ResetPlanning } from '../../store/modules/Planning/Planning.actions';
import scenariosLocalStorage from '../../components/Scenarios/Scenarios.LocalStorage';
import GridPagination from '../../components/GridPagination';
import ContainerWrapper from '../../components/ContainerWrapper';
import {
  LoadSimulationsRequest,
  DeleteSimulationRequest,
  SetSimulationsSortColumns,
  SetSimulationsPage,
  SetSimulationsRowsPerPage,
  SetSimulationsSearch,
  ResetAll
} from '../../store/modules/Simulations/Simulations.actions';
import { Simulation } from '../../store/modules/Simulations/Simulations.types';
import { confirmAlertApp } from '../../components/ModalShell';
import useSimulations from './useSimulations';
import Info from '../../assets/images/icon-info.svg';
import useSimulation from '../../components/Simulation/useSimulation';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import AppSVG from '../../components/SVG/AppSVG';
import ModalSimulationDuplicate from '../../components/Simulation/ModalSimulationDuplicate';

const { path, childrens } = paths.home.childrens.simulation;

const DEFAULT_PATH_FILTERS = `${path}/0/${childrens.filters}`;

const Simulations = () => {
  const dispatch = useAppDispatch();
  const { simulations } = useAppSelector((state) => state);
  const {
    loadSimulation,
    duplicateSimulation,
    openModalInfo,
    progressDownload,
    finished,
    errorLoad
  } = useSimulation();

  const [openModal, setOpenModal] = useState(false);
  const [openDuplicate, setOpenDuplicate] = useState(false);
  const [currentSimulation, setCurrentSimulation] = useState<Simulation>();
  const [orderBy, setOrderBy] = useState<string | undefined>(
    simulations.sortColumns.length
      ? simulations.sortColumns[0].columnKey
      : undefined
  );
  const [orderDir, setOrderDir] = useState<string | undefined>(
    simulations.sortColumns.length
      ? simulations.sortColumns[0].direction
      : undefined
  );

  const scenariosStore = scenariosLocalStorage();
  const simulationStore = simualtionLocalStorage();

  const navigate = useNavigate();

  const handleOpenModal = () => {
    const scenarios = scenariosStore.scenarios.get();

    if (scenarios) {
      scenarios.forEach((scenery) => {
        filtersLocalStorage(scenery.uuid).remove();
      });

      scenariosStore.currentScenery.remove();
      scenariosStore.currentSceneryId.remove();
      scenariosStore.scenarios.remove();
    }

    dispatch(ResetSimulation());
    dispatch(ResetFilters());
    dispatch(ResetPlanning());
    simulationStore.remove();
    setOpenModal(true);
  };

  const handleClickInitSimulation = () => {
    setOpenModal(false);
    navigate(DEFAULT_PATH_FILTERS, {
      state: { typeTV: null }
    });
  };

  const handleUpdate = async (row: Simulation) => {
    dispatch(ResetAll());
    simulationStore.set(row);

    await loadSimulation(row);
  };

  const handleCopy = async (row: Simulation) => {
    dispatch(ResetAll());
    setCurrentSimulation(row);
    setOpenDuplicate(true);
  };

  const handleDuplicate = async (nome: string) => {
    dispatch(ResetAll());
    setOpenDuplicate(false);
    if (currentSimulation !== undefined) {
      await duplicateSimulation(currentSimulation, nome);
    }
  };

  const handleDelete = (row: Simulation) => {
    confirmAlertApp({
      title: '',
      message: (
        <>
          <span>Tem certeza que deseja excluir a simulação&nbsp;</span>
          <b {...attributeMaskOrUnmask(true)}>{row.name}</b>
          <span>?</span>
        </>
      ),
      onClickOK: () => {
        if (row.id !== null) {
          dispatch(DeleteSimulationRequest(row.id, row?.isDraft));
        }
      }
    });
  };

  const handleSortColumnsChange = (sortedColumn: SortColumn[]) => {
    setOrderBy(sortedColumn[0]?.columnKey || undefined);
    setOrderDir(sortedColumn[0]?.direction || undefined);
    dispatch(SetSimulationsSortColumns(sortedColumn.slice(-1)));
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(SetSimulationsPage(newPage));
    },
    [dispatch]
  );

  const handleRowsPerPageChange = useCallback(
    (newRows: number) => {
      dispatch(SetSimulationsRowsPerPage(newRows));
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (search: string) => {
      dispatch(SetSimulationsSearch(search));
    },
    [dispatch]
  );

  const { columns } = useSimulations(handleUpdate, handleCopy, handleDelete);

  const transformData = (data: Simulation[]) => {
    const transformedData: Simulation[] = [];

    data.map((item) => {
      if (item.draftUrl) {
        transformedData.push({
          ...item,
          dataUrl: item.draftUrl,
          fileName: item.draftFileName,
          isDraft: true
        });
      }
      if (item.dataUrl) {
        transformedData.push(item);
      }

      return null;
    });

    return transformedData;
  };

  const reloadSimulations = useCallback(() => {
    dispatch(
      LoadSimulationsRequest({
        limit: simulations.rowsPerPage,
        offset:
          simulations.page > 0 ? simulations.page * simulations.rowsPerPage : 0,
        orderBy,
        orderDir,
        search: simulations.search
      })
    );
  }, [
    dispatch,
    simulations.rowsPerPage,
    simulations.page,
    simulations.search,
    orderBy,
    orderDir
  ]);

  useEffect(() => {
    if (!simulations.data.length && simulations.reload) {
      reloadSimulations();
      return;
    }
    if (simulations.reload) reloadSimulations();
  }, [reloadSimulations, simulations.data.length, simulations.reload]);

  const MessageNoRows = simulations.count === 0 && !simulations.loading && (
    <div className="pr-5 pl-5 no-message" {...attributeMaskOrUnmask(false)}>
      <div>
        <AppSVG
          height={25}
          loader={<Skeleton height={25} width={24} />}
          src={Info}
          width={24}
        />
      </div>
      <div>Nenhuma simulação encontrada.</div>
    </div>
  );

  return (
    <div>
      <ModalLoadSimulation
        error={errorLoad}
        finished={finished}
        open={openModalInfo}
        progressDownload={progressDownload}
      />
      <ModalSimulation
        onClickCancel={() => setOpenModal(false)}
        onClickInitSimulation={handleClickInitSimulation}
        open={openModal}
      />
      <ModalSimulationDuplicate
        onClickCancel={() => setOpenDuplicate(false)}
        onClickInitSimulation={handleDuplicate}
        label={currentSimulation?.name ? currentSimulation.name : ''}
        open={openDuplicate}
      />
      <SubHeader>
        <SubHeaderSimulations onClick={handleOpenModal} />
      </SubHeader>
      <ContainerWrapper>
        <GridPagination
          className="simulations__grid--height"
          columns={columns}
          countData={simulations.count}
          defaultColumnOptions={{ resizable: true }}
          hasPagination
          loading={simulations.loading}
          renderers={{ noRowsFallback: MessageNoRows }}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSearchChange={handleSearchChange}
          onSortColumnsChange={handleSortColumnsChange}
          page={simulations.page}
          rows={transformData(simulations.data)}
          rowsPerPage={simulations.rowsPerPage}
          search={simulations.search}
          sortColumns={simulations.sortColumns}
        />
      </ContainerWrapper>
    </div>
  );
};

export default Simulations;
