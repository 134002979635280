import { isArray } from 'lodash';

import { apiV1 } from '../../shared/Api.service';
import { IAudienceByChannelsPayTvComparativeFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AudienceEvolutionResponse } from '../../../store/modules/AudienceModule/PayTv/byChannels/Comparative/AudienceEvolutions/Audience.audienceEvolutions.types';

const path = '/audience/pay-tv/data';

interface IProps extends IAudienceByChannelsPayTvComparativeFilterParams {
  fields: string[];
  table: string;
}

export default async function getPayTvAudienceEvolutionsByChannels({
  target,
  timeRanges,
  weekDays,
  programmingGenders,
  programmers,
  channelGeneres,
  channels,
  channelsNotIn,
  year,
  fields,
  table
}: IProps): Promise<AudienceEvolutionResponse> {
  const { data } = await apiV1.get<AudienceEvolutionResponse>(path, {
    params: {
      fields,
      table,
      targets: target?.value ? [target?.value] : undefined,
      timeRanges: isArray(timeRanges)
        ? timeRanges?.map((timeRange) => timeRange.value)
        : ['Total dia (06h-30H)'],
      weekDays: weekDays?.value ? [weekDays?.value] : ['TODOS'],
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : [programmingGenders?.value ?? 'TODOS'],
      channelCompanies: programmers?.map((prog) => prog.value),
      channelGenres: channelGeneres?.map(
        (channelGenere) => channelGenere.value
      ),
      channels: channels?.map((channel) => channel.value),
      years: [year?.value],
      programs: ['TODOS'],
      average:
        timeRanges && timeRanges?.length > 1
          ? {
              column: fields[0],
              groupBy: fields?.filter((field) => field !== fields[0])
            }
          : undefined,
      channelsNotIn
    }
  });
  return data;
}
