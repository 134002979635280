/* eslint-disable react/jsx-child-element-spacing */
import React from 'react';
import ModalShell from '../ModalShell';
import { SimulationScenery } from '../../store/modules/Simulation/Simulation.types';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  onClickCancel(): void;
  onClickOk(): void;
  scenery: SimulationScenery;
  open: boolean;
}

const ModalScenarios: React.FC<Props> = ({
  onClickCancel,
  onClickOk,
  open = false,
  scenery
}) => {
  const handleClickOk = () => {
    onClickOk();
  };

  const handleClickCancel = () => {
    onClickCancel();
  };

  return (
    <ModalShell
      labelBtnCancel="não"
      labelBtnOK="sim"
      onClickCancel={handleClickCancel}
      onClickOK={handleClickOk}
      open={open}
      title=""
    >
      <span {...attributeMaskOrUnmask(false)}>
        Tem certeza que deseja remover o&nbsp;
        <b>{scenery.name}</b>
        &nbsp; ?
      </span>
    </ModalShell>
  );
};

export default ModalScenarios;
