import { Market } from '../store/modules/Markets/Markets.types';
import { Program } from '../store/modules/Programs/Programs.types';
import { PlanningRow } from '../store/modules/Planning/Planning.types';
import { Channel } from '../store/modules/Channels/Channels.types';

export const TV_ABERTA_GLOBO = 'TV GLOBO';

export const removeSpecialChars = (str: string) =>
  str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const isEqualName =
  (program: Program | PlanningRow) => (arrValue: Program | PlanningRow) => {
    return arrValue.name.toLowerCase() === program.name.toLowerCase();
  };

const isEqualInitials =
  (program: Program | PlanningRow) => (arrValue: Program | PlanningRow) => {
    return arrValue.initials === program.initials;
  };

const isEqualChannel =
  (program: Program | PlanningRow) => (arrValue: Program | PlanningRow) => {
    return arrValue.channel.toLowerCase() === program.channel.toLowerCase();
  };

const isEqualProgramByChannelAndInitials =
  (program: Program | PlanningRow) => (arrValue: Program | PlanningRow) =>
    isEqualChannel(program)(arrValue) && isEqualInitials(program)(arrValue);

const isEqualProgramByChannelAndInitialsAndName =
  (program: Program | PlanningRow) => (arrValue: Program | PlanningRow) =>
    isEqualProgramByChannelAndInitials(program)(arrValue) &&
    isEqualName(program)(arrValue);

export const isEqualProgram =
  <T extends PlanningRow, R extends Program>(program: T) =>
  (arrValue: R, index: number, programs: R[]) => {
    const countEquals = programs.filter(
      (p) => p?.initials === program?.initials
    ).length;

    if (countEquals === 1) {
      return isEqualProgramByChannelAndInitials(program)(arrValue);
    }

    return isEqualProgramByChannelAndInitialsAndName(program)(arrValue);
  };

export const isChannelGlobo = (channel: Channel | undefined) =>
  channel?.channelGlobo === 1;

export const isNotebook = (opt: Market | Channel | undefined) =>
  opt?.notebook === 1;

export const attributeMaskOrUnmask = (isMask: boolean | undefined) => ({
  [`data-clarity-${isMask ? 'mask' : 'unmask'}`]: 'true'
});

export const classMaskOrUnmask = (isMask: boolean | undefined) =>
  isMask ? 'data-mask' : 'data-unmask';

export const calcPage = (
  countData: number,
  currentPage: number | undefined,
  rowsPerPage: number | undefined
) => {
  if (currentPage !== undefined && rowsPerPage !== undefined) {
    return currentPage > 0 &&
      (rowsPerPage > countData ||
        Math.ceil(countData / rowsPerPage) <= currentPage)
      ? currentPage - 1
      : currentPage;
  }

  return 0;
};

export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export default isEqualProgramByChannelAndInitialsAndName;
