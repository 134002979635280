import { AxiosResponse } from 'axios';
import {
  UserAuthResponse,
  UserSignInResponse,
  LoginResponse,
  LogoutResponse
} from '../../store/modules/Auth/Auth.types';
import { api } from '../shared/Api.service';

export async function login(): Promise<LoginResponse> {
  const { data }: AxiosResponse<LoginResponse> = await api.get('/auth/login');

  return data;
}

export async function authenticate(state: string): Promise<UserAuthResponse> {
  const { data } = await api.get(`/auth/authenticate${state}`);

  return data;
}

export async function authenticateWithKeycloak({
  keycloakToken,
  refreshToken
}: {
  keycloakToken: string;
  refreshToken?: string;
}): Promise<UserAuthResponse> {
  const { data } = await api.get('/auth/authenticate/keycloak', {
    headers: {
      Authorization: `Bearer ${keycloakToken}`,
      'x-refresh-token': refreshToken
    }
  });

  return data;
}

export async function getUserInfo(): Promise<UserSignInResponse> {
  const { data }: AxiosResponse<UserSignInResponse> = await api.get(
    '/auth/userInfo'
  );

  return data;
}

export async function logout(): Promise<LogoutResponse> {
  const { data }: AxiosResponse<LogoutResponse> = await api.get('/auth/logout');

  return data;
}

export async function logoutKeycloak(): Promise<LogoutResponse> {
  const { data }: AxiosResponse<LogoutResponse> = await api.get(
    '/auth/logout/keycloak'
  );

  return data;
}
