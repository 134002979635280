/* eslint-disable @typescript-eslint/no-unused-vars */
import './AudiencePayTvProgramsFilters.scss';
import React, { useRef, useEffect, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import { Grid, Typography } from '@mui/material';

import ButtonApp from '../../../../ButtonApp';
import FormContainer from '../../../../Form/FormContainer';
import { OptionsList } from '../../../../Form/types';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import LoadAudienceFilterTargetsRequest from '../../../../../store/modules/AudienceModule/PayTv/Targets/Audience.targets.actions';
import AutocompleteInput from '../../../../Form/AutocompleteInput';
import { listboxSigleProps } from '../../../../Competitive/CompetitiveFilter/constants';
import LoadAudienceFilterTimeRangesRequest from '../../../../../store/modules/AudienceModule/PayTv/TimeRanges/Audience.timeRanges.actions';
import classesGTM from '../../../../../config/classesGTM';
import { IAudienceByProgramsContainerProps } from './types';
import { LoadAudienceFilterProgrammersRequest } from '../../../../../store/modules/AudienceModule/PayTv/Programmers/Audience.programmers.actions';
import { LoadAudienceFilterChannelsRequest } from '../../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.actions';

interface IAudienceByProgramsContainerExtendedProps
  extends IAudienceByProgramsContainerProps {
  showTimeRange?: boolean;
}

const AudienceByPayTvProgramsFilter: React.FC<
  IAudienceByProgramsContainerExtendedProps
> = ({
  target,
  setTarget,
  timeRange,
  setTimeRange,
  newFilter,
  setNewFilter,
  showTimeRange = true
}) => {
  const formRef = useRef<FormHandles>(null);
  const inputsConfig = {
    target: {
      name: 'target',
      defaultValue: 'Total Domicílios',
      state: target
    },
    timeRange: {
      name: 'timeRange',
      defaultValue: 'Total dia (06h-30H)',
      state: timeRange
    }
  };
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    dispatch(LoadAudienceFilterTargetsRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LoadAudienceFilterTimeRangesRequest());
  }, [dispatch]);

  const targetsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.targets.count) {
      return audience?.payTv?.targets.data
        .filter((targetOption) => targetOption.name)
        .map((targetOption) => ({
          label: targetOption.name,
          value: targetOption.name
        }));
    }

    return [];
  }, [audience?.payTv?.targets.data, audience?.payTv?.targets.count]);

  useEffect(() => {
    if (!target) {
      setTarget(
        targetsOptions.find(
          (option) => option.value === inputsConfig.target.defaultValue
        )
      );
    }
  }, [inputsConfig.target, targetsOptions, target, setTarget]);

  const timeRangesOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.timeRanges.count) {
      const order = [
        'Manhã',
        'Tarde',
        'Noite',
        'Madrugada',
        'Total dia (06h-30H)',
        'Total dia (07h-24H)'
      ];

      return audience?.payTv?.timeRanges.data
        .filter((timeRangeOption) => timeRangeOption.name)
        .map((timeRangeOption) => ({
          label: timeRangeOption.name,
          value: timeRangeOption.name
        }))
        .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
    }

    return [];
  }, [audience?.payTv?.timeRanges.data, audience?.payTv?.timeRanges.count]);

  useEffect(() => {
    if (!timeRange && showTimeRange && setTimeRange) {
      setTimeRange(
        timeRangesOptions.find(
          (option) => option.value === inputsConfig.timeRange.defaultValue
        )
      );
    }
  }, [
    inputsConfig.timeRange,
    timeRangesOptions,
    timeRange,
    setTimeRange,
    showTimeRange
  ]);

  useEffect(() => {
    if (target && (timeRange || !showTimeRange) && !ready) {
      if (newFilter !== undefined && setNewFilter) {
        setNewFilter(true);
      }

      if (showTimeRange && timeRange) {
        dispatch(
          LoadAudienceFilterProgrammersRequest({
            target,
            timeRange: showTimeRange && timeRange ? timeRange : undefined
          })
        );
      } else {
        dispatch(
          LoadAudienceFilterChannelsRequest({
            target
          })
        );
      }

      setReady(true);
    }
  }, [
    dispatch,
    target,
    timeRange,
    ready,
    newFilter,
    setNewFilter,
    showTimeRange
  ]);

  useEffect(() => {
    setReady(false);
  }, [showTimeRange]);

  const handleTargetChange = (option: OptionsList) => {
    setTarget(option);
  };

  const handleTimeRangeChange = (option: OptionsList) => {
    if (setTimeRange) setTimeRange(option);
  };

  const handleSendClick = () => {
    if (newFilter !== undefined && setNewFilter) {
      setNewFilter(true);
    }

    if (target && (timeRange || !showTimeRange)) {
      dispatch(
        LoadAudienceFilterProgrammersRequest({
          target,
          timeRange: showTimeRange && timeRange ? timeRange : undefined
        })
      );
    }

    if (target && !showTimeRange) {
      dispatch(
        LoadAudienceFilterChannelsRequest({
          target
        })
      );
    }
  };

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
      sx={{
        width: '100%',
        paddingLeft: '1%',
        paddingRight: '1%',
        marginBottom: '-30px'
      }}
    >
      <Typography variant="h6">Filtros</Typography>
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          alignItems: 'center'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Target"
          name={inputsConfig.target?.name}
          data-testid="target"
          disabled={audience?.payTv?.targets.loading}
          loading={audience?.payTv?.targets.loading}
          ListboxProps={listboxSigleProps}
          options={targetsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={targetsOptions.find(
            (option) => option.value === inputsConfig.target.defaultValue
          )}
          onChange={handleTargetChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Faixa Horária"
          name={inputsConfig.timeRange?.name}
          data-testid="timeRange"
          disabled={audience?.payTv?.timeRanges.loading}
          loading={audience?.payTv?.timeRanges.loading}
          ListboxProps={listboxSigleProps}
          options={timeRangesOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={timeRangesOptions.find(
            (option) => option.value === inputsConfig.timeRange.defaultValue
          )}
          onChange={handleTimeRangeChange}
          visible={showTimeRange}
          sx={{
            width: '20%'
          }}
        />
        <Grid
          item
          display="flex"
          justifyContent="space-between"
          marginTop={1}
          sx={{
            marginLeft: 'auto'
          }}
        >
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--primary',
              loading: 'competitive-filter__buttons--loading',
              tagManager: classesGTM.audience.filter
            }}
            loading={false}
            onClick={handleSendClick}
            title="Filtrar"
          />
          <ButtonApp
            classes={{
              button:
                'competitive-filter__buttons competitive-filter__buttons--secondary',
              tagManager: classesGTM.audience.clearFilter
            }}
            disabled={false}
            onClick={() => formRef.current?.reset() || setReady(false)}
            title="Limpar Filtros"
          />
        </Grid>
      </FormContainer>
    </Grid>
  );
};

AudienceByPayTvProgramsFilter.defaultProps = {
  showTimeRange: true
};

export default AudienceByPayTvProgramsFilter;
