import './InfoCard.scss';
import { Card, CardContent, Skeleton, Typography } from '@mui/material';
import { InfoCardProps } from './types';

type Props = InfoCardProps;

export default function InfoCard({
  header,
  content,
  description,
  color,
  opacity,
  loading,
  metric
}: Props) {
  if (loading) {
    return (
      <Card sx={{ bgcolor: color, opacity }} className="info-card">
        <CardContent
          className="info-card__content"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Skeleton variant="text" width="100%" height={50} />
          <Skeleton variant="text" width="100%" height={20} />
        </CardContent>
      </Card>
    );
  }

  if (!header || !content) {
    return null;
  }

  return (
    <Card sx={{ bgcolor: color, opacity }} className="info-card">
      <CardContent className="info-card__content">
        <Typography
          sx={{
            fontSize: '18px',
            lineHeight: '30px',
            width: '239px',
            marginBottom: '8px'
          }}
          fontWeight={600}
        >
          {header}
        </Typography>
        <div className="metrics">
          <Typography
            variant="h3"
            component="h3"
            maxWidth="239px"
            fontWeight={600}
            sx={{
              fontSize: '32px',
              lineHeight: '30px',
              marginBottom: '8px'
            }}
          >
            {content}
          </Typography>
          <p>{metric}</p>
        </div>
        <Typography>{description}</Typography>
      </CardContent>
    </Card>
  );
}
InfoCard.defaultProps = {
  color: '$blue',
  opacity: 0.1
};
