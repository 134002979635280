import './BarChart.scss';
import React from 'react';
import { ResponsiveBar, BarTooltipProps } from '@nivo/bar';
import { BarChartData } from './types';
import { formatterNumber } from '../../../../helpers/Formatters';

interface Props {
  data: BarChartData[];
}

const maxPadding = 1;

const customTooltip =
  (): React.FC<BarTooltipProps<BarChartData>> => (datum) => {
    const { data } = datum;

    return (
      <div className="donut-chart__tooltip">
        <div className="donut-chart__tooltip__title">{data.id}</div>
        <div className="donut-chart__tooltip__text">{data.label}</div>
      </div>
    );
  };

const calcPaddingBars = (quantityBars: number) => {
  const res = maxPadding - quantityBars * 0.05 - 0.05;

  return res <= 0 ? 0.1 : res;
};

export default function BarChart({ data }: Props) {
  return (
    <ResponsiveBar
      axisBottom={null}
      axisTop={null}
      colors={{ datum: 'data.color' }}
      data={data}
      enableLabel={false}
      indexBy="id"
      keys={['value']}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      gridYValues={8}
      axisLeft={{
        tickValues: 8,
        format: (val: number | string) =>
          formatterNumber(val, { notation: 'compact' })
      }}
      indexScale={{ type: 'band', round: true }}
      margin={{ bottom: 10, left: 60, right: 20, top: 30 }}
      padding={calcPaddingBars(data.length)}
      role="application"
      tooltip={customTooltip()}
      valueScale={{ type: 'linear', nice: true }}
    />
  );
}
