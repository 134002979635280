import React from 'react';
import { Grid } from '@mui/material';

import paths from '../../../../../routes/paths';
import SubMenuTabs from '../../../Tabs/OpenTv/SubMenuTabs';
import IconComparativeChannels from '../../../../../assets/images/channels-comparative-icon.svg';
import IconSummary from '../../../../../assets/images/summary.svg';
import AudiencebyChannelsFilter from './AudienceOpenTvRegularPlazasChannelsFilter';
import { IAudiencebyChannelsContainerProps } from './types';
import classesGTM from '../../../../../config/classesGTM';

const { childrens, path } = paths.home.childrens.audience;

const AudiencebyChannelsContainer: React.FC<
  IAudiencebyChannelsContainerProps
> = ({
  target,
  timeRange,
  weekDays,
  programmingGenders,
  newFilter,
  setNewFilter,
  setTarget,
  setTimeRange,
  setWeekDays,
  setProgrammingGenders,
  children
}) => {
  const additionalProps = {
    target,
    timeRange,
    weekDays,
    programmingGenders,
    newFilter,
    setNewFilter,
    setTarget,
    setTimeRange,
    setWeekDays,
    setProgrammingGenders
  };

  return (
    <>
      <SubMenuTabs
        items={[
          {
            icon: IconComparativeChannels,
            itemId: 1,
            title: 'Comparativo da Concorrência',
            path: `${path}/${childrens.openTv.path}/${childrens.openTv.childrens.regularPlazas.path}/${childrens.openTv.childrens.regularPlazas.childrens.channels.path}/${childrens.openTv.childrens.regularPlazas.childrens.channels.childrens.comparative.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.openTV.channels.comparative
            }
          },
          {
            icon: IconSummary,
            itemId: 2,
            title: 'Radiografia no Período',
            path: `${path}/${childrens.openTv.path}/${childrens.openTv.childrens.regularPlazas.path}/${childrens.openTv.childrens.regularPlazas.childrens.channels.path}/${childrens.openTv.childrens.regularPlazas.childrens.channels.childrens.radiography.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.openTV.channels.radiography
            }
          }
        ]}
      />
      <Grid container height="100%" padding={5} paddingTop={0} spacing={0}>
        <Grid
          className="competitive__container"
          container
          direction="row"
          item
          width="100%"
          border="none"
        >
          <AudiencebyChannelsFilter {...additionalProps} />
        </Grid>
        <Grid container direction="column" item width="calc(100%)">
          <Grid height="calc(100% - 60px)" item width="100%">
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AudiencebyChannelsContainer;
