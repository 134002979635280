import { toast } from 'react-toastify';

import { AppThunk } from '../../../../../..';
import {
  AudienceFilterAvarageAudienceByAgesAction,
  AudienceFiltersAvarageAudienceByAgesType,
  AvarageAudienceByAgeResponse
} from './Audience.avarageAudienceByAges.types';
import getPayTvAvarageAudienceByAgeByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.avarageAudienceByAge.service';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const LoadAudienceFilterAvarageAudienceByAgesSuccess = (
  response: AvarageAudienceByAgeResponse
) => {
  const payload: AudienceFilterAvarageAudienceByAgesAction = {
    type: AudienceFiltersAvarageAudienceByAgesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByAgesError = (error: string) => {
  const payload: AudienceFilterAvarageAudienceByAgesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByAgesType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByAgesFilter = () => ({
  type: AudienceFiltersAvarageAudienceByAgesType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByAgesData = () => ({
  type: AudienceFiltersAvarageAudienceByAgesType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByAgesRequest =
  ({
    targets,
    timeRange,
    weekDays,
    programmingGenders,
    channel,
    period,
    table
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByAgesFilter());
      const response = await getPayTvAvarageAudienceByAgeByChannels({
        targets,
        timeRange,
        weekDays,
        programmingGenders,
        channel,
        period,
        table: table ?? 'anual',
        fields: ['pc_adh', 'nm_target']
      });

      dispatch(LoadAudienceFilterAvarageAudienceByAgesSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByAgesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por idade'
      );
    }
  };
