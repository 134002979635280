import { Reducer } from 'redux';
import {
  AudienceFilterPlazasAction,
  AudienceFilterPlazasState,
  AudienceFiltersPlazasType
} from './Audience.plazas.types';

const plazaInitialState: AudienceFilterPlazasState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterPlazasState,
  AudienceFilterPlazasAction
> = (state = plazaInitialState, action): AudienceFilterPlazasState => {
  switch (action.type) {
    case AudienceFiltersPlazasType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersPlazasType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersPlazasType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
