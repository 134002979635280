import { toast } from 'react-toastify';
import {
  AudienceFilterProgrammersAction,
  AudienceFiltersProgrammersType,
  ProgrammerResponse
} from './Audience.programmers.types';
import { AppThunk } from '../../../..';
import getProgrammers from '../../../../../services/Audience/PayTv/Audience.payTv.programmers.service';
import { IAudienceByChannelsPayTvComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const LoadAudienceFilterProgrammersSuccess = (response: ProgrammerResponse) => {
  const payload: AudienceFilterProgrammersAction = {
    type: AudienceFiltersProgrammersType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterProgrammersError = (error: string) => {
  const payload: AudienceFilterProgrammersAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersProgrammersType.ERROR
  };

  return payload;
};

const LoadingAudienceProgrammersFilter = () => ({
  type: AudienceFiltersProgrammersType.REQUEST,
  payload: {}
});

export const ClearAudienceProgrammersData = () => ({
  type: AudienceFiltersProgrammersType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterProgrammersRequest =
  (props: IAudienceByChannelsPayTvComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceProgrammersFilter());
      const response = await getProgrammers({
        ...props
      });

      dispatch(LoadAudienceFilterProgrammersSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterProgrammersError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar as programadoras.'
      );
    }
  };
