import { SortColumn } from 'react-data-grid';
import { Price } from '../../../services/MediaSimulator/Price.service';
import { Program } from '../Programs/Programs.types';

export enum PlanningTypes {
  SET_HIDE_DAYS_OF_WEEK = '@planning/SET_HIDE_DAYS_OF_WEEK',
  SET_PLANNING_PROGRAMS = '@planning/SET_PLANNING_PROGRAMS',
  ADD_PLANNING_PROGRAM = '@planning/ADD_PLANNING_PROGRAM',
  COPY_PLANNING_PROGRAM = '@planning/COPY_PLANNING_PROGRAM',
  SELECT_TARGET_PLANNING = '@planning/SELECT_TARGET_PLANNING',
  CLEAR_PLANNING_PROGRAMS = '@planning/CLEAR_PLANNING_PROGRAMS',
  RESET_PLANNING = '@planning/RESET_PLANNING',
  SET_LOADING_PRICE_PROGRAM = '@planning/SET_LOADING_PRICE_PROGRAM',
  REMOVE_PLANNING_PROGRAMS_SCENERY = '@planning/REMOVE_PLANNING_PROGRAMS_SCENERY',
  SET_TOTALIZERS = '@planning/SET_TOTALIZERS',
  SET_PLANNING_SORTED_COLUMNS = '@planning/SET_PLANNING_SORTED_COLUMNS'
}

export interface DateExibitionsInsertion {
  day: string;
  insertions: number;
  id: string;
}

export type PlanningInsertionByDay = {
  day: Date | string;
  value: number;
  editable: boolean;
};

export type MetricsByCompetency = {
  competency: Date;
  cpm: number;
};
export interface PlanningRow extends Omit<Program, 'type'> {
  type: 'planningRow';
  id: string;
  format: string;
  insertions: number;
  secondary: string;
  totalImpacts: number;
  amount: number;
  discount: number;
  negotiatedValue: number;
  insertionsByDay: PlanningInsertionByDay[];
  metricsByCompetency: MetricsByCompetency[];
  addAt: Date | string;
}

export interface Planning {
  hideDaysOfWeek: boolean;
  planningPrograms: PlanningRow[];
  target: string;
  message?: string;
  error?: boolean;
  loading: boolean;
  sceneryId: string;
  totalAmount: number;
  totalNegotiated: number;
  avgDiscount: number;
  totalInsertions: number;
  totalGrpOrTrp: number;
  sortedColumns: SortColumn[];
}

interface PlanningPayload extends Omit<Partial<Planning>, 'scenery'> {
  program?: Program;
  planningRow?: PlanningRow;
  planningProgramPrice?: Price;
  insertionsByDay?: PlanningInsertionByDay[];
  message?: string;
  error?: boolean;
  rowId?: string;
  sortedColumns?: SortColumn[];
}

export interface PlanningAction {
  type: PlanningTypes;
  payload: PlanningPayload;
}

export type PlanningState = Readonly<Planning>;
