import './Planning.scss';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { EditorProps } from 'react-data-grid';
import {
  format,
  isLastDayOfMonth,
  eachDayOfInterval,
  isFirstDayOfMonth,
  isSameMonth
} from 'date-fns';
import cloneDeep from 'lodash/cloneDeep';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import brLocale from 'date-fns/locale/pt-BR';
import capitalize from 'lodash/capitalize';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import getValueProp from 'lodash/get';
import ActionColumn from '../ActionColumn';
import IconMinus from '../../assets/images/IconMinus.svg';
import Copy from '../../assets/images/copy.svg';
import { sortNumber, sortString } from '../../helpers/Sort';
import TextEditor from '../Editors/TextEditor';
import { Filter } from '../../store/modules/Filters/Filters.types';
import Discount from '../Editors/Discount';
import GridPaginationHeader from '../GridPagination/GridPaginationHeader';
import { useAppDispatch, useAppMediaQuery } from '../../hooks';
import { formatterPercent, formatterNumber } from '../../helpers/Formatters';
import {
  RemovePlanningProgram,
  RecalculateMetrics,
  CopyPlanningProgram,
  SetLoading
} from '../../store/modules/Planning/Planning.actions';
import {
  PlanningInsertionByDay,
  PlanningRow
} from '../../store/modules/Planning/Planning.types';
import useFilters from '../Filters/useFilters';
import { GridColumn } from '../GridPagination/types';
import SelectInput from '../Form/SelectInput';
import { OptionsList } from '../Form/types';
import usePlanningRedux from './usePlanningRedux';
import paths from '../../routes/paths';
import useAppSelector from '../../hooks/useAppSelector';
import { stringToDate } from '../../helpers/Dates';
import getProgramPrice, {
  Price,
  PriceRequest
} from '../../services/MediaSimulator/Price.service';
import { ApiErrorResponse, TypeTV } from '../../services/shared/Api.service';
import { calcMetricsOfInsertions } from '../../helpers/CalcsSimulation';
import { classMaskOrUnmask, isChannelGlobo } from '../../helpers/Utils';
import classesGTM from '../../config';

interface UpdateRow {
  row?: PlanningRow;
  confirm?: (row: PlanningRow, commit?: boolean) => void;
}

const usePlanning = (
  filters: Filter | undefined,
  target: string,
  hideDaysOfWeek: boolean,
  setOpenModal: (open: boolean) => void
) => {
  const mediaQueryUpLg = useAppMediaQuery('lg');
  const mediaQueryUpMd = useAppMediaQuery('md');
  const { getCurrentFilters } = useFilters();
  const currentFilter = getCurrentFilters();
  const planning = usePlanningRedux().currentPlanning;
  const { simulation, formats, secondaries, channels } = useAppSelector(
    (state) => state
  );
  const [actualProgram, setActualProgram] = useState('');

  const [weekDays, setWeekDays] = useState<Date[]>([]);
  const [indexTarget, setIndexTarget] = useState(
    filters?.data?.targets.indexOf(target) ?? -1
  );
  const [dateExibitionInserts, setDateExibitionInserts] = useState<string[]>(
    []
  );

  const optionsSecondary: OptionsList[] = useMemo(() => {
    const result = secondaries.data.map((secondary) => {
      return {
        label: secondary.toString().concat('s'),
        value: secondary
      };
    });
    return result;
  }, [secondaries]);

  const rowUpdated = useRef<UpdateRow>({ row: undefined, confirm: undefined });

  const listFormats = useMemo(() => {
    const formatList: OptionsList[] = [];
    let formatsByTv = [];
    if (currentFilter?.data?.typeTV === TypeTV.OPEN) {
      formatsByTv = formats.data.filter((f) => f.tvAberta === true);
    } else {
      formatsByTv = formats.data.filter((f) => f.tvPaga === true);
    }

    formatsByTv?.forEach((f) => {
      if (
        !formatList.find(
          (fl) => fl.value.toLocaleLowerCase() === f.name.toLowerCase()
        )
      )
        formatList.push({
          label: capitalize(f.name.toLowerCase()),
          value: f.name.toLowerCase()
        });
    });
    return formatList.filter((f) => f.value === 'comercial break');
  }, [formats.data, currentFilter]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentFilter?.data?.dates.campaign) {
      const dateFrom = currentFilter.data.dates.campaign.from;
      const dateTo = currentFilter.data.dates.campaign.to;

      const params = {
        start: dateFrom instanceof Date ? dateFrom : stringToDate(dateFrom),
        end: dateTo instanceof Date ? dateTo : stringToDate(dateTo)
      };

      setWeekDays(eachDayOfInterval(params));
    }
  }, [currentFilter]);

  useEffect(() => {
    if (target) {
      setIndexTarget((state) => {
        if (filters?.data) {
          const newIndex = filters.data.targets.indexOf(target);
          if (state !== newIndex) return newIndex;
        }
        return state;
      });
    }
  }, [filters, target]);

  const competenciesPrice = useMemo(() => {
    if (planning.planningPrograms.length) {
      return planning.planningPrograms[0].pricesByDate.map((price) =>
        stringToDate(price.competency)
      );
    }
    return [];
  }, [planning.planningPrograms]);

  useEffect(() => {
    dispatch(RecalculateMetrics());
  }, [dispatch, indexTarget]);

  const handleRemove = useCallback(
    (row: PlanningRow) => {
      dispatch(RemovePlanningProgram(row));

      if (planning.planningPrograms.length - 1 === 0) {
        const { path, childrens } = paths.home.childrens.simulation;
        navigate(`${path}/${simulation.currentScenery}/${childrens.ranking}`);
      }
    },
    [
      dispatch,
      navigate,
      planning.planningPrograms.length,
      simulation.currentScenery
    ]
  );

  const handleCopy = useCallback(
    (row: PlanningRow) => {
      dispatch(CopyPlanningProgram(cloneDeep(row)));
    },
    [dispatch]
  );

  const handleChangeInsertion = useCallback(
    (
      row: PlanningRow,
      oldRow: PlanningRow,
      onRowChange: EditorProps<PlanningRow>['onRowChange']
    ) => {
      const totalInsertions = row.insertionsByDay.reduce(
        (acc, inserts) => acc + inserts.value,
        0
      );

      const metrics = calcMetricsOfInsertions(
        row.metricsPerTarget,
        row.pricesByDate,
        row.insertionsByDay,
        row.discount,
        target
      );

      onRowChange(
        {
          ...row,
          insertions: totalInsertions,
          totalImpacts: metrics.totalImpacts,
          metricsByCompetency: metrics.metricsByCompetency
        },
        true
      );
    },
    [target]
  );

  const getPriceAndCpmPerMonthColumn = useCallback(
    (competency: Date, idx: number): GridColumn<PlanningRow>[] => {
      const competencyFormat = format(competency, 'MM/yyyy');

      const cellClasses = `${
        !hideDaysOfWeek ? 'text-right grid-col__separate--none' : 'text-right'
      } ${classMaskOrUnmask(false)}`;

      return [
        {
          key: `pricesByDate[${idx}].price`,
          name: `Valor Unitário R$ (${competencyFormat})`,
          hideColumn: false,
          formatter: ({ row }) => {
            return (
              <>
                {formatterNumber(row.pricesByDate[idx].price, {
                  fractionDigits: 1
                })}
              </>
            );
          },
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'Valor Unitário R$',
              subtitle: competencyFormat,
              ...props
            }),
          cellClass: cellClasses,
          headerCellClass: !hideDaysOfWeek ? 'grid-col__separate--none' : '',
          width: 150
        },
        {
          key: `metricsByCompetency[${idx}].cpm`,
          name: `CPM (${competencyFormat})`,
          hideColumn: false,
          formatter: ({ row }) => {
            const cpmCompetency = row.metricsByCompetency.find((met) =>
              isSameMonth(met.competency, competency)
            );

            return (
              <>
                {formatterNumber(cpmCompetency?.cpm || 0, {
                  fractionDigits: 1
                })}
              </>
            );
          },
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'right',
              title: 'CPM',
              subtitle: competencyFormat,
              ...props
            }),
          cellClass: cellClasses,
          headerCellClass: !hideDaysOfWeek ? 'grid-col__separate--none' : '',
          width: 100
        }
      ];
    },
    [hideDaysOfWeek]
  );

  const getWeekDaysByAudienceRange = useCallback(
    (
      accWeekDay: GridColumn<PlanningRow>[],
      weekDay: Date,
      idx: number,
      arrWeekDays: Date[]
    ): GridColumn<PlanningRow>[] => {
      const lastIndex = idx === arrWeekDays.length - 1;
      const titleCol = capitalize(
        format(weekDay, 'EEEEEE', { locale: brLocale })
      );
      const subtitleCol = format(weekDay, 'dd/MM/yyyy');

      const competencyIndex = competenciesPrice.findIndex((competency) =>
        isSameMonth(competency, weekDay)
      );

      if (competencyIndex !== -1 && (idx === 0 || isFirstDayOfMonth(weekDay))) {
        accWeekDay.push(
          ...getPriceAndCpmPerMonthColumn(
            competenciesPrice[competencyIndex],
            competencyIndex
          )
        );
      }

      const classes = `${
        !lastIndex && !isLastDayOfMonth(weekDay)
          ? 'grid-col__separate--none'
          : ''
      } ${classMaskOrUnmask(false)}`;

      return [
        ...accWeekDay,
        {
          key: `insertionsByDay[${idx}].value`,
          name: `${titleCol} (${subtitleCol})`,
          hideColumn: hideDaysOfWeek,
          formatter: ({ row }) => {
            if (row.insertionsByDay[idx].editable) {
              return (
                <div className="text-row cursor-pointer">
                  {row.insertionsByDay[idx].value}
                </div>
              );
            }
            return <div className="text-row-disabled">-</div>;
          },
          editorOptions: { editOnClick: true },
          editable: (row) => row.insertionsByDay[idx].editable,
          headerRenderer: (props) =>
            GridPaginationHeader({
              alignment: 'left',
              title: titleCol,
              subtitle: subtitleCol,
              ...props
            }),
          cellClass: classes,
          headerCellClass: classes,
          editor: (props) => (
            <TextEditor
              maxLength={3}
              onChange={handleChangeInsertion}
              {...props}
            />
          ),
          width: 85
        }
      ];
    },
    [
      hideDaysOfWeek,
      competenciesPrice,
      getPriceAndCpmPerMonthColumn,
      handleChangeInsertion
    ]
  );

  const getFormatData = useCallback(
    (value: string, secondary: number) => {
      const typeTV = currentFilter?.data?.typeTV;

      const formatos = formats.data.filter(
        (f) =>
          f.name.toLowerCase() === value.toLowerCase() &&
          f.secundagem === secondary
      );
      if (typeTV === TypeTV.OPEN) {
        return formatos.find((f) => f.tvAberta);
      }
      return formatos.find((f) => f.tvPaga);
    },
    [formats.data, currentFilter]
  );

  const getCoefficientOfFormat = useCallback(
    (formatStr: string, secondary: number) => {
      const formatData = getFormatData(formatStr, secondary);

      if (!formatData || formatStr.toLowerCase() === 'comercial break')
        return 1;

      const typeTV = currentFilter?.data?.typeTV;
      const result =
        typeTV === TypeTV.OPEN
          ? formatData.coeficienteTvAberta || 1
          : formatData.coeficienteTvPaga || 1;

      return result;
    },
    [currentFilter, getFormatData]
  );

  const getPricesByDateOfFormat = useCallback(
    (pricesByDate: Price['pricesByDate'], formatStr: string) =>
      pricesByDate.map((pd) => ({
        ...pd,
        secondary: `${pd.secondary}s`,
        price: pd.price * getCoefficientOfFormat(formatStr, pd.secondary)
      })),
    [getCoefficientOfFormat]
  );

  const handleChangeFormat = useCallback(
    async (value: string, { row, onRowChange }: EditorProps<PlanningRow>) => {
      onRowChange({ ...row, format: value }, true);
    },
    []
  );

  const handleChange = useCallback(
    async (value: number, { row, onRowChange }: EditorProps<PlanningRow>) => {
      if (currentFilter?.data) {
        const channel = currentFilter.data.channels.find(
          (ch) => ch.description.toUpperCase() === row.channel.toUpperCase()
        );

        if (channel) {
          try {
            dispatch(SetLoading(simulation.currentSceneryId, true));

            const data: PriceRequest = {
              secondary: value || 30,
              dates: currentFilter.data.dates,
              mnemonico: row.initials || '',
              name: row.name,
              market: currentFilter.data.market,
              channel,
              typeTV: currentFilter.data.typeTV
            };

            const response = await getProgramPrice(data);
            if (response.status !== 200) {
              throw new Error(response.msg);
            }

            const [result] = response.data.rows;

            const newPrices = getPricesByDateOfFormat(
              result.pricesByDate,
              row.format
            );

            const metrics = calcMetricsOfInsertions(
              row.metricsPerTarget,
              newPrices,
              row.insertionsByDay,
              row.discount,
              target
            );

            dispatch(SetLoading(simulation.currentSceneryId, false));

            onRowChange(
              {
                ...row,
                secondary: `${value}s`,
                pricesByDate: newPrices,
                metricsByCompetency: metrics.metricsByCompetency,
                totalImpacts: metrics.totalImpacts
              },
              true
            );
            toast.success('Preço atualizado com sucesso.');
          } catch (error) {
            dispatch(SetLoading(simulation.currentSceneryId, false));

            const message =
              error instanceof Error
                ? error.message
                : (error as ApiErrorResponse)?.msg ||
                  'Ops, houve um problema ao realizar a busca do preço.';

            toast.error(message);
          }
        }
      }
    },
    [
      currentFilter,
      dispatch,
      simulation.currentSceneryId,
      target,
      getPricesByDateOfFormat
    ]
  );

  const updateInsertions = (
    planningInsertionDays: PlanningInsertionByDay[],
    totalInsertions: number
  ): PlanningInsertionByDay[] => {
    let keepUpdating = true;
    let index = 0;
    let remainingInserts = totalInsertions;
    const copyPlanningInsertionDays = cloneDeep(planningInsertionDays);

    if (copyPlanningInsertionDays.some((ins) => ins.editable)) {
      while (keepUpdating) {
        if (index >= planningInsertionDays.length) {
          index = 0;
        }
        if (remainingInserts > 0) {
          if (copyPlanningInsertionDays[index].editable) {
            copyPlanningInsertionDays[index].value += 1;
            remainingInserts -= 1;
          }
          index += 1;
        } else {
          keepUpdating = false;
        }
      }
    }

    return copyPlanningInsertionDays;
  };

  const handleConfirmInsertion = useCallback(() => {
    if (rowUpdated.current.confirm && rowUpdated.current.row) {
      const { row } = rowUpdated.current;
      const newInsertionsByDay = updateInsertions(
        row.insertionsByDay.map((insertion) => ({
          ...insertion,
          value: 0
        })),
        row.insertions
      );

      const metrics = calcMetricsOfInsertions(
        row.metricsPerTarget,
        row.pricesByDate,
        newInsertionsByDay,
        row.discount,
        target
      );

      const newRow: PlanningRow = {
        ...row,
        insertionsByDay: newInsertionsByDay,
        totalImpacts: metrics.totalImpacts,
        metricsByCompetency: metrics.metricsByCompetency
      };

      rowUpdated.current.confirm(newRow, true);
    }
  }, [target]);

  const handleDiscount = useCallback(
    (
      row: PlanningRow,
      oldRow: PlanningRow,
      onRowChange: EditorProps<PlanningRow>['onRowChange']
    ) => {
      const metrics = calcMetricsOfInsertions(
        row.metricsPerTarget,
        row.pricesByDate,
        row.insertionsByDay,
        row.discount,
        target
      );

      onRowChange(
        {
          ...row,
          metricsByCompetency: metrics.metricsByCompetency
        },
        true
      );
    },
    [target]
  );

  const handleRowChange = useCallback(
    (
      row: PlanningRow,
      oldRow: PlanningRow,
      onRowChange: EditorProps<PlanningRow>['onRowChange']
    ) => {
      rowUpdated.current.row = row;
      rowUpdated.current.confirm = onRowChange;

      if (oldRow.insertions > 0) {
        setOpenModal(true);
        setActualProgram(row.name);
        return;
      }

      handleConfirmInsertion();
    },
    [setOpenModal, handleConfirmInsertion]
  );

  const getSecondaryValue = useCallback(
    (label: string) => {
      return optionsSecondary.find((opt) => opt.label === label)?.value;
    },
    [optionsSecondary]
  );

  const getDataChannel = useCallback(
    (channel: string) => {
      const channelsByTypeTv =
        currentFilter?.data?.typeTV === TypeTV.OPEN
          ? channels.openedTv
          : channels.closedTv;

      return channelsByTypeTv.data.find(
        (ch) => ch.description.toLowerCase() === channel.toLowerCase()
      );
    },
    [currentFilter, channels.closedTv, channels.openedTv]
  );

  const getSecondaries = useCallback(
    (channel: string): OptionsList[] => {
      const channelObj = getDataChannel(channel);

      if (channelObj) {
        return !isChannelGlobo(channelObj)
          ? optionsSecondary.filter((s) => +s.value !== 90 && +s.value !== 120)
          : optionsSecondary;
      }

      return optionsSecondary;
    },
    [optionsSecondary, getDataChannel]
  );

  const columns: GridColumn<PlanningRow>[] = useMemo(() => {
    const weekDaysColumns = weekDays.reduce<GridColumn<PlanningRow>[]>(
      getWeekDaysByAudienceRange,
      []
    );
    return [
      {
        key: 'action',
        name: 'Ações',
        width: 12,
        frozen: mediaQueryUpMd,
        formatter: ({ row }) => {
          return (
            <>
              <ActionColumn
                classes={{ tagManager: classesGTM.planning.removePlanning }}
                icon={IconMinus}
                onClick={() => handleRemove(row)}
              />
              <ActionColumn
                classes={{
                  tagManager: classesGTM.planning.duplicateProgram
                }}
                icon={Copy}
                onClick={() => handleCopy(row)}
              />
            </>
          );
        },
        cellClass: `${classMaskOrUnmask(false)} action_column__container`,
        headerCellClass: classMaskOrUnmask(false)
      },
      {
        key: 'channel',
        name: 'Canal',
        width: 150,
        frozen: mediaQueryUpMd,
        sortable: true,
        resizable: true,
        cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
        headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`
      },
      {
        key: 'initials',
        name: 'Sigla',
        frozen: mediaQueryUpMd,
        sortable: true,
        headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
        cellClass: (row: PlanningRow) =>
          `${
            row.initials
              ? 'grid-col__separate--none'
              : 'grid-col__separate--none text-center'
          } ${classMaskOrUnmask(false)}`,
        formatter: ({ row }) => <>{row?.initials ? row.initials : '-'}</>
      },
      {
        key: 'name',
        name: 'Programa',
        sortable: true,
        width: 300,
        frozen: mediaQueryUpLg,
        cellClass: classMaskOrUnmask(false),
        headerCellClass: classMaskOrUnmask(false)
      },
      {
        key: 'format',
        name: 'Formato',
        width: 150,
        cellClass: classMaskOrUnmask(false),
        headerCellClass: classMaskOrUnmask(false),
        formatter: ({ row }) => (
          <div
            className={
              isChannelGlobo(getDataChannel(row.channel)) && !row.format
                ? 'text-row'
                : 'text-row-disabled'
            }
          >
            <div className="text_capitalize">{row.format}</div>
          </div>
        ),
        editorOptions: { editOnClick: true },
        editor: (props) => {
          return (
            <div className="select-row">
              <SelectInput
                autoFocus
                className="select-input__grid text_capitalize"
                defaultValue={props.row.format}
                name="format"
                onChange={(event: SelectChangeEvent) => {
                  handleChangeFormat(event.target.value, props);
                }}
                options={listFormats}
              />
            </div>
          );
        },
        editable: (row) =>
          isChannelGlobo(getDataChannel(row.channel)) && !row.format
      },
      {
        key: 'secondary',
        name: 'Secundagem',
        width: 120,
        cellClass: classMaskOrUnmask(false),
        headerCellClass: classMaskOrUnmask(false),
        formatter: ({ row }) => (
          <div className="text-row cursor-pointer">{row.secondary}</div>
        ),
        editorOptions: { editOnClick: true },
        editor: (props) => (
          <div className="select-row">
            <SelectInput
              autoFocus
              className="select-input__grid"
              defaultValue={getSecondaryValue(props.row.secondary) || 30}
              name="secundagem"
              onChange={(e: SelectChangeEvent) => {
                handleChange(Number(e.target.value), props);
              }}
              options={getSecondaries(props.row.channel) as OptionsList[]}
            />
          </div>
        )
      },
      {
        key: `metricsPerTarget[${indexTarget}].avgAudiencePercent`,
        name: 'Méd. aud%',
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Méd.',
            subtitle: 'aud%',
            ...props
          }),
        exportXlsxFormat: '0.00000%',
        formatter: ({ row }) => {
          return (
            <>
              {formatterPercent(
                row.metricsPerTarget[indexTarget].avgAudiencePercent,
                { fractionDigits: 5 }
              )}
            </>
          );
        },
        sortable: true,
        width: 120,
        cellClass: `${classMaskOrUnmask(false)} text-right`
      },
      {
        key: 'totalImpacts',
        name: 'Méd. impactos',
        sortable: true,
        width: 150,
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Méd.',
            subtitle: 'impactos',
            ...props
          }),
        formatter: ({ row }) => {
          return (
            <span>
              {formatterNumber(row.totalImpacts, { fractionDigits: 0 })}
            </span>
          );
        },
        cellClass: `${classMaskOrUnmask(false)} text-right`
      },
      {
        key: 'insertions',
        name: 'Qtd. Inserções',
        sortable: true,
        width: 130,
        cellClass: classMaskOrUnmask(false),
        headerCellClass: classMaskOrUnmask(false),
        formatter: ({ row }) => {
          const found = row.insertionsByDay.some((inserts) => inserts.editable);

          if (found) {
            return <div className="text-row">{row.insertions}</div>;
          }
          return <div className="text-row-disabled">-</div>;
        },
        editorOptions: { editOnClick: true },
        editor: (props) => (
          <TextEditor {...props} maxLength={3} onChange={handleRowChange} />
        ),
        editable: (row) =>
          row.insertionsByDay.some((inserts) => inserts.editable)
      },
      ...weekDaysColumns,
      {
        key: 'amount',
        name: 'Valor total R$',
        sortable: true,
        width: 150,
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none text-right`,
        headerCellClass: 'grid-col__separate--none',
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Valor Total',
            subtitle: 'R$',
            ...props
          }),
        formatter: ({ row }) => {
          return (
            <>
              {formatterNumber(row.amount, {
                fractionDigits: 1
              })}
            </>
          );
        }
      },
      {
        key: 'discount',
        name: 'Desc. (%)',
        sortable: true,
        width: 110,
        headerRenderer: (props) =>
          GridPaginationHeader({
            title: 'Desc.',
            subtitle: '%',
            ...props
          }),
        exportXlsxFormat: '0.000%',
        formatter: ({ row }) => {
          const found = row.insertionsByDay.some((inserts) => inserts.editable);
          if (found) {
            return (
              <div className="text-row">
                {formatterPercent(String(row.discount).replace(',', '.'), {
                  fractionDigits: 3
                })}
              </div>
            );
          }
          return (
            <div className="text-row-disabled">
              {formatterPercent(0, { fractionDigits: 3 })}
            </div>
          );
        },
        editorOptions: { editOnClick: true },
        editable: (row) =>
          row.insertionsByDay.some((inserts) => inserts.editable),
        editor: (props) => (
          <Discount className="text-row" {...props} onChange={handleDiscount} />
        ),
        cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
        headerCellClass: 'grid-col__separate--none'
      },
      {
        key: 'negotiatedValue',
        name: 'Valor Negociado R$',
        sortable: true,
        width: 150,
        cellClass: `${classMaskOrUnmask(
          false
        )} grid-col__separate--none text-right`,
        headerCellClass: 'grid-col__separate--none',
        headerRenderer: (props) =>
          GridPaginationHeader({
            alignment: 'right',
            title: 'Valor Negociado',
            subtitle: 'R$',
            ...props
          }),
        formatter: ({ row }) => {
          return (
            <>{formatterNumber(row.negotiatedValue, { fractionDigits: 1 })}</>
          );
        }
      }
    ];
  }, [
    handleRemove,
    handleCopy,
    indexTarget,
    getWeekDaysByAudienceRange,
    weekDays,
    handleChange,
    handleRowChange,
    handleDiscount,
    mediaQueryUpLg,
    mediaQueryUpMd,
    listFormats,
    handleChangeFormat,
    getSecondaryValue,
    getSecondaries,
    getDataChannel
  ]);

  const sortedRows = useMemo((): readonly PlanningRow[] => {
    if (planning.sortedColumns.length === 0) return planning.planningPrograms;
    const { columnKey, direction } = planning.sortedColumns[0];

    if (columnKey.search('metricsPerTarget') !== -1) {
      return planning.planningPrograms.sort((a, b) =>
        sortNumber(
          (getValueProp(a, columnKey) as number) || 0,
          (getValueProp(b, columnKey) as number) || 0,
          direction
        )
      );
    }

    switch (columnKey) {
      case 'channel':
      case 'initials':
      case 'name':
      case 'avgProgramStartTime':
      case 'avgProgramEndTime':
        return planning.planningPrograms.sort((a, b) =>
          sortString(
            (a[columnKey] as string) || '',
            (b[columnKey] as string) || '',
            direction
          )
        );
      case 'totalImpacts':
      case 'insertions':
      case 'amount':
      case 'discount':
      case 'negotiatedValue':
        return planning.planningPrograms.sort((a, b) =>
          sortNumber(
            (a[columnKey] as number) || 0,
            (b[columnKey] as number) || 0,
            direction
          )
        );
      default:
        return planning.planningPrograms;
    }
  }, [planning.sortedColumns, planning.planningPrograms]);

  return {
    columns,
    sortedRows,
    dateExibitionInserts,
    setDateExibitionInserts,
    handleConfirmInsertion,
    actualProgram
  };
};
export default usePlanning;
