import { AllParams } from '../../../../services/shared/types';

export interface Client {
  client: string;
}

export interface CompetitiveFilterClientsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Client[];
  readonly count: number;
}

export interface ClientRequest extends AllParams {
  sectors?: string[];
}

export interface ClientResponse {
  data: {
    rows: Client[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum CompetitiveFiltersClientsType {
  REQUEST = '@competitive/clients/load',
  SUCESS = '@competitive/clients/success',
  ERROR = '@competitive/clients/error'
}

export interface CompetitiveFilterClientsAction {
  type: CompetitiveFiltersClientsType;
  payload: Omit<ClientResponse, 'status'>;
}
