import { useEffect, useRef, useMemo } from 'react';
import { Outlet, useParams, Navigate, useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  SetSceneryIdSelected,
  SetCurrentScenery
} from '../../store/modules/Simulation/Simulation.actions';
import scenariosLocalStorage from './Scenarios.LocalStorage';
import paths from '../../routes/paths';
import useFilters from '../Filters/useFilters';
// eslint-disable-next-line import/no-cycle
import { filtersLocalStorage } from '../Filters';

const { path, childrens } = paths.home.childrens.simulation;

type ParamType = {
  id: string;
};

const ControllerSceneryRoute = () => {
  const params = useParams() as ParamType;
  const dispatch = useAppDispatch();
  const { simulation } = useAppSelector((state) => state);
  const scenariosRef = useRef(scenariosLocalStorage());
  const { getCurrentFilters } = useFilters();
  const currentFilters = getCurrentFilters();
  const location = useLocation();

  const currentFiltersRef = useRef(getCurrentFilters());

  const pathFilters = useMemo(() => {
    return `${path}/${params.id}/${childrens.filters}`;
  }, [params.id]);

  useEffect(() => {
    if (params?.id) {
      const sceneryNumber =
        Number(params.id) < simulation.scenarios.length ? Number(params.id) : 0;

      const scenery = simulation.scenarios.find(
        (sc) => sc.sceneryNumber === sceneryNumber
      );

      if (scenery) {
        const oldSceneryStore = filtersLocalStorage(
          simulation.currentSceneryId
        ).get();
        const partialData = currentFiltersRef.current?.partialData;

        const isSaveStoreInPartialData =
          oldSceneryStore && isEmpty(partialData) && !simulation.id;

        dispatch(
          SetCurrentScenery(
            sceneryNumber,
            isSaveStoreInPartialData ? oldSceneryStore : undefined
          )
        ); // save partial data

        dispatch(SetSceneryIdSelected(scenery.uuid));
        scenariosRef.current.currentSceneryId.set(scenery.uuid);
      }
    }
  }, [
    dispatch,
    params,
    simulation.scenarios,
    simulation.id,
    simulation.currentSceneryId
  ]);

  if (
    (currentFilters?.error || !currentFilters?.data) &&
    simulation.uuid &&
    location.pathname !== pathFilters
  ) {
    const typeTV =
      currentFilters?.data?.typeTV || currentFilters?.partialData?.typeTV;

    return (
      <Navigate replace state={typeTV ? { typeTV } : null} to={pathFilters} />
    );
  }

  return <Outlet />;
};

export default ControllerSceneryRoute;
