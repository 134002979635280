export interface Channel {
  id: string;
  name: string;
}
export interface AudienceFilterChannelsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Channel[];
  readonly count: number | null;
  readonly requests: number;
}

export interface ChannelResponse {
  data: {
    rows: Channel[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersChannelsType {
  REQUEST = '@audience/openTv/channels/load',
  SUCESS = '@audience/openTv/channels/success',
  ERROR = '@audience/openTv/channels/error',
  CLEAR = '@audience/openTv/channels/clear'
}

export interface AudienceFilterChannelsAction {
  type: AudienceFiltersChannelsType;
  payload: Omit<ChannelResponse, 'status'>;
}
