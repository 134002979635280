import { isArray } from 'lodash';

import { apiV1 } from '../../shared/Api.service';
import { PeriodResponse } from '../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.types';
import { IAudienceByProgramsRankingFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Programs/types';

const path = '/audience/pay-tv/data';

export default async function getPayTvPeriods({
  channelGeneres,
  programmers,
  program,
  channels,
  programmingGenders,
  target,
  timeRange,
  timeRanges,
  channelsNotIn
}: IAudienceByProgramsRankingFilterParams): Promise<PeriodResponse> {
  const { data } = await apiV1.get<PeriodResponse>(path, {
    params: {
      fields: ['ds_periodo', 'tp_dado'],
      table: 'all',
      targets: target?.value ? [target?.value] : undefined,
      timeRanges: timeRange?.value
        ? [timeRange?.value]
        : timeRanges?.map((timeRangeOption) => timeRangeOption.value),
      weekDays: ['TODOS'],
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : ['TODOS'],
      channelGenres: channelGeneres?.map((genere) => genere.value),
      channelCompanies: programmers?.map((prog) => prog.value),
      channels: channels?.map((channel) => channel.value),
      channelsNotIn,
      programs: program?.value ? [program?.value] : ['TODOS']
    }
  });
  return data;
}
