import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import AudienceSubHeader from '../../SubHeader/AudienceSubHeader';
import SubHeader from '../../../SubHeader';
import paths from '../../../../routes/paths';
import classesGTM from '../../../../config/classesGTM';

const { childrens } =
  paths.home.childrens.audience.childrens.openTv.childrens.regularPlazas;

const AudiencePayTvPanel = () => {
  const title = 'TV por Assinatura';
  const menuItems = useMemo(
    () => [
      {
        index: 0,
        label: 'Emissoras',
        path: childrens.channels.path,
        // TODO: CRIAR CLASSE NO GTM
        className: classesGTM.tabs.audienceOpenTvChannels,
        id: 'audience-open-tv-channels'
      },
      {
        index: 1,
        label: 'Programas',
        path: childrens.programs.path,
        // TODO: CRIAR CLASSE NO GTM
        className: classesGTM.tabs.audienceOpenTvPrograms,
        id: 'audience-open-tv-programs'
      }
    ],
    []
  );

  const breadCrumbItems = useMemo(
    () => [
      {
        id: 1,
        title: 'Audiência',
        path: paths.home.childrens.audience.path
      },
      {
        id: 2,
        title,
        path: `${paths.home.childrens.audience.path}/${paths.home.childrens.audience.childrens.payTv.path}/${paths.home.childrens.audience.childrens.payTv.childrens.channels.path}`,
        dataClarityMask: true
      }
    ],
    [title]
  );

  return (
    <Box height="calc(100% - 72px)" sx={{ flexGrow: 1 }}>
      <SubHeader>
        <AudienceSubHeader
          title={title}
          menuItems={menuItems}
          breadCrumbItems={breadCrumbItems}
        />
      </SubHeader>
      <Outlet />
    </Box>
  );
};

export default AudiencePayTvPanel;
