import { Reducer } from 'redux';
import {
  CompetitiveFilterSectorsAction,
  CompetitiveFilterSectorsState,
  CompetitiveFiltersSectorsType
} from './Competitive.sectors.types';

const targetInitialState: CompetitiveFilterSectorsState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<
  CompetitiveFilterSectorsState,
  CompetitiveFilterSectorsAction
> = (state = targetInitialState, action): CompetitiveFilterSectorsState => {
  switch (action.type) {
    case CompetitiveFiltersSectorsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case CompetitiveFiltersSectorsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: 0
      };
    }

    case CompetitiveFiltersSectorsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
