// @ts-nocheck

import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { FormHandles, FormProps, SubmitHandler } from '@unform/core';
import { Form } from '@unform/web';
import React, { RefObject, useEffect, useState } from 'react';

interface Form extends FormProps {
  formRef: RefObject<FormHandles>;
}

interface Props extends FormControlProps<any> {
  id?: string;
  formRef: RefObject<FormHandles> | undefined;
  initialData?: Record<string, any>;
  formProps?: { [key: string]: any };
  onSubmit: SubmitHandler;
  onInitialDataChange?(): void;
}

const FormContainer: React.FC<Props> = ({
  id,
  children,
  formRef,
  initialData,
  formProps,
  onSubmit,
  onInitialDataChange,
  'data-testid': dataTest,
  ...rest
}) => {
  const [dipatchChange, setDipatchChange] = useState(false);
  useEffect(() => {
    if (formRef?.current && initialData) {
      formRef.current.setData(initialData);
      setDipatchChange(true);
    }
  }, [initialData, formRef]);

  useEffect(() => {
    if (dipatchChange && onInitialDataChange) {
      onInitialDataChange();
      setDipatchChange(false);
    }
  }, [dipatchChange, onInitialDataChange]);

  return (
    /* @ts-ignore */
    <Form
      ref={formRef}
      autoComplete="on"
      data-testid={dataTest}
      id={id}
      onSubmit={onSubmit}
      placeholder="Form"
      {...formProps}
    >
      <FormControl {...rest}>{children}</FormControl>
    </Form>
  );
};

FormContainer.defaultProps = {
  id: '',
  initialData: undefined,
  onInitialDataChange: undefined,
  formProps: undefined
};

export default FormContainer;
