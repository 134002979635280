export interface AvarageAudienceByFinancialClasse {
  pc_adh: number;
  nm_target: string;
}
export interface AudienceFilterAvarageAudienceByFinancialClassesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByFinancialClasse[];
  readonly count: number | null;
}

export interface AvarageAudienceByFinancialClasseResponse {
  data: {
    rows: AvarageAudienceByFinancialClasse[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByFinancialClassesType {
  REQUEST = '@audience/payTv/byPrograms/avarageAudienceByFinancialClasses/load',
  SUCESS = '@audience/payTv/byPrograms/avarageAudienceByFinancialClasses/success',
  ERROR = '@audience/payTv/byPrograms/avarageAudienceByFinancialClasses/error',
  CLEAR = '@audience/payTv/byPrograms/avarageAudienceByFinancialClasses/clear'
}

export interface AudienceFilterAvarageAudienceByFinancialClassesAction {
  type: AudienceFiltersAvarageAudienceByFinancialClassesType;
  payload: Omit<AvarageAudienceByFinancialClasseResponse, 'status'>;
}
