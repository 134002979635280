import { toast } from 'react-toastify';
import {
  AudienceFilterChannelsAction,
  AudienceFiltersChannelsType,
  ChannelResponse
} from './Audience.channels.types';
import { AppThunk } from '../../../..';
import getChannels from '../../../../../services/Audience/PayTv/Audience.payTv.channels.service';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const LoadAudienceFilterChannelsSuccess = (response: ChannelResponse) => {
  const payload: AudienceFilterChannelsAction = {
    type: AudienceFiltersChannelsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterChannelsError = (error: string) => {
  const payload: AudienceFilterChannelsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersChannelsType.ERROR
  };

  return payload;
};

const LoadingAudienceChannelsFilter = () => ({
  type: AudienceFiltersChannelsType.REQUEST,
  payload: {}
});

export const ClearAudienceChannelsData = () => ({
  type: AudienceFiltersChannelsType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterChannelsRequest =
  (props: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceChannelsFilter());
      const response = await getChannels({
        ...props
      });

      dispatch(LoadAudienceFilterChannelsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterChannelsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar as emissoras.');
    }
  };
