import { toast, ToastOptions } from 'react-toastify';
import { SortColumn } from 'react-data-grid';
import { SimulationData } from '../Simulation/Simulation.types';
import { AppThunk } from '../..';
import {
  deleteSimulation,
  getAllSimulations,
  saveSimulation,
  updateSimulation
} from '../../../services/MediaSimulator/Simulations.service';
import {
  ResetSimulation,
  SetSimulation
} from '../Simulation/Simulation.actions';
import {
  SimulationsAction,
  SimulationsQueryParams,
  SimulationsResponse,
  SimulationsTypes
} from './Simulations.types';
import simualtionLocalStorage from '../../../components/Simulation/Simulation.LocalStorage';
import scenariosLocalStorage from '../../../components/Scenarios/Scenarios.LocalStorage';
// eslint-disable-next-line import/no-cycle
import { filtersLocalStorage } from '../../../components/Filters';
import { ResetFilters } from '../Filters/Filters.actions';
import { ResetPlanning } from '../Planning/Planning.actions';
import { ResetPrograms } from '../Programs/Programs.actions';
import { ResetSummary } from '../Summary/Summary.actions';
import { ApiErrorResponse } from '../../../services/shared/Api.service';

const propsToastInfo: ToastOptions = {
  isLoading: true,
  closeOnClick: false,
  closeButton: false,
  autoClose: false
};
const propsToastSuccess: ToastOptions = {
  type: 'success',
  progress: undefined,
  autoClose: 5000,
  isLoading: false,
  closeOnClick: true,
  closeButton: true
};
const propsToastError: ToastOptions = {
  type: 'error',
  progress: undefined,
  autoClose: 5000,
  isLoading: false,
  closeOnClick: true,
  closeButton: true
};
export const LoadSimulationsSuccess = (
  simulationsResponse: SimulationsResponse
) => {
  const payload: SimulationsAction = {
    payload: {
      simulations: simulationsResponse.data.rows,
      count: simulationsResponse.data.count,
      message: simulationsResponse.msg
    },
    type: SimulationsTypes.LOAD_SIMULATIONS_SUCCESS
  };
  return payload;
};

export const LoadSimulationsFailure = (error: string) => ({
  type: SimulationsTypes.LOAD_SIMULATIONS_FAILURE,
  payload: { message: error }
});

const LoadingSimulationsRequest = () => ({
  type: SimulationsTypes.SIMULATIONS_REQUEST
});

export const LoadSimulationsRequest =
  (queryParams: SimulationsQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingSimulationsRequest());
      const response = await getAllSimulations(queryParams);
      if (response.status !== 200) {
        throw new Error(response.msg);
      }
      dispatch(LoadSimulationsSuccess(response));
    } catch (error) {
      dispatch(
        LoadSimulationsFailure(
          error instanceof Error
            ? error.message
            : (error as ApiErrorResponse)?.msg || ''
        )
      );
    }
  };

export const SaveOrUpdateSimulationFailure = (error: string) => {
  return {
    type: SimulationsTypes.SAVE_OR_UPDATE_SIMULATION_FAILURE,
    payload: { message: error }
  };
};

export const SaveSimulationSuccess = (message: string) => {
  return {
    type: SimulationsTypes.SAVE_SIMULATION_SUCCESS,
    payload: { message }
  };
};

const onUploadProgress =
  (toastId: string | number, type: 'save' | 'update') =>
  (event: ProgressEvent) => {
    const progress = event.loaded / event.total;
    const msg =
      type === 'save' ? 'Salvando a simulação' : 'Atualizando a simulação';

    toast.update(toastId, {
      ...propsToastInfo,
      progress: progress === 1 ? undefined : progress,
      render: progress === 1 ? msg : undefined
    });
  };

export const SaveSimulation =
  (simulationData: SimulationData, isDuplicate = false): AppThunk =>
  async (dispatch, getState) => {
    const { simulation } = getState();
    const simulationStore = simualtionLocalStorage();
    const toastId = toast.info(
      'Enviando dados da simulação...',
      propsToastInfo
    );
    try {
      dispatch(
        SetSimulation({ ...getState()?.simulation, mustBeLoading: true })
      );
      dispatch(LoadingSimulationsRequest());
      const response = await saveSimulation(
        {
          ...simulationData,
          scenarios: simulationData?.scenarios || simulation?.scenarios
        },
        onUploadProgress(toastId, 'save'),
        isDuplicate
      );
      if (response.status !== 200) {
        throw new Error(response.msg);
      }

      simulationStore.set(response.data);

      dispatch(SetSimulation({ ...simulation, ...response.data }));
      dispatch(SaveSimulationSuccess(response.msg));
      toast.update(toastId, {
        ...propsToastSuccess,
        render: isDuplicate ? 'Simulação copiada com sucesso' : response.msg
      });
    } catch (error) {
      const errMsg =
        error instanceof Error
          ? error.message
          : (error as ApiErrorResponse)?.msg ||
            'Ops, houve um problema ao tentar salvar a simulação.';

      dispatch(SaveOrUpdateSimulationFailure(errMsg));
      toast.update(toastId, {
        ...propsToastError,
        render: errMsg
      });
    }
  };

export const UpdateSuccess = (message: string) => ({
  type: SimulationsTypes.UPDATE_SIMULATION_SUCCESS,
  payload: {
    message
  }
});

export const UpdateSimulation =
  (
    updateData: SimulationData,
    idSimulation: number,
    autosave?: boolean
  ): AppThunk =>
  async (dispatch, getState) => {
    const simulationStore = simualtionLocalStorage();
    const toastId = !autosave
      ? toast.info('Enviando dados da simulação...', propsToastInfo)
      : null;
    try {
      dispatch(
        SetSimulation({ ...getState()?.simulation, mustBeLoading: !autosave })
      );

      dispatch(LoadingSimulationsRequest());
      const response = await updateSimulation(
        updateData,
        idSimulation,
        toastId ? onUploadProgress(toastId, 'update') : undefined,
        autosave
      );
      if (response.status !== 200) {
        throw new Error(response.msg);
      }

      simulationStore.set(response.data);

      dispatch(
        SetSimulation({
          ...getState()?.simulation,
          ...response.data
        })
      );
      dispatch(UpdateSuccess(response.msg));
      toastId &&
        toast.update(toastId, {
          ...propsToastSuccess,
          render: response.msg
        });
    } catch (error) {
      const errMsg =
        error instanceof Error
          ? error.message
          : (error as ApiErrorResponse)?.msg ||
            'Ops, houve um problema ao tentar atualizar a simulação.';

      !autosave && dispatch(SaveOrUpdateSimulationFailure(errMsg));
      toastId &&
        toast.update(toastId, {
          ...propsToastError,
          render: errMsg
        });
    }
  };

export const DeleteSuccess = (message: string) => ({
  type: SimulationsTypes.DELETE_SIMULATION_SUCCESS,
  payload: {
    message
  }
});

export const DeleteFailure = (message: string) => {
  return {
    type: SimulationsTypes.DELETE_SIMULATION_FAILURE,
    payload: {
      message
    }
  };
};

export const DeleteSimulationRequest =
  (id: number, isDraft = false): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingSimulationsRequest());
      const response = await deleteSimulation(id, isDraft);
      if (response.status !== 200) {
        throw new Error(response.msg);
      }
      dispatch(DeleteSuccess(response.msg));
      toast.success(response.msg);
    } catch (error) {
      const errMsg =
        error instanceof Error
          ? error.message
          : (error as ApiErrorResponse)?.msg ||
            'Ops, houve um problema ao tentar excluir a simulação.';

      dispatch(DeleteFailure(errMsg));
      toast.error(errMsg);
    }
  };

export const SetSimulationsPage = (newPage: number): SimulationsAction => ({
  type: SimulationsTypes.SET_SIMULATIONS_PAGE,
  payload: {
    page: newPage
  }
});

export const SetSimulationsRowsPerPage = (
  newRows: number
): SimulationsAction => ({
  type: SimulationsTypes.SET_SIMULATIONS_ROWS_PER_PAGE,
  payload: {
    rowsPerPage: newRows
  }
});

export const SetSimulationsSortColumns = (
  columns: SortColumn[]
): SimulationsAction => ({
  type: SimulationsTypes.SET_SIMULATIONS_SORT_COLUMNS,
  payload: {
    sortColumns: columns
  }
});

export const SetSimulationsSearch = (search: string): SimulationsAction => ({
  type: SimulationsTypes.SET_SIMULATIONS_SEARCH,
  payload: {
    search
  }
});

export const ResetAll = (): AppThunk => async (dispatch) => {
  const scenariosStore = scenariosLocalStorage();
  const simulationStore = simualtionLocalStorage();
  const scenarios = scenariosStore.scenarios.get();

  if (scenarios) {
    scenarios.forEach((scenery) => {
      filtersLocalStorage(scenery.uuid).remove();
    });

    scenariosStore.currentScenery.remove();
    scenariosStore.currentSceneryId.remove();
    scenariosStore.scenarios.remove();
  }

  dispatch(ResetSimulation());
  dispatch(ResetFilters());
  dispatch(ResetPrograms());
  dispatch(ResetPlanning());
  dispatch(ResetSummary());
  simulationStore.remove();
};
