import { toast } from 'react-toastify';
import { AppThunk } from '../../..';
import {
  CompetitiveCommercialResponse,
  CompetitiveCommercialAction,
  CompetitiveCommercialTypes,
  CompetitiveCommercialQueryParams
} from './Competitive.commercial.types';
import getCompetitiveCommercial from '../../../../services/Competitive/Competitive.commercial.service';

export const LoadCompetitiveCommercialSuccess = (
  response: CompetitiveCommercialResponse
) => {
  const payload: CompetitiveCommercialAction = {
    type: CompetitiveCommercialTypes.LOAD_COMMERCIAL_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg
    }
  };

  return payload;
};

const LoadCompetitiveCommercialError = (error: string) => {
  const payload: CompetitiveCommercialAction = {
    type: CompetitiveCommercialTypes.LOAD_COMMERCIAL_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingCompetitiveCommercial = () => ({
  type: CompetitiveCommercialTypes.LOAD_COMMERCIAL_REQUEST
});

const LoadCompetitiveCommercialRequest =
  (params: CompetitiveCommercialQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveCommercial());

      const response = await getCompetitiveCommercial(params);

      dispatch(LoadCompetitiveCommercialSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveCommercialError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar os canais.');
    }
  };

export default LoadCompetitiveCommercialRequest;
