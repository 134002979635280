import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import paths from '../../../routes/paths';

const { REACT_APP_AUDIENCE_DASH_URL } = process.env;

const { childrens } = paths.home.childrens.audience;

const AudiencePowerBi: React.FC = () => {
  const params = useParams() as unknown as { type: keyof typeof childrens };

  const powerBiLink = useMemo(() => {
    const children = Object.values(childrens).find(
      (el) => el.path === params.type
    ) as { link: string };

    if (children && children.link) {
      return children?.link;
    }

    return REACT_APP_AUDIENCE_DASH_URL;
  }, [params.type]);

  return (
    <div className="mt-2">
      <iframe
        style={{ border: 0 }}
        title="Painel de Audiência V2"
        allowFullScreen
        height={window.innerHeight - 86}
        src={powerBiLink}
        width="100%"
      />
    </div>
  );
};

export default AudiencePowerBi;
