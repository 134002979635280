import LocalStorage from '../../helpers/LocalStorage';
import { SimulationProps } from '../../store/modules/Simulation/Simulation.types';

type SimulationStore = Partial<
  Omit<SimulationProps, 'scenarios' | 'currentSceneryId' | 'currentScenery'>
>;

export default function simualtionLocalStorage() {
  return LocalStorage<SimulationStore>('currentSimulation');
}
