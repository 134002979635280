import { useState, useRef, useMemo, useCallback } from 'react';
import { RowsChangeData, SortColumn } from 'react-data-grid';
import cloneDeep from 'lodash/cloneDeep';
import omitProps from 'lodash/omit';
import getValueProp from 'lodash/get';
import { SummaryPropsGrid, SummaryRow, TypesRow } from './types';
import GridPagination from '../GridPagination';
import { sortNumber } from '../../helpers/Sort';
import useSummaryGridDetail from './useSummaryGridDetail';
import { GridColumn } from '../GridPagination/types';
import SetSummarySortedColumns from '../../store/modules/Summary/Summary.actions';
import { useAppDispatch } from '../../hooks';
import useSummaryRedux from './useSummaryRedux';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  title: string;
  target: string;
  columns: readonly GridColumn<any>[];
  rows: any[];
  rowsExpanded: boolean;
  classTagManagerDownXlxs: string;
}

interface RowSheet extends Partial<SummaryPropsGrid> {
  sheetName: string;
}

const SummaryGrid = ({
  title,
  columns,
  rowsExpanded,
  rows,
  target,
  classTagManagerDownXlxs
}: Props) => {
  const [stateRows, setStateRows] = useState<SummaryRow[]>(rows);
  const { getColumns: getColumnsSummaryDetail } = useSummaryGridDetail();
  const dispatch = useAppDispatch();
  const summary = useSummaryRedux().currentSummary;

  const onRowsChange = (
    dataRows: SummaryRow[],
    { indexes }: RowsChangeData<SummaryRow>
  ) => {
    if (!rowsExpanded) return;

    const copyRows = cloneDeep(dataRows);
    const row = copyRows[indexes[0]];
    if (row.type === TypesRow.MASTER) {
      if (!row.expanded) {
        copyRows.splice(indexes[0] + 1, 1);
      } else {
        copyRows.splice(indexes[0] + 1, 0, {
          type: TypesRow.DETAIL,
          programs: row.programs
        });
      }
      setStateRows(copyRows);
    }
  };

  const copyRows = useRef<SummaryRow[]>([]);
  const onSortColumnsChange = (sortedColumn: SortColumn[]) => {
    if (!copyRows.current.length) {
      copyRows.current = stateRows
        .filter((el) => el.type === TypesRow.MASTER)
        .map((el) => ({ ...el, expanded: false }));
    }

    const sortColumns = sortedColumn.slice(-1);
    if (!sortColumns.length) {
      dispatch(SetSummarySortedColumns(sortColumns));
      setStateRows(copyRows.current);
      copyRows.current = [];
      return;
    }

    const { columnKey, direction } = sortColumns[0];

    const sortedRows = stateRows
      .filter((el) => el.type === TypesRow.MASTER)
      .map((el) => ({ ...el, expanded: false }))
      .sort((a, b) =>
        sortNumber(
          getValueProp(a, columnKey) as number,
          getValueProp(b, columnKey) as number,
          direction
        )
      );

    dispatch(SetSummarySortedColumns(sortColumns));
    setStateRows(sortedRows);
  };

  const sheets = useMemo(
    () =>
      stateRows
        .filter((r) => r.type === TypesRow.MASTER)
        .reduce((acc: RowSheet[], row) => {
          if (row.type === TypesRow.MASTER) {
            acc.push({
              ...omitProps(row, ['programs']),
              sheetName: 'Channels'
            });
            acc.push({
              ...omitProps(row, 'type'),
              sheetName: row.channel.description.toUpperCase()
            });
          }
          return acc;
        }, []),
    [stateRows]
  );

  const sheetsName = useMemo(
    () => [
      'Channels',
      ...sheets
        .filter((s) => s.sheetName === s?.channel?.description.toUpperCase())
        .map((s) => s.sheetName)
    ],
    [sheets]
  );

  const getRowsSheet = useCallback(
    (name: string) => {
      const tmpSheet = sheets.find((s) => s.sheetName === name);
      if (tmpSheet?.type === TypesRow.MASTER) {
        return sheets.filter((s) => s.sheetName === name);
      }
      return sheets
        .filter((s) => s.sheetName === name)
        .flatMap((s) => s.programs);
    },
    [sheets]
  );

  const getColumnsSheet = useCallback(
    (name: string) => {
      const tmpSheet = sheets.find((s) => s.sheetName === name);
      if (tmpSheet?.type === TypesRow.MASTER) {
        return columns;
      }
      return getColumnsSummaryDetail(target);
    },
    [sheets, getColumnsSummaryDetail, target, columns]
  );

  return (
    <>
      <h3 {...attributeMaskOrUnmask(true)}>{title}</h3>
      <GridPagination
        className={rowsExpanded ? 'summary__grid mb-4' : undefined}
        classTagManagerDownXlxs={classTagManagerDownXlxs}
        columns={columns}
        enableVirtualization={false}
        exportXlsxFileName={`Summary-${target}`}
        getColumnsSheet={getColumnsSheet}
        getRowsSheet={getRowsSheet}
        hasExportGridToXlxs
        hasSearch={false}
        onRowsChange={onRowsChange}
        onSortColumnsChange={onSortColumnsChange}
        rowClass={() => (rowsExpanded ? 'grid__row' : undefined)}
        rowHeight={(args) => {
          return rowsExpanded &&
            args.type === 'ROW' &&
            args.row.type === TypesRow.DETAIL
            ? 306
            : 35;
        }}
        rows={stateRows}
        sortColumns={summary.sortedColumns}
        xlsxSheetName={sheetsName}
      />
    </>
  );
};

export default SummaryGrid;
