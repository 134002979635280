export interface Programmer {
  nm_programadora: string;
}
export interface AudienceFilterProgrammersState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Programmer[];
  readonly count: number | null;
  readonly requests: number;
}

export interface ProgrammerResponse {
  data: {
    rows: Programmer[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersProgrammersType {
  REQUEST = '@audience/openTv/programmers/load',
  SUCESS = '@audience/openTv/programmers/success',
  ERROR = '@audience/openTv/programmers/error',
  CLEAR = '@audience/openTv/programmers/clear'
}

export interface AudienceFilterProgrammersAction {
  type: AudienceFiltersProgrammersType;
  payload: Omit<ProgrammerResponse, 'status'>;
}
