import { Reducer } from 'redux';
import {
  CompetitiveFilterClientsAction,
  CompetitiveFilterClientsState,
  CompetitiveFiltersClientsType
} from './Competitive.clients.types';

const clientsInitialState: CompetitiveFilterClientsState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<
  CompetitiveFilterClientsState,
  CompetitiveFilterClientsAction
> = (state = clientsInitialState, action): CompetitiveFilterClientsState => {
  switch (action.type) {
    case CompetitiveFiltersClientsType.SUCESS:
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };

    case CompetitiveFiltersClientsType.ERROR:
      return {
        loading: false,
        error: true,
        data: [],
        count: 0
      };

    case CompetitiveFiltersClientsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
