import { Reducer } from 'redux';
import { calcPage } from '../../../helpers/Utils';
import {
  SimulationsState,
  SimulationsAction,
  SimulationsTypes
} from './Simulations.types';

const simulationInitialState: SimulationsState = {
  data: [],
  loading: false,
  mustBeLoading: false,
  error: false,
  reload: true,
  count: 0,
  message: '',
  page: 0,
  rowsPerPage: 10,
  sortColumns: [],
  search: ''
};

const reducer: Reducer<SimulationsState, SimulationsAction> = (
  state = simulationInitialState,
  action
): SimulationsState => {
  switch (action.type) {
    case SimulationsTypes.SIMULATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
        message: ''
      };
    }
    case SimulationsTypes.LOAD_SIMULATIONS_SUCCESS: {
      if (action?.payload?.simulations) {
        return {
          ...state,
          data: action.payload.simulations,
          count: action.payload.count || 0,
          message: action.payload.message || '',
          error: false,
          loading: false,
          reload: false
        };
      }

      return state;
    }
    case SimulationsTypes.DELETE_SIMULATION_SUCCESS: {
      const newCount = state.count - 1;
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.message || '',
        count: newCount,
        page: calcPage(newCount, state.page, state.rowsPerPage),
        reload: true
      };
    }
    case SimulationsTypes.SAVE_SIMULATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.message || '',
        count: state.count + 1,
        reload: true
      };
    }
    case SimulationsTypes.UPDATE_SIMULATION_SUCCESS: {
      return {
        ...state,
        message: action.payload.message || '',
        loading: false,
        error: false,
        reload: true
      };
    }
    case SimulationsTypes.DELETE_SIMULATION_FAILURE:
    case SimulationsTypes.SAVE_OR_UPDATE_SIMULATION_FAILURE:
    case SimulationsTypes.LOAD_SIMULATIONS_FAILURE: {
      return {
        ...state,
        error: true,
        message: action.payload.message || '',
        loading: false,
        reload: false
      };
    }
    case SimulationsTypes.SET_SIMULATIONS_PAGE: {
      return {
        ...state,
        page: action.payload.page || 0,
        reload: true
      };
    }
    case SimulationsTypes.SET_SIMULATIONS_ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage || 10,
        reload: true
      };
    }
    case SimulationsTypes.SET_SIMULATIONS_SORT_COLUMNS: {
      return {
        ...state,
        sortColumns: action.payload.sortColumns || [],
        reload: true
      };
    }
    case SimulationsTypes.SET_SIMULATIONS_SEARCH: {
      return {
        ...state,
        search: action.payload.search || '',
        page: 0,
        reload: true
      };
    }
    default:
      return state;
  }
};

export default reducer;
