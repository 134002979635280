import { formatBrLocale } from '../../../helpers/Dates';
import { OptionsList } from '../../Form/types';
import {
  CompetitiveData,
  ValidCompetitiveFormData
} from '../../../store/modules/Competitive/Shared/Competitive.shared.types';
import { FIXED_VALUE } from './constants';

const filterFixedOption = (options?: OptionsList[]) =>
  options?.filter((opt) => opt.value !== FIXED_VALUE.value) || [];

const serializeFormData = (
  formData: ValidCompetitiveFormData
): CompetitiveData => {
  return {
    marketId: formData.market,
    sectors: filterFixedOption(formData.sectors).map((opt) => opt.value),
    clients: filterFixedOption(formData.clients).map((opt) => opt.value),
    channels: formData.channels
      ?.filter((ch) => ch.selected)
      .map((ch) => ({
        mnemonic: ch.channelMnemonic,
        discount: ch.discount,
        channelName: ch.channelName
      })),
    categories: filterFixedOption(formData.categories).map((opt) => opt.value),
    brands: filterFixedOption(formData.brands).map((opt) => opt.value),
    commercialTypes: filterFixedOption(formData.commercialTypes).map(
      (opt) => opt.value
    ),
    targetId: formData.target,
    from: formatBrLocale(formData.period[0], 'yyyy-MM-dd'),
    to: formatBrLocale(formData.period[1], 'yyyy-MM-dd')
  };
};

export { serializeFormData, filterFixedOption };
