export interface ChannelGeneres {
  nm_genero_paytv: string;
}
export interface AudienceFilterChannelGeneresState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: ChannelGeneres[];
  readonly count: number | null;
}

export interface ChannelGeneresResponse {
  data: {
    rows: ChannelGeneres[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersChannelGeneresType {
  REQUEST = '@audience/payTv/channelGeneres/load',
  SUCESS = '@audience/payTv/channelGeneres/success',
  ERROR = '@audience/payTv/channelGeneres/error',
  CLEAR = '@audience/payTv/channelGeneres/clear'
}

export interface AudienceFilterChannelGeneresAction {
  type: AudienceFiltersChannelGeneresType;
  payload: Omit<ChannelGeneresResponse, 'status'>;
}
