import { eachDayOfInterval, isSameMonth } from 'date-fns';
import { SortColumn } from 'react-data-grid';
import { PlanningAction, PlanningTypes, PlanningRow } from './Planning.types';
import { AppThunk } from '../..';
import { Program } from '../Programs/Programs.types';
import { calcMetricsOfInsertions } from '../../../helpers/CalcsSimulation';
import isEqualProgramByChannelAndInitialsAndName from '../../../helpers/Utils';
import { stringToDate, formatBrLocale } from '../../../helpers/Dates';

const SetHideDaysOfWeek =
  (hideDaysOfWeek: boolean): AppThunk =>
  async (dispatch, getState) => {
    const action: PlanningAction = {
      type: PlanningTypes.SET_HIDE_DAYS_OF_WEEK,
      payload: {
        hideDaysOfWeek,
        sceneryId: getState().simulation.currentSceneryId
      }
    };
    dispatch(action);
  };

export default SetHideDaysOfWeek;

const ActionSetPlanningPrograms = (
  programs: PlanningRow[],
  sceneryId: string
): PlanningAction => ({
  type: PlanningTypes.SET_PLANNING_PROGRAMS,
  payload: {
    planningPrograms: programs,
    sceneryId
  }
});

export const SetPlanningProgramsByScenery =
  (programs: PlanningRow[], sceneryId: string): AppThunk =>
  async (dispatch) => {
    dispatch(ActionSetPlanningPrograms(programs, sceneryId));
  };
export const SetPlanningPrograms =
  (programs: PlanningRow[]): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      SetPlanningProgramsByScenery(
        programs,
        getState().simulation.currentSceneryId
      )
    );
  };

export const AddPlanningProgram =
  (program: Program): AppThunk =>
  async (dispatch, getState) => {
    const { filters, simulation } = getState();
    const currentFilters = filters.forms.find(
      (f) => f.sceneryId === simulation.currentSceneryId
    );

    if (currentFilters?.data) {
      const dateFrom = currentFilters.data.dates.campaign.from;
      const dateTo = currentFilters.data.dates.campaign.to;

      const params = {
        start: dateFrom instanceof Date ? dateFrom : stringToDate(dateFrom),
        end: dateTo instanceof Date ? dateTo : stringToDate(dateTo)
      };

      const insertionsByDay = eachDayOfInterval(params).map((day) => {
        const programOfCompetencie = program.pricesByDate.find((price) =>
          isSameMonth(stringToDate(price.competency), day)
        );

        return {
          value: 0,
          day,
          editable:
            program.daysExhibition.includes(
              formatBrLocale(day, 'EEEEEE').toUpperCase()
            ) &&
            !!programOfCompetencie &&
            programOfCompetencie?.price > 0
        };
      });

      const action: PlanningAction = {
        type: PlanningTypes.ADD_PLANNING_PROGRAM,
        payload: {
          program,
          insertionsByDay,
          sceneryId: getState().simulation.currentSceneryId
        }
      };
      dispatch(action);
    }
  };

export const CopyPlanningProgram =
  (row: PlanningRow): AppThunk =>
  async (dispatch, getState) => {
    const action: PlanningAction = {
      type: PlanningTypes.COPY_PLANNING_PROGRAM,
      payload: {
        planningRow: row,
        sceneryId: getState().simulation.currentSceneryId
      }
    };
    dispatch(action);
  };

export const RemovePlanningProgram =
  (program: PlanningRow | Program): AppThunk =>
  (dispatch, getState) => {
    const { planning, simulation } = getState();

    const currentePlanning = planning.find(
      (plan) => plan.sceneryId === simulation.currentSceneryId
    );

    if (currentePlanning) {
      const newPrograms = currentePlanning.planningPrograms.filter((p) =>
        program.type === 'programs'
          ? !isEqualProgramByChannelAndInitialsAndName(program)
          : program.id !== p.id
      );

      dispatch(
        ActionSetPlanningPrograms(newPrograms, simulation.currentSceneryId)
      );
    }
  };

export const SelectTargetPlanningByScenery =
  (target: string, sceneryId: string): AppThunk =>
  async (dispatch) => {
    const action: PlanningAction = {
      type: PlanningTypes.SELECT_TARGET_PLANNING,
      payload: {
        target,
        sceneryId
      }
    };
    dispatch(action);
  };

export const SelectTargetPlanning =
  (target: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(
      SelectTargetPlanningByScenery(
        target,
        getState().simulation.currentSceneryId
      )
    );
  };

export const RecalculateMetrics = (): AppThunk => (dispatch, getState) => {
  const { planning, simulation } = getState();

  const currentePlanning = planning.find(
    (plan) => plan.sceneryId === simulation.currentSceneryId
  );

  if (currentePlanning) {
    const newPrograms = currentePlanning.planningPrograms.map((program) => {
      const metrics = calcMetricsOfInsertions(
        program.metricsPerTarget,
        program.pricesByDate,
        program.insertionsByDay,
        program.discount,
        currentePlanning.target
      );

      return {
        ...program,
        totalImpacts: metrics.totalImpacts,
        metricsByCompetency: metrics.metricsByCompetency
      };
    });

    dispatch(
      ActionSetPlanningPrograms(newPrograms, simulation.currentSceneryId)
    );
  }
};

export const SetLoading = (sceneryId: string, loading: boolean) => {
  const action: PlanningAction = {
    type: PlanningTypes.SET_LOADING_PRICE_PROGRAM,
    payload: { sceneryId, loading }
  };
  return action;
};

export const ClearPlanningPrograms = (sceneryId: string): PlanningAction => ({
  type: PlanningTypes.CLEAR_PLANNING_PROGRAMS,
  payload: { sceneryId }
});

export const ResetPlanning = () => ({
  type: PlanningTypes.RESET_PLANNING
});

export const RemovePlanningProgramsScenery = (
  sceneryId: string
): PlanningAction => ({
  type: PlanningTypes.REMOVE_PLANNING_PROGRAMS_SCENERY,
  payload: { sceneryId }
});

export const SetTotalizers =
  (
    totalAmount: number,
    totalNegotiated: number,
    avgDiscount: number,
    totalInsertions: number,
    totalGrpOrTrp: number,
    sceneryId: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: PlanningTypes.SET_TOTALIZERS,
      payload: {
        totalAmount,
        totalNegotiated,
        avgDiscount,
        totalInsertions,
        totalGrpOrTrp,
        sceneryId
      }
    });
  };

export const SetPlanningSortedColumns =
  (sortedColumns: SortColumn[]): AppThunk =>
  async (dispatch, getState) => {
    const action: PlanningAction = {
      type: PlanningTypes.SET_PLANNING_SORTED_COLUMNS,
      payload: {
        sceneryId: getState().simulation.currentSceneryId,
        sortedColumns
      }
    };
    dispatch(action);
  };
