export enum ClientsTypes {
  LOAD_CLIENTS_REQUEST = 'LOAD_CLIENTS_REQUEST',
  LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS',
  LOAD_CLIENTS_ERROR = 'LOAD_CLIENTS_ERROR'
}

export interface Client {
  oi_cliente: number;
  me_cliente: string;
  cpf_cnpj: string;
  razao_social: string;
  nome_cliente: string;
}

export interface IRowsData {
  all?: Client[] | [];
  uniques?: Client[] | [];
}

export interface ClientsAction {
  type: ClientsTypes;
  payload: {
    rows?: IRowsData;
    count?: number;
    message?: string;
  };
}

export interface ClientsResponse {
  data: {
    rows: IRowsData;
    count: number;
  };
  status: number;
  msg: string;
}

export interface ClientsState {
  readonly data: IRowsData;
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
  readonly message: string;
}
