import { Column } from 'react-data-grid';
import { format } from 'date-fns';
import {
  getRole,
  getCentral,
  getAreaPerCentral,
  getBaseAudience
} from '../../components/ModalUser/contants';
import { User } from '../../store/modules/Users/Users.types';
import { GridPaginationHeader } from '../../components/GridPagination';
import ActionColumn from '../../components/ActionColumn';
import close from '../../assets/images/remove.svg';
import edit from '../../assets/images/edit_b.svg';
import { classMaskOrUnmask } from '../../helpers/Utils';
import { getUuid } from '../../helpers/UUID';

export default function useUsers(
  handleUpdate: (row: User) => void,
  handleDelete: (row: User) => void
) {
  const columns: Column<User>[] = [
    {
      key: 'name',
      name: 'Nome',
      sortable: true,
      resizable: true,
      cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 190
    },
    {
      key: 'email',
      name: 'E-mail',
      sortable: true,
      resizable: true,
      cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 225
    },
    {
      key: 'role',
      name: 'Tipo',
      sortable: true,
      resizable: true,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 122.5,
      formatter: ({ row }) => <>{getRole(row.role).label}</>
    },
    {
      key: 'central',
      name: 'Central',
      resizable: true,
      sortable: true,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 210,
      formatter: ({ row }) => <>{getCentral(row.central)?.label}</>
    },
    {
      key: 'area',
      name: 'Área',
      sortable: true,
      resizable: true,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 210,
      formatter: ({ row }) => (
        <>{getAreaPerCentral(row.area, row.central)?.label}</>
      )
    },
    {
      key: 'baseAudience',
      name: 'Base de Audiência',
      sortable: true,
      resizable: true,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 220,
      formatter: ({ row }) => <>{getBaseAudience(row.baseAudience)?.label}</>
    },
    {
      key: 'updatedAt',
      name: 'Modificado',
      sortable: true,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      width: 180,
      headerRenderer: (props) =>
        GridPaginationHeader({
          title: 'Modificado',
          classes: { title: 'plim-violet ' },
          ...props
        }),
      formatter: ({ row }) => {
        const date = new Date(row.updatedAt);
        return date ? (
          <span className="plim-violet">
            {format(date, 'dd/MM/yyyy HH:mm:ss')}
          </span>
        ) : null;
      }
    },
    {
      key: 'action',
      name: 'Ações',
      width: 40,
      formatter: ({ row }) => (
        <>
          <ActionColumn
            icon={close}
            id={`btn_delete_users_${getUuid()}`}
            onClick={() => handleDelete(row)}
          />
          <ActionColumn
            icon={edit}
            id={`btn_update_users_${getUuid()}`}
            onClick={() => handleUpdate(row)}
          />
        </>
      ),
      cellClass: `${classMaskOrUnmask(false)} action_column__container`,
      headerCellClass: classMaskOrUnmask(false)
    }
  ];

  return { columns };
}
