import { toast, ToastOptions } from 'react-toastify';
import { secondsToMilliseconds } from 'date-fns';
import { sleep } from '../../../../helpers/Utils';
import {
  AppStatus,
  CompetitiveMetricsJobStatus
} from './Competitive.shared.types';
import { formatterPercent } from '../../../../helpers/Formatters';

const propsToastInfo: ToastOptions = {
  isLoading: true,
  closeOnClick: false,
  closeButton: false,
  autoClose: false
};

const propsToastSuccess: ToastOptions = {
  type: 'success',
  progress: undefined,
  autoClose: 5000,
  isLoading: false,
  closeOnClick: true,
  closeButton: true
};

const updateToast = (
  toastId: string | number,
  progress: number,
  msg: string,
  options = propsToastInfo
) => {
  toast.update(toastId, {
    ...options,
    progress: progress / 100,
    render: msg
  });
};

const controllerActionsStatusMetrics = async (
  token: string,
  toastId: React.ReactText,
  callbacks: {
    success: (status: CompetitiveMetricsJobStatus, token: string) => void;
    request: () => Promise<CompetitiveMetricsJobStatus>;
  }
) => {
  updateToast(toastId, 0, 'Processando as métricas...');
  let status = await callbacks.request();

  let { progress } = status.data.row;

  let countExecuted = 0;
  while (status.data.row.status === AppStatus.RUNNING) {
    updateToast(
      toastId,
      progress,
      `Processando as métricas... (${formatterPercent(progress, {
        fractionDigits: 0
      })})`
    );

    callbacks.success(status, token);

    if (status.data.row.status === AppStatus.RUNNING) {
      // eslint-disable-next-line no-await-in-loop
      await sleep(secondsToMilliseconds(countExecuted < 8 ? 5 : 30));
    }

    // eslint-disable-next-line no-await-in-loop
    status = await callbacks.request();

    countExecuted += 1;

    progress =
      status.data.row.progress > progress ? status.data.row.progress : progress;
  }

  callbacks.success(status, token);

  if (status.data.row.status === AppStatus.COMPLETED) {
    updateToast(toastId, 100, 'Processado!');
  }

  if (status.data.row.status === AppStatus.FAILED) {
    toast.dismiss(toastId);
    throw new Error(
      status.msg || 'Ops, houve um problema na busca das métricas.'
    );
  }
};

export {
  updateToast,
  controllerActionsStatusMetrics,
  propsToastSuccess,
  propsToastInfo
};
