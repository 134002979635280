import { Reducer } from 'redux';
import {
  AudiencePercentageAction,
  AudiencePercentageState,
  AudiencePercentageType
} from './Audience.percentage.types';

const yearInitialState: AudiencePercentageState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<AudiencePercentageState, AudiencePercentageAction> = (
  state = yearInitialState,
  action
): AudiencePercentageState => {
  switch (action.type) {
    case AudiencePercentageType.SUCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudiencePercentageType.ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
        count: 0
      };
    }

    case AudiencePercentageType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudiencePercentageType.CLEAR: {
      return {
        ...state,
        data: [],
        count: 0
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
