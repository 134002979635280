import { AllParams } from '../../../../services/shared/types';

export enum CompetitiveCategoriesTypes {
  LOAD_CATEGORIES_REQUEST = '@competitive/categories/request',
  LOAD_CATEGORIES_SUCCESS = '@competitive/categories/success',
  LOAD_CATEGORIES_ERROR = '@competitive/categories/error'
}

export interface CompetitiveCategory {
  category: string;
}

export interface CompetitiveCategoryAction {
  type: CompetitiveCategoriesTypes;
  payload: {
    rows: CompetitiveCategory[];
    count: number;
    message: string;
  };
}

export interface CompetitiveCategoriesState {
  readonly data: CompetitiveCategory[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface CompetitiveCategoriesResponse {
  data: {
    rows: CompetitiveCategory[];
    count: number;
  };
  status: number;
  msg: string;
}
export interface CompetitiveCategoriesQueryParams extends AllParams {
  sectors?: string[];
  clients?: string[];
  channelMnemonics?: string[];
}
