import { useEffect, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { IAudienceByProgramsRadiographyFilterProps } from '../types';
import { LoadAudienceFilterAvarageDatasByProgramsRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByPrograms/AvarageDatas/Audience.avarageDatas.actions';
import { LoadAudienceFilterAvarageAudienceByAgesRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByPrograms/AvarageAudienceByAges/Audience.avarageAudienceByAges.actions';
import { LoadAudienceFilterAvarageAudienceByGendersRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByPrograms/AvarageAudienceByGenders/Audience.avarageAudienceByGenders.actions';
import { LoadAudienceFilterAvarageAudienceByFinancialClassesRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByPrograms/AvarageAudienceByFinancialClasses/Audience.avarageAudienceByFinancialClasses.actions';
import { LoadAudienceFilterAvarageAudienceByMonthsRequest } from '../../../../../../store/modules/AudienceModule/OpenTv/ByPrograms/AvarageAudienceByMonths/Audience.avarageAudienceByMonths.actions';
import Months from '../../../../../../helpers/Months';

const useAudienceOpenTvRegularPlazasProgramsRadiographyData = ({
  channel,
  plaza,
  period,
  target,
  program
}: IAudienceByProgramsRadiographyFilterProps) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const targetRef = useRef(target);

  const getPeriod = (periodValue: string) => {
    const [periodWithNumber, year] = periodValue.split('/');
    const [periodNumber, periodIdentifier] = periodWithNumber.split(' ');

    if (periodIdentifier?.includes('TRI')) {
      switch (periodNumber) {
        case '1':
          return (
            Months?.months
              .filter((month) => month.num_trimestre === 1)
              ?.map((month) => `${month.ds_periodo}/${year}`) ?? undefined
          );
        case '2':
          return (
            Months?.months
              .filter((month) => month.num_trimestre === 2)
              ?.map((month) => `${month.ds_periodo}/${year}`) ?? undefined
          );
        case '3':
          return (
            Months?.months
              .filter((month) => month.num_trimestre === 3)
              ?.map((month) => `${month.ds_periodo}/${year}`) ?? undefined
          );
        case '4':
          return (
            Months?.months
              .filter((month) => month.num_trimestre === 4)
              ?.map((month) => `${month.ds_periodo}/${year}`) ?? undefined
          );
        default:
          return undefined;
      }
    } else if (periodIdentifier?.includes('SEM')) {
      switch (periodNumber) {
        case '1':
          return (
            Months?.months
              .filter((month) => month.num_semestre === 1)
              ?.map((month) => `${month.ds_periodo}/${year}`) ?? undefined
          );
        case '2':
          return (
            Months?.months
              .filter((month) => month.num_semestre === 2)
              ?.map((month) => `${month.ds_periodo}/${year}`) ?? undefined
          );
        default:
          return undefined;
      }
    }

    return undefined;
  };

  useEffect(() => {
    targetRef.current = target;
  }, [target]);

  useEffect(() => {
    if (plaza && channel && period && program && targetRef?.current) {
      dispatch(
        LoadAudienceFilterAvarageDatasByProgramsRequest({
          targets: targetRef.current?.value ? [targetRef.current?.value] : [],
          timeRanges: ['Total dia (06h-30H)'],
          weekDays: ['TODOS'],
          programmingGenders: ['TODOS'],
          channels: [channel.value],
          plazas: [plaza.value],
          periods: [period?.value],
          programs: [program.value],
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL'],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByAgesRequest({
          targets:
            audience?.openTv?.targets?.data
              ?.filter(
                (tar) =>
                  tar.name === 'AS ABCDE 04-11' ||
                  tar.name === 'AS ABCDE 12-17' ||
                  tar.name === 'AS ABCDE 18-24' ||
                  tar.name === 'AS ABCDE 25-34' ||
                  tar.name === 'AS ABCDE 35-49' ||
                  tar.name === 'AS ABCDE 50-59' ||
                  tar.name === 'AS ABCDE 60+'
              )
              ?.map((tar) => tar.name) ?? [],
          timeRanges: ['Total dia (06h-30H)'],
          weekDays: ['TODOS'],
          programmingGenders: ['TODOS'],
          channels: [channel.value],
          plazas: [plaza.value],
          periods: [period?.value],
          programs: [program.value],
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL'],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByGendersRequest({
          targets:
            audience?.openTv?.targets?.data
              ?.filter(
                (tar) =>
                  tar.name === 'HH ABCDE 04+' || tar.name === 'MM ABCDE 04+'
              )
              ?.map((tar) => tar.name) ?? [],
          timeRanges: ['Total dia (06h-30H)'],
          weekDays: ['TODOS'],
          programmingGenders: ['TODOS'],
          channels: [channel.value],
          plazas: [plaza.value],
          periods: [period?.value],
          programs: [program.value],
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL'],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByFinancialClassesRequest({
          targets:
            audience?.openTv?.targets?.data
              ?.filter(
                (tar) =>
                  tar.name === 'AS AB 04+' ||
                  tar.name === 'AS C 04+' ||
                  tar.name === 'AS DE 04+'
              )
              ?.map((tar) => tar.name) ?? [],
          timeRanges: ['Total dia (06h-30H)'],
          weekDays: ['TODOS'],
          programmingGenders: ['TODOS'],
          channels: [channel.value],
          plazas: [plaza.value],
          periods: [period?.value],
          programs: [program.value],
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL'],
          table: period?.tp_dado
        })
      );

      dispatch(
        LoadAudienceFilterAvarageAudienceByMonthsRequest({
          targets: targetRef.current?.value ? [targetRef.current?.value] : [],
          timeRanges: ['Total dia (06h-30H)'],
          weekDays: ['TODOS'],
          programmingGenders: ['TODOS'],
          channels: [channel.value],
          periods: getPeriod(period?.value) ?? undefined,
          plazas: [plaza.value],
          nrAno: [period?.nr_ano],
          programs: [program.value],
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL'],
          table: 'mensal'
        })
      );
    }
  }, [
    plaza,
    channel,
    program,
    period,
    audience?.openTv?.targets?.data,
    audience?.openTv?.programs?.data,
    dispatch
  ]);

  const audienceAvarageDataByGenders = useMemo(() => {
    const avarageByGender: {
      [key: string]: {
        id: string;
        label: string;
        color: string;
        value: number;
        percentage: number;
      };
    } = {
      'MM ABCDE 04+': {
        id: 'FEM',
        label: 'Feminino',
        color: '#FDA529',
        value: 0,
        percentage: 0
      },
      'HH ABCDE 04+': {
        id: 'MASC',
        label: 'Masculino',
        color: '#1EC370',
        value: 0,
        percentage: 0
      }
    };

    audience?.openTv?.byPrograms?.avarageAudienceByGenders?.data?.forEach(
      (avarage) => {
        avarageByGender[avarage?.nm_target].value += avarage?.pc_adh ?? 0;
        avarageByGender[avarage?.nm_target].percentage += avarage?.pc_adh ?? 0;
      }
    );

    return Object.values(avarageByGender);
  }, [audience?.openTv?.byPrograms?.avarageAudienceByGenders?.data]);

  const audienceAvarageDataByAges = useMemo(() => {
    const avarageByAges: {
      [key: string]: {
        id: string;
        name: string;
        label: string;
        color: string;
        value: number;
        percentage: number;
      };
    } = {
      'AS ABCDE 04-11': {
        id: '04-11',
        name: '04-11',
        label: '04-11',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 12-17': {
        id: '12-17',
        name: '12-17',
        label: '12-17',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 18-24': {
        id: '18-24',
        name: '18-24',
        label: '18-24',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 25-34': {
        id: '25-34',
        name: '25-34',
        label: '25-34',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 35-49': {
        id: '35-49',
        name: '35-49',
        label: '35-49',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 50-59': {
        id: '50-59',
        name: '50-59',
        label: '50-59',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      },
      'AS ABCDE 60+': {
        id: '60+',
        name: '60+',
        label: '60+',
        color: '#5D43FA',
        value: 0,
        percentage: 0
      }
    };

    audience?.openTv?.byPrograms?.avarageAudienceByAges?.data?.forEach(
      (avarage) => {
        avarageByAges[avarage?.nm_target].value +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
        avarageByAges[avarage?.nm_target].percentage +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
      }
    );

    return Object.values(avarageByAges);
  }, [audience?.openTv?.byPrograms?.avarageAudienceByAges?.data]);

  const audienceAvarageDataByFinancialClasses = useMemo(() => {
    const avarageByFinancialClasses: {
      [key: string]: {
        id: string;
        name: string;
        label: string;
        color: string;
        value: number;
        percentage: number;
      };
    } = {
      'AS AB 04+': {
        id: 'AB',
        name: 'AB',
        label: 'AB',
        color: '#FDA529',
        value: 0,
        percentage: 0
      },
      'AS C 04+': {
        id: 'C',
        name: 'C',
        label: 'C',
        color: '#FC228C',
        value: 0,
        percentage: 0
      },
      'AS DE 04+': {
        id: 'DE',
        name: 'DE',
        label: 'DE',
        color: '#05A6FF',
        value: 0,
        percentage: 0
      }
    };

    audience?.openTv?.byPrograms?.avarageAudienceByFinancialClasses?.data?.forEach(
      (avarage) => {
        avarageByFinancialClasses[avarage?.nm_target].value +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
        avarageByFinancialClasses[avarage?.nm_target].percentage +=
          Number(avarage?.pc_adh?.toFixed(2)) ?? 0;
      }
    );

    return Object.values(avarageByFinancialClasses);
  }, [audience?.openTv?.byPrograms?.avarageAudienceByFinancialClasses?.data]);

  const audienceAvarageDataByMonths = useMemo(() => {
    const order = [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ'
    ];

    return audience?.openTv?.byPrograms?.avarageAudienceByMonths?.data
      ?.map((avarage) => {
        return {
          x: avarage?.ds_periodo ?? '',
          y: avarage?.pc_audiencia ?? 0
        };
      })
      .sort(
        (a, b) =>
          order.indexOf(a?.x?.split('/')?.[0] ?? a?.x) -
          order.indexOf(b?.x?.split('/')?.[0] ?? b?.x)
      );
  }, [audience?.openTv?.byPrograms?.avarageAudienceByMonths?.data]);

  return {
    audienceAvarageDataByGenders,
    audienceAvarageDataByAges,
    audienceAvarageDataByFinancialClasses,
    audienceAvarageDataByMonths
  };
};

export default useAudienceOpenTvRegularPlazasProgramsRadiographyData;
