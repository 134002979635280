import { TypeTV } from '../../../services/shared/Api.service';

export enum ChannelsTypes {
  LOAD_CHANNELS_REQUEST = '@channels/LOAD_CHANNELS_REQUEST',
  LOAD_CHANNELS_SUCCESS = '@channels/LOAD_CHANNELS_SUCCESS',
  LOAD_CHANNELS_ERROR = '@channels/LOAD_CHANNELS_ERROR',
  SET_CHANNELS_TYPETV = '@channels/SET_CHANNELS_TYPETV'
}

export interface Channel {
  description: string;
  channelId: number;
  channelGlobo: 0 | 1;
  notebook: 0 | 1;
}
export interface ChannelAction {
  type: ChannelsTypes;
  payload: {
    rows?: Channel[];
    count?: number;
    message?: string;
    typeTV: TypeTV.CLOSED | TypeTV.OPEN;
  };
}

export interface ChannelsState {
  readonly closedTv: {
    readonly data: Channel[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly count: number;
  };
  readonly openedTv: {
    readonly data: Channel[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly count: number;
  };
}

export interface ChannelsResponse {
  data: {
    rows: Channel[];
    count: number;
  };
  status: number;
  msg: string;
}
