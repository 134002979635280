import { AppThunk } from '../..';
import getAgencies from '../../../services/MediaSimulator/Agencies.service';

import {
  AgenciesAction,
  AgenciesResponse,
  AgenciesTypes
} from './Agencies.types';

export const LoadAgenciesSuccess = (
  response: AgenciesResponse
): AgenciesAction => {
  const payload: AgenciesAction = {
    type: AgenciesTypes.LOAD_AGENCIES_SUCCESS,
    payload: {
      count: response?.data?.count,
      rows: response?.data?.rows,
      message: response?.msg
    }
  };

  return payload;
};

const LoadAgenciesError = (error: string): AgenciesAction => {
  const payload: AgenciesAction = {
    type: AgenciesTypes.LOAD_AGENCIES_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingAgencies = () => ({
  type: AgenciesTypes.LOAD_AGENCIES_REQUEST,
  payload: {}
});

export const LoadAgenciesRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingAgencies());
    const response = await getAgencies();
    dispatch(LoadAgenciesSuccess(response));
  } catch (error: any) {
    dispatch(
      LoadAgenciesError(
        error instanceof Error ? error.message : error?.msg ?? ''
      )
    );
  }
};
