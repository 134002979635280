import { useEffect, useMemo, useRef } from 'react';
import { IAudienceByChannelsComparativeFilterParams } from '../../types';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import getDefaultColor from '../../../../../../../helpers/Colors';
import { LoadPayTvAudienceFilterAudienceAvaragesRequestByChannels } from '../../../../../../../store/modules/AudienceModule/PayTv/byChannels/Comparative/AudienceAvarages/Audience.audienceAvarages.actions';
import { LoadPayTvAudienceFilterShareAvaragesRequestByChannels } from '../../../../../../../store/modules/AudienceModule/PayTv/byChannels/Comparative/ShareAvarages/Audience.shareAvarages.actions';
import { LoadPayTvAudienceFilterAudienceEvolutionsRequestByChannels } from '../../../../../../../store/modules/AudienceModule/PayTv/byChannels/Comparative/AudienceEvolutions/Audience.audienceEvolutions.actions';
import { LoadPayTvAudienceFilterRangeAvaragesRequestByChannels } from '../../../../../../../store/modules/AudienceModule/PayTv/byChannels/Comparative/RangeAvarages/Audience.rangeAvarages.actions';
import { LoadPayTvAudienceFilterAudienceAbsolutesRequestByChannels } from '../../../../../../../store/modules/AudienceModule/PayTv/byChannels/Comparative/AudienceAbsolutes/Audience.audienceAbsolutes.actions';

const useAudiencePayTvChannelsComparativeData = ({
  target,
  timeRanges,
  weekDays,
  programmingGenders,
  programmers,
  channelGeneres,
  channels,
  year
}: IAudienceByChannelsComparativeFilterParams) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const targetRef = useRef(target);
  const timeRangesRef = useRef(timeRanges);
  const weekDaysRef = useRef(weekDays);
  const programmingGendersRef = useRef(programmingGenders);

  useEffect(() => {
    targetRef.current = target;
    timeRangesRef.current = timeRanges;
    weekDaysRef.current = weekDays;
    programmingGendersRef.current = programmingGenders;
  }, [target, timeRanges, weekDays, programmingGenders]);

  useEffect(() => {
    if (
      programmers &&
      programmers.length !== 0 &&
      channelGeneres &&
      channelGeneres.length !== 0 &&
      channels &&
      channels.length !== 0 &&
      year &&
      targetRef.current &&
      timeRangesRef.current &&
      timeRangesRef.current.length !== 0 &&
      weekDaysRef.current &&
      programmingGendersRef.current
    ) {
      dispatch(
        LoadPayTvAudienceFilterAudienceEvolutionsRequestByChannels({
          target: targetRef.current,
          timeRanges: timeRangesRef.current,
          weekDays: weekDaysRef.current,
          programmingGenders: programmingGendersRef.current,
          programmers,
          channelGeneres,
          channels,
          year
        })
      );

      dispatch(
        LoadPayTvAudienceFilterAudienceAvaragesRequestByChannels({
          target: targetRef.current,
          timeRanges: timeRangesRef.current,
          weekDays: weekDaysRef.current,
          programmingGenders: programmingGendersRef.current,
          programmers,
          channelGeneres,
          channels,
          year
        })
      );

      dispatch(
        LoadPayTvAudienceFilterShareAvaragesRequestByChannels({
          target: targetRef.current,
          timeRanges: timeRangesRef.current,
          weekDays: weekDaysRef.current,
          programmingGenders: programmingGendersRef.current,
          programmers,
          channelGeneres,
          channels,
          year
        })
      );

      dispatch(
        LoadPayTvAudienceFilterRangeAvaragesRequestByChannels({
          target: targetRef.current,
          timeRanges: timeRangesRef.current,
          weekDays: weekDaysRef.current,
          programmingGenders: programmingGendersRef.current,
          programmers,
          channelGeneres,
          channels,
          year
        })
      );

      dispatch(
        LoadPayTvAudienceFilterAudienceAbsolutesRequestByChannels({
          target: targetRef.current,
          timeRanges: timeRangesRef.current,
          weekDays: weekDaysRef.current,
          programmingGenders: programmingGendersRef.current,
          programmers,
          channelGeneres,
          channels,
          year
        })
      );
    }
  }, [programmers, channelGeneres, channels, year, dispatch]);

  const monthOrder = useMemo(
    () => [
      'JAN',
      'FEV',
      'MAR',
      'ABR',
      'MAI',
      'JUN',
      'JUL',
      'AGO',
      'SET',
      'OUT',
      'NOV',
      'DEZ'
    ],
    []
  );

  const audienceEvolutionData = useMemo(() => {
    if (audience?.payTv?.byChannels?.audienceEvolutions?.data) {
      const ids = [
        ...new Set(
          audience?.payTv?.byChannels?.audienceEvolutions?.data.map(
            (item) => item?.nm_emissora
          )
        )
      ];

      return ids
        .map((id) => {
          return {
            id,
            color: getDefaultColor(id, 'paytv'),
            data: audience?.payTv?.byChannels?.audienceEvolutions?.data
              .filter((item) => item?.nm_emissora === id)
              .map((item) => {
                return {
                  x: item?.ds_periodo,
                  y: item?.pc_audiencia
                };
              })
              .sort((a, b) => {
                return (
                  monthOrder.indexOf(a?.x?.split('/')[0]) -
                  monthOrder.indexOf(b?.x?.split('/')[0])
                );
              })
          };
        })
        .sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
    }

    return [];
  }, [audience?.payTv?.byChannels?.audienceEvolutions?.data, monthOrder]);

  const audienceAvaragesData = useMemo(() => {
    if (audience?.payTv?.byChannels?.avarageAudience?.data) {
      return audience?.payTv?.byChannels?.avarageAudience?.data
        .map((item) => {
          return {
            name: item?.nm_emissora,
            id: item?.nm_emissora,
            color: getDefaultColor(item?.nm_emissora, 'paytv'),
            label: item?.nm_emissora,
            value: item?.pc_audiencia
          };
        })
        .sort((a, b) => {
          return b.value - a.value;
        });
    }

    return [];
  }, [audience?.payTv?.byChannels?.avarageAudience?.data]);

  const shareAvaragesData = useMemo(() => {
    if (audience?.payTv?.byChannels?.share?.data) {
      return audience?.payTv?.byChannels?.share?.data
        .map((item) => {
          return {
            name: item?.nm_emissora,
            id: item?.nm_emissora,
            color: getDefaultColor(item?.nm_emissora, 'paytv'),
            label: item?.nm_emissora,
            value: item?.pc_share_tle
          };
        })
        .sort((a, b) => {
          return b.value - a.value;
        });
    }

    return [];
  }, [audience?.payTv?.byChannels?.share?.data]);

  const rangeAvaragesData = useMemo(() => {
    if (audience?.payTv?.byChannels?.range?.data) {
      return audience?.payTv?.byChannels?.range?.data
        .map((item) => {
          return {
            name: item?.nm_emissora,
            id: item?.nm_emissora,
            color: getDefaultColor(item?.nm_emissora, 'paytv'),
            label: item?.nm_emissora,
            value: item?.pc_alcance
          };
        })
        .sort((a, b) => {
          return b.value - a.value;
        });
    }

    return [];
  }, [audience?.payTv?.byChannels?.range?.data]);

  const audienceAbsolutesData = useMemo(() => {
    if (audience?.payTv?.byChannels?.absoluteAudience?.data) {
      const ids = [
        ...new Set(
          audience?.payTv?.byChannels?.absoluteAudience?.data.map(
            (item) => item?.nm_emissora
          )
        )
      ];

      return ids
        .map((id) => {
          return {
            id,
            color: getDefaultColor(id, 'paytv'),
            data: audience?.payTv?.byChannels?.absoluteAudience?.data
              .filter((item) => item?.nm_emissora === id)
              .map((item) => {
                return {
                  x: item?.ds_periodo,
                  y: item?.vl_audiencia_abs
                };
              })
              .sort((a, b) => {
                return (
                  monthOrder.indexOf(a?.x?.split('/')[0]) -
                  monthOrder.indexOf(b?.x?.split('/')[0])
                );
              })
          };
        })
        .sort((a, b) => {
          return a.id.localeCompare(b.id);
        });
    }

    return [];
  }, [audience?.payTv?.byChannels?.absoluteAudience?.data, monthOrder]);

  return {
    audienceEvolutionData,
    audienceAvaragesData,
    _shareAvaragesData: shareAvaragesData,
    rangeAvaragesData,
    audienceAbsolutesData
  };
};

export default useAudiencePayTvChannelsComparativeData;
