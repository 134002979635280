import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { useAppMediaQuery, useAppSelector } from '../../../hooks';
import InfoCard, { MetricTypesEnum } from '../InfoCard';
import DonutsChartsCard from '../ChartsCard/DonutsChartsCard';
import { formatterPercent } from '../../../helpers/Formatters';
import {
  MetricsByChannels,
  MetricsByPeriod
} from '../../../store/modules/Competitive/ConsolidatedMetrics/Competitive.consolidated.types';
import PanelContainer, { PanelHeader, SectionHeader } from '../PanelContainer';
import { defaultTarget } from './constants';

enum periodTypePt {
  morning = 'Manhã',
  afternoon = 'Vespertino',
  nightOne = 'Noturno 1',
  nightTwo = 'Noturno 2',
  nightThree = 'Noturno 3',
  earlyMorning = 'Madrugada'
}

export default function ConsolidatedMetrics() {
  const matches = useAppMediaQuery('lg');
  const { consolidatedMetrics, competitive } = useAppSelector((state) => state);

  const generalMetrics = useMemo(
    () =>
      consolidatedMetrics?.data?.metricsByTargets?.filter(
        (x) => x.targetId === defaultTarget
      )[0],
    [consolidatedMetrics]
  );

  const targetMetrics = useMemo(() => {
    if (
      consolidatedMetrics.data &&
      consolidatedMetrics.data.metricsByTargets.length > 1
    ) {
      return consolidatedMetrics.data.metricsByTargets.filter(
        (x) => x.targetId !== defaultTarget
      )[0];
    }

    return generalMetrics;
  }, [generalMetrics, consolidatedMetrics.data]);

  const targetName = useMemo((): string => {
    if (targetMetrics) {
      return competitive.targets.data.filter(
        (t) => t.id === targetMetrics?.targetId
      )[0].name;
    }
    return '-';
  }, [competitive.targets.data, targetMetrics]);

  const direction = useMemo(() => (!matches ? 'column' : 'row'), [matches]);

  return (
    <PanelContainer>
      <PanelHeader text="Informações gerais" />
      <SectionHeader text="Frequência e Afinidade" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <InfoCard
          header={`FREQUÊNCIA MÉDIA: ${targetName}`}
          info={targetMetrics?.avgFrequency || 0}
          infoType={MetricTypesEnum.FIXED}
          dataClarityMask
        />
        <InfoCard
          fractionDigits={0}
          header={`AFINIDADE: ${targetName}`}
          info={targetMetrics?.affinity || 0}
          infoType={MetricTypesEnum.INTEGER}
          dataClarityMask
        />
      </Stack>
      <SectionHeader text="Alcance (%)" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <InfoCard
          header="TOTAL INDIVÍDUOS"
          info={generalMetrics?.percentageCoverage || 0}
          infoType={MetricTypesEnum.PERCENT}
          dataClarityMask
          fractionDigits={2}
        />
        <InfoCard
          header={targetName}
          info={targetMetrics?.percentageCoverage || 0}
          infoType={MetricTypesEnum.PERCENT}
          dataClarityMask
          fractionDigits={2}
        />
      </Stack>
      <SectionHeader text="Alcance Absoluto (Pessoas)" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <InfoCard
          fractionDigits={0}
          header="TOTAL INDIVÍDUOS"
          info={generalMetrics?.absoluteCoverage || 0}
          infoType={MetricTypesEnum.INTEGER}
          dataClarityMask
        />
        <InfoCard
          fractionDigits={0}
          header={targetName}
          info={targetMetrics?.absoluteCoverage || 0}
          infoType={MetricTypesEnum.INTEGER}
          dataClarityMask
        />
      </Stack>
      <SectionHeader text="Impactos" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <InfoCard
          fractionDigits={0}
          header="TOTAL INDIVÍDUOS"
          info={generalMetrics?.impacts || 0}
          infoType={MetricTypesEnum.INTEGER}
          dataClarityMask
        />
        <InfoCard
          fractionDigits={0}
          header={targetName}
          info={targetMetrics?.impacts || 0}
          infoType={MetricTypesEnum.INTEGER}
          dataClarityMask
        />
      </Stack>
      <SectionHeader text="CPM" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <InfoCard
          header="TOTAL INDIVÍDUOS"
          info={generalMetrics?.cpm || 0}
          infoType={MetricTypesEnum.CURRENCY}
          dataClarityMask
        />
        <InfoCard
          header={targetName}
          info={targetMetrics?.cpm || 0}
          infoType={MetricTypesEnum.CURRENCY}
          dataClarityMask
        />
      </Stack>
      <SectionHeader text="Verba" />
      <InfoCard
        header="VERBA LÍQUIDA"
        info={consolidatedMetrics?.data?.totalLiquidInvestment || 0}
        infoType={MetricTypesEnum.CURRENCY}
        dataClarityMask
      />
      <PanelHeader text="Período" />
      <DonutsChartsCard
        direction={direction}
        getMetricId={(x: MetricsByPeriod, percentage: number) => {
          return `${formatterPercent(percentage, { fractionDigits: 1 })} ${
            periodTypePt[x.type]
          } (${x.timeBand})`;
        }}
        getMetricLabel={(x: MetricsByPeriod) => {
          return periodTypePt[x.type];
        }}
        rawData={consolidatedMetrics?.data?.metricsByPeriods ?? []}
        rawLegends={
          consolidatedMetrics?.data?.metricsByPeriods?.map(
            (x) => periodTypePt[x.type]
          ) ?? []
        }
        targetName={targetName}
        totalGrpOrTrp={consolidatedMetrics?.data?.totalGrpOrTrp ?? 0}
        totalInsertions={consolidatedMetrics?.data?.totalInsertions ?? 0}
        totalLiquidInvestment={
          consolidatedMetrics?.data?.totalLiquidInvestment ?? 0
        }
        dataClarityMask
      />
      <PanelHeader text="Emissora" />
      <DonutsChartsCard
        direction={direction}
        getMetricId={(x: MetricsByChannels, percentage: number) => {
          return `${formatterPercent(percentage, { fractionDigits: 1 })} ${
            x.channelName
          }`;
        }}
        getMetricLabel={(x: MetricsByChannels) => {
          return `${x.channelMnemonic}`;
        }}
        rawData={consolidatedMetrics?.data?.metricsByChannels ?? []}
        rawLegends={
          consolidatedMetrics?.data?.metricsByChannels.map(
            (x) => x.channelName
          ) ?? []
        }
        targetName={targetName}
        totalGrpOrTrp={consolidatedMetrics?.data?.totalGrpOrTrp ?? 0}
        totalInsertions={consolidatedMetrics?.data?.totalInsertions ?? 0}
        totalLiquidInvestment={
          consolidatedMetrics?.data?.totalLiquidInvestment ?? 0
        }
        dataClarityMask
      />
    </PanelContainer>
  );
}
