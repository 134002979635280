import './Competitive.scss';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Outlet, useNavigate } from 'react-router-dom';
import CompetitiveFiltersForm from '../../components/Competitive/CompetitiveFilter';
import StyledTabs, { StyledTab } from '../../components/StyledTabs';
import paths from '../../routes/paths';
import { useAppSelector } from '../../hooks';
import classesGTM from '../../config/classesGTM';

const { path, childrens } = paths.home.childrens.competitive;

const Competitive = () => {
  const navigate = useNavigate();
  const { competitiveNav } = useAppSelector((state) => state);
  const [tabValue, setTabValue] = useState(competitiveNav.tab);

  useEffect(() => {
    if (childrens.consolidated.tab === tabValue) {
      navigate(`${path}/${childrens.consolidated.path}`);
    } else if (childrens.comparative.tab === tabValue) {
      navigate(`${path}/${childrens.comparative.path}`);
    }
  }, [tabValue, navigate]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box height="calc(100% - 72px)" sx={{ flexGrow: 1 }}>
      <Grid container height="100%" spacing={0}>
        <Grid className="competitive__container" item padding={5} width={330}>
          <CompetitiveFiltersForm />
        </Grid>
        <Grid container direction="column" item width="calc(100% - 330px)">
          <Grid className="competitive__tabs" height={60} item width="100%">
            <StyledTabs
              allowScrollButtonsMobile
              onChange={handleChange}
              scrollButtons="auto"
              value={competitiveNav.tab}
            >
              <StyledTab
                className={classesGTM.tabs.competitiveConsolided}
                index={childrens.consolidated.tab}
                label="Visão consolidada"
                prefixId="competitive-tab"
              />
              <StyledTab
                className={classesGTM.tabs.competitiveComparative}
                index={childrens.comparative.tab}
                label="Visão comparativa"
                prefixId="competitive-tab"
              />
            </StyledTabs>
          </Grid>
          <Grid height="calc(100% - 60px)" item width="100%">
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Competitive;
