import './Summary.scss';
import { format } from 'date-fns';
import { TypesRow } from './types';
import useSummaryGrid from './useSummaryGrid';
import SummaryGrid from './SummaryGrid';
import useFilters from '../Filters/useFilters';
import { InformationAlert } from '../Information';
import classesGTM from '../../config';

export default function Summary() {
  const { getColumnsMaster, getRowsFromPlanning } = useSummaryGrid();
  const { getCurrentFilters } = useFilters();

  const currentFilters = getCurrentFilters();
  const getBuyDate = () => {
    if (currentFilters?.data?.dates.buy) {
      return new Date(currentFilters.data.dates.buy);
    }
    return new Date();
  };

  const buyDate = new Date(getBuyDate());
  const lastDayOfMonth = new Date(
    buyDate.getFullYear(),
    buyDate.getMonth() + 1,
    0
  );

  return (
    <>
      <div className="mb-4">
        <InformationAlert
          message={`Esta simulação é válida somente até ${format(
            lastDayOfMonth,
            'dd/MM/yyyy'
          )}`}
        />
      </div>
      {currentFilters
        ? currentFilters?.data?.targets.map((target, idx) => {
            const classTagManagerDownXlxs =
              idx === 0
                ? classesGTM.summary.firstExportXlsx
                : classesGTM.summary.secondExportXlsx;

            const classTagManagerExpanded =
              idx === 0
                ? classesGTM.summary.firstExpandChannel
                : classesGTM.summary.secondExpandChannel;

            return (
              <SummaryGrid
                key={target}
                classTagManagerDownXlxs={classTagManagerDownXlxs}
                columns={getColumnsMaster(target, classTagManagerExpanded)}
                rows={getRowsFromPlanning(target).map((row) => ({
                  type: TypesRow.MASTER,
                  expanded: false,
                  ...row
                }))}
                rowsExpanded
                target={target}
                title={`Resumo Geral (Target: ${target})`}
              />
            );
          })
        : null}
    </>
  );
}
