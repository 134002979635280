import axios from 'axios';
import { stringToDate } from '../../helpers/Dates';
import { eventEmitter, Events } from '../../helpers/EventEmitter';
import loginLocalStorage from '../../components/Auth/LoginWrapper/Login.LocalStorage';

const loginStore = loginLocalStorage();

const URL = `${process.env.REACT_APP_API}`;

const api = axios.create({
  baseURL: `${
    URL.lastIndexOf('/') === URL.length - 1 ? URL.slice(0, URL.length - 1) : URL
  }`,
  withCredentials: true
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      loginStore.authenticated.remove();
      eventEmitter.dispatch(Events.LOGIN_RELOAD);
    }

    return Promise.reject(error?.response?.data || error);
  }
);

const apiV1 = axios.create({
  baseURL: `${
    URL.lastIndexOf('/') === URL.length - 1 ? URL.slice(0, URL.length - 1) : URL
  }/api/v1`,
  withCredentials: true
});

apiV1.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      loginStore.authenticated.remove();
      eventEmitter.dispatch(Events.LOGIN_RELOAD);
    }

    return Promise.reject(error?.response?.data || error);
  }
);

export { apiV1, api };

export enum TypeTV {
  OPEN = 'opened',
  CLOSED = 'closed'
}

export interface ApiResponse<T> {
  data: T;
  status: number;
  msg: string;
}

export type ApiErrorResponse = ApiResponse<ApiResponse<any>>;

export const isTokenExpired = () => {
  const exp = loginLocalStorage().expires.get();
  return !exp || stringToDate(exp) < new Date();
};
