import './AlertInfoCard.scss';
import { Card, CardContent, Typography } from '@mui/material';
import infoIcon from '../../../../assets/images/info-alert.svg';
import { InfoCardProps } from './types';

type Props = InfoCardProps;

export default function AlertInfoCard({ text }: Props) {
  return (
    <Card className="alert-card">
      <CardContent className="alert-card__content">
        <img alt="" src={infoIcon} />
        <Typography
          sx={{
            fontSize: '18px',
            lineHeight: '30px',
            marginLeft: '10px'
          }}
        >
          {text}
        </Typography>
      </CardContent>
    </Card>
  );
}
