import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import { IAudienceByChannelsPayTvComparativeFilterProps } from '../../types';
import { LoadPayTvAudienceFilterChannelGeneresRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/ChannelGeneres/Audience.channelGeneres.actions';
import { LoadAudienceFilterChannelsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.actions';
import { LoadAudienceFilterYearsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Years/Audience.years.actions';

const useAudiencePayTvChannelsComparativeFilterLoadData = ({
  programmers,
  channelGeneres,
  channels,
  target,
  timeRanges,
  weekDays,
  programmingGenders
}: Partial<IAudienceByChannelsPayTvComparativeFilterProps>) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      audience?.payTv?.programmers?.count !== 0 &&
      audience?.payTv?.programmers?.count !== null &&
      audience?.payTv?.programmers?.loading === false &&
      target &&
      timeRanges &&
      weekDays &&
      programmingGenders &&
      audience?.payTv?.channelGeneres?.count === null &&
      !audience?.payTv?.channelGeneres?.error &&
      !audience?.payTv?.channelGeneres?.loading
    ) {
      dispatch(
        LoadPayTvAudienceFilterChannelGeneresRequest({
          target,
          timeRanges,
          weekDays,
          programmingGenders,
          programmers
        })
      );
    }
  }, [
    audience?.payTv?.programmers?.count,
    audience?.payTv?.programmers?.loading,
    audience?.payTv?.channelGeneres?.count,
    audience?.payTv?.channelGeneres?.error,
    audience?.payTv?.channelGeneres?.loading,
    dispatch,
    programmers,
    programmingGenders,
    target,
    timeRanges,
    weekDays
  ]);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      channelGeneres?.length &&
      channelGeneres?.length > 0 &&
      audience?.payTv?.channelGeneres?.count !== 0 &&
      audience?.payTv?.channelGeneres?.count !== null &&
      audience?.payTv?.channelGeneres?.loading === false &&
      target &&
      timeRanges &&
      weekDays &&
      programmingGenders &&
      audience?.payTv?.channels?.count === null &&
      !audience?.payTv?.channels?.error &&
      !audience?.payTv?.channels?.loading
    ) {
      dispatch(
        LoadAudienceFilterChannelsRequest({
          target,
          timeRanges,
          weekDays,
          programmingGenders,
          programmers,
          channelGeneres
        })
      );
    }
  }, [
    channelGeneres,
    audience?.payTv?.channelGeneres?.count,
    audience?.payTv?.channelGeneres?.loading,
    audience?.payTv?.channels?.count,
    audience?.payTv?.channels?.error,
    audience?.payTv?.channels?.loading,
    dispatch,
    programmers,
    programmingGenders,
    target,
    timeRanges,
    weekDays
  ]);

  useEffect(() => {
    if (
      programmers?.length &&
      programmers?.length > 0 &&
      channelGeneres?.length &&
      channelGeneres?.length > 0 &&
      channels?.length &&
      channels?.length > 0 &&
      audience?.payTv?.channels?.count !== 0 &&
      audience?.payTv?.channels?.count !== null &&
      audience?.payTv?.channels?.loading === false &&
      target &&
      timeRanges &&
      weekDays &&
      programmingGenders &&
      audience?.payTv?.years?.count === null &&
      !audience?.payTv?.years?.error &&
      !audience?.payTv?.years?.loading
    ) {
      dispatch(
        LoadAudienceFilterYearsRequest({
          target,
          timeRanges,
          weekDays,
          programmingGenders,
          programmers,
          channelGeneres,
          channels
        })
      );
    }
  }, [
    channels,
    audience?.payTv?.channels?.count,
    audience?.payTv?.channels?.loading,
    audience?.payTv?.years?.count,
    audience?.payTv?.years?.error,
    audience?.payTv?.years?.loading,
    channelGeneres,
    dispatch,
    programmers,
    programmingGenders,
    target,
    timeRanges,
    weekDays
  ]);

  const programersOptions = useMemo(() => {
    if (
      audience?.payTv?.programmers?.count &&
      audience?.payTv?.programmers?.count > 0
    ) {
      const option = audience?.payTv?.programmers?.data
        ?.filter((programersOption) => programersOption?.nm_programadora)
        ?.map((programersOption) => {
          return {
            label: programersOption?.nm_programadora,
            value: programersOption?.nm_programadora
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [audience?.payTv?.programmers?.count, audience?.payTv?.programmers.data]);

  const channelGeneresOptions = useMemo(() => {
    if (
      audience?.payTv?.channelGeneres?.count &&
      audience?.payTv?.channelGeneres?.count > 0
    ) {
      const option = audience?.payTv?.channelGeneres?.data
        ?.filter(
          (channelGeneresOption) => channelGeneresOption?.nm_genero_paytv
        )
        ?.map((channelGeneresOption) => {
          return {
            label: channelGeneresOption?.nm_genero_paytv,
            value: channelGeneresOption?.nm_genero_paytv
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [
    audience?.payTv?.channelGeneres?.count,
    audience?.payTv?.channelGeneres.data
  ]);

  const channelsOptions = useMemo(() => {
    if (
      audience?.payTv?.channels?.count &&
      audience?.payTv?.channels?.count > 0
    ) {
      const option = audience?.payTv?.channels?.data
        ?.filter((channelsOption) => channelsOption?.nm_emissora)
        ?.map((channelsOption) => {
          return {
            label: channelsOption?.nm_emissora,
            value: channelsOption?.nm_emissora
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));

      return option;
    }

    return [];
  }, [audience?.payTv?.channels?.count, audience?.payTv?.channels.data]);

  const yearsOptions = useMemo(() => {
    if (audience?.payTv?.years?.count && audience?.payTv?.years?.count > 0) {
      const option = audience?.payTv?.years?.data
        ?.filter((yearOption) => yearOption?.nr_ano)
        ?.map((yearOption) => {
          return {
            label: String(yearOption?.nr_ano),
            value: String(yearOption?.nr_ano)
          };
        });

      return option;
    }

    return [];
  }, [audience?.payTv?.years?.count, audience?.payTv?.years.data]);

  return {
    programersOptions,
    channelGeneresOptions,
    channelsOptions,
    yearsOptions
  };
};

export default useAudiencePayTvChannelsComparativeFilterLoadData;
