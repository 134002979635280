import { toast } from 'react-toastify';
import {
  SecondaryAction,
  SecondaryResponse,
  SecondariesTypes
} from './Secondaries.types';
import getSecondaries from '../../../services/MediaSimulator/Secondaries.service';
import { AppThunk } from '../..';

const LoadSecondariesSuccess = (response: SecondaryResponse) => {
  const payload: SecondaryAction = {
    type: SecondariesTypes.LOAD_SECONDARIES_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg
    }
  };
  return payload;
};

const LoadSecondariesError = (error: string) => {
  const payload: SecondaryAction = {
    type: SecondariesTypes.LOAD_SECONDARIES_ERROR,
    payload: {
      message: error
    }
  };

  return payload;
};

const LoadSecondaries = () => ({
  type: SecondariesTypes.LOAD_SECONDARIES_REQUEST,
  payload: {}
});

const LoadSecondaryRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadSecondaries);
    const response = await getSecondaries();
    if (response.status === 200) {
      dispatch(LoadSecondariesSuccess(response));
    } else {
      dispatch(LoadSecondariesError(response.msg));
    }
  } catch (error) {
    dispatch(LoadSecondariesError(error instanceof Error ? error.message : ''));
    toast.error('Ops, houve um problema ao tentar carregar as secundagens.');
  }
};

export default LoadSecondaryRequest;
