import { ChipTypeMap } from '@mui/material/Chip';
import { CheckboxClasses } from '@mui/material/Checkbox';
import { AutocompleteClasses } from '@mui/material/Autocomplete';

const FIXED_VALUE = { label: 'Todos', value: 'all' };

const listboxSigleProps: React.HTMLAttributes<HTMLUListElement> = {
  className: 'competitive-filter__inputs__listbox--sigle'
};

const listboxProps: React.HTMLAttributes<HTMLUListElement> = {
  className: 'competitive-filter__inputs__listbox'
};

const autoCompleteClasses: Partial<AutocompleteClasses> = {
  inputRoot: 'competitive-filter__inputs'
};

const checkboxClasses: Partial<CheckboxClasses> = {
  root: 'plim-gray1',
  checked: 'plim-violet'
};

const chipProps = (input: any[] | undefined): ChipTypeMap['props'] => {
  return {
    classes: {
      root: 'competitive-filter__inputs__chip',
      deleteIcon: input?.length
        ? 'competitive-filter__inputs__chip--delete'
        : 'competitive-filter__inputs__chip--delete d-none'
    }
  };
};

const inputNames = {
  market: 'market',
  period: 'period',
  sectors: 'sectors',
  clients: 'clients',
  channels: 'channels',
  categories: 'categories',
  brands: 'brands',
  target: 'target',
  commercialTypes: 'commercialTypes'
};

export {
  FIXED_VALUE,
  inputNames,
  checkboxClasses,
  autoCompleteClasses,
  listboxProps,
  listboxSigleProps,
  chipProps
};
