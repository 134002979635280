import { MapCol } from '../types';

export const INIT_ROW_DAYS_OF_WEEK = 16;
export const INIT_ROW_DAYS_OF_WEEK_IDX = INIT_ROW_DAYS_OF_WEEK - 1;

export const mapColsXlsx = Object.freeze<MapCol[]>([
  { name: 'A', colInfo: { width: 12 } },
  { name: 'B', colInfo: { width: 28.5 } },
  { name: 'C', colInfo: { width: 8 } },
  { name: 'D', colInfo: { width: 17 } },
  { name: 'E', colInfo: { width: 5 } },
  { name: 'F', colInfo: { width: 4 } },
  { name: 'G', colInfo: { width: 4 } },
  { name: 'H', colInfo: { width: 4 } },
  { name: 'I', colInfo: { width: 4 } },
  { name: 'J', colInfo: { width: 4 } },
  { name: 'K', colInfo: { width: 4 } },
  { name: 'L', colInfo: { width: 4 } },
  { name: 'M', colInfo: { width: 4 } },
  { name: 'N', colInfo: { width: 4 } },
  { name: 'O', colInfo: { width: 4 } },
  { name: 'P', colInfo: { width: 4 } },
  { name: 'Q', colInfo: { width: 4 } },
  { name: 'R', colInfo: { width: 4 } },
  { name: 'S', colInfo: { width: 4 } },
  { name: 'T', colInfo: { width: 4 } },
  { name: 'U', colInfo: { width: 4 } },
  { name: 'V', colInfo: { width: 4 } },
  { name: 'W', colInfo: { width: 4 } },
  { name: 'X', colInfo: { width: 4 } },
  { name: 'Y', colInfo: { width: 4 } },
  { name: 'Z', colInfo: { width: 4 } },
  { name: 'AA', colInfo: { width: 4 } },
  { name: 'AB', colInfo: { width: 4 } },
  { name: 'AC', colInfo: { width: 4 } },
  { name: 'AD', colInfo: { width: 4 } },
  { name: 'AE', colInfo: { width: 4 } },
  { name: 'AF', colInfo: { width: 4 } },
  { name: 'AG', colInfo: { width: 4 } },
  { name: 'AH', colInfo: { width: 4 } },
  { name: 'AI', colInfo: { width: 4 } },
  { name: 'AJ', colInfo: { width: 4 } },
  { name: 'AK', colInfo: { width: 5 } },
  { name: 'AL', colInfo: { width: 12 } },
  { name: 'AM', colInfo: { width: 17 } },
  { name: 'AN', colInfo: { width: 10 } },
  { name: 'AO', colInfo: { hidden: true } }
]);

export const textsFooter = [
  '1 – Programação acima está sujeita à alteração devido à disponibilidade de espaço e/ou alterações na programação da Globo.',
  '2 – Será aplicada a valoração de acordo com a tabela da data da compra, não podendo ser considerada outras tabelas.',
  '3 - Os valores constantes das Listas de Preços referem-se a vendas à vista. As faturas representativas das exibições e/ou inserções devem ser liquidadas contra a apresentação. O período concedido à agência e ao anunciante para análise de documentação não descaracteriza a venda à vista.',
  '4 – Esta programação terá o faturamento padrão de 15 dfm.',
  '5 – Toda reserva configura compra, que é incancelável.',
  '6 – A administração do processo operacional se regerá pelos Manuais de Práticas Comerciais, disponíveis em https://negocios8.redeglobo.com.br/paginas/midia-kit.aspx'
];
