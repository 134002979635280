export interface Target {
  id: string;
  name: string;
}
export interface AudienceFilterTargetsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Target[];
  readonly count: number;
}

export interface TargetResponse {
  data: {
    rows: Target[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersTargetsType {
  REQUEST = '@audience/payTv/targets/load',
  SUCESS = '@audience/payTv/targets/success',
  ERROR = '@audience/payTv/targets/error'
}

export interface AudienceFilterTargetsAction {
  type: AudienceFiltersTargetsType;
  payload: Omit<TargetResponse, 'status'>;
}
