import { Reducer } from 'redux';
import { ClientsAction, ClientsState, ClientsTypes } from './Clients.types';

const targetInitialState: ClientsState = {
  data: {
    uniques: [],
    all: []
  },
  loading: false,
  error: false,
  count: 0,
  message: ''
};

const reducer: Reducer<ClientsState, ClientsAction> = (
  state = targetInitialState,
  action
): ClientsState => {
  switch (action?.type) {
    case ClientsTypes.LOAD_CLIENTS_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.rows || {
          uniques: [],
          all: []
        },
        count: action?.payload?.count || 0,
        error: false,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case ClientsTypes.LOAD_CLIENTS_ERROR: {
      return {
        ...state,
        data: {
          uniques: [],
          all: []
        },
        error: true,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case ClientsTypes.LOAD_CLIENTS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
