import { SortColumn } from 'react-data-grid';

export interface Ranking {
  emissora: string | undefined;
  periodo: string | undefined;
  programa: string | undefined;
  genero: string | undefined;
  audienciaPc: number | string | undefined;
  share: number | string | undefined;
  audienciaAvg: number | string | undefined;
  alcance: number | undefined;
  exibicoes: number | undefined;
}
export interface AudienceFilterRankingsState {
  readonly data: Ranking[];
  readonly loading: boolean;
  readonly mustBeLoading: boolean;
  readonly error: boolean;
  readonly reload: boolean;
  readonly message: string;
  readonly count: number;
  readonly total: number;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly sortColumns: SortColumn[];
}

export interface RankingResponse {
  data: {
    rows: Ranking[];
    count: number;
    total: number;
  };
  status: number;
  msg: string;
  page?: number;
  rowsPerPage?: number;
  sortColumns?: SortColumn[];
}

export enum AudienceFiltersRankingsType {
  REQUEST = '@audience/Rankings/load',
  SUCESS = '@audience/Rankings/success',
  ERROR = '@audience/Rankings/error',
  CLEAR = '@audience/Rankings/clear',
  PAGE = '@audience/Rankings/page',
  ROWS_PER_PAGE = '@audience/Rankings/rows_per_page',
  SORT_COLUMNS = '@audience/Rankings/sort_columns'
}

export interface AudienceFilterRankingsAction {
  type: AudienceFiltersRankingsType;
  payload: Omit<RankingResponse, 'status'>;
}

export interface RankingState {
  readonly data: Ranking[];
  readonly loading: boolean;
  readonly mustBeLoading: boolean;
  readonly error: boolean;
  readonly reload: boolean;
  readonly message: string;
  readonly count: number;
  readonly total: number;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly sortColumns: SortColumn[];
}

export interface RankingAction {
  type: AudienceFiltersRankingsType;
  payload: {
    simulations?: Ranking[];
    count?: number;
    message?: string;
    search?: string;
    loading?: boolean;
    mustBeLoading?: boolean;
    page?: number;
    rowsPerPage?: number;
    sortColumns?: SortColumn[];
  };
}
