import { CompetitiveOptionalQueryParams } from '../../../../services/shared/types';

export interface Target {
  id: string;
  name: string;
}
export interface CompetitiveFilterTargetsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Target[];
  readonly count: number;
}

export type TargetsQueryParams = CompetitiveOptionalQueryParams;

export interface TargetResponse {
  data: {
    rows: Target[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum CompetitiveFiltersTargetsType {
  REQUEST = '@competitive/targets/load',
  SUCESS = '@competitive/targets/success',
  ERROR = '@competitive/targets/error'
}

export interface CompetitiveFilterTargetsAction {
  type: CompetitiveFiltersTargetsType;
  payload: Omit<TargetResponse, 'status'>;
}
