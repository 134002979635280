import { toast } from 'react-toastify';
import { capitalize } from 'lodash';
import { apiV1 } from '../shared/Api.service';
import {
  ProgramsResponse,
  ProgramsRequest,
  ProgramsQueryParams,
  Program
} from '../../store/modules/Programs/Programs.types';
import { ClosedPeriodTypesEnum } from '../../store/modules/Filters/Filters.types';

const path = '/programs';
const QUANT_MAX_REQUESTS = 10;

async function getProgramsRequest(params: ProgramsRequest) {
  try {
    const { market, channels, dates, targets, typeTV } = params;

    let quantRequest = 0;
    let idx = 1;
    const response = [];
    while (quantRequest < channels.length) {
      const newChannels = channels.slice(
        quantRequest,
        idx * QUANT_MAX_REQUESTS
      );
      const programsPromise = newChannels.map((channel) => {
        const newParams: ProgramsQueryParams = {
          targets,
          typeTV,
          market: market.signal,
          channel: channel.description.toUpperCase(),
          dateFromAudience: dates.audience.from,
          dateToAudience: dates.audience.to,
          dateBuy: dates.buy,
          secondary: 30,
          dateCampaignFrom: dates.campaign.from,
          dateCampaignTo: dates.campaign.to,
          closedPeriodType:
            !dates.audience?.closedPeriod?.type ||
            dates.audience.closedPeriod.type === ClosedPeriodTypesEnum.MONTH
              ? null
              : dates.audience.closedPeriod.type
        };

        return apiV1.get<ProgramsResponse>(path, {
          params: newParams
        });
      });

      // eslint-disable-next-line no-await-in-loop
      const result = await Promise.allSettled(programsPromise);
      response.push(...result);

      quantRequest += QUANT_MAX_REQUESTS;
      idx += 1;
    }

    return response;
  } catch (error) {
    console.error(error);
    throw new Error(
      error instanceof Error
        ? error.message
        : 'Ocorreu um erro na busca dos programs.'
    );
  }
}

export default async function getPrograms(params: ProgramsRequest) {
  const programsByChannel = await getProgramsRequest(params);

  const unauthorized = programsByChannel.find(
    (program) => program.status === 'rejected' && program.reason?.status === 401
  );

  if (unauthorized && unauthorized.status === 'rejected') {
    return [];
  }

  const allPrograms = programsByChannel.reduce<Program[]>(
    (accPrograms, response, idx) => {
      if (response.status === 'rejected') {
        if (response.reason?.message) {
          throw new Error(response.reason.message);
        }

        toast.error(
          `Ops, houve um problema ao carregar os programas do canal ${capitalize(
            params.channels[idx]?.description
          )}.`
        );
        return accPrograms;
      }
      return [...accPrograms, ...response.value.data.data.rows];
    },
    []
  );

  return allPrograms;
}
