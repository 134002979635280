import { toast } from 'react-toastify';

import { AppThunk } from '../../../../../..';
import {
  AudienceFilterAvarageAudienceByProgrammingGendersAction,
  AudienceFiltersAvarageAudienceByProgrammingGendersType,
  AvarageAudienceByProgrammingGenderResponse
} from './Audience.avarageAudienceByProgrammingGenders.types';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import getPayTvAvarageAudienceByProgrammingGendersByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.avarageAudienceByProgrammingGenders.service';

const LoadAudienceFilterAvarageAudienceByProgrammingGendersSuccess = (
  response: AvarageAudienceByProgrammingGenderResponse
) => {
  const payload: AudienceFilterAvarageAudienceByProgrammingGendersAction = {
    type: AudienceFiltersAvarageAudienceByProgrammingGendersType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByProgrammingGendersError = (
  error: string
) => {
  const payload: AudienceFilterAvarageAudienceByProgrammingGendersAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByProgrammingGendersType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByProgrammingGendersFilter = () => ({
  type: AudienceFiltersAvarageAudienceByProgrammingGendersType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByProgrammingGendersData = () => ({
  type: AudienceFiltersAvarageAudienceByProgrammingGendersType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByProgrammingGendersRequest =
  ({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    channel,
    period,
    table
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByProgrammingGendersFilter());
      const response =
        await getPayTvAvarageAudienceByProgrammingGendersByChannels({
          target,
          timeRanges,
          weekDays,
          programmingGenders,
          channel,
          period,
          table: table ?? 'anual',
          fields: ['pc_audiencia', 'nm_genero']
        });

      dispatch(
        LoadAudienceFilterAvarageAudienceByProgrammingGendersSuccess(response)
      );
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByProgrammingGendersError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por gênero de programação'
      );
    }
  };
