import { SortColumn } from 'react-data-grid';

export enum SummaryTypes {
  REMOVE_SUMMARY_SCENERY = '@summary/REMOVE_SUMMARY_SCENERY',
  SET_SUMMARY_SORTED_COLUMNS = '@summary/SET_SUMMARY_SORTED_COLUMNS',
  RESET_SUMMARY = '@summary/RESET_SUMMARY'
}

export interface Summary {
  sortedColumns: SortColumn[];
  sceneryId: string;
}

type SummaryPayload = Partial<Summary>;

export interface SummaryAction {
  type: SummaryTypes;
  payload: SummaryPayload;
}

export type SummaryState = Readonly<Summary>;
