import { useEffect, useRef, memo } from 'react';
import { useField } from '@unform/core';
import './Input.scss';

import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

interface Props {
  name: string;
  label?: string;
  onClearError?(name: string): void;
}

type FullProps = Props & TextFieldProps;

const Input = ({ name, label, onClearError, ...rest }: FullProps) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const { fieldName, registerField, error, clearError } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    });
  }, [fieldName, registerField, inputRef]);

  const handleClearError = () => {
    if (onClearError) onClearError(name);
  };

  return (
    <>
      <TextField
        error={!!error}
        inputRef={inputRef}
        label={label}
        onBlur={handleClearError}
        onFocus={clearError}
        {...rest}
      />
      <FormHelperText>{error}</FormHelperText>
    </>
  );
};
Input.defaultProps = {
  label: null,
  onClearError: undefined
};
export default memo(Input);
