import { AllParams } from '../../../../services/shared/types';

export enum CompetitiveChannelsTypes {
  LOAD_CHANNELS_REQUEST = '@competitive/channels/request',
  LOAD_CHANNELS_SUCCESS = '@competitive/channels/success',
  LOAD_CHANNELS_ERROR = '@competitive/channels/error'
}

export interface CompetitiveChannel {
  channel: string;
  mnemonic: string;
}

export interface CompetitiveChannelAction {
  type: CompetitiveChannelsTypes;
  payload: {
    rows: CompetitiveChannel[];
    count: number;
    message: string;
  };
}

export interface CompetitiveChannelsState {
  readonly data: CompetitiveChannel[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface CompetitiveChannelsResponse {
  data: {
    rows: CompetitiveChannel[];
    count: number;
  };
  status: number;
  msg: string;
}
export interface CompetitiveChannelsQueryParams extends AllParams {
  sectors?: string[];
  clients?: string[];
}

export interface ChannelWithDiscount {
  mnemonic: string;
  channelName: string;
  discount: number;
}
