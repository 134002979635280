import { Channel } from '../../store/modules/Channels/Channels.types';

export type SummaryMaster = {
  totalInserts: number;
  grp: number;
  trp: number;
  totalImpacts: number;
  cpm: number;
  cpp: number;
  totalInvestment: number;
  totalAvgDiscount: number;
  totalNegotiated: number;
};
export interface SummaryDetailProps extends SummaryMaster {
  name: string;
  initials: string;
}

export enum TypesRow {
  MASTER = 'MASTER',
  DETAIL = 'DETAIL'
}
export interface SummaryDetailPropsGrid {
  type: TypesRow.DETAIL;
  programs: SummaryDetailProps[];
}

export interface SummaryProps extends SummaryMaster {
  channel: Channel;
  programs: SummaryDetailProps[]; // TODO: Pode manter separado, e realizar um filter nos programas relacionados ao canal
}

export interface SummaryPropsGrid extends SummaryProps {
  expanded: boolean;
  type: TypesRow.MASTER;
}

export type SummaryRow = SummaryPropsGrid | SummaryDetailPropsGrid;
export type SummaryRowDetail = SummaryDetailProps;
