import { Reducer } from 'redux';
import {
  AudienceFilterChannelsAction,
  AudienceFilterChannelsState,
  AudienceFiltersChannelsType
} from './Audience.channels.types';

const channelInitialState: AudienceFilterChannelsState = {
  error: false,
  loading: false,
  data: [],
  count: null,
  requests: 0
};

const reducer: Reducer<
  AudienceFilterChannelsState,
  AudienceFilterChannelsAction
> = (state = channelInitialState, action): AudienceFilterChannelsState => {
  switch (action.type) {
    case AudienceFiltersChannelsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count,
        requests: state.requests + 1
      };
    }

    case AudienceFiltersChannelsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null,
        requests: state.requests + 1
      };
    }

    case AudienceFiltersChannelsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersChannelsType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
