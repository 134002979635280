import { useCallback, useMemo } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import { summaryInitialState } from '../../store/modules/Summary/Summary.reducer';

export default function useSummaryRedux() {
  const { summary, simulation } = useAppSelector((state) => state);

  const getSummary = useCallback(
    (sceneryId: string) => {
      const curSummary = summary.find((summ) => summ.sceneryId === sceneryId);
      return curSummary || summaryInitialState[0];
    },
    [summary]
  );

  const currentSummary = useMemo(
    () => getSummary(simulation.currentSceneryId),
    [getSummary, simulation.currentSceneryId]
  );

  return { getSummary, currentSummary };
}
