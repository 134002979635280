export interface AvarageAudienceByWeekday {
  pc_audiencia: number;
  tp_dado: string;
  ds_periodo: string;
  dt_periodo: string;
}
export interface AudienceFilterAvarageAudienceByMonthsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByWeekday[];
  readonly count: number | null;
}

export interface AvarageAudienceByWeekdayResponse {
  data: {
    rows: AvarageAudienceByWeekday[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByMonthsType {
  REQUEST = '@audience/openTv/byPrograms/avarageAudienceByMonths/load',
  SUCESS = '@audience/openTv/byPrograms/avarageAudienceByMonths/success',
  ERROR = '@audience/openTv/byPrograms/avarageAudienceByMonths/error',
  CLEAR = '@audience/openTv/byPrograms/avarageAudienceByMonths/clear'
}

export interface AudienceFilterAvarageAudienceByMonthsAction {
  type: AudienceFiltersAvarageAudienceByMonthsType;
  payload: Omit<AvarageAudienceByWeekdayResponse, 'status'>;
}
