import { SortColumn } from 'react-data-grid';
import { AppThunk } from '../..';
import { SummaryAction, SummaryTypes } from './Summary.types';

const SetSummarySortedColumns =
  (sortedColumns: SortColumn[]): AppThunk =>
  async (dispatch, getState) => {
    const action: SummaryAction = {
      type: SummaryTypes.SET_SUMMARY_SORTED_COLUMNS,
      payload: {
        sceneryId: getState().simulation.currentSceneryId,
        sortedColumns
      }
    };
    dispatch(action);
  };

export const ResetSummary = () => ({
  type: SummaryTypes.RESET_SUMMARY
});

export const RemoveSummaryScenery = (sceneryId: string): SummaryAction => ({
  type: SummaryTypes.REMOVE_SUMMARY_SCENERY,
  payload: { sceneryId }
});

export default SetSummarySortedColumns;
