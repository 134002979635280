// SCSS
import '../../Menu.scss';
import React, { ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import { attributeMaskOrUnmask } from '../../../../helpers/Utils';
import AppSVG from '../../../SVG/AppSVG';

export interface MenuUserItemProps {
  itemId: number;
  icon: string;
  path?: string;
  disabled?: boolean;
  visibled: boolean;
  divider?: boolean;
  selected?: boolean;
  dataMask?: boolean;
  onClick?(id?: number): void;
  classes?: {
    item?: string;
    itemSelected?: string;
    button?: string;
    tagManager?: string;
  };
  childrenAfterButton?: ReactNode;
  childrenBeforeButton?: ReactNode;
}

const defaultClasses = {
  item: 'user-menu__item',
  button: 'user-menu__button',
  itemSelected: 'user-menu--selected'
};

const MenuUserItem: React.FC<MenuUserItemProps> = ({
  itemId,
  icon,
  classes,
  path,
  onClick,
  childrenAfterButton,
  childrenBeforeButton,
  disabled = false,
  selected = false,
  visibled = true,
  divider = false,
  dataMask = false
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;

    if (onClick) onClick(itemId);
    if (path && location.pathname !== path) {
      navigate(path);
    }
  };

  const fullClasses = {
    ...defaultClasses,
    ...classes
  };

  return visibled ? (
    <>
      {divider ? (
        <Divider
          flexItem
          orientation="vertical"
          sx={{ height: '23px', alignSelf: 'center' }}
        />
      ) : null}
      <div
        className={`no-select ${fullClasses?.item || ''} ${
          selected ? fullClasses.itemSelected : ''
        } ${disabled ? 'menu--disabled' : ''}`}
        data-testid="user-menu__item"
        {...attributeMaskOrUnmask(dataMask)}
      >
        {childrenBeforeButton}
        <div
          className={`${fullClasses?.tagManager || ''} ${
            fullClasses?.button || ''
          } ${disabled ? 'menu__item--button-disabled no-pointer-events' : ''}`}
          data-testid="user-menu__item--button"
          onClick={handleClick}
          role="button"
          tabIndex={0}
        >
          <AppSVG
            className="no-pointer-events"
            loader={<Skeleton height={24} width={24} />}
            src={icon}
          />
        </div>
        {childrenAfterButton}
      </div>
    </>
  ) : null;
};

MenuUserItem.defaultProps = {
  childrenAfterButton: undefined,
  childrenBeforeButton: undefined,
  classes: undefined,
  dataMask: false,
  disabled: undefined,
  divider: undefined,
  onClick: undefined,
  path: undefined,
  selected: undefined
};

export default MenuUserItem;
