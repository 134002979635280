import { Reducer } from 'redux';
import { ChannelsState, ChannelsTypes, ChannelAction } from './Channels.types';
import { TypeTV } from '../../../services/shared/Api.service';

const targetInitialState: ChannelsState = {
  closedTv: {
    data: [],
    loading: false,
    error: false,
    count: 0
  },
  openedTv: {
    data: [],
    loading: false,
    error: false,
    count: 0
  }
};

const reducer: Reducer<ChannelsState, ChannelAction> = (
  state = targetInitialState,
  action
): ChannelsState => {
  switch (action.type) {
    case ChannelsTypes.LOAD_CHANNELS_SUCCESS: {
      if (action.payload.rows && action.payload.count !== undefined) {
        const data = action.payload.rows.map((row) => ({
          ...row,
          channelId: row.channelId || 0
        }));

        if (action.payload.typeTV === TypeTV.OPEN) {
          return {
            ...state,
            openedTv: {
              ...state.openedTv,
              data,
              count: action.payload.count,
              error: false,
              loading: false
            }
          };
        }
        return {
          ...state,
          closedTv: {
            ...state.closedTv,
            data,
            count: action.payload.count,
            error: false,
            loading: false
          }
        };
      }
      return state;
    }
    case ChannelsTypes.LOAD_CHANNELS_ERROR: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            data: [],
            error: true,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          data: [],
          error: true,
          loading: false
        }
      };
    }
    case ChannelsTypes.LOAD_CHANNELS_REQUEST: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            error: false,
            loading: true
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          error: false,
          loading: true
        }
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
