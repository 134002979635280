import './Univers.scss';

interface Props {
  channel: string;
  viewer: string | number;
  source: string;
}

const UniversInfo = ({ channel, viewer, source }: Props) => {
  return (
    <div className="univers-info">
      <span className="univers-info__channel">{channel}</span>
      <div>
        <span className="univers-info__channel--value">{viewer}</span>
        <span className="univers-info__channel--source">{source}</span>
      </div>
    </div>
  );
};

export default UniversInfo;
