import { Reducer } from 'redux';
import {
  CompetitiveBrandsState,
  CompetitiveBrandAction,
  CompetitiveBrandsTypes
} from './Competitive.brands.types';

const targetInitialState: CompetitiveBrandsState = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const reducer: Reducer<CompetitiveBrandsState, CompetitiveBrandAction> = (
  state = targetInitialState,
  action
): CompetitiveBrandsState => {
  switch (action.type) {
    case CompetitiveBrandsTypes.LOAD_BRANDS_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        loading: false,
        error: false
      };
    }
    case CompetitiveBrandsTypes.LOAD_BRANDS_ERROR: {
      return {
        ...state,
        data: [],
        count: 0,
        loading: false,
        error: true
      };
    }
    case CompetitiveBrandsTypes.LOAD_BRANDS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
