import { isArray } from 'lodash';

import { apiV1 } from '../../shared/Api.service';
import { RankingResponse } from '../../../store/modules/AudienceModule/PayTv/ProgramRanking/Audience.programRanking.types';
import { IAudienceByProgramsRakingsRequest } from '../../../components/Audience/AudiencePanels/PayTv/Programs/types';

const path = '/audience/pay-tv/programs/ranking/items';

export default async function getPayTvRanking({
  programmers,
  channelGeneres,
  channels,
  programmingGenders,
  target,
  timeRange,
  channelsNotIn,
  program,
  period,
  order,
  limit,
  offset
}: IAudienceByProgramsRakingsRequest): Promise<RankingResponse> {
  const { data } = await apiV1.get<RankingResponse>(path, {
    params: {
      channelGeneres: channelGeneres?.map(
        (channelGenere) => channelGenere.value
      ),
      channelCompanies: programmers?.map((programmer) => programmer.value),
      channels: channels?.map((channel) => channel.value),
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders,
      target: target?.value,
      timeRange: timeRange?.value,
      channelsNotIn,
      program: program?.value ?? 'TODOS',
      period: period?.value ?? new Date().getFullYear(),
      order: order ?? 'audienciaPc',
      limit: limit ?? 10,
      offset: offset ?? 0
    }
  });
  return data;
}
