/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import {
  AudienceFilterShareAvaragesAction,
  AudienceFiltersShareAvaragesType,
  ShareAvarageResponse
} from './Audience.shareAvarages.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AppThunk } from '../../../../../..';
import getPayTvShareAvaragesByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.shareAvarages.service';

const LoadAudienceFilterShareAvaragesSuccess = (
  response: ShareAvarageResponse
) => {
  const payload: AudienceFilterShareAvaragesAction = {
    type: AudienceFiltersShareAvaragesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterShareAvaragesError = (error: string) => {
  const payload: AudienceFilterShareAvaragesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersShareAvaragesType.ERROR
  };

  return payload;
};

const LoadingAudienceShareAvaragesFilter = () => ({
  type: AudienceFiltersShareAvaragesType.REQUEST,
  payload: {}
});

export const ClearAudienceShareAvaragesData = () => ({
  type: AudienceFiltersShareAvaragesType.CLEAR,
  payload: {}
});

export const LoadPayTvAudienceFilterShareAvaragesRequestByChannels =
  ({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    programmers,
    channelGeneres,
    channels,
    channelsNotIn,
    year
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceShareAvaragesFilter());
      const response = await getPayTvShareAvaragesByChannels({
        target,
        programmers,
        channelGeneres,
        channels,
        timeRanges,
        weekDays,
        programmingGenders,
        year,
        fields: ['pc_share_tle', 'nm_emissora'],
        table: 'anual',
        channelsNotIn
      });

      dispatch(LoadAudienceFilterShareAvaragesSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterShareAvaragesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de programação.'
      );
    }
  };
