import { Reducer } from 'redux';
import {
  AudienceFilterAudienceAbsolutesAction,
  AudienceFilterAudienceAbsolutesState,
  AudienceFiltersAudienceAbsolutesType
} from './Audience.audienceAbsolutes.types';

const audienceAbsoluteInitialState: AudienceFilterAudienceAbsolutesState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterAudienceAbsolutesState,
  AudienceFilterAudienceAbsolutesAction
> = (
  state = audienceAbsoluteInitialState,
  action
): AudienceFilterAudienceAbsolutesState => {
  switch (action.type) {
    case AudienceFiltersAudienceAbsolutesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAudienceAbsolutesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAudienceAbsolutesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAudienceAbsolutesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
