/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import {
  AudienceFilterAudienceEvolutionsAction,
  AudienceFiltersAudienceEvolutionsType,
  AudienceEvolutionResponse
} from './Audience.audienceEvolutions.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AppThunk } from '../../../../../..';
import getPayTvAudienceEvolutionsByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.audienceEvolutions.service';

const LoadAudienceFilterAudienceEvolutionsSuccess = (
  response: AudienceEvolutionResponse
) => {
  const payload: AudienceFilterAudienceEvolutionsAction = {
    type: AudienceFiltersAudienceEvolutionsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAudienceEvolutionsError = (error: string) => {
  const payload: AudienceFilterAudienceEvolutionsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAudienceEvolutionsType.ERROR
  };

  return payload;
};

const LoadingAudienceAudienceEvolutionsFilter = () => ({
  type: AudienceFiltersAudienceEvolutionsType.REQUEST,
  payload: {}
});

export const ClearAudienceAudienceEvolutionsData = () => ({
  type: AudienceFiltersAudienceEvolutionsType.CLEAR,
  payload: {}
});

export const LoadPayTvAudienceFilterAudienceEvolutionsRequestByChannels =
  ({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    programmers,
    channelGeneres,
    channels,
    channelsNotIn,
    year
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAudienceEvolutionsFilter());
      const response = await getPayTvAudienceEvolutionsByChannels({
        target,
        programmers,
        channelGeneres,
        channels,
        timeRanges,
        weekDays,
        programmingGenders,
        year,
        fields: ['pc_audiencia', 'nm_emissora', 'ds_periodo'],
        table: 'mensal',
        channelsNotIn
      });

      dispatch(LoadAudienceFilterAudienceEvolutionsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAudienceEvolutionsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de programação.'
      );
    }
  };
