import capitalize from 'lodash/capitalize';
import React from 'react';
import ModalShell from '../ModalShell';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  onClickCancel(): void;
  onClickOk(): void;
  open: boolean;
  actualProgram: string;
}

const ModalPlanning: React.FC<Props> = ({
  onClickCancel,
  onClickOk,
  open = false,
  actualProgram
}) => {
  const handleClickOk = () => {
    onClickOk();
  };

  const handleClickCancel = () => {
    onClickCancel();
  };

  return (
    <ModalShell
      labelBtnCancel="não"
      labelBtnOK="sim, alterar"
      onClickCancel={handleClickCancel}
      onClickOK={handleClickOk}
      open={open}
      title="Deseja alterar a inserção do programa?"
    >
      <span {...attributeMaskOrUnmask(true)}>
        Ao alterar o campo inserção do programa&nbsp;
        <strong>{capitalize(actualProgram.toLowerCase())}</strong>
        &nbsp;os valores atuais serão excluídos e ocorrerá a distribuição com o
        novo valor de inserção digitado!
      </span>
    </ModalShell>
  );
};

export default ModalPlanning;
