import '../AudiencePayTvProgramsFilters.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Grid } from '@mui/material';

import FormContainer from '../../../../../Form/FormContainer';
import { IAudienceByProgramsRankingFilterProps, TypePeriod } from '../types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import useAudiencePayTvProgramsRankingFilterLoadData from './hooks/useAudiencePayTvProgramsRankingFilterLoadData';
import useAudiencePayTvProgramsRankingFilterAutoSelect from './hooks/useAudiencePayTvProgramsRankingFilterAutoSelect';
import { ClearAudienceChannelGeneresData } from '../../../../../../store/modules/AudienceModule/PayTv/ChannelGeneres/Audience.channelGeneres.actions';
import { ClearAudienceChannelsData } from '../../../../../../store/modules/AudienceModule/PayTv/Channels/Audience.channels.actions';
import { ClearAudiencePeriodsData } from '../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';
import { OptionsList } from '../../../../../Form/types';
import { ClearAudienceProgrammingGendersData } from '../../../../../../store/modules/AudienceModule/PayTv/byPrograms/ProgrammingGenders/Audience.programmingGenders.actions';
import {
  checkboxClasses,
  chipProps,
  listboxProps,
  listboxSigleProps
} from '../../../../../Competitive/CompetitiveFilter/constants';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';

const AudienceByPayTvProgramsRankingFilter: React.FC<
  IAudienceByProgramsRankingFilterProps
> = ({
  programmers,
  channelGeneres,
  channels,
  programmingGenders,
  period,
  setProgrammers,
  setChannelGeneres,
  setChannels,
  setProgrammingGenders,
  setPeriod,
  newFilter,
  setNewFilter,
  target,
  timeRange
}) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const FIXED_VALUE = useMemo(
    () => ({
      label: 'Todos',
      value: 'all'
    }),
    []
  );
  const inputsConfig = useMemo(
    () => ({
      programmers: {
        name: 'programmers',
        defaultValue: [FIXED_VALUE]
      },
      channelGeneres: {
        name: 'channelGenres',
        defaultValue: [FIXED_VALUE]
      },
      channels: {
        name: 'channels',
        defaultValue: [FIXED_VALUE]
      },
      programmingGenders: {
        name: 'programmingGenders',
        defaultValue: [FIXED_VALUE]
      },
      period: {
        name: 'period',
        defaultValue: '2024'
      }
    }),
    [FIXED_VALUE]
  );

  const {
    programersOptions,
    channelGeneresOptions,
    channelsOptions,
    programmingGendersOptions,
    periodOptions
  } = useAudiencePayTvProgramsRankingFilterLoadData({
    programmers,
    channelGeneres,
    channels,
    period,
    target,
    timeRange,
    programmingGenders
  });

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  useAudiencePayTvProgramsRankingFilterAutoSelect({
    programmers,
    programmingGenders,
    channelGeneres,
    channels,
    period,
    setProgrammers,
    setChannelGeneres,
    setChannels,
    setPeriod,
    clearInputs,
    FIXED_VALUE,
    inputsConfig
  });

  const handleProgrammerChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceChannelGeneresData());
    clearInputs([
      inputsConfig.channelGeneres.name,
      inputsConfig.channels.name,
      inputsConfig.programmingGenders.name,
      inputsConfig.period.name
    ]);

    setProgrammers(option);
  };

  const handleChannelGenreChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceChannelsData());
    clearInputs([
      inputsConfig.channels.name,
      inputsConfig.programmingGenders.name,
      inputsConfig.period.name
    ]);

    setChannelGeneres(option);
  };

  const handleChannelChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([
      inputsConfig.programmingGenders.name,
      inputsConfig.period.name
    ]);

    setChannels(option);
  };

  const handleProgrammingGenderChange = (option: OptionsList[]) => {
    dispatch(ClearAudiencePeriodsData());
    clearInputs([inputsConfig.period.name]);

    setProgrammingGenders(option);
  };

  const handlePeriodChange = (option: TypePeriod) => {
    setPeriod(option);
  };

  const handleSendClick = () => {
    return;
  };

  useEffect(() => {
    setPeriod(periodOptions?.[0]);
  }, [periodOptions, setPeriod]);

  useEffect(() => {
    if (newFilter) {
      clearInputs([
        inputsConfig.programmers.name,
        inputsConfig.channelGeneres.name,
        inputsConfig.channels.name,
        inputsConfig.programmingGenders.name,
        inputsConfig.period.name
      ]);
      setProgrammers(undefined);
      setChannelGeneres(undefined);
      setChannels(undefined);
      setProgrammingGenders(undefined);
      setPeriod(undefined);

      if (setNewFilter) setNewFilter(false);
    }
  }, [
    newFilter,
    setNewFilter,
    setProgrammers,
    setChannelGeneres,
    setChannels,
    setProgrammingGenders,
    setPeriod,
    inputsConfig
  ]);

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(programmers)}
          /* classes={} */
          data-testid={inputsConfig.programmers?.name}
          disableCloseOnSelect
          disabled={
            audience?.payTv?.programmers.loading ||
            audience?.payTv?.programmers.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Programadoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.payTv?.programmers.loading}
          multiple
          name={inputsConfig.programmers?.name}
          onChange={handleProgrammerChange}
          options={programersOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channelGeneres)}
          /* classes={} */
          data-testid={inputsConfig.channelGeneres?.name}
          disableCloseOnSelect
          disabled={
            audience?.payTv?.channelGeneres.loading ||
            audience?.payTv?.channelGeneres.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Gêneros das Emissoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.payTv?.channelGeneres.loading}
          multiple
          name={inputsConfig.channelGeneres?.name}
          onChange={handleChannelGenreChange}
          options={channelGeneresOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channels)}
          /* classes={} */
          data-testid="channels"
          disableCloseOnSelect
          disabled={
            audience?.payTv?.channels.loading ||
            audience?.payTv?.channels.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Emissoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.payTv?.channels.loading}
          multiple
          name={inputsConfig.channels?.name}
          onChange={handleChannelChange}
          options={channelsOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(programmingGenders)}
          data-testid="programmingGenders"
          disableCloseOnSelect
          disabled={
            audience?.payTv?.byPrograms?.programmingGenders?.loading ||
            audience?.payTv?.byPrograms?.programmingGenders?.count === 0
          }
          loading={audience?.payTv?.byPrograms?.programmingGenders?.loading}
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Gêneros de Programação"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          multiple
          name="programmingGenders"
          onChange={handleProgrammingGenderChange}
          options={programmingGendersOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Período"
          name={inputsConfig.period?.name}
          data-testid="period"
          disabled={
            audience?.payTv?.periods.loading ||
            audience?.payTv?.periods.count === 0
          }
          loading={audience?.payTv?.periods.loading}
          ListboxProps={listboxSigleProps}
          options={periodOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={periodOptions[0]}
          onChange={handlePeriodChange}
          sx={{
            width: '20%',
            marginLeft: 'auto',
            marginRight: '-70px'
          }}
        />
      </FormContainer>
    </Grid>
  );
};

export default AudienceByPayTvProgramsRankingFilter;
