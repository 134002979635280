export interface AvarageAudienceByAge {
  pc_adh: number;
  nm_target: string;
}
export interface AudienceFilterAvarageAudienceByAgesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByAge[];
  readonly count: number | null;
}

export interface AvarageAudienceByAgeResponse {
  data: {
    rows: AvarageAudienceByAge[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByAgesType {
  REQUEST = '@audience/payTv/byChannels/avarageAudienceByAges/load',
  SUCESS = '@audience/payTv/byChannels/avarageAudienceByAges/success',
  ERROR = '@audience/payTv/byChannels/avarageAudienceByAges/error',
  CLEAR = '@audience/payTv/byChannels/avarageAudienceByAges/clear'
}

export interface AudienceFilterAvarageAudienceByAgesAction {
  type: AudienceFiltersAvarageAudienceByAgesType;
  payload: Omit<AvarageAudienceByAgeResponse, 'status'>;
}
