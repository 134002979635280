enum GenderColors {
  'INFANTIS' = '#974706',
  'ENTRETENIMENTO' = '#d500f9',
  'ESPORTES' = '#006600',
  'NOTÍCIAS' = '#000000',
  'FILMES' = '#ffc000',
  'DOCUMENTÁRIOS' = '#1e2a8f',
  'FILMES E SÉRIES' = '#ff1a1a',
  'LIFESTYLE' = '#8dbf60',
  'OCP' = '#6d6e71'
}

export default GenderColors;
