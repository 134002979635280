import React, { HTMLAttributes } from 'react';
import { attributeMaskOrUnmask } from '../../../../helpers/Utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode | React.ReactNode[];
  dataClarityMask?: boolean;
}

export default function MetricCardContent({
  children,
  className = '',
  dataClarityMask,
  ...rest
}: Props) {
  return (
    <div
      className={'metric-card__content'.concat(' ', className).trim()}
      {...attributeMaskOrUnmask(dataClarityMask)}
      {...rest}
    >
      {children}
    </div>
  );
}

MetricCardContent.defaultProps = {
  dataClarityMask: false
};
