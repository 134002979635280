import { Reducer } from 'redux';
import { SummaryAction, SummaryState, SummaryTypes } from './Summary.types';

export const summaryInitialState: SummaryState[] = [
  {
    sceneryId: '',
    sortedColumns: []
  }
];

const reducer: Reducer<SummaryState[], SummaryAction> = (
  state = summaryInitialState,
  action
): SummaryState[] => {
  switch (action.type) {
    case SummaryTypes.RESET_SUMMARY: {
      return summaryInitialState;
    }
    case SummaryTypes.SET_SUMMARY_SORTED_COLUMNS: {
      const summaryNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      ) || { ...summaryInitialState[0], sceneryId: action.payload.sceneryId };

      if (currentState && action?.payload?.sortedColumns) {
        return [
          ...summaryNotCurrentScenery,
          {
            ...currentState,
            sortedColumns: action.payload.sortedColumns,
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }
      return state;
    }
    case SummaryTypes.REMOVE_SUMMARY_SCENERY: {
      return state.filter((s) => s.sceneryId !== action.payload.sceneryId);
    }
    default: {
      return state;
    }
  }
};
export default reducer;
