import { ApiResponse } from '../../../services/shared/Api.service';
import { ActionThunk } from '../ActionThunk.type';
import { Roles } from '../Users/Users.types';

/**
 * Action types
 */

export enum AuthTypes {
  USER_SIGN_IN_REQUEST = '@auth/USER_SIGN_IN_REQUEST',
  USER_SIGN_IN_SUCESS = '@auth/USER_SIGN_IN_SUCCESS',
  USER_SIGN_IN_FAILURE = '@auth/USER_SIGN_IN_FAILURE',
  REDIRECT_TO_LOGIN = '@auth/REDIRECT_TO_LOGIN',
  REDIRECT_TO_LOGIN_SUCCESS = '@auth/REDIRECT_TO_LOGIN_SUCCESS',
  REDIRECT_TO_LOGIN_FAILURE = '@auth/REDIRECT_TO_LOGIN_FAILURE',
  AUTHENTICATE_SUCCESS = '@auth/AUTHENTICATE_SUCCESS',
  AUTHENTICATE_FAILURE = '@auth/AUTHENTICATE_FAILURE',
  AUTHENTICATE_REQUEST = '@auth/AUTHENTICATE_REQUEST',

  SIGN_OUT_SUCCESS = '@auth/SIGN_OUT_SUCCESS',
  SIGN_OUT_REQUEST = '@auth/SIGN_OUT_REQUEST',
  SIGN_OUT_FAILURE = '@auth/SIGN_OUT_FAILURE',

  SET_PROFILE = '@auth/SET_PROFILE',
  RESET_PROFILE = '@auth/RESET_PROFILE'
}

/**
 * Reducer Types
 */

export interface AuthProfile {
  name: string;
  email: string;
  role: Roles.MASTER | Roles.ADMIN | Roles.EXECUTIVE | Roles.AGENCY;
  id: number;
  baseAudience: string;
}

export interface AuthState {
  readonly loggedIn: boolean;
  readonly profile: AuthProfile | null;
  readonly authenticated: boolean;
  readonly error: boolean;
  readonly loading: boolean;
}

export interface AuthPayload {
  userInfo: AuthProfile;
}
export type UserAuthResponse = ApiResponse<{ expires: string }>;
export type UserSignInResponse = ApiResponse<AuthProfile>;
export type LoginResponse = ApiResponse<{
  url: string;
  codeVerifier?: string;
}>;

export type LogoutResponse = ApiResponse<{
  url: string;
}>;

export type AuthAction = ActionThunk<AuthPayload>;
