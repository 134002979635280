export interface Evolution {
  label: string;
  labelMes: string;
  value: number;
}

export interface ByYear {
  label: string;
  value: number;
}

export interface Share {
  label: string;
  value: number;
}

export interface Percentage {
  evolution: Evolution[];
  byYear: ByYear[];
  share: Share[];
}
export interface AudiencePercentageState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Percentage[];
  readonly count: number;
}

export interface PercentageResponse {
  data: {
    rows: Percentage[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudiencePercentageType {
  REQUEST = '@audience/openTv/percentage/load',
  SUCESS = '@audience/openTv/percentage/success',
  ERROR = '@audience/openTv/percentage/error',
  CLEAR = '@audience/openTv/percentage/clear'
}

export interface AudiencePercentageAction {
  type: AudiencePercentageType;
  payload: Omit<PercentageResponse, 'status'>;
}
