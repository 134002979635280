import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import ModalShell, { ModalShellProps } from './ModalShell';

interface Props extends Omit<ModalShellProps, 'children' | 'open'> {
  message: React.ReactNode | string;
}

export default function confirmModal({
  message,
  onClickCancel,
  onClickOK,
  labelBtnCancel = 'não',
  labelBtnOK = 'sim',
  ...rest
}: Props) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <ModalShell
          labelBtnCancel={labelBtnCancel}
          labelBtnOK={labelBtnOK}
          onClickCancel={() => {
            if (onClickCancel) onClickCancel();
            setTimeout(() => {
              onClose();
            }, 200);
          }}
          onClickOK={() => {
            if (onClickOK) onClickOK();
            setTimeout(() => {
              onClose();
            }, 200);
          }}
          open
          {...rest}
        >
          {message}
        </ModalShell>
      );
    }
  });
}
