import './ListChannels.scss';
import { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import cloneDeep from 'lodash/cloneDeep';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import FormHelperText from '@mui/material/FormHelperText';
import { useUnform } from '../../../hooks';
import ListItemChannel from './ListItemChannel';
import { ListValue, ListChannelsChangeReason } from './types';

interface Props {
  dataClarityMask?: boolean;
  name: string;
  data: ListValue[];
  loading?: boolean;
  disabled?: boolean;
  onChange?: (values: ListValue[], reason: ListChannelsChangeReason) => void;
}

export default function ListChannels({
  name,
  data,
  loading,
  disabled,
  onChange,
  dataClarityMask
}: Props) {
  const [listData, setListData] = useState<ListValue[]>(data);
  const unform = useUnform(name);
  const clearError = useRef(unform?.clearError);
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    if (data.length) {
      if (clearError.current) clearError.current();

      setListData(data);
      if (onChangeRef.current) {
        onChangeRef.current(data, 'toggleActive');
      }
    }
  }, [data]);

  useEffect(() => {
    if (unform) {
      unform.registerField({
        name: unform.fieldName,
        getValue: () => listData.filter((l) => l.selected) || [],
        clearValue: () => {
          setListData([]);
          if (onChangeRef.current) {
            onChangeRef.current([], 'clear');
          }
        },
        setValue: (_, values: ListValue[]) => {
          unform.clearError();
          if (values) setListData(values);

          if (onChangeRef.current) {
            onChangeRef.current(values, 'toggleActive');
          }
        }
      });
    }
  }, [unform, listData]);

  const getValueUpdatedList = (array: ListValue[], updatedItem: ListValue) => {
    const currentIndex = array.findIndex((d) => d.id === updatedItem.id);
    const copyList = cloneDeep(array);

    if (currentIndex !== -1) {
      copyList.splice(currentIndex, 1);
      copyList.push(updatedItem);
    }

    return copyList.sort((a, b) => a.id - b.id);
  };

  const handleToggle = (newValue: ListValue) => {
    unform?.clearError();
    const newList = getValueUpdatedList(listData, newValue);
    setListData(newList);
    if (onChange) {
      onChange(newList, newValue.selected ? 'toggleActive' : 'toggleOff');
    }
  };

  const handleDiscountChange = (newValue: ListValue) => {
    const newList = getValueUpdatedList(listData, newValue);
    setListData(newList);
    if (onChange) onChange(newList, 'discountChange');
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 250, minHeight: 200 }}>
      <Grid container spacing={0}>
        <Grid
          alignItems="center"
          display="flex"
          item
          justifyContent="space-between"
          width="100%"
        >
          <div className="list-channels__title list-channels__title__name--spacing">
            Emissoras
          </div>
          <div className="list-channels__title list-channels__title__discount--spacing">
            Desconto
          </div>
        </Grid>
        {listData.map((value) => (
          <ListItemChannel
            key={value.id}
            disabled={disabled}
            item={value}
            loading={loading}
            onCheckToggle={handleToggle}
            onDiscountChange={handleDiscountChange}
            dataClarityMask={dataClarityMask}
          />
        ))}
        {!listData.length ? (
          <Grid
            alignItems="center"
            display="flex"
            item
            justifyContent="center"
            minHeight={200}
            width="100%"
          >
            {loading ? (
              <Skeleton height={300} variant="rectangular" width={250} />
            ) : (
              <div className="plim-gray2 list-channels__fallback--text">
                Nenhuma emissora disponível
              </div>
            )}
          </Grid>
        ) : null}
      </Grid>
      <FormHelperText>{unform?.error}</FormHelperText>
    </Box>
  );
}

ListChannels.defaultProps = {
  dataClarityMask: false,
  disabled: false,
  loading: false,
  onChange: undefined
};
