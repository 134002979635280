import React from 'react';

import './Breadcrumb.scss';
import { useNavigate } from 'react-router-dom';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface BreadcrumbProps {
  links: {
    id: number;
    title: string;
    path: string;
    dataClarityMask?: boolean;
  }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ links }) => {
  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <div className="breadcrumb">
      {links.map((link, idx) => (
        <div key={link.id} className="breadcrumb__item">
          <div
            className="breadcrumb__link"
            onClick={() => handleClick(link.path)}
            role="link"
            tabIndex={idx}
            {...attributeMaskOrUnmask(link.dataClarityMask)}
          >
            {link.title}
          </div>
          {idx < links.length - 1 && <span> » </span>}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
