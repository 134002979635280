import {
  CompetitiveComparativeTokenResponse,
  CompetitiveMetricsJobStatus,
  ComparativeMetricsParams,
  ComparativeMetricsResponse
} from '../../store/modules/Competitive/ComparativeMetrics/Competitive.comparative.types';
import { CompetitiveData } from '../../store/modules/Competitive/Shared/Competitive.shared.types';
import { apiV1 } from '../shared/Api.service';

const path = '/sectoral/metrics';

export async function runComparativeMetricsJob(
  params: CompetitiveData
): Promise<CompetitiveComparativeTokenResponse> {
  const { data } = await apiV1.post<CompetitiveComparativeTokenResponse>(
    `${path}/run/comparative`,
    params
  );

  return data;
}

export async function getComparativeMetricsStatus(
  params: ComparativeMetricsParams
): Promise<CompetitiveMetricsJobStatus> {
  const { data } = await apiV1.get<CompetitiveMetricsJobStatus>(
    `${path}/status/comparative`,
    {
      params,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    }
  );

  return data;
}

export async function getCompetitiveMetricsResult(
  params: ComparativeMetricsParams
): Promise<ComparativeMetricsResponse> {
  const { data } = await apiV1.get<ComparativeMetricsResponse>(
    `${path}/result/comparative`,
    {
      params
    }
  );

  return data;
}
