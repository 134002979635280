import * as Yup from 'yup';
import {
  SimulationTypes,
  SimulationProps,
  SimulationScenery
} from './Simulation.types';
import { AppThunk } from '../..';
import {
  SaveFiltersFormData,
  SaveFiltersFormDataError,
  SavePartialFormData
} from '../Filters/Filters.actions';
import getNewSelectedScenery from '../../../components/Scenarios/ScenariosUtil';
import { FiltersData } from '../Filters/Filters.types';
import validateFields from '../../../components/Filters/Filters.validations';
import { GetProgramsByScenery } from '../Programs/Programs.actions';
import handleErrors, { YupError } from '../../../helpers/HandleErrors';

export const NewSimulation = (
  simulation: Pick<SimulationProps, 'name' | 'uuid'>
) => ({
  type: SimulationTypes.NEW_SIMULATION,
  payload: simulation
});

export const EditSimulation = (
  simulation: Pick<
    SimulationProps,
    | 'name'
    | 'currentScenery'
    | 'currentSceneryId'
    | 'scenarios'
    | 'me_filial_agencia'
    | 'nu_cgc_cpf_agencia'
    | 'oi_agencia'
    | 'oi_cliente'
    | 'me_cliente'
    | 'cpf_cnpj_cliente'
  >
) => ({
  type: SimulationTypes.SET_SIMULATION,
  payload: simulation
});

export const SetSimulation = (simulation: Partial<SimulationProps>) => ({
  type: SimulationTypes.SET_SIMULATION,
  payload: simulation
});

export const ResetSimulation = () => ({
  type: SimulationTypes.RESET_SIMULATION
});

export const SetScenerySelected = (newCurrentScenary: number) => ({
  type: SimulationTypes.SET_CURRENT_SCENERY_IN_SIMULATION,
  payload: {
    currentScenery: newCurrentScenary
  }
});

export const SetSceneryIdSelected = (newCurrentScenaryId: string) => ({
  type: SimulationTypes.SET_SCENERY_ID_IN_SIMULATION,
  payload: {
    currentSceneryId: newCurrentScenaryId
  }
});

export const SetCurrentScenery =
  (newCurrentScenary: number, partialData?: Partial<FiltersData>): AppThunk =>
  async (dispatch, getState) => {
    if (partialData) {
      dispatch(
        SavePartialFormData(getState().simulation.currentSceneryId, partialData)
      );
    }
    dispatch(SetScenerySelected(newCurrentScenary));
  };

export const AddScenery = (data?: Partial<SimulationScenery>) => ({
  type: SimulationTypes.ADD_SCENERY_IN_SIMULATION,
  payload: data
});

export const RemoveScenery =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    const { simulation } = getState();

    const sceneryDeleted = simulation.scenarios.find(
      (sceneryId) => sceneryId.uuid === uuid
    );

    if (sceneryDeleted) {
      const newScenerySelected = getNewSelectedScenery(
        simulation.scenarios,
        sceneryDeleted.sceneryNumber,
        simulation.currentScenery
      );

      if (newScenerySelected) {
        dispatch(SavePartialFormData(simulation.currentSceneryId));
        dispatch(SetScenerySelected(newScenerySelected.sceneryNumber));
        dispatch(SetSceneryIdSelected(newScenerySelected.uuid));
      }

      dispatch({
        type: SimulationTypes.REMOVE_SCENERY_IN_SIMULATION,
        payload: { uuid }
      });
    }
  };

export const SaveSceneryPath =
  (path: string): AppThunk =>
  async (dispatch, getState) => {
    const { simulation } = getState();
    const currentScenery = simulation.scenarios[simulation.currentScenery];

    if (currentScenery) {
      const scenariosNotCurrentScenery = simulation.scenarios.filter(
        (sceneryId) => sceneryId.uuid !== currentScenery.uuid
      );

      let newScenarios: SimulationScenery[] = [];

      if (scenariosNotCurrentScenery) {
        newScenarios = [
          ...scenariosNotCurrentScenery,
          { ...currentScenery, lastPath: path }
        ];
      } else {
        newScenarios = [{ ...currentScenery, lastPath: path }];
      }

      dispatch(
        SetSimulation({
          ...simulation,
          scenarios: newScenarios.sort(
            (a, b) => a.sceneryNumber - b.sceneryNumber
          )
        })
      );
    }
  };

export const ValidAndSaveFiltersAndGetPrograms =
  (filterData: Partial<FiltersData>, sceneryId: string): AppThunk =>
  async (dispatch) => {
    try {
      await validateFields(filterData);
      dispatch(
        SaveFiltersFormData({ data: filterData as FiltersData, sceneryId })
      );
      dispatch(GetProgramsByScenery(sceneryId));
    } catch (error) {
      let errors: YupError | undefined;
      if (error instanceof Yup.ValidationError) {
        errors = handleErrors(error);
      }
      dispatch(SaveFiltersFormDataError(sceneryId, errors));
    }
  };
