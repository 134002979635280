import { MarketResponse } from '../../store/modules/Competitive/Markets/Competitive.markets.types';
import {
  ClientRequest,
  ClientResponse
} from '../../store/modules/Competitive/Clients/Competitive.clients.types';
import {
  SectorRequest,
  SectorResponse
} from '../../store/modules/Competitive/Sectors/Competitive.sectors.types';
import { apiV1 } from '../shared/Api.service';
import {
  TargetsQueryParams,
  TargetResponse
} from '../../store/modules/Competitive/Targets/Competitive.targets.types';

const path = '/sectoral';

export async function getMarkets(): Promise<MarketResponse> {
  const { data } = await apiV1.get<MarketResponse>(`${path}/markets`, {
    params: {}
  });

  return data;
}

export async function getSectors(
  request: SectorRequest
): Promise<SectorResponse> {
  const { data } = await apiV1.get<SectorResponse>(`${path}/sectors`, {
    params: request
  });

  return data;
}

export async function getClients(
  request: ClientRequest
): Promise<ClientResponse> {
  const { data } = await apiV1.get<ClientResponse>(`${path}/clients`, {
    params: request
  });

  return data;
}

export async function getTargets(
  request?: TargetsQueryParams
): Promise<TargetResponse> {
  const { data } = await apiV1.get<TargetResponse>(`${path}/targets`, {
    params: request
  });

  return data;
}
