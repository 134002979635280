import { Reducer } from 'redux';
import {
  AudienceFilterAvarageAudienceByProgrammingGendersAction,
  AudienceFilterAvarageAudienceByProgrammingGendersState,
  AudienceFiltersAvarageAudienceByProgrammingGendersType
} from './Audience.avarageAudienceByProgrammingGenders.types';

const avarageAudienceByProgrammingGenderInitialState: AudienceFilterAvarageAudienceByProgrammingGendersState =
  {
    error: false,
    loading: false,
    data: [],
    count: 0
  };

const reducer: Reducer<
  AudienceFilterAvarageAudienceByProgrammingGendersState,
  AudienceFilterAvarageAudienceByProgrammingGendersAction
> = (
  state = avarageAudienceByProgrammingGenderInitialState,
  action
): AudienceFilterAvarageAudienceByProgrammingGendersState => {
  switch (action.type) {
    case AudienceFiltersAvarageAudienceByProgrammingGendersType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageAudienceByProgrammingGendersType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageAudienceByProgrammingGendersType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageAudienceByProgrammingGendersType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
