import { Reducer } from 'redux';
import { AgenciesAction, AgenciesState, AgenciesTypes } from './Agencies.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0,
  message: ''
};

const targetInitialState: AgenciesState = {
  ...defaultData
};

const reducer: Reducer<AgenciesState, AgenciesAction> = (
  state = targetInitialState,
  action
): AgenciesState => {
  switch (action?.type) {
    case AgenciesTypes.LOAD_AGENCIES_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.rows || [],
        count: action?.payload?.count || 0,
        error: false,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case AgenciesTypes.LOAD_AGENCIES_ERROR: {
      return {
        ...state,
        data: [],
        error: true,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case AgenciesTypes.LOAD_AGENCIES_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
