import { toast } from 'react-toastify';
import {
  AudienceFilterWeekDaysAction,
  AudienceFiltersWeekDaysType,
  WeekDayResponse
} from './Audience.weekDays.types';
import getWeekDays from '../../../../../services/Audience/PayTv/Audience.payTv.weekDays.service';
import { AppThunk } from '../../../..';

const LoadAudienceFilterWeekDaysSuccess = (response: WeekDayResponse) => {
  const payload: AudienceFilterWeekDaysAction = {
    type: AudienceFiltersWeekDaysType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterWeekDaysError = (error: string) => {
  const payload: AudienceFilterWeekDaysAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersWeekDaysType.ERROR
  };

  return payload;
};

const LoadingAudienceWeekDaysFilter = () => ({
  type: AudienceFiltersWeekDaysType.REQUEST,
  payload: {}
});

const LoadAudienceFilterWeekDaysRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingAudienceWeekDaysFilter());
    const response = await getWeekDays();

    dispatch(LoadAudienceFilterWeekDaysSuccess(response));
  } catch (error) {
    dispatch(
      LoadAudienceFilterWeekDaysError(
        error instanceof Error ? error.message : ''
      )
    );
    toast.error(
      'Ops, houve um problema ao tentar carregar os dias da semana disponíveis'
    );
  }
};

export default LoadAudienceFilterWeekDaysRequest;
