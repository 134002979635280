import React, { useState } from 'react';
import { EditorProps } from 'react-data-grid';
import NumberFormat from 'react-number-format';
import getValueProp from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import setValueProp from 'lodash/set';

interface DiscountProps<TRow> {
  className?: string;
  onChange?: (
    row: TRow,
    oldRow: TRow,
    onRowChange: EditorProps<TRow>['onRowChange']
  ) => void | undefined;
}
export default function Discount<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  onChange,
  className
}: EditorProps<TRow, TSummaryRow> & DiscountProps<TRow>) {
  const [value, setValue] = useState(getValueProp(row, column.key) || 0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue =
      Number(event.target.value.replace(' ', '').replace(',', '.')) || 0;

    setValue((oldValue: number) =>
      Number.isNaN(newValue) ? oldValue : newValue
    );
  };

  const handleClose = () => {
    if (value !== Number(getValueProp(row, column.key))) {
      const copyRow = cloneDeep(row);
      const newRow = setValueProp(copyRow as any, column.key, value);
      if (onChange) {
        onChange(newRow, row, onRowChange);
      } else {
        onRowChange(newRow, true);
      }
    }
    onClose(true);
  };

  return (
    <NumberFormat
      autoFocus
      className={className}
      decimalScale={3}
      decimalSeparator=","
      fixedDecimalScale
      format="##,###"
      max="100"
      min="0"
      onBlur={handleClose}
      onChange={handleChange}
      onFocus={(e: any) => {
        e.target.value = '';
      }}
      onKeyDown={(e: any) => {
        (e?.key === 'Enter' || e?.key === 'Tab') && handleClose();
      }}
      value={value}
    />
  );
}
Discount.defaultProps = {
  className: '',
  onChange: undefined
};
