export interface Market {
  id: string;
  name: string;
  mnemonic: string;
}
export interface CompetitiveFilterMarketsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Market[];
  readonly count: number;
}
export interface MarketResponse {
  data: {
    rows: Market[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum CompetitiveFiltersMarketsType {
  REQUEST = '@competitive/markets/load',
  SUCESS = '@competitive/markets/success',
  ERROR = '@competitive/markets/error'
}

export interface CompetitiveFilterMarketsAction {
  type: CompetitiveFiltersMarketsType;
  payload: Omit<MarketResponse, 'status'>;
}
