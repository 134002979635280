import { toast, ToastOptions } from 'react-toastify';
import { AppThunk } from '../../..';
import {
  CompetitiveComparativeTokenResponse,
  CompetitiveComparativeTypes,
  CompetitiveMetricsJobStatus,
  ComparativeMetricsAction,
  ComparativeMetricsParams,
  ComparativeMetricsResponse
} from './Competitive.comparative.types';
import {
  getCompetitiveMetricsResult,
  runComparativeMetricsJob,
  getComparativeMetricsStatus
} from '../../../../services/Competitive/Competitive.comparative.service';
import { CompetitiveData } from '../Shared/Competitive.shared.types';
import { controllerActionsStatusMetrics } from '../Shared/MetricsStatus.share.actions';
import { ApiErrorResponse } from '../../../../services/shared/Api.service';

const propsToastInfo: ToastOptions = {
  isLoading: true,
  closeOnClick: false,
  closeButton: false,
  autoClose: false
};

const propsToastSuccess: ToastOptions = {
  type: 'success',
  progress: undefined,
  autoClose: 5000,
  isLoading: false,
  closeOnClick: true,
  closeButton: true
};

const updateToast = (
  toastId: string | number,
  progress: number,
  msg: string,
  options = propsToastInfo
) => {
  toast.update(toastId, {
    ...options,
    progress: progress / 100,
    render: msg
  });
};

const ComparativeMetricsError = (error: string) => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.COMPARATIVE_METRICS_ERROR,
    payload: {
      progress: 0,
      token: '',
      message: error
    }
  };

  return payload;
};

const ClearComparativeMetrics = () => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.CLEAR_COMPARATIVE_METRICS,
    payload: {
      progress: 0,
      token: '',
      message: ''
    }
  };

  return payload;
};

const LoadingComparativeMetricsStatus = (
  response: CompetitiveComparativeTokenResponse
) => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.STATUS_COMPARATIVE_METRICS_REQUEST,
    payload: {
      progress: 0,
      token: response.data.row.jobToken,
      message: response.msg
    }
  };

  return payload;
};

const LoadComparativeMetricsStatisSuccess = (
  response: CompetitiveMetricsJobStatus,
  token: string
) => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.STATUS_COMPARATIVE_METRICS_SUCCESS,
    payload: {
      progress: response.data.row.progress,
      token,
      message: response.msg
    }
  };

  return payload;
};

const LoadingComparativeMetrics = () => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.RESULT_COMPARATIVE_METRICS_REQUEST,
    payload: {
      progress: 100,
      token: '',
      message: ''
    }
  };

  return payload;
};

const LoadingComparativeMetricsSuccess = (
  response: ComparativeMetricsResponse
) => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.RESULT_COMPARATIVE_METRICS_SUCCESS,
    payload: {
      progress: 100,
      token: '',
      message: response.msg,
      metrics: response
    }
  };

  return payload;
};

const LoadingComparativeMetricsRun = () => {
  const payload: ComparativeMetricsAction = {
    type: CompetitiveComparativeTypes.RUN_COMPARATIVE_METRICS_REQUEST,
    payload: {
      progress: 0,
      token: '',
      message: ''
    }
  };

  return payload;
};

const LoadRunComparativeMetricsRequest =
  (params: CompetitiveData): AppThunk =>
  async (dispatch) => {
    const toastId = toast.info('Processando as métricas...', propsToastInfo);

    try {
      dispatch(LoadingComparativeMetricsRun());

      const response = await runComparativeMetricsJob(params);

      dispatch(LoadingComparativeMetricsStatus(response));

      const token: ComparativeMetricsParams = {
        jobToken: response.data.row.jobToken
      };

      await controllerActionsStatusMetrics(
        response.data.row.jobToken,
        toastId,
        {
          request: () => getComparativeMetricsStatus(token),
          success: (...args) => {
            dispatch(LoadComparativeMetricsStatisSuccess(...args));
          }
        }
      );

      dispatch(LoadingComparativeMetrics());

      updateToast(toastId, 0, 'Aplicando os cálculos...');

      const metrics = await getCompetitiveMetricsResult(token);

      updateToast(toastId, 0, 'Finalizado!', propsToastSuccess);

      dispatch(LoadingComparativeMetricsSuccess(metrics));
    } catch (error) {
      toast.dismiss(toastId);
      dispatch(
        ComparativeMetricsError(error instanceof Error ? error.message : '')
      );
      toast.error(
        (error as ApiErrorResponse)?.msg ||
          'Ops, houve um problema ao tentar carregar as métricas.'
      );
    }
  };

export { LoadRunComparativeMetricsRequest, ClearComparativeMetrics };
