import { useEffect } from 'react';
import { IAudienceByChannelsPayTvComparativeFilterProps } from '../../types';

interface Props
  extends Partial<IAudienceByChannelsPayTvComparativeFilterProps> {
  inputsConfig: {
    programmers: {
      name: string;
      defaultValue: {
        label: string;
        value: string;
      }[];
    };
    channelGeneres: {
      name: string;
      defaultValue: {
        label: string;
        value: string;
      }[];
    };
    channels: {
      name: string;
      defaultValue: {
        label: string;
        value: string;
      }[];
    };
    year: {
      name: string;
      defaultValue: string;
    };
  };
  FIXED_VALUE: { label: string; value: string };
  clearInputs: (names: string[]) => void;
}

const useAudiencePayTvChannelsComparativeFilterAutoSelect = ({
  programmers,
  channelGeneres,
  channels,
  setProgrammers,
  setChannelGeneres,
  setChannels,
  FIXED_VALUE,
  clearInputs,
  inputsConfig
}: Props) => {
  useEffect(() => {
    if (!programmers || !programmers.length || programmers?.length === 0) {
      if (setProgrammers) setProgrammers([FIXED_VALUE]);
      clearInputs([inputsConfig.programmers.name]);
    }
  }, [
    programmers,
    setProgrammers,
    FIXED_VALUE,
    clearInputs,
    inputsConfig.programmers.name
  ]);

  useEffect(() => {
    if (
      !channelGeneres ||
      !channelGeneres.length ||
      channelGeneres?.length === 0
    ) {
      if (setChannelGeneres) setChannelGeneres([FIXED_VALUE]);
      clearInputs([inputsConfig.channelGeneres.name]);
    }
  }, [
    channelGeneres,
    setChannelGeneres,
    FIXED_VALUE,
    clearInputs,
    inputsConfig.channelGeneres.name
  ]);

  useEffect(() => {
    if (!channels || !channels.length || channels?.length === 0) {
      if (setChannels) setChannels([FIXED_VALUE]);
      clearInputs([inputsConfig.channels.name]);
    }
  }, [
    channels,
    setChannels,
    FIXED_VALUE,
    clearInputs,
    inputsConfig.channels.name
  ]);
};

export default useAudiencePayTvChannelsComparativeFilterAutoSelect;
