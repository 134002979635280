import { Reducer } from 'redux';
import {
  AudienceAbsoluteAction,
  AudienceAbsoluteState,
  AudienceAbsoluteType
} from './Audience.absolutes.types';

const yearInitialState: AudienceAbsoluteState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<AudienceAbsoluteState, AudienceAbsoluteAction> = (
  state = yearInitialState,
  action
): AudienceAbsoluteState => {
  switch (action.type) {
    case AudienceAbsoluteType.SUCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceAbsoluteType.ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
        count: 0
      };
    }

    case AudienceAbsoluteType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceAbsoluteType.CLEAR: {
      return {
        ...state,
        data: [],
        count: 0
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
