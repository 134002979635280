import { Reducer } from 'redux';
import {
  CompetitiveCategoriesState,
  CompetitiveCategoryAction,
  CompetitiveCategoriesTypes
} from './Competitive.categories.types';

const targetInitialState: CompetitiveCategoriesState = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const reducer: Reducer<
  CompetitiveCategoriesState,
  CompetitiveCategoryAction
> = (state = targetInitialState, action): CompetitiveCategoriesState => {
  switch (action.type) {
    case CompetitiveCategoriesTypes.LOAD_CATEGORIES_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        loading: false,
        error: false
      };
    }
    case CompetitiveCategoriesTypes.LOAD_CATEGORIES_ERROR: {
      return {
        ...state,
        data: [],
        count: 0,
        loading: false,
        error: true
      };
    }
    case CompetitiveCategoriesTypes.LOAD_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
