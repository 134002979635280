export interface AvarageData {
  pc_audiencia: number;
  pc_share_tle: number;
  vl_audiencia_abs: number;
  vl_alcance_abs: number;
  hr_ats: string;
}
export interface AudienceFilterAvarageDatasState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageData[];
  readonly count: number | null;
}

export interface AvarageDataResponse {
  data: {
    rows: AvarageData[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageDatasType {
  REQUEST = '@audience/openTv/byChannels/avarageDatas/load',
  SUCESS = '@audience/openTv/byChannels/avarageDatas/success',
  ERROR = '@audience/openTv/byChannels/avarageDatas/error',
  CLEAR = '@audience/openTv/byChannels/avarageDatas/clear'
}

export interface AudienceFilterAvarageDatasAction {
  type: AudienceFiltersAvarageDatasType;
  payload: Omit<AvarageDataResponse, 'status'>;
}
