import { toast } from 'react-toastify';
import { AppThunk } from '../../..';
import {
  CompetitiveCategoriesResponse,
  CompetitiveCategoryAction,
  CompetitiveCategoriesTypes,
  CompetitiveCategoriesQueryParams
} from './Competitive.categories.types';
import getCompetitiveCategories from '../../../../services/Competitive/Competitive.categories.service';

export const LoadCompetitiveCategoriesSuccess = (
  response: CompetitiveCategoriesResponse
) => {
  const payload: CompetitiveCategoryAction = {
    type: CompetitiveCategoriesTypes.LOAD_CATEGORIES_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg
    }
  };

  return payload;
};

const LoadCompetitiveCategoriesError = (error: string) => {
  const payload: CompetitiveCategoryAction = {
    type: CompetitiveCategoriesTypes.LOAD_CATEGORIES_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingCompetitiveCategories = () => ({
  type: CompetitiveCategoriesTypes.LOAD_CATEGORIES_REQUEST
});

const LoadCompetitiveCategoriesRequest =
  (params: CompetitiveCategoriesQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveCategories());

      const response = await getCompetitiveCategories(params);

      dispatch(LoadCompetitiveCategoriesSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveCategoriesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar os canais.');
    }
  };

export default LoadCompetitiveCategoriesRequest;
