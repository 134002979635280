import { apiV1 } from '../shared/Api.service';
import {
  CompetitiveBrandsQueryParams,
  CompetitiveBrandsResponse
} from '../../store/modules/Competitive/Brands/Competitive.brands.types';

const path = '/sectoral/brands';

export default async function getCompetitiveBrands(
  params: CompetitiveBrandsQueryParams
): Promise<CompetitiveBrandsResponse> {
  const { data } = await apiV1.get<CompetitiveBrandsResponse>(path, {
    params
  });

  return data;
}
