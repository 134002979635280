import { AllParams } from '../../../../services/shared/types';

export enum CompetitiveBrandsTypes {
  LOAD_BRANDS_REQUEST = '@competitive/brands/request',
  LOAD_BRANDS_SUCCESS = '@competitive/brands/success',
  LOAD_BRANDS_ERROR = '@competitive/brands/error'
}

export interface CompetitiveBrand {
  brand: string;
}

export interface CompetitiveBrandAction {
  type: CompetitiveBrandsTypes;
  payload: {
    rows: CompetitiveBrand[];
    count: number;
    message: string;
  };
}

export interface CompetitiveBrandsState {
  readonly data: CompetitiveBrand[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface CompetitiveBrandsResponse {
  data: {
    rows: CompetitiveBrand[];
    count: number;
  };
  status: number;
  msg: string;
}
export interface CompetitiveBrandsQueryParams extends AllParams {
  sectors?: string[];
  clients?: string[];
  channelMnemonics?: string[];
  categories?: string[];
}
