import { MapBorder, MapFill, MapFont } from './types';

export const FONT_DEFAULT: MapFont = {
  name: 'Arial',
  sz: 9
};

export const FONT_DEFAULT_CAMBRIA: MapFont = {
  name: 'Cambria',
  sz: 9
};

export const FONT_DEFAULT_BOLD: MapFont = {
  ...FONT_DEFAULT,
  bold: true
};

export const FONT_DEFAULT_BOLD_RED: MapFont = {
  ...FONT_DEFAULT_BOLD,
  color: { rgb: 'EA3324' }
};

export const FONT_DEFAULT_BOLD_BLUE: MapFont = {
  ...FONT_DEFAULT_BOLD,
  color: { rgb: '101080' }
};

export const FONT_DEFAULT_SIZE_16: MapFont = {
  ...FONT_DEFAULT,
  sz: 16
};

export const FONT_DEFAULT_SIZE_16_RED: MapFont = {
  ...FONT_DEFAULT_SIZE_16,
  color: { rgb: 'EA3324' }
};

export const BORDER_SOLID_BLACK: MapBorder = {
  style: 'thin',
  color: { rgb: '000000' }
};

export const BORDER_SOLID_MED_BLACK: MapBorder = {
  ...BORDER_SOLID_BLACK,
  style: 'medium'
};

export const BORDER_SLA_DASH_DOT_BLACK: MapBorder = {
  ...BORDER_SOLID_BLACK,
  style: 'slantDashDot'
};

export const FILL_DEFAULT: MapFill = {
  patternType: 'solid',
  fgColor: { rgb: 'FFFFFF' }
};

export const FILL_DEFAULT_GRAY_1: MapFill = {
  ...FILL_DEFAULT,
  fgColor: { rgb: 'D9D9D9' }
};

export const FILL_DEFAULT_GRAY_2: MapFill = {
  ...FILL_DEFAULT,
  fgColor: { rgb: 'C0C0C0' }
};

export const FILL_COLORS = {
  default: FILL_DEFAULT,
  gray1: FILL_DEFAULT_GRAY_1,
  gray2: FILL_DEFAULT_GRAY_2
};
