import { encodeCell, formulaMultiplication } from '../utils';
import { mapColsXlsx } from './MediaMap.constants';

export const getIdxCol = (name: string) =>
  mapColsXlsx.findIndex((col) => col.name === name.toUpperCase());

export const getColsDaysOfWeek = () =>
  mapColsXlsx.slice(getIdxCol('F'), getIdxCol('AJ') + 1);

export const formulaAvgDiscount = (
  rowIdx: number,
  colTotNegotiated: string,
  colTotAmount: string
) =>
  `(1 - (${encodeCell(rowIdx, colTotNegotiated)} / ${encodeCell(
    rowIdx,
    colTotAmount
  )}))`;

export const formulaDiscount = (
  rowIdx: number,
  colInsertion: string,
  colVal: string,
  colDiscount: string
) =>
  `(${formulaMultiplication(
    colInsertion,
    colVal,
    rowIdx
  )} - (${formulaMultiplication(colInsertion, colVal, rowIdx)}) * ${encodeCell(
    rowIdx,
    colDiscount
  )})`;
