import './ModalPeriod.scss';
import { useRef, useEffect, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import ModalShell from '../ModalShell';
import {
  OptionsPeriodTypesProps,
  OPTIONS_PERIOD_TYPES,
  getOptionPeriodType
} from '../Filters/contants';
import AutocompleteInput from '../Form/AutocompleteInput';
import DatePickerInput from '../Form/DatePickerInput';
import useFiltersClosedPeriod from './useClosedPeriod';
import { TypeTV } from '../../services/shared/Api.service';
import {
  ClosedPeriodTypesEnum,
  ClosedPeriodValue
} from '../../store/modules/Filters/Filters.types';

import FormContainer from '../Form/FormContainer';
import { stringToDate } from '../../helpers/Dates';
import validateFields from './Period.validations';
import handleErrors, { YupError } from '../../helpers/HandleErrors';
import { FormClosedPeriod } from './types';

interface Props {
  open: boolean;
  onCancelClick?(): void;
  onModalSubmit(data: FormClosedPeriod): void;
  initialData?: FormClosedPeriod;
  typeTV: TypeTV | undefined;
}

interface FormProps {
  year?: string | Date;
  type?: OptionsPeriodTypesProps;
  value?: ClosedPeriodValue;
}

const ModalPeriod = ({
  open,
  onCancelClick,
  initialData,
  onModalSubmit,
  typeTV
}: Props) => {
  const actualFormRef = useRef<FormHandles>(null);

  const {
    closedPeriodType,
    closedPeriodYear,
    setClosedPeriodNumOfMonths,
    setClosedPeriodType,
    setClosedPeriodYear,
    getMaxYear,
    getMinYear,
    getClosedPeriodNumOfMonths,
    getAllOptionsClosedPeriod
  } = useFiltersClosedPeriod(typeTV);

  const [initialPeriodData, setInitialPeriodData] = useState<FormProps>();

  const allOptionsClosedPeriod = closedPeriodYear
    ? getAllOptionsClosedPeriod(closedPeriodYear)
    : null;

  const handleTypePeriodChange = (type: OptionsPeriodTypesProps | null) => {
    actualFormRef?.current?.clearField('value');
    if (type) {
      setClosedPeriodType(type.value);
      setClosedPeriodNumOfMonths(type.numOfMonths);
    }
  };

  const filteredOptionsClosedPeriodTypes =
    OPTIONS_PERIOD_TYPES.filter(
      (opt) =>
        allOptionsClosedPeriod && allOptionsClosedPeriod[opt.value].length
    ) || [];

  const handleYearChange = (newValue: Date | null) => {
    let msgError = '';
    if (!newValue) {
      setClosedPeriodYear(undefined);
      msgError = 'Informe um ano válido.';
    }

    actualFormRef.current?.setFieldError('year', msgError);

    actualFormRef?.current?.clearField('value');

    if (newValue && newValue.getFullYear() !== closedPeriodYear) {
      setClosedPeriodYear(newValue.getFullYear());
    }
  };

  const serializeData = useCallback(
    (data: FormClosedPeriod | undefined) => {
      const cloneData = cloneDeep(data);

      const newData: FormProps = {};

      if (data && cloneData) {
        if (cloneData.year) {
          const date =
            data.year instanceof Date
              ? data.year
              : stringToDate(data.year.toString());

          setClosedPeriodYear(date.getFullYear());
          newData.year = cloneData.year;
        }

        const closedType =
          typeTV === TypeTV.CLOSED && data.type === ClosedPeriodTypesEnum.MONTH
            ? ClosedPeriodTypesEnum.QUARTER
            : data.type;

        setClosedPeriodType(closedType);
        setClosedPeriodNumOfMonths(getClosedPeriodNumOfMonths(closedType));

        newData.type = getOptionPeriodType(closedType);

        newData.value = cloneData?.value;
      }
      return newData;
    },
    [
      getClosedPeriodNumOfMonths,
      setClosedPeriodNumOfMonths,
      setClosedPeriodType,
      setClosedPeriodYear,
      typeTV
    ]
  );

  useEffect(() => {
    if (initialData) {
      setInitialPeriodData(serializeData(initialData));
    } else if (getMaxYear) {
      setClosedPeriodYear(getMaxYear.getFullYear());
    }
  }, [initialData, serializeData, setClosedPeriodYear, getMaxYear]);

  const handleFormSubmit = async (formData: FormClosedPeriod) => {
    try {
      actualFormRef?.current?.setErrors({});

      await validateFields(formData);

      onModalSubmit(formData);
    } catch (error) {
      let errors: YupError | undefined;
      if (error instanceof Yup.ValidationError) {
        errors = handleErrors(error);
        actualFormRef?.current?.setErrors(errors);
      }
    }
  };

  const handleSubmitFormData = () => {
    if (actualFormRef?.current) actualFormRef.current.submitForm();
  };

  return (
    <div>
      <ModalShell
        labelBtnOK="Filtrar"
        labelBtnSubmit="Filtrar"
        onClickCancel={onCancelClick}
        onClickOK={handleSubmitFormData}
        open={open}
        title=""
      >
        <FormContainer
          formRef={actualFormRef}
          fullWidth
          initialData={initialPeriodData}
          onSubmit={handleFormSubmit}
        >
          <h2 className="modal-period__title">Audiência Período Fechado</h2>
          <div className="modal-period__info">
            Selecione o ano disponível para sua audiência.
          </div>
          <div className="input-wrapper">
            <div className="input-wrapper__divisor_select">
              <div className="input-wrapper__divisor__column">
                <DatePickerInput
                  defaultValue={getMaxYear}
                  label="Ano"
                  maxDate={getMaxYear}
                  minDate={getMinYear}
                  name="year"
                  onChange={handleYearChange}
                  required
                  views={['year']}
                />
              </div>
            </div>
          </div>
          <div className="modal-period__info mb-2">
            Selecione o período para sua audiência.
          </div>
          <div className="input-wrapper">
            <AutocompleteInput
              disableClearable
              disabled={!closedPeriodYear}
              label="Período de Audiência"
              name="type"
              onChange={handleTypePeriodChange}
              options={filteredOptionsClosedPeriodTypes}
              required
              {...attributeMaskOrUnmask(false)}
            />
          </div>
          <div className="input-wrapper">
            <AutocompleteInput
              disableClearable
              disabled={!closedPeriodType}
              label="Período"
              name="value"
              options={
                allOptionsClosedPeriod && closedPeriodType
                  ? allOptionsClosedPeriod[closedPeriodType]
                  : []
              }
              required
              returnObject
            />
          </div>
        </FormContainer>
      </ModalShell>
    </div>
  );
};

export default ModalPeriod;

ModalPeriod.defaultProps = {
  initialData: undefined,
  onCancelClick: undefined
};
