import { apiV1 } from '../../../shared/Api.service';
import { ProgrammingGenderResponse } from '../../../../store/modules/AudienceModule/OpenTv/ProgrammingGenders/Audience.programmingGenders.types';
import { ICommonsFilter as ICommonsFilterChannel } from '../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import { IAudienceByProgramsRankingFilterParams as ICommonsFilterPrograms } from '../../../../components/Audience/AudiencePanels/OpenTv/Programs/types';

const path = '/audience/open-tv/regular-plazas/programming-genders';

export default async function getOpenTvRegularPlazasProgrammingGenders({
  target,
  timeRange,
  weekDays,
  channels,
  plaza,
  channelsNotIn
}:
  | ICommonsFilterChannel
  | ICommonsFilterPrograms): Promise<ProgrammingGenderResponse> {
  const { data } = await apiV1.get<ProgrammingGenderResponse>(path, {
    params: {
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value ?? 'TODOS',
      channels: channels?.map((channel) => channel.value) ?? undefined,
      plaza: plaza?.value ?? undefined,
      channelsNotIn
    }
  });

  return data;
}
