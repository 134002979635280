import './ListItemChannel.scss';
import React, { useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import cloneDeep from 'lodash/cloneDeep';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import InputAdornment from '@mui/material/InputAdornment';
import PercentNumber from '../../Input/PercentNumber';
import { ListValue } from './types';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';

interface Props {
  dataClarityMask?: boolean;
  item: ListValue;
  loading?: boolean;
  disabled?: boolean;
  onCheckToggle: (item: ListValue) => void;
  onDiscountChange: (item: ListValue) => void;
}

export default function ListItemChannel({
  dataClarityMask,
  item,
  loading,
  disabled,
  onCheckToggle,
  onDiscountChange
}: Props) {
  const valueRef = useRef<number>(item.discount || 0);

  const handleToggle = (value: ListValue) => () => {
    onCheckToggle({ ...value, selected: !value.selected });
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const floatValue = parseFloat(value.replace(',', '.'));

    valueRef.current = floatValue;
  };

  const handleDiscountClose = (listVal: ListValue) => () => {
    onDiscountChange({
      ...cloneDeep(listVal),
      discount: valueRef.current || 0
    });
  };

  const labelId = `checkbox-list-label-${item.id}`;

  return (
    <Grid
      key={item.id}
      alignItems="center"
      classes={{ item: 'list-item-channel' }}
      display="flex"
      item
      justifyContent="space-between"
      width="100%"
    >
      <div className="list-channels__title__name--spacing">
        {loading ? (
          <Skeleton height={40} />
        ) : (
          <>
            <Checkbox
              checked={item.selected}
              classes={{
                checked: !disabled ? 'plim-violet' : 'plim-violet opacity-5',
                root: 'plim-gray1'
              }}
              disabled={disabled}
              inputProps={{ 'aria-labelledby': labelId }}
              onChange={handleToggle(item)}
              size="small"
              sx={{ padding: '0px' }}
            />
            <span
              aria-disabled={disabled}
              className="list-item-channel__name pl-1"
              {...attributeMaskOrUnmask(dataClarityMask)}
            >
              {item.channelName}
            </span>
          </>
        )}
      </div>
      <div className="list-channels__title__discount--spacing">
        {loading ? (
          <Skeleton height={40} />
        ) : (
          <TextField
            classes={{ root: 'list-item-channel__discount--input' }}
            disabled={disabled}
            InputProps={{
              inputComponent: PercentNumber as any,
              endAdornment: (
                <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                  <span className="list-item-channel__discount--adornment">
                    %
                  </span>
                </InputAdornment>
              ),
              inputProps: {
                decimalScale: 2,
                autoFocus: false
              },
              classes: { root: 'list-item-channel__discount--input' }
            }}
            label=""
            margin="dense"
            onBlur={handleDiscountClose(item)}
            onChange={handleDiscountChange}
            size="small"
            value={item.discount}
            {...attributeMaskOrUnmask(dataClarityMask)}
          />
        )}
      </div>
    </Grid>
  );
}

ListItemChannel.defaultProps = {
  dataClarityMask: false,
  disabled: false,
  loading: false
};
