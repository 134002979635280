import {
  CompetitiveChannelsResponse,
  CompetitiveChannelsQueryParams
} from '../../store/modules/Competitive/Channels/Competitive.channels.types';
import { apiV1 } from '../shared/Api.service';

const path = '/sectoral/channels';

export default async function getCompetitiveChannels(
  params: CompetitiveChannelsQueryParams
): Promise<CompetitiveChannelsResponse> {
  const { data } = await apiV1.get<CompetitiveChannelsResponse>(path, {
    params
  });

  return data;
}
