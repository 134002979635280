import capitalize from 'lodash/capitalize';
import format from 'date-fns/format';
import brLocale from 'date-fns/locale/pt-BR';

export const stringToDate = (date: string) =>
  new Date(
    date.replace(/-/g, '/').replace('T', ' ')
    // .replace('.000Z', '')
  );

export const firstDayOfMonth = (date: Date) => {
  if (date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  throw new Error(`Invalid date: ${date}`);
};

export const formatBrLocale = (date: number | Date, strFormat: string) =>
  format(date, strFormat, { locale: brLocale });

export const getStrMonth = (date: Date) =>
  capitalize(formatBrLocale(date, 'MMMM'));

export const translateForPviTime = (time: string) => {
  const preTime = time.split(':');
  const h = preTime[0];
  const m = preTime[1];
  if (Number(h) >= 0 && Number(h) < 6) {
    switch (Number(h)) {
      case 0: {
        return `24:${m}`;
      }
      case 1: {
        return `25:${m}`;
      }
      case 2: {
        return `26:${m}`;
      }
      case 3: {
        return `27:${m}`;
      }
      case 4: {
        return `28:${m}`;
      }
      default:
        return `29:${m}`;
    }
  }
  return `${h}:${m}`;
};
