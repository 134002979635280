import { Reducer } from 'redux';
import { parseISO } from 'date-fns';
import {
  BaseAudienceState,
  BaseAudienceAction,
  BaseAudienceTypes
} from './BaseAudience.types';
import { TypeTV } from '../../../services/shared/Api.service';

const baseAudienceInitialState: BaseAudienceState = {
  closedTv: {
    dateMax: null,
    dateMin: null,
    loading: false,
    error: false
  },
  openedTv: {
    dateMax: null,
    dateMin: null,
    loading: false,
    error: false
  }
};

const reducer: Reducer<BaseAudienceState, BaseAudienceAction> = (
  state = baseAudienceInitialState,
  action
): BaseAudienceState => {
  switch (action.type) {
    case BaseAudienceTypes.LOAD_BASE_AUDIENCE_LIMITS_REQUEST: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            error: false,
            loading: true
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          error: false,
          loading: true
        }
      };
    }
    case BaseAudienceTypes.LOAD_BASE_AUDIENCE_LIMITS_SUCCESS: {
      const row = action.payload;
      const dateMax =
        typeof row.dateMax === 'string' ? parseISO(row.dateMax) : row.dateMax;
      const dateMin =
        typeof row.dateMin === 'string' ? parseISO(row.dateMin) : row.dateMin;

      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            dateMax,
            dateMin,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          dateMax,
          dateMin,
          loading: false
        }
      };
    }
    case BaseAudienceTypes.LOAD_BASE_AUDIENCE_LIMITS_ERROR: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            error: true,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          error: true,
          loading: false
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
