import { Reducer } from 'redux';
import {
  AudienceFilterPeriodsAction,
  AudienceFilterPeriodsState,
  AudienceFiltersPeriodsType
} from './Audience.periods.types';

const periodInitialState: AudienceFilterPeriodsState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterPeriodsState,
  AudienceFilterPeriodsAction
> = (state = periodInitialState, action): AudienceFilterPeriodsState => {
  switch (action.type) {
    case AudienceFiltersPeriodsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersPeriodsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersPeriodsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersPeriodsType.CLEAR: {
      return {
        loading: false,
        error: false,
        data: [],
        count: null
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
