import React from 'react';

import './NotificationsList.scss';
import message from '../../../assets/images/message.svg';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';

export interface NotificationListProps {
  id: number;
  title: string;
  message: string;
  icon?: string;
  dataClarityMask?: boolean;
}
interface NotificationsListProps {
  notifications: NotificationListProps[];
}

const NotificationsList: React.FC<NotificationsListProps> = ({
  notifications
}) => {
  return (
    <div className="notifications__list" data-testid="notifications__list">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="notifications-item"
          data-testid="notifications-item"
          {...attributeMaskOrUnmask(notification.dataClarityMask)}
        >
          <div className="notifications-item__left">
            <img alt="" src={notification?.icon || message} />
          </div>
          <div className="notifications-item__right">
            <p
              className="notifications-item__title"
              data-testid="notifications-item__title"
            >
              {notification.title}
            </p>
            <p
              className="notifications-item__info"
              data-testid="notifications-item__info"
            >
              {notification.message}
            </p>
          </div>
        </div>
      ))}
      <span className="no-more" data-testid="notifications__no-more">
        Sem mais notificações
      </span>
    </div>
  );
};

export default NotificationsList;
