export enum FormatsTypes {
  LOAD_FORMATS_REQUEST = '@formats/LOAD_FORMATS_REQUEST',
  LOAD_FORMATS_SUCCESS = '@formats/formatsORMATS_SUCCESS',
  LOAD_FORMATS_ERROR = '@formatsformats'
}

export type FormatReduced = {
  name: string;
  sigla: string;
};

export type Format = {
  name: string;
  sigla: string;
  secundagem: number;
  coeficienteTvPaga: number;
  coeficienteTvAberta: number;
  formatsForList: FormatReduced[];
  tvAberta: boolean;
  tvPaga: boolean;
};
export interface FormatsResponse {
  data: {
    rows: Format[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface FormatsAction {
  type: FormatsTypes;
  payload: {
    rows: Format[];
    count: number;
    message?: string;
  };
}
export interface FormatsState {
  readonly data: Format[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}
