// SCSS
import './Header.scss';
import Skeleton from '@mui/material/Skeleton';
import logo from '../../assets/images/logo.svg';
import notification from '../../assets/images/notification.svg';
import users from '../../assets/images/users.svg';
import logout from '../../assets/images/logout.svg';
import MenuNav from '../Menu';
import itemsMenuNav from './ItemsMenu';
import { OpenNotificationsDrawer } from '../../store/modules/Notifications/Notifications.actions';
import { Roles } from '../../store/modules/Users/Users.types';
import { MenuUserItemProps } from '../Menu/Nav/Item/MenuUserItem';
import paths from '../../routes/paths';
import { useAppDispatch, useAppSelector, useAppMediaQuery } from '../../hooks';
import { UserSignOut } from '../../store/modules/Auth/Auth.actions';
import classesGTM from '../../config';
import AppSVG from '../SVG/AppSVG';

const Header = () => {
  const dispatch = useAppDispatch();
  const itemsArray = itemsMenuNav();

  const { profile } = useAppSelector((state) => state.auth);

  const hasPermition = (
    roles: string[] = [Roles.MASTER, Roles.ADMIN, Roles.EXECUTIVE, Roles.AGENCY]
  ) => roles.includes(profile?.role || '');

  const matches = useAppMediaQuery('sm');

  const Profile = (
    <div className="logout__name ml-2">
      <span>Olá</span>
      <span className="profile__name" data-testid="profile__name">
        {!matches ? profile?.name?.split(' ')[0] : profile?.name}
      </span>
    </div>
  );

  const Badge = (
    <span
      className="user-menu__notification"
      data-testid="user-menu__notification--badge"
    >
      1
    </span>
  );

  const { users: pathUsers } = paths.home.childrens;
  const itemsMenuUser: MenuUserItemProps[] = [
    {
      itemId: 4,
      icon: users,
      path: pathUsers,
      visibled: hasPermition([Roles.ADMIN]),
      classes: { tagManager: classesGTM.home.users }
    },
    {
      itemId: 5,
      childrenAfterButton: Badge,
      classes: { item: 'user-menu__item ml-2' },
      icon: notification,
      onClick: () => dispatch(OpenNotificationsDrawer()),
      visibled: false
    },
    {
      itemId: 6,
      childrenBeforeButton: Profile,
      onClick: () => dispatch(UserSignOut()),
      icon: logout,
      classes: { item: 'logout', button: 'logout__button' },
      visibled: hasPermition(),
      disabled: false,
      divider: true,
      dataMask: true
    }
  ];

  return (
    <div className="main-header">
      <div className="main-header__wrapper">
        <div className="logo">
          <AppSVG
            onClick={() => window.location.replace('/')}
            className="cursor-pointer"
            loader={<Skeleton height={24} width={24} />}
            src={logo}
          />
        </div>
        <MenuNav itemsMenu={itemsArray} itemsMenuUser={itemsMenuUser} />
      </div>
    </div>
  );
};

export default Header;
