import { Reducer } from 'redux';
import {
  AudienceFilterYearsAction,
  AudienceFilterYearsState,
  AudienceFiltersYearsType
} from './Audience.years.types';

const yearInitialState: AudienceFilterYearsState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<AudienceFilterYearsState, AudienceFilterYearsAction> = (
  state = yearInitialState,
  action
): AudienceFilterYearsState => {
  switch (action.type) {
    case AudienceFiltersYearsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersYearsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersYearsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersYearsType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
