import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import { applyMask } from '../../Editors/Discount/DiscountUtils';

type Props = NumberFormatProps;

const PercentNumber = React.forwardRef(function PercentNumber(
  props: Props,
  ref
) {
  const { decimalScale = 3, autoFocus = true, ...other } = props;

  return (
    <NumberFormat
      {...other}
      autoComplete="off"
      autoFocus={autoFocus}
      decimalScale={decimalScale}
      decimalSeparator=","
      fixedDecimalScale
      format={(val) => applyMask(val, decimalScale, '##,##')}
      getInputRef={ref}
      max={100}
      min={0}
      onFocus={(e: any) => {
        if (Number(e.target.value) === 0) e.target.value = '';
      }}
      thousandSeparator="."
      {...attributeMaskOrUnmask(false)}
    />
  );
});
export default PercentNumber;

PercentNumber.defaultProps = {
  name: undefined
};
