import { toast } from 'react-toastify';

import { AppThunk } from '../../../../../..';
import {
  AudienceFilterAvarageAudienceByTimeRangesAction,
  AudienceFiltersAvarageAudienceByTimeRangesType,
  AvarageAudienceByTimeRangeResponse
} from './Audience.avarageAudienceByTimeRanges.types';
import getPayTvAvarageAudienceByTimeRangesByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.avarageAudienceByTimeRanges.service';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const LoadAudienceFilterAvarageAudienceByTimeRangesSuccess = (
  response: AvarageAudienceByTimeRangeResponse
) => {
  const payload: AudienceFilterAvarageAudienceByTimeRangesAction = {
    type: AudienceFiltersAvarageAudienceByTimeRangesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByTimeRangesError = (error: string) => {
  const payload: AudienceFilterAvarageAudienceByTimeRangesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByTimeRangesType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByTimeRangesFilter = () => ({
  type: AudienceFiltersAvarageAudienceByTimeRangesType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByTimeRangesData = () => ({
  type: AudienceFiltersAvarageAudienceByTimeRangesType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByTimeRangesRequest =
  ({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    channel,
    period,
    table
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByTimeRangesFilter());
      const response = await getPayTvAvarageAudienceByTimeRangesByChannels({
        target,
        timeRanges,
        weekDays,
        programmingGenders,
        channel,
        period,
        table: table ?? 'anual',
        fields: ['pc_audiencia', 'ds_faixa_horaria']
      });

      dispatch(LoadAudienceFilterAvarageAudienceByTimeRangesSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByTimeRangesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por faixa horária'
      );
    }
  };
