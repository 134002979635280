import { isArray } from 'lodash';
import { IAudienceByProgramsRankingFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Programs/types';
import { apiV1 } from '../../shared/Api.service';

const path = '/audience/pay-tv/data';

export default async function getPayTvPrograms({
  channelGeneres,
  programmers,
  channels,
  programmingGenders,
  target,
  timeRange,
  timeRanges,
  channelsNotIn
}: IAudienceByProgramsRankingFilterParams): Promise<any> {
  const { data } = await apiV1.get(path, {
    params: {
      fields: ['nm_programa'],
      table: 'anual',
      targets: target?.value ? [target?.value] : undefined,
      timeRanges: timeRange?.value
        ? [timeRange?.value]
        : timeRanges?.map((timeRangeOption) => timeRangeOption.value),
      weekDays: ['TODOS'],
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : ['TODOS'],
      channelGenres: channelGeneres?.map((genere) => genere.value),
      channelCompanies: programmers?.map((prog) => prog.value),
      channels: channels?.map((channel) => channel.value),
      channelsNotIn
    }
  });

  return data;
}
