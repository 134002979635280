import { toast } from 'react-toastify';
import getFormats from '../../../services/MediaSimulator/Formats.service';
import { AppThunk } from '../..';
import { FormatsAction, FormatsResponse, FormatsTypes } from './Formats.types';

const LoadFormatsSuccess = (response: FormatsResponse) => {
  const payload: FormatsAction = {
    type: FormatsTypes.LOAD_FORMATS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows
    }
  };

  return payload;
};

const LoadFormatsError = (error: string) => {
  const payload: FormatsAction = {
    type: FormatsTypes.LOAD_FORMATS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingFormats = () => ({
  type: FormatsTypes.LOAD_FORMATS_REQUEST
});

const LoadFormatsRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingFormats());
    const response = await getFormats();
    dispatch(LoadFormatsSuccess(response));
  } catch (error) {
    dispatch(LoadFormatsError(error instanceof Error ? error.message : ''));
    toast.error('Ops, houve um problema ao tentar carregar os formatos.');
  }
};

export default LoadFormatsRequest;
