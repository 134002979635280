import { format } from 'date-fns';
import usePlanningRedux from './usePlanningRedux';
import useFilters from '../Filters/useFilters';
import { translateForPviTime } from '../../helpers/Dates';
import { useFiltersRedux } from '../Filters';
import { TypeTV } from '../../services/shared/Api.service';

const usePlanningPvi = () => {
  const planning = usePlanningRedux().currentPlanning;
  const { getCurrentFilters } = useFilters();

  const filters = getCurrentFilters();

  const { currentTargets } = useFiltersRedux(
    filters?.data?.typeTV || TypeTV.OPEN
  );

  const getHour = (hour: string) => {
    const transformedHouder = translateForPviTime(hour);
    const returnedHours = transformedHouder.split(':')[0];
    return returnedHours.concat(hour.split(':')[1]);
  };
  const getMinute = (hour: string) => {
    const returnedMinute = hour.split(':')[1];
    return returnedMinute.concat(hour.split(':')[2]);
  };
  const transformPlanningToJson = (title: string) => {
    const currentFilter = getCurrentFilters();
    const pvi: any = {};

    pvi.Publico = {
      NomePublico: currentTargets.data.find((t) => t.name === planning.target)
        ?.kantarName
    };

    let inserctionCount = 1;
    planning.planningPrograms.forEach((p) => {
      let codEmissora: number | undefined;
      if (filters?.data) {
        const emissora = filters.data.channels.find(
          (x) => x.description.toLowerCase() === p.channel.toLowerCase()
        );
        codEmissora = emissora?.channelId;
      }
      const sigla = p?.initials || '';
      const programName = p.name;
      const { secondary } = p;
      pvi.Plano = {
        NomePlano: title,
        Duracao: 30,
        CodigoPraca: currentFilter?.data?.market.id,
        Publico: planning.target,
        Inicio: format(
          new Date(currentFilter?.data?.dates.audience.from as Date),
          'yyyy/MM/dd'
        ),
        Termino: format(
          new Date(currentFilter?.data?.dates.audience.to as Date),
          'yyyy/MM/dd'
        )
      };

      p.insertionsByDay.forEach((insertion) => {
        if (insertion.value > 0) {
          const nInsercao = insertion.value;
          for (let insercao = 1; insercao <= nInsercao; insercao += 1) {
            pvi.Insercoes = {
              Count: inserctionCount
            };

            pvi[`Insercao_${inserctionCount}`] = {
              Data: format(new Date(insertion.day), 'yyyy/MM/dd'),
              CodEmissora: codEmissora,
              Mnemonico: sigla,
              NomePrograma: programName,
              Duracao: secondary.replace('s', ''),
              Custo: p.negotiatedValue.toString().replace('.', ','),
              HoraInicio: getHour(p.avgProgramStartTime),
              HoraFim: getHour(p.avgProgramEndTime),
              Minuto: getMinute(p.avgProgramStartTime)
            };
            inserctionCount += 1;
          }
        }
      });
    });

    return pvi;
  };

  return {
    transformPlanningToJson
  };
};

export default usePlanningPvi;
