import { Reducer } from 'redux';
import {
  AudienceFilterWeekDaysAction,
  AudienceFilterWeekDaysState,
  AudienceFiltersWeekDaysType
} from './Audience.weekDays.types';

const weekDayInitialState: AudienceFilterWeekDaysState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<
  AudienceFilterWeekDaysState,
  AudienceFilterWeekDaysAction
> = (state = weekDayInitialState, action): AudienceFilterWeekDaysState => {
  switch (action.type) {
    case AudienceFiltersWeekDaysType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersWeekDaysType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: 0
      };
    }

    case AudienceFiltersWeekDaysType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
