import { useCallback, useMemo } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import { planningInitialState } from '../../store/modules/Planning/Planning.reducer';

export default function usePlanningRedux() {
  const { planning, simulation } = useAppSelector((state) => state);

  const getPlanningByScenery = useCallback(
    (sceneryId: string) =>
      planning.find((plan) => plan.sceneryId === sceneryId),
    [planning]
  );

  const currentPlanning = useMemo(() => {
    const currentPlan = getPlanningByScenery(simulation.currentSceneryId);
    if (!currentPlan) {
      return {
        ...planningInitialState[0],
        sceneryId: simulation.currentSceneryId
      };
    }

    return currentPlan;
  }, [simulation.currentSceneryId, getPlanningByScenery]);

  return { currentPlanning, getPlanningByScenery };
}
