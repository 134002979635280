import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import {
  IAudienceByChannelsPayTvRadiographyFilterProps,
  TypePeriod
} from '../../types';
import { LoadAudienceFilterPeriodsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';
import { OptionsList } from '../../../../../../Form/types';

const useAudiencePayTvChannelsRadiographyFilterLoadData = ({
  channel,
  period,
  target,
  timeRange
}: Partial<IAudienceByChannelsPayTvRadiographyFilterProps>) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);

  useEffect(() => {
    if (
      channel &&
      audience?.payTv?.channels?.count !== 0 &&
      audience?.payTv?.channels?.count !== null &&
      audience?.payTv?.channels?.loading === false &&
      target &&
      timeRange &&
      audience?.payTv?.periods?.count === null &&
      !audience?.payTv?.periods?.error &&
      !audience?.payTv?.periods?.loading
    ) {
      dispatch(
        LoadAudienceFilterPeriodsRequest({
          target,
          timeRange,
          channel
        })
      );
    }
  }, [
    audience?.payTv?.channels?.count,
    audience?.payTv?.channels?.loading,
    audience?.payTv?.periods?.count,
    audience?.payTv?.periods?.error,
    audience?.payTv?.periods?.loading,
    target,
    timeRange,
    channel,
    dispatch,
    period
  ]);

  const channelsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.channels.count) {
      return audience?.payTv?.channels.data
        .filter((channelOption) => channelOption.nm_emissora)
        .map((channelOption) => ({
          label: channelOption.nm_emissora,
          value: channelOption.nm_emissora
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  }, [audience?.payTv?.channels.data, audience?.payTv?.channels.count]);

  const periodsOptions = useMemo<TypePeriod[]>(() => {
    if (audience?.payTv?.periods.count) {
      return audience?.payTv?.periods.data
        .filter((timeRangeOption) => timeRangeOption.ds_periodo)
        .map((timeRangeOption) => ({
          label: timeRangeOption.ds_periodo,
          value: timeRangeOption.ds_periodo,
          tp_dado: timeRangeOption.tp_dado
        }))
        .sort((a, b) => {
          if (a?.value?.includes('SEM') && !b?.value?.includes('SEM')) {
            return 1;
          }

          if (!a?.value?.includes('SEM') && b?.value?.includes('SEM')) {
            return -1;
          }

          if (a?.value?.includes('TRI') && !b?.value?.includes('TRI')) {
            return 1;
          }

          if (!a?.value?.includes('TRI') && b?.value?.includes('TRI')) {
            return -1;
          }

          return a?.value?.localeCompare(b?.value);
        });
    }

    return [];
  }, [audience?.payTv?.periods.data, audience?.payTv?.periods.count]);

  return {
    periodsOptions,
    channelsOptions
  };
};

export default useAudiencePayTvChannelsRadiographyFilterLoadData;
