import { IAudienceByChannelsRadiographyRequestParams } from '../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import { apiV1 } from '../../../shared/Api.service';

const path = '/audience/open-tv/regular-plazas/programs';

export default async function getOpenTvRegularPlazasPrograms({
  targets,
  timeRanges,
  weekDays,
  programmingGenders,
  channels,
  plazas,
  periods,
  table,
  fields,
  channelsNotIn
}: IAudienceByChannelsRadiographyRequestParams): Promise<any> {
  const { data } = await apiV1.get(path, {
    params: {
      targets,
      timeRanges,
      weekDays,
      programmingGenders,
      channels,
      plazas,
      periods,
      table,
      fields,
      channelsNotIn
    }
  });

  return data;
}
