import { apiV1 } from '../../../shared/Api.service';
import { TimeRangeResponse } from '../../../../store/modules/AudienceModule/OpenTv/TimeRanges/Audience.timeRanges.types';

const path = '/audience/open-tv/regular-plazas/time-ranges';

export default async function getOpenTvRegularPlazasTimeRanges(): Promise<TimeRangeResponse> {
  const { data } = await apiV1.get<TimeRangeResponse>(path);

  return data;
}
