import '../AudiencePayTvChannelsFilters.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Grid } from '@mui/material';

import FormContainer from '../../../../../Form/FormContainer';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { OptionsList } from '../../../../../Form/types';
import { ClearAudiencePeriodsData } from '../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';
import { IAudienceByChannelsPayTvRadiographyFilterProps } from '../types';
import { TypePeriod } from '../../../OpenTv/Channels/types';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';
import { listboxSigleProps } from '../../../../../Competitive/CompetitiveFilter/constants';
import useAudiencePayTvChannelsRadiographyFilterLoadData from './hooks/useAudiencePayTvChannelsRadiographyFilterLoadData';
import useAudiencePayTvChannelsRadiographyFilterAutoSelect from './hooks/useAudiencePayTvChannelsRadiographyFilterAutoSelect';

const AudiencebyPayTvChannelsRadiographyFilter: React.FC<
  IAudienceByChannelsPayTvRadiographyFilterProps
> = ({
  channel,
  period,
  setChannel,
  setPeriod,
  newFilter,
  setNewFilter,
  target,
  timeRange,
  weekDays,
  programmingGenders
}: IAudienceByChannelsPayTvRadiographyFilterProps) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const inputsConfig = useMemo(
    () => ({
      channels: {
        name: 'channels',
        defaultValue: 'GloboNews'
      },
      period: {
        name: 'period',
        defaultValue: '2024'
      }
    }),
    []
  );
  const { channelsOptions, periodsOptions } =
    useAudiencePayTvChannelsRadiographyFilterLoadData({
      channel,
      period,
      target,
      timeRange,
      weekDays,
      programmingGenders
    });

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  useAudiencePayTvChannelsRadiographyFilterAutoSelect({
    channel,
    period,
    setChannel,
    setPeriod,
    channelsOptions,
    periodsOptions,
    clearInputs,
    inputsConfig
  });

  const handleChannelChange = (option: OptionsList) => {
    dispatch(ClearAudiencePeriodsData());
    clearInputs([inputsConfig.period.name]);

    setChannel(option);
  };

  const handlePeriodChange = (option: TypePeriod) => {
    setPeriod(option);
  };

  const handleSendClick = () => {
    return;
  };

  useEffect(() => {
    if (newFilter === true) {
      clearInputs([inputsConfig.channels.name, inputsConfig.period.name]);
      setChannel(undefined);
      setPeriod(undefined);

      if (setNewFilter) setNewFilter(false);
    }
  }, [newFilter, setNewFilter, setChannel, setPeriod, inputsConfig]);

  return (
    <Grid
      container
      padding={0}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Emissora"
          name={inputsConfig.channels?.name}
          data-testid="channel"
          disabled={
            audience?.payTv?.channels.loading ||
            audience?.payTv?.channels.count === 0
          }
          loading={audience?.payTv?.channels.loading}
          ListboxProps={listboxSigleProps}
          options={channelsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={channelsOptions.find(
            (option) => option.value === inputsConfig.channels.defaultValue
          )}
          onChange={handleChannelChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Período"
          name={inputsConfig.period?.name}
          data-testid="mercado"
          disabled={
            audience?.payTv?.periods.loading ||
            audience?.payTv?.periods.count === 0
          }
          loading={audience?.payTv?.periods.loading}
          ListboxProps={listboxSigleProps}
          options={periodsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={periodsOptions.find(
            (option) => option.value === inputsConfig.period.defaultValue
          )}
          onChange={handlePeriodChange}
          sx={{
            marginLeft: 'auto',
            marginRight: '-40px',
            width: '20%'
          }}
        />
      </FormContainer>
    </Grid>
  );
};

export default AudiencebyPayTvChannelsRadiographyFilter;
