import { isWeekend, lastDayOfMonth } from 'date-fns';
import { formatBrLocale } from '../../helpers/Dates';
import {
  BORDER_SLA_DASH_DOT_BLACK,
  BORDER_SOLID_BLACK,
  BORDER_SOLID_MED_BLACK,
  FILL_COLORS,
  FILL_DEFAULT,
  FILL_DEFAULT_GRAY_1,
  FILL_DEFAULT_GRAY_2,
  FONT_DEFAULT,
  FONT_DEFAULT_BOLD,
  FONT_DEFAULT_BOLD_BLUE,
  FONT_DEFAULT_BOLD_RED,
  FONT_DEFAULT_CAMBRIA,
  FONT_DEFAULT_SIZE_16,
  FONT_DEFAULT_SIZE_16_RED
} from '../constants';
import { MapCell, MapCol, MapColData } from '../types';
import {
  applyAllConfig,
  encodeCell,
  formulaIF,
  formulaMultiplication,
  formulaSUM
} from '../utils';
import {
  INIT_ROW_DAYS_OF_WEEK,
  INIT_ROW_DAYS_OF_WEEK_IDX,
  mapColsXlsx,
  textsFooter
} from './MediaMap.constants';
import {
  ExportMapMediaDataHead,
  ExportMapMediaDataProps,
  ExportMapMediaDataRow
} from './MediaMap.types';
import {
  formulaAvgDiscount,
  formulaDiscount,
  getColsDaysOfWeek,
  getIdxCol
} from './MediaMap.utils';

const getConfigDefaultColEmpty = (
  rowIdx: number,
  col: MapCol,
  diagonalUp: boolean,
  color: 'default' | 'gray1' | 'gray2' = 'default'
): MapColData => ({
  cell: { r: rowIdx, c: getIdxCol(col.name) },
  value: '',
  cellStyle: {
    fill: FILL_COLORS[color],
    border: {
      diagonal: BORDER_SOLID_BLACK,
      diagonalUp,
      top: BORDER_SOLID_BLACK,
      bottom: BORDER_SOLID_BLACK,
      right: BORDER_SOLID_BLACK,
      left: BORDER_SOLID_BLACK
    },
    alignment: { horizontal: 'center' }
  }
});

const generateConfigDaysOfWeek = (
  rowIdx: number,
  year: number,
  month = 0,
  format: 'string' | 'number' = 'string'
): MapColData[] => {
  const lastDay = lastDayOfMonth(new Date(year, month, 1));

  return getColsDaysOfWeek().map<MapColData>((col, idx) => {
    if (idx + 1 > lastDay.getDate()) {
      return getConfigDefaultColEmpty(13, col, true, 'gray2');
    }

    return {
      cell: { r: rowIdx, c: getIdxCol(col.name) },
      value: formatBrLocale(
        new Date(year, month, idx + 1),
        format === 'string' ? 'EEEEE' : 'd'
      ),
      cellStyle: {
        fill: FILL_DEFAULT_GRAY_2,
        font: format === 'number' ? FONT_DEFAULT_BOLD : FONT_DEFAULT_BOLD_BLUE,
        border: {
          top: BORDER_SOLID_BLACK,
          bottom: BORDER_SOLID_BLACK,
          right: BORDER_SOLID_BLACK,
          left: BORDER_SOLID_BLACK
        },
        alignment: { horizontal: 'center' }
      }
    };
  });
};

export const fillOthersRowData = (
  rowIdx: number,
  data: ExportMapMediaDataRow,
  lastDay: Date
): MapCell => {
  const defaultConfig: MapColData['cellStyle'] = {
    fill: FILL_DEFAULT,
    font: FONT_DEFAULT,
    border: {
      top: BORDER_SOLID_BLACK,
      bottom: BORDER_SOLID_BLACK,
      right: BORDER_SOLID_BLACK,
      left: BORDER_SOLID_BLACK
    },
    alignment: { horizontal: 'center' }
  };

  const colsWeek = getColsDaysOfWeek();
  const SUM = formulaSUM(
    colsWeek[0].name,
    colsWeek[lastDay.getDate() - 1].name,
    rowIdx
  );

  return {
    rowData: [
      {
        cellStyle: {
          ...defaultConfig,
          border: {
            ...defaultConfig.border,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'left' }
        },
        cell: { r: rowIdx, c: getIdxCol('A') },
        value: data.initials
      },
      {
        cell: { r: rowIdx, c: getIdxCol('B') },
        value: data.name,
        range: {
          s: { r: rowIdx, c: getIdxCol('B') },
          e: { r: rowIdx, c: getIdxCol('C') }
        },
        cellStyle: { ...defaultConfig, alignment: { horizontal: 'left' } }
      },
      {
        cell: { r: rowIdx, c: getIdxCol('D') },
        value: '',
        cellStyle: defaultConfig
      },
      {
        type: 'n',
        cell: { r: rowIdx, c: getIdxCol('E') },
        value: data.duration || '',
        cellStyle: defaultConfig
      },
      {
        type: 'n',
        cell: { r: rowIdx, c: getIdxCol('AK') },
        formula: formulaIF(`${SUM} = 0`, '""', SUM),
        cellStyle: defaultConfig
      },
      {
        type: 'n',
        cell: { r: rowIdx, c: getIdxCol('AL') },
        value: data.price || '',
        cellStyle: {
          ...defaultConfig,
          alignment: { horizontal: 'right' },
          numFmt: '0.00'
        }
      },
      {
        type: 'n',
        cell: { r: rowIdx, c: getIdxCol('AM') },
        formula: formulaIF(
          `${encodeCell(rowIdx, 'AK')} = ""`,
          '""',
          formulaMultiplication('AK', 'AL', rowIdx)
        ),
        cellStyle: {
          ...defaultConfig,
          alignment: { horizontal: 'right' },
          numFmt: '0.00'
        }
      },
      {
        type: 'n',
        cell: { r: rowIdx, c: getIdxCol('AN') },
        value: data.discount ? data.discount / 100 : '',
        cellStyle: {
          ...defaultConfig,
          numFmt: '0.000%',
          border: {
            ...defaultConfig.border,
            right: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'right' }
        }
      },
      {
        type: 'n',
        cell: { r: rowIdx, c: getIdxCol('AO') },
        formula: formulaIF(
          `${encodeCell(rowIdx, 'AN')} = ""`,
          encodeCell(rowIdx, 'AM'),
          formulaDiscount(rowIdx, 'AK', 'AL', 'AN')
        )
      }
    ]
  };
};

export const fillRowDaysOfWeek = (
  insertionsByDay: ExportMapMediaDataRow['insertionsByDay'],
  lastDay: Date,
  rowIdx: number
): MapCell => {
  const cols = getColsDaysOfWeek();
  const rowData = cols.map<MapColData>((col, idx) => {
    if (idx + 1 > lastDay.getDate()) {
      return getConfigDefaultColEmpty(rowIdx, col, true);
    }

    const insertion = insertionsByDay.find(
      (ins) => new Date(ins.day).getDate() === idx + 1
    );

    return {
      type: 'n',
      cell: { r: rowIdx, c: getIdxCol(col.name) },
      value: insertion?.value ? insertion.value.toString() : '',
      cellStyle: {
        fill: isWeekend(
          new Date(lastDay.getFullYear(), lastDay.getMonth(), idx + 1)
        )
          ? FILL_DEFAULT_GRAY_2
          : FILL_DEFAULT,
        font: FONT_DEFAULT,
        border: {
          top: BORDER_SOLID_BLACK,
          bottom: BORDER_SOLID_BLACK,
          right: BORDER_SOLID_BLACK,
          left: BORDER_SOLID_BLACK
        },
        alignment: { horizontal: 'center' }
      }
    };
  });

  return { rowData };
};

const fillRowSummary = (rowIdx: number, lastDay: Date): MapCell => {
  const colsDaysOfWeek = getColsDaysOfWeek();

  const rowData = mapColsXlsx.map<MapColData>((col) => {
    if (colsDaysOfWeek.includes(col) || ['AK', 'AM', 'AO'].includes(col.name)) {
      const dayOfCol = colsDaysOfWeek.findIndex((c) => c.name === col.name);
      const SUM_DAY = formulaSUM(
        col.name,
        col.name,
        INIT_ROW_DAYS_OF_WEEK_IDX,
        rowIdx - 1
      );

      const cell = getConfigDefaultColEmpty(
        rowIdx,
        col,
        colsDaysOfWeek.includes(col) && dayOfCol > lastDay.getDate(),
        'gray2'
      );

      const config: MapColData = {
        ...cell,
        cellStyle: { ...cell.cellStyle, font: FONT_DEFAULT_BOLD },
        type: 'n',
        formula: formulaIF(
          `${SUM_DAY} = 0`,
          col.name === 'AO' ? '0' : '""',
          SUM_DAY
        )
      };

      if (col.name === 'AM') {
        config.cellStyle = {
          ...config.cellStyle,
          border: {
            ...(cell.cellStyle?.border || {}),
            left: undefined
          },
          alignment: { horizontal: 'right' },
          numFmt: '0.00'
        };
      }

      return config;
    }
    const cell = getConfigDefaultColEmpty(rowIdx, col, false, 'gray2');

    if (['A', 'AN', 'AL'].includes(col.name)) {
      const config: MapColData = {
        ...cell,
        type: 'n'
      };

      if (col.name === 'A') {
        config.range = {
          s: { r: rowIdx, c: getIdxCol('A') },
          e: { r: rowIdx, c: getIdxCol('E') }
        };
        config.cellStyle = {
          ...cell.cellStyle,
          border: {
            ...(cell.cellStyle?.border || {}),
            left: BORDER_SOLID_MED_BLACK
          }
        };
      }

      if (col.name === 'AL') {
        config.cellStyle = {
          ...cell.cellStyle,
          border: {
            ...(cell.cellStyle?.border || {}),
            right: undefined
          }
        };
      }

      if (col.name === 'AN') {
        config.cellStyle = {
          ...cell.cellStyle,
          border: {
            ...(cell.cellStyle?.border || {}),
            right: BORDER_SOLID_MED_BLACK
          },
          numFmt: '0.000%',
          alignment: { horizontal: 'right' },
          font: FONT_DEFAULT_BOLD
        };

        config.formula = formulaIF(
          `${encodeCell(rowIdx, 'AM')} = ${encodeCell(rowIdx, 'AO')}`,
          '""',
          formulaAvgDiscount(rowIdx, 'AO', 'AM')
        );
      }

      return config;
    }

    return cell;
  });

  return { rowData };
};

const generateModelXlsxFooter = (
  rowIdxSummary: number,
  dateBuy: Date
): MapCell[] => {
  const defaultCellStyle: MapColData['cellStyle'] = {
    font: FONT_DEFAULT_CAMBRIA,
    border: {
      left: BORDER_SOLID_MED_BLACK,
      right: BORDER_SOLID_MED_BLACK
    },
    alignment: { horizontal: 'left' }
  };

  const allMedBorders = {
    ...defaultCellStyle.border,
    bottom: BORDER_SOLID_MED_BLACK,
    top: BORDER_SOLID_MED_BLACK
  };

  return [
    {
      rowInfo: { hpt: 14.5 },
      rowData: [
        {
          cell: { r: rowIdxSummary + 1, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 1, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 1, c: getIdxCol('Y') }
          },
          value: '',
          cellStyle: {
            ...defaultCellStyle,
            border: {
              ...defaultCellStyle.border,
              top: BORDER_SOLID_MED_BLACK
            }
          }
        },
        {
          cell: { r: rowIdxSummary + 1, c: getIdxCol('AA') },
          range: {
            s: { r: rowIdxSummary + 1, c: getIdxCol('AA') },
            e: { r: rowIdxSummary + 1, c: getIdxCol('AJ') }
          },
          value: 'CARIMBO DA EMPRESA',
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: { ...defaultCellStyle.border, top: BORDER_SOLID_MED_BLACK },
            alignment: { horizontal: 'center' }
          }
        },
        {
          cell: { r: rowIdxSummary + 1, c: getIdxCol('AK') },
          range: {
            s: { r: rowIdxSummary + 1, c: getIdxCol('AK') },
            e: { r: rowIdxSummary + 2, c: getIdxCol('AL') }
          },
          value: 'VALOR NEGOCIADO',
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: allMedBorders,
            alignment: { horizontal: 'center' }
          }
        },
        {
          type: 'n',
          cell: { r: rowIdxSummary + 1, c: getIdxCol('AM') },
          range: {
            s: { r: rowIdxSummary + 1, c: getIdxCol('AM') },
            e: { r: rowIdxSummary + 2, c: getIdxCol('AN') }
          },
          formula: encodeCell(rowIdxSummary, 'AO'),
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: allMedBorders,
            alignment: { horizontal: 'center' },
            numFmt: '0.00'
          }
        }
      ]
    },
    {
      rowInfo: { hpt: 14 },
      rowData: [
        {
          cell: { r: rowIdxSummary + 2, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 2, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 2, c: getIdxCol('Y') }
          },
          value: textsFooter[0],
          cellStyle: defaultCellStyle
        },
        {
          cell: { r: rowIdxSummary + 2, c: getIdxCol('AA') },
          range: {
            s: { r: rowIdxSummary + 2, c: getIdxCol('AA') },
            e: { r: rowIdxSummary + 2, c: getIdxCol('AJ') }
          },
          value: 'ASSINATURA CLIENTE E',
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            alignment: { horizontal: 'center' }
          }
        }
      ]
    },
    {
      // rowInfo: { hpt: 27.5 },
      rowData: [
        {
          cell: { r: rowIdxSummary + 3, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 3, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 3, c: getIdxCol('Y') }
          },
          value: textsFooter[1].replace(
            '#MES#',
            formatBrLocale(dateBuy, 'MMMM').toUpperCase()
          ),
          cellStyle: {
            ...defaultCellStyle,
            alignment: { ...defaultCellStyle.alignment, wrapText: true }
          }
        },
        {
          cell: { r: rowIdxSummary + 3, c: getIdxCol('AA') },
          range: {
            s: { r: rowIdxSummary + 3, c: getIdxCol('AA') },
            e: { r: rowIdxSummary + 3, c: getIdxCol('AJ') }
          },
          value: 'NOME LEGÍVEL',
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            alignment: { horizontal: 'center', vertical: 'top' }
          }
        },
        {
          cell: { r: rowIdxSummary + 3, c: getIdxCol('AK') },
          range: {
            s: { r: rowIdxSummary + 3, c: getIdxCol('AK') },
            e: { r: rowIdxSummary + 4, c: getIdxCol('AL') }
          },
          value: 'COMISSÃO AGENCIA',
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: allMedBorders,
            alignment: { horizontal: 'center' }
          }
        },
        {
          type: 'n',
          cell: { r: rowIdxSummary + 3, c: getIdxCol('AM') },
          range: {
            s: { r: rowIdxSummary + 3, c: getIdxCol('AM') },
            e: { r: rowIdxSummary + 4, c: getIdxCol('AN') }
          },
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: allMedBorders,
            alignment: { horizontal: 'center' },
            numFmt: '0.00'
          }
        }
      ]
    },
    {
      rowInfo: { hpt: 21 },
      rowData: [
        {
          cell: { r: rowIdxSummary + 4, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 4, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 4, c: getIdxCol('Y') }
          },
          value: textsFooter[2],
          cellStyle: {
            ...defaultCellStyle,
            alignment: { ...defaultCellStyle.alignment, wrapText: true }
          }
        },
        {
          cell: { r: rowIdxSummary + 4, c: getIdxCol('AA') },
          range: {
            s: { r: rowIdxSummary + 4, c: getIdxCol('AA') },
            e: { r: rowIdxSummary + 7, c: getIdxCol('AJ') }
          },
          cellStyle: {
            ...defaultCellStyle,
            border: {
              ...defaultCellStyle.border,
              bottom: BORDER_SOLID_MED_BLACK
            },
            alignment: { horizontal: 'center' }
          }
        }
      ]
    },
    {
      // rowInfo: { hpt: 21 },
      rowData: [
        {
          cell: { r: rowIdxSummary + 5, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 5, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 5, c: getIdxCol('Y') }
          },
          value: textsFooter[3],
          cellStyle: {
            ...defaultCellStyle,
            alignment: { ...defaultCellStyle.alignment, wrapText: true }
          }
        },
        {
          cell: { r: rowIdxSummary + 5, c: getIdxCol('AK') },
          range: {
            s: { r: rowIdxSummary + 5, c: getIdxCol('AK') },
            e: { r: rowIdxSummary + 7, c: getIdxCol('AL') }
          },
          value: 'VALOR LIQUIDO',
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: allMedBorders,
            alignment: { horizontal: 'center' }
          }
        },
        {
          type: 'n',
          cell: { r: rowIdxSummary + 5, c: getIdxCol('AM') },
          range: {
            s: { r: rowIdxSummary + 5, c: getIdxCol('AM') },
            e: { r: rowIdxSummary + 7, c: getIdxCol('AN') }
          },
          formula: `${encodeCell(rowIdxSummary + 1, 'AM')} - ${encodeCell(
            rowIdxSummary + 3,
            'AM'
          )}`,
          cellStyle: {
            ...defaultCellStyle,
            font: FONT_DEFAULT_BOLD,
            border: allMedBorders,
            alignment: { horizontal: 'center' },
            numFmt: '0.00'
          }
        }
      ]
    },
    {
      rowData: [
        {
          cell: { r: rowIdxSummary + 6, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 6, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 6, c: getIdxCol('Y') }
          },
          value: textsFooter[4],
          cellStyle: defaultCellStyle
        }
      ]
    },
    {
      rowData: [
        {
          cell: { r: rowIdxSummary + 7, c: getIdxCol('A') },
          range: {
            s: { r: rowIdxSummary + 7, c: getIdxCol('A') },
            e: { r: rowIdxSummary + 7, c: getIdxCol('Y') }
          },
          value: textsFooter[5],
          cellStyle: {
            ...defaultCellStyle,
            border: {
              ...defaultCellStyle.border,
              bottom: BORDER_SOLID_MED_BLACK
            }
          }
        }
      ]
    }
  ];
};

const generateModelXlsx = ({
  year,
  month = 0,
  market,
  channel,
  userName
}: ExportMapMediaDataHead): MapCell[] => [
  { rowData: [] },
  {
    rowData: [
      {
        value: 'EMISSORA:',
        cell: { r: 1, c: getIdxCol('A') },
        cellStyle: {
          font: FONT_DEFAULT_BOLD
        }
      },
      {
        value: channel,
        cell: { r: 1, c: getIdxCol('B') },
        cellStyle: {
          font: FONT_DEFAULT_SIZE_16
        }
      },
      {
        value: 'CANAL:',
        cell: { r: 1, c: getIdxCol('F') },
        range: {
          s: { r: 1, c: getIdxCol('F') },
          e: { r: 1, c: getIdxCol('G') }
        },
        cellStyle: { font: FONT_DEFAULT_BOLD }
      },
      {
        value: 'CIDADE:',
        cell: { r: 1, c: getIdxCol('O') },
        range: {
          s: { r: 1, c: getIdxCol('O') },
          e: { r: 1, c: getIdxCol('P') }
        },
        cellStyle: { font: FONT_DEFAULT_BOLD }
      },
      {
        value: market?.signal || '',
        cell: { r: 1, c: getIdxCol('R') },
        range: {
          s: { r: 1, c: getIdxCol('R') },
          e: { r: 1, c: getIdxCol('S') }
        },
        cellStyle: {
          font: FONT_DEFAULT_SIZE_16_RED,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_MED_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        value: '-',
        cell: { r: 1, c: getIdxCol('T') },
        cellStyle: {
          font: FONT_DEFAULT_SIZE_16,
          alignment: { horizontal: 'center' }
        }
      },
      {
        value: market?.name || '',
        cell: { r: 1, c: getIdxCol('U') },
        range: {
          s: { r: 1, c: getIdxCol('U') },
          e: { r: 1, c: getIdxCol('AL') }
        },
        cellStyle: { font: FONT_DEFAULT_SIZE_16 }
      },
      {
        value: new Date().toLocaleDateString(),
        cell: { r: 1, c: getIdxCol('AM') },
        range: {
          s: { r: 1, c: getIdxCol('AM') },
          e: { r: 1, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: { ...FONT_DEFAULT_BOLD, sz: 14 },
          alignment: { horizontal: 'right' }
        }
      }
    ]
  },
  { rowData: [] },

  {
    rowData: [
      {
        cell: { r: 3, c: getIdxCol('A') },
        value: 'DADOS DA AGÊNCIA / CORRETOR',
        range: {
          s: { r: 3, c: getIdxCol('A') },
          e: { r: 3, c: getIdxCol('Q') }
        },
        cellStyle: {
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          fill: FILL_DEFAULT_GRAY_1,
          font: FONT_DEFAULT_BOLD
        }
      },
      {
        cell: { r: 3, c: getIdxCol('U') },
        value: 'DADOS DO CLIENTE',
        range: {
          s: { r: 3, c: getIdxCol('U') },
          e: { r: 3, c: getIdxCol('AN') }
        },
        cellStyle: {
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          fill: FILL_DEFAULT_GRAY_1,
          font: FONT_DEFAULT_BOLD
        }
      }
    ]
  },
  {
    rowData: [
      {
        value: 'NOME:',
        cell: { r: 4, c: getIdxCol('A') },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { left: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        cell: { r: 4, c: getIdxCol('B') },
        range: {
          s: { r: 4, c: getIdxCol('B') },
          e: { r: 4, c: getIdxCol('Q') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { right: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        value: 'NOME:',
        cell: { r: 4, c: getIdxCol('U') },
        range: {
          s: { r: 4, c: getIdxCol('U') },
          e: { r: 4, c: getIdxCol('W') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { left: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        cell: { r: 4, c: getIdxCol('X') },
        range: {
          s: { r: 4, c: getIdxCol('X') },
          e: { r: 4, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { right: BORDER_SOLID_MED_BLACK }
        }
      }
    ]
  },
  {
    rowData: [
      {
        value: 'ENDEREÇO:',
        cell: { r: 5, c: getIdxCol('A') },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { left: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        cell: { r: 5, c: getIdxCol('B') },
        range: {
          s: { r: 5, c: getIdxCol('B') },
          e: { r: 5, c: getIdxCol('I') }
        },
        cellStyle: {
          font: FONT_DEFAULT
        }
      },
      {
        value: 'BAIRRO:',
        cell: { r: 5, c: getIdxCol('J') },
        range: {
          s: { r: 5, c: getIdxCol('J') },
          e: { r: 5, c: getIdxCol('K') }
        },
        cellStyle: { font: FONT_DEFAULT }
      },
      {
        cell: { r: 5, c: getIdxCol('L') },
        range: {
          s: { r: 5, c: getIdxCol('L') },
          e: { r: 5, c: getIdxCol('Q') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { right: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        value: 'ENDEREÇO:',
        cell: { r: 5, c: getIdxCol('U') },
        range: {
          s: { r: 5, c: getIdxCol('U') },
          e: { r: 5, c: getIdxCol('W') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { left: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        cell: { r: 5, c: getIdxCol('X') },
        range: {
          s: { r: 5, c: getIdxCol('X') },
          e: { r: 5, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { right: BORDER_SOLID_MED_BLACK }
        }
      }
    ]
  },
  {
    rowData: [
      {
        value: 'CIDADE:',
        cell: { r: 6, c: getIdxCol('A') },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { left: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        cell: { r: 6, c: getIdxCol('B') },
        range: {
          s: { r: 6, c: getIdxCol('B') },
          e: { r: 6, c: getIdxCol('I') }
        },
        cellStyle: {
          font: FONT_DEFAULT
        }
      },
      {
        value: 'ESTADO:',
        cell: { r: 6, c: getIdxCol('J') },
        range: {
          s: { r: 6, c: getIdxCol('J') },
          e: { r: 6, c: getIdxCol('K') }
        },
        cellStyle: { font: FONT_DEFAULT }
      },
      {
        cell: { r: 6, c: getIdxCol('L') },
        range: {
          s: { r: 6, c: getIdxCol('L') },
          e: { r: 6, c: getIdxCol('Q') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { right: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        value: 'CIDADE:',
        cell: { r: 6, c: getIdxCol('U') },
        range: {
          s: { r: 6, c: getIdxCol('U') },
          e: { r: 6, c: getIdxCol('W') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { left: BORDER_SOLID_MED_BLACK }
        }
      },
      {
        cell: { r: 6, c: getIdxCol('X') },
        range: {
          s: { r: 6, c: getIdxCol('X') },
          e: { r: 6, c: getIdxCol('AK') }
        },
        cellStyle: {
          font: FONT_DEFAULT
        }
      },
      {
        value: 'UF:',
        cell: { r: 6, c: getIdxCol('AL') },
        cellStyle: { font: FONT_DEFAULT }
      },
      {
        cell: { r: 6, c: getIdxCol('AM') },
        range: {
          s: { r: 6, c: getIdxCol('AM') },
          e: { r: 6, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: { right: BORDER_SOLID_MED_BLACK }
        }
      }
    ]
  },
  {
    rowData: [
      {
        value: 'CGC/CPF No.:',
        cell: { r: 7, c: getIdxCol('A') },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            left: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_MED_BLACK
          }
        }
      },
      {
        cell: { r: 7, c: getIdxCol('B') },
        range: {
          s: { r: 7, c: getIdxCol('B') },
          e: { r: 7, c: getIdxCol('I') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            bottom: BORDER_SOLID_MED_BLACK
          }
        }
      },
      {
        value: 'INSC.No.:',
        cell: { r: 7, c: getIdxCol('J') },
        range: {
          s: { r: 7, c: getIdxCol('J') },
          e: { r: 7, c: getIdxCol('K') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            bottom: BORDER_SOLID_MED_BLACK
          }
        }
      },
      {
        cell: { r: 7, c: getIdxCol('L') },
        range: {
          s: { r: 7, c: getIdxCol('L') },
          e: { r: 7, c: getIdxCol('Q') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            right: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_MED_BLACK
          }
        }
      },
      {
        value: 'CNPJ No.:',
        cell: { r: 7, c: getIdxCol('U') },
        range: {
          s: { r: 7, c: getIdxCol('U') },
          e: { r: 7, c: getIdxCol('W') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            left: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_MED_BLACK
          }
        }
      },
      {
        cell: { r: 7, c: getIdxCol('X') },
        range: {
          s: { r: 7, c: getIdxCol('X') },
          e: { r: 7, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            right: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_MED_BLACK
          }
        }
      }
    ]
  },
  { rowData: [] },

  {
    rowData: [
      {
        cell: { r: 9, c: getIdxCol('A') },
        value: 'EXECUTIVO GLOBO',
        range: {
          s: { r: 9, c: getIdxCol('A') },
          e: { r: 9, c: getIdxCol('C') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 9, c: getIdxCol('D') },
        value: 'SETOR',
        range: {
          s: { r: 9, c: getIdxCol('D') },
          e: { r: 9, c: getIdxCol('F') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 9, c: getIdxCol('G') },
        value: 'PRODUTO',
        range: {
          s: { r: 9, c: getIdxCol('G') },
          e: { r: 9, c: getIdxCol('Q') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 9, c: getIdxCol('U') },
        value: 'PERÍODO',
        range: {
          s: { r: 9, c: getIdxCol('U') },
          e: { r: 9, c: getIdxCol('AH') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 9, c: getIdxCol('AI') },
        value: 'CONDIÇÃO DE PAGAMENTO',
        range: {
          s: { r: 9, c: getIdxCol('AI') },
          e: { r: 9, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SLA_DASH_DOT_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      }
    ]
  },
  {
    rowData: [
      {
        cell: { r: 10, c: getIdxCol('A') },
        value: userName,
        range: {
          s: { r: 10, c: getIdxCol('A') },
          e: { r: 10, c: getIdxCol('C') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            bottom: BORDER_SOLID_MED_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 10, c: getIdxCol('D') },
        value: '',
        range: {
          s: { r: 10, c: getIdxCol('D') },
          e: { r: 10, c: getIdxCol('F') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            bottom: BORDER_SOLID_MED_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 10, c: getIdxCol('G') },
        value: '',
        range: {
          s: { r: 10, c: getIdxCol('G') },
          e: { r: 10, c: getIdxCol('Q') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            bottom: BORDER_SOLID_MED_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 10, c: getIdxCol('U') },
        value: formatBrLocale(
          new Date(year, month, 1),
          'MMMM yyyy'
        ).toUpperCase(),
        range: {
          s: { r: 10, c: getIdxCol('U') },
          e: { r: 10, c: getIdxCol('AH') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD_RED,
          border: {
            bottom: BORDER_SOLID_MED_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 10, c: getIdxCol('AI') },
        value: '15 DIAS FORA O MÊS',
        range: {
          s: { r: 10, c: getIdxCol('AI') },
          e: { r: 10, c: getIdxCol('AN') }
        },
        cellStyle: {
          font: FONT_DEFAULT,
          border: {
            bottom: BORDER_SOLID_MED_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      }
    ]
  },
  { rowData: [] },
  {
    rowData: [
      {
        cell: { r: 12, c: getIdxCol('A') },
        value: 'PROGRAMAS',
        range: {
          s: { r: 12, c: getIdxCol('A') },
          e: { r: 13, c: getIdxCol('C') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 12, c: getIdxCol('D') },
        value: 'TÍTULO DO FILME',
        range: {
          s: { r: 12, c: getIdxCol('D') },
          e: { r: 13, c: getIdxCol('D') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 12, c: getIdxCol('E') },
        value: 'DUR',
        range: {
          s: { r: 12, c: getIdxCol('E') },
          e: { r: 13, c: getIdxCol('E') }
        },
        cellStyle: {
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 12, c: getIdxCol('F') },
        value: 'DATA E NÚMERO DE INSERÇÕES PROGRAMADAS',
        range: {
          s: { r: 12, c: getIdxCol('F') },
          e: { r: 12, c: getIdxCol('AJ') }
        },
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 12, c: getIdxCol('AK') },
        value: 'N°',
        cellStyle: {
          fill: FILL_DEFAULT,
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 12, c: getIdxCol('AL') },
        value: 'PREÇO',
        range: {
          s: { r: 12, c: getIdxCol('AL') },
          e: { r: 12, c: getIdxCol('AM') }
        },
        cellStyle: {
          fill: FILL_DEFAULT,
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 12, c: getIdxCol('AN') },
        value: 'DESC',
        cellStyle: {
          fill: FILL_DEFAULT,
          font: FONT_DEFAULT_BOLD,
          border: {
            top: BORDER_SOLID_MED_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      }
    ]
  },
  {
    rowData: [
      ...generateConfigDaysOfWeek(13, year, month, 'string'),
      {
        cell: { r: 13, c: getIdxCol('AK') },
        value: 'INS',
        range: {
          s: { r: 13, c: getIdxCol('AK') },
          e: { r: 14, c: getIdxCol('AK') }
        },
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT_BOLD_BLUE,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 13, c: getIdxCol('AL') },
        value: 'UNITÁRIO',
        range: {
          s: { r: 13, c: getIdxCol('AL') },
          e: { r: 14, c: getIdxCol('AL') }
        },
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT_BOLD_BLUE,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 13, c: getIdxCol('AM') },
        value: 'TOTAL',
        range: {
          s: { r: 13, c: getIdxCol('AM') },
          e: { r: 14, c: getIdxCol('AM') }
        },
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT_BOLD_BLUE,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 13, c: getIdxCol('AN') },
        value: 'APLIC',
        range: {
          s: { r: 13, c: getIdxCol('AN') },
          e: { r: 14, c: getIdxCol('AN') }
        },
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT_BOLD_BLUE,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_MED_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      }
    ]
  },
  {
    rowData: [
      {
        cell: { r: 14, c: getIdxCol('A') },
        value: 'SIGLA',
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_MED_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 14, c: getIdxCol('B') },
        value: 'NOME',
        range: {
          s: { r: 14, c: getIdxCol('B') },
          e: { r: 14, c: getIdxCol('C') }
        },
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 14, c: getIdxCol('D') },
        value: '',
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      {
        cell: { r: 14, c: getIdxCol('E') },
        value: '',
        cellStyle: {
          fill: FILL_DEFAULT_GRAY_2,
          font: FONT_DEFAULT,
          border: {
            top: BORDER_SOLID_BLACK,
            bottom: BORDER_SOLID_BLACK,
            right: BORDER_SOLID_BLACK,
            left: BORDER_SOLID_BLACK
          },
          alignment: { horizontal: 'center' }
        }
      },
      ...generateConfigDaysOfWeek(14, year, month, 'number')
    ]
  }
];

const getCompletedModel = ({
  rowsDayOfMonth,
  dateBuy,
  ...rest
}: ExportMapMediaDataProps) => {
  const modelDefault = generateModelXlsx(rest);
  const lastDay = lastDayOfMonth(new Date(rest.year, rest.month, 1));

  if (rowsDayOfMonth.length < 20) {
    Array.from({ length: 21 - rowsDayOfMonth.length }).forEach(() => {
      rowsDayOfMonth.push({
        initials: '',
        name: '',
        price: 0,
        insertionsByDay: [],
        duration: 0,
        discount: 0
      });
    });
  }

  const modelOthers = rowsDayOfMonth.map((data, idx) =>
    fillOthersRowData(modelDefault.length + idx, data, lastDay)
  );

  const modelDaysOfWeek = rowsDayOfMonth.map((data, idx) =>
    fillRowDaysOfWeek(data.insertionsByDay, lastDay, modelDefault.length + idx)
  );

  const joinModels = modelOthers.reduce<MapCell[]>((acc, model, idx) => {
    acc.push({
      rowData: [...model.rowData, ...modelDaysOfWeek[idx].rowData],
      rowInfo: { ...model.rowInfo, ...modelDaysOfWeek[idx].rowInfo }
    });
    return acc;
  }, []);

  const rowIdxSummary = INIT_ROW_DAYS_OF_WEEK + joinModels.length - 1;

  const modelSummary = fillRowSummary(rowIdxSummary, lastDay);

  const modelFooter = generateModelXlsxFooter(rowIdxSummary, dateBuy);

  return [...modelDefault, ...joinModels, modelSummary, ...modelFooter];
};

const getModelXlsx = (props: ExportMapMediaDataProps) => {
  const modelXlsx = getCompletedModel(props);

  return applyAllConfig(modelXlsx, mapColsXlsx);
};

export default getModelXlsx;
