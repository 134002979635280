import {
  version as uuidVersion,
  validate as uuidValidate,
  v4 as uuidv4
} from 'uuid';

export const getUuid = () => uuidv4();

export const isUUID = (uuid: string) =>
  uuidValidate(uuid) && uuidVersion(uuid) === 4;
