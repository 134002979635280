import { Reducer } from 'redux';
import {
  CompetitiveConsolidatedTypes,
  ConsolidatedMetricsAction,
  ConsolidatedMetricsState
} from './Competitive.consolidated.types';

const targetInitialState: ConsolidatedMetricsState = {
  data: undefined,
  loading: false,
  error: false,
  progress: 0
};

const reducer: Reducer<ConsolidatedMetricsState, ConsolidatedMetricsAction> = (
  state = targetInitialState,
  action
): ConsolidatedMetricsState => {
  switch (action.type) {
    case CompetitiveConsolidatedTypes.CONSOLIDATED_METRICS_ERROR: {
      return {
        ...state,
        data: undefined,
        loading: false,
        error: true,
        progress: 0
      };
    }

    case CompetitiveConsolidatedTypes.CLEAR_CONSOLIDATED_METRICS: {
      return {
        data: undefined,
        loading: false,
        error: false,
        progress: 0
      };
    }
    case CompetitiveConsolidatedTypes.RUN_CONSOLIDATED_METRICS_REQUEST:
    case CompetitiveConsolidatedTypes.STATUS_CONSOLIDATED_METRICS_REQUEST: {
      return {
        ...state,
        data: undefined,
        loading: true,
        error: false,
        progress: 0
      };
    }
    case CompetitiveConsolidatedTypes.STATUS_CONSOLIDATED_METRICS_SUCCESS: {
      return {
        ...state,
        data: undefined,
        loading: true,
        error: false,
        progress: action.payload.progress
      };
    }
    case CompetitiveConsolidatedTypes.RESULT_CONSOLIDATED_METRICS_REQUEST: {
      return {
        ...state
      };
    }
    case CompetitiveConsolidatedTypes.RESULT_CONSOLIDATED_METRICS_SUCCESS: {
      return {
        ...state,
        data: action.payload.metrics?.data.row.consolidatedMetrics,
        loading: false,
        error: false,
        progress: action.payload.progress
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
