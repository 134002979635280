import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  AudienceFilterAvarageDatasAction,
  AudienceFiltersAvarageDatasType,
  AvarageDataResponse
} from './Audience.avarageDatas.types';
import getRadiographies from '../../../../../../services/Audience/PayTv/Audience.payTv.radiographies.service';
import { IAudienceByProgramsRadiographyRequestParams } from '../../../../../../components/Audience/AudiencePanels/PayTv/Programs/types';

const LoadAudienceFilterAvarageDatasSuccess = (
  response: AvarageDataResponse
) => {
  const payload: AudienceFilterAvarageDatasAction = {
    type: AudienceFiltersAvarageDatasType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageDatasError = (error: string) => {
  const payload: AudienceFilterAvarageDatasAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageDatasType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageDatasFilter = () => ({
  type: AudienceFiltersAvarageDatasType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageDatasData = () => ({
  type: AudienceFiltersAvarageDatasType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageDatasByProgramsRequest =
  ({
    targets,
    channels,
    programs,
    periods,
    table
  }: IAudienceByProgramsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageDatasFilter());
      const response = await getRadiographies({
        targets,
        channels,
        programs,
        periods,
        table: table ?? 'anual',
        fields: [
          'pc_audiencia',
          'pc_share_tle',
          'vl_audiencia_abs',
          'vl_alcance_abs',
          'hr_ats'
        ]
      });

      dispatch(LoadAudienceFilterAvarageDatasSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageDatasError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência'
      );
    }
  };
