import './CompetitiveFilter.scss';
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  SyntheticEvent
} from 'react';
import { FormHandles } from '@unform/core';
import capitalize from 'lodash/capitalize';
import uniqBy from 'lodash/uniqBy';
import { subDays, subMonths } from 'date-fns/esm';
import Stack from '@mui/material/Stack';
import { DateRange } from '@mui/lab/DateRangePicker';
import * as Yup from 'yup';
import { AutocompleteChangeReason } from '@mui/material/Autocomplete';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import FormContainer from '../../Form/FormContainer/FormContainer';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import DateRangePickerInput from '../../Form/DateRangePickerInput';
import { OptionsList } from '../../Form/types';
import ListChannels from '../ListChannels';
import { SectorRequest } from '../../../store/modules/Competitive/Sectors/Competitive.sectors.types';
import { ClientRequest } from '../../../store/modules/Competitive/Clients/Competitive.clients.types';
import LoadCompetitiveMarketsRequest from '../../../store/modules/Competitive/Markets/Competitive.markets.actions';
import LoadCompetitiveSectorsRequest from '../../../store/modules/Competitive/Sectors/Competitive.sectors.actions';
import LoadCompetitiveClientsRequest from '../../../store/modules/Competitive/Clients/Competitive.clients.actions';
import LoadCompetitiveChannelsRequest from '../../../store/modules/Competitive/Channels/Competitive.channels.actions';
import LoadCompetitiveCategoriesRequest from '../../../store/modules/Competitive/Categories/Competitive.categories.actions';
import LoadCompetitiveBrandsRequest from '../../../store/modules/Competitive/Brands/Competitive.brands.actions';
import LoadCompetitiveCommercialRequest from '../../../store/modules/Competitive/CommercialTypes/Competitive.commercial.actions';
import LoadCompetitiveTargetsRequest from '../../../store/modules/Competitive/Targets/Competitive.targets.actions';
import {
  LoadRunConsolidatedMetricsRequest,
  ClearConsolidatedMetrics
} from '../../../store/modules/Competitive/ConsolidatedMetrics/Competitive.consolidated.actions';
import {
  LoadRunComparativeMetricsRequest,
  ClearComparativeMetrics
} from '../../../store/modules/Competitive/ComparativeMetrics/Competitive.comparative.actions';

import { ContainerField } from '../../ContainerWrapper';
import getDiscountByChannel from './DefaultDiscounts';
import AutocompleteInput from '../../Form/AutocompleteInput';
import { formatBrLocale } from '../../../helpers/Dates';
import { ListValue, ListChannelsChangeReason } from '../ListChannels/types';
import validateFields from './validations';
import {
  CompetitiveData,
  CompetitiveFormData,
  ValidCompetitiveFormData
} from '../../../store/modules/Competitive/Shared/Competitive.shared.types';
import ButtonApp from '../../ButtonApp';
import handleErrors, { YupError } from '../../../helpers/HandleErrors';
import {
  FIXED_VALUE,
  inputNames,
  autoCompleteClasses,
  listboxProps,
  chipProps,
  checkboxClasses,
  listboxSigleProps
} from './constants';
import { filterFixedOption, serializeFormData } from './utils';
import paths from '../../../routes/paths';
import { SaveFiltersTab } from '../../../store/modules/Competitive/Navigation/Competitive.navigation.actions';
import classesGTM from '../../../config/classesGTM';

type TypeIputsTemp = {
  [key: string]: OptionsList[] | undefined;
};

export interface CompetitiveFilter {
  name: string;
}

const CompetitiveFilter = () => {
  const formRef = useRef<FormHandles>(null);
  const { childrens } = paths.home.childrens.competitive;
  const { competitive, consolidatedMetrics, competitiveNav } = useAppSelector(
    (state) => state
  );

  const [market, setMarket] = useState<string | undefined>();
  const [sectors, setSectors] = useState<OptionsList[] | undefined>();
  const [clients, setClients] = useState<OptionsList[] | undefined>();
  const [channelMnemonics, setChannelMnemonics] = useState<
    string[] | undefined
  >();
  const [categories, setCategories] = useState<OptionsList[] | undefined>();
  const [brands, setBrands] = useState<OptionsList[] | undefined>();
  const [commercialTypes, setCommercialTypes] = useState<
    OptionsList[] | undefined
  >();

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const today = new Date();
  const maxDate: Date = subDays(today, 4);
  const minDate: Date = new Date(2021, 0, 1);
  const dispatch = useAppDispatch();

  const [inputValueSectors, setInputValueSectors] = useState<
    string | undefined
  >();
  const [inputValueClients, setInputValueClients] = useState<
    string | undefined
  >();
  const [inputValueBrands, setInputValueBrands] = useState<
    string | undefined
  >();
  const [inputValueCategories, setInputValueCategories] = useState<
    string | undefined
  >();
  const [inputValueCommercial, setInputValueCommercial] = useState<
    string | undefined
  >();

  const valuesTmp = useRef<TypeIputsTemp>({
    [inputNames.sectors]: undefined,
    [inputNames.clients]: undefined,
    [inputNames.categories]: undefined,
    [inputNames.brands]: undefined,
    [inputNames.commercialTypes]: undefined
  });

  /** EFFECTS */

  // Markets and Targets
  useEffect(() => {
    dispatch(LoadCompetitiveMarketsRequest());
    dispatch(LoadCompetitiveTargetsRequest());
  }, [dispatch]);

  // Sectors
  useEffect(() => {
    if (market && dateTo && dateFrom) {
      const request: SectorRequest = {
        marketId: market,
        to: formatBrLocale(dateTo, 'yyyy-MM-dd'),
        from: formatBrLocale(dateFrom, 'yyyy-MM-dd'),
        limit: 10,
        offset: 0,
        search: inputValueSectors
      };

      dispatch(LoadCompetitiveSectorsRequest(request));
    }
  }, [dispatch, market, dateTo, dateFrom, inputValueSectors]);

  // Clients
  useEffect(() => {
    if (market && dateTo && dateFrom) {
      const request: ClientRequest = {
        marketId: market,
        to: formatBrLocale(dateTo, 'yyyy-MM-dd'),
        from: formatBrLocale(dateFrom, 'yyyy-MM-dd'),
        limit: 10,
        offset: 0,
        sectors: sectors?.map((s) => s.value),
        search: inputValueClients
      };

      dispatch(LoadCompetitiveClientsRequest(request));
    }
  }, [dispatch, market, dateTo, dateFrom, sectors, inputValueClients]);

  // Channels
  useEffect(() => {
    if (market && dateTo && dateFrom) {
      dispatch(
        LoadCompetitiveChannelsRequest({
          marketId: market,
          sectors: sectors?.map((s) => s.value),
          clients: clients?.map((c) => c.value),
          to: formatBrLocale(dateTo, 'yyyy-MM-dd'),
          from: formatBrLocale(dateFrom, 'yyyy-MM-dd')
        })
      );
    }
  }, [dispatch, market, dateTo, dateFrom, clients, sectors]);

  // Categories
  useEffect(() => {
    if (market && dateTo && dateFrom) {
      dispatch(
        LoadCompetitiveCategoriesRequest({
          marketId: market,
          to: formatBrLocale(dateTo, 'yyyy-MM-dd'),
          from: formatBrLocale(dateFrom, 'yyyy-MM-dd'),
          limit: 10,
          offset: 0,
          search: inputValueCategories,
          sectors: sectors?.map((s) => s.value),
          clients: clients?.map((c) => c.value),
          channelMnemonics
        })
      );
    }
  }, [
    dispatch,
    market,
    inputValueCategories,
    sectors,
    clients,
    channelMnemonics,
    dateTo,
    dateFrom
  ]);

  // Brands
  useEffect(() => {
    if (market && dateTo && dateFrom) {
      dispatch(
        LoadCompetitiveBrandsRequest({
          marketId: market,
          to: formatBrLocale(dateTo, 'yyyy-MM-dd'),
          from: formatBrLocale(dateFrom, 'yyyy-MM-dd'),
          limit: 10,
          offset: 0,
          search: inputValueBrands,
          categories: categories?.map((cat) => cat.value),
          sectors: sectors?.map((s) => s.value),
          clients: clients?.map((c) => c.value),
          channelMnemonics
        })
      );
    }
  }, [
    dispatch,
    market,
    inputValueBrands,
    categories,
    sectors,
    clients,
    channelMnemonics,
    dateTo,
    dateFrom
  ]);

  // Commercial Types
  useEffect(() => {
    if (market && dateTo && dateFrom) {
      dispatch(
        LoadCompetitiveCommercialRequest({
          marketId: market,
          to: formatBrLocale(dateTo, 'yyyy-MM-dd'),
          from: formatBrLocale(dateFrom, 'yyyy-MM-dd'),
          limit: 10,
          offset: 0,
          search: inputValueCommercial,
          categories: categories?.map((cat) => cat.value),
          brands: brands?.map((brand) => brand.value),
          sectors: sectors?.map((s) => s.value),
          clients: clients?.map((c) => c.value),
          channelMnemonics
        })
      );
    }
  }, [
    dispatch,
    market,
    inputValueCommercial,
    categories,
    sectors,
    clients,
    channelMnemonics,
    brands,
    dateTo,
    dateFrom
  ]);

  /** Memos */

  const marketsOptions = useMemo<OptionsList[]>(() => {
    if (competitive.markets.count) {
      return competitive.markets.data.map((m) => {
        return {
          label: `(${m.mnemonic}) ${m.name}`,
          value: m.id
        };
      });
    }
    return [];
  }, [competitive.markets.data, competitive.markets.count]);

  const sectorsOptions = useMemo<OptionsList[]>(() => {
    if (competitive.sectors.data.length) {
      const result = competitive.sectors.data
        .map((s) => ({
          label: capitalize(s.sector),
          value: s.sector
        }))
        .concat(sectors || [])
        .concat(valuesTmp.current[inputNames.sectors] || [])
        .sort((a, b) => a.value.localeCompare(b.value));

      if (!result.length) return [];

      return uniqBy([FIXED_VALUE, ...result], 'value');
    }

    return [];
  }, [competitive.sectors.data, sectors]);

  const clientsOptions = useMemo<OptionsList[]>(() => {
    if (competitive.clients.data.length) {
      const result = competitive.clients.data
        .map((c) => ({
          label: capitalize(c.client),
          value: c.client
        }))
        .concat(clients || [])
        .concat(valuesTmp.current[inputNames.clients] || [])
        .sort((a, b) => a.value.localeCompare(b.value));

      if (!result.length) return [];

      return uniqBy([FIXED_VALUE, ...result], 'value');
    }

    return [];
  }, [competitive.clients.data, clients]);

  const channelsOptions = useMemo(() => {
    if (competitive.channels.count) {
      return competitive.channels.data.map((ch, idx) => {
        const discount = getDiscountByChannel(ch.channel);

        return {
          id: idx,
          channelName: ch.channel,
          channelMnemonic: ch.mnemonic,
          discount,
          selected: true
        };
      });
    }
    return [];
  }, [competitive.channels.count, competitive.channels.data]);

  const targetsOptions = useMemo(() => {
    if (!market) return [];

    const result = competitive.targets.data.map((t) => ({
      label: t.name,
      value: t.id
    }));

    return result;
  }, [market, competitive.targets.data]);

  const categoriesOptions = useMemo(() => {
    if (competitive.categories.data.length) {
      const categoriesData = competitive.categories.data
        .map((c) => ({
          label: capitalize(c.category),
          value: c.category
        }))
        .concat(categories || [])
        .concat(valuesTmp.current[inputNames.categories] || [])
        .sort((a, b) => a.value.localeCompare(b.value));

      if (!categoriesData.length) return [];

      return uniqBy([FIXED_VALUE, ...categoriesData], 'value');
    }

    return [];
  }, [competitive.categories.data, categories]);

  const brandsOptions = useMemo(() => {
    if (competitive.brands.data.length) {
      const brandsData = competitive.brands.data
        .map((c) => ({
          label: capitalize(c.brand),
          value: c.brand
        }))
        .concat(brands || [])
        .concat(valuesTmp.current[inputNames.brands] || [])
        .sort((a, b) => a.value.localeCompare(b.value));

      if (!brandsData.length) return [];

      return uniqBy([FIXED_VALUE, ...brandsData], 'value');
    }

    return [];
  }, [competitive.brands.data, brands]);

  const commercialTypesOptions = useMemo(() => {
    if (competitive.commercialTypes.data.length) {
      const commercialTypesData = competitive.commercialTypes.data
        .map((c) => ({
          label: capitalize(c.commercialType),
          value: c.commercialType
        }))
        .concat(commercialTypes || [])
        .concat(valuesTmp.current[inputNames.commercialTypes] || [])
        .sort((a, b) => a.value.localeCompare(b.value));

      if (!commercialTypesData.length) return [];

      return uniqBy([FIXED_VALUE, ...commercialTypesData], 'value');
    }

    return [];
  }, [competitive.commercialTypes.data, commercialTypes]);

  const disabledInputs = useMemo(
    () => !market || !dateFrom || !dateTo || consolidatedMetrics.loading,
    [market, dateFrom, dateTo, consolidatedMetrics.loading]
  );

  /** * HANDLES ** */

  // FORM
  const clearInputs = (names: string[]) => {
    names.forEach((name) => formRef.current?.clearField(name));
  };

  /** * HANDLES ** */

  const handleMarketSelect = (option: OptionsList) => {
    clearInputs([
      inputNames.period,
      inputNames.sectors,
      inputNames.clients,
      inputNames.channels,
      inputNames.categories,
      inputNames.brands,
      inputNames.commercialTypes
    ]);

    setMarket(option?.value || undefined);
  };

  const handlePeriodChange = (newDate: DateRange<Date>) => {
    setDateFrom(newDate[0]);
    setDateTo(newDate[1]);

    if (newDate[0] && newDate[1]) {
      clearInputs([
        inputNames.clients,
        inputNames.channels,
        inputNames.categories,
        inputNames.brands,
        inputNames.commercialTypes
      ]);
    }
  };

  const handleAutocompleteChange =
    (
      setState: React.Dispatch<React.SetStateAction<OptionsList[] | undefined>>,
      inputName: string,
      clearInputNames: string[]
    ) =>
    (values: OptionsList[] | undefined, reason: AutocompleteChangeReason) => {
      const selectedValues = filterFixedOption(values);

      if (
        ['selectOption', 'removeOption'].includes(reason) &&
        selectedValues.length
      ) {
        valuesTmp.current = {
          ...valuesTmp.current,
          [inputName]: selectedValues
        };

        return;
      }

      setState(selectedValues);

      valuesTmp.current = {
        ...valuesTmp.current,
        [inputName]: []
      };

      clearInputs(clearInputNames);
    };

  const handleChannelsChange = (
    listChannels: ListValue[],
    reason: ListChannelsChangeReason
  ) => {
    if (reason !== 'discountChange') {
      setChannelMnemonics(
        listChannels.filter((c) => c.selected).map((c) => c.channelMnemonic)
      );
    }

    if (reason === 'toggleOff' || reason === 'clear') {
      clearInputs([
        inputNames.categories,
        inputNames.brands,
        inputNames.commercialTypes
      ]);
    }
  };

  const handleAutocompleteBlur =
    (
      inputName: string,
      setState: React.Dispatch<React.SetStateAction<OptionsList[] | undefined>>
    ) =>
    () => {
      setState((oldState) => {
        const newState = valuesTmp.current[inputName];
        if (!oldState?.length && !newState?.length) {
          return oldState;
        }
        return newState;
      });
    };

  const handleInputChange =
    (setState: React.Dispatch<React.SetStateAction<string | undefined>>) =>
    (event: SyntheticEvent<Element, Event>, value: string) => {
      if (market && dateTo && dateFrom) {
        setState(value);
      }
    };

  const handleSendClick = () => {
    formRef.current?.submitForm();
  };

  const compareFilters = (
    objValue: CompetitiveData | undefined,
    othValue: CompetitiveData | undefined
  ) => {
    if (!objValue || !othValue) return true;

    if (objValue?.marketId !== othValue?.marketId) return false;
    if (objValue?.from !== othValue?.from) return false;
    if (objValue?.to !== othValue?.to) return false;
    if (objValue?.targetId !== othValue?.targetId) return false;
    if (!isEqual(objValue?.sectors.sort(), othValue?.sectors.sort())) {
      return false;
    }
    if (!isEqual(objValue?.clients.sort(), othValue?.clients.sort())) {
      return false;
    }
    if (
      !isEqual(
        objValue?.channels.map((c) => `${c.mnemonic}:${c.discount}`).sort(),
        othValue?.channels.map((c) => `${c.mnemonic}:${c.discount}`).sort()
      )
    ) {
      return false;
    }
    if (!isEqual(objValue?.categories.sort(), othValue?.categories.sort())) {
      return false;
    }
    if (!isEqual(objValue?.brands.sort(), othValue?.brands.sort())) {
      return false;
    }
    if (
      !isEqual(
        objValue?.commercialTypes.sort(),
        othValue?.commercialTypes.sort()
      )
    ) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (formData: CompetitiveFormData) => {
    try {
      formRef?.current?.setErrors({});

      await validateFields(formData);

      const data = serializeFormData(formData as ValidCompetitiveFormData);

      dispatch(SaveFiltersTab(data));

      if (competitiveNav.tab === childrens.consolidated.tab) {
        dispatch(ClearConsolidatedMetrics());
        dispatch(LoadRunConsolidatedMetricsRequest(data));

        if (!isEqualWith(data, competitiveNav.filters, compareFilters)) {
          dispatch(ClearComparativeMetrics());
        }
      } else if (competitiveNav.tab === childrens.comparative.tab) {
        dispatch(ClearComparativeMetrics());
        dispatch(LoadRunComparativeMetricsRequest(data));

        if (!isEqualWith(data, competitiveNav.filters, compareFilters)) {
          dispatch(ClearConsolidatedMetrics());
        }
      } else {
        console.error('Tab not found');
      }
    } catch (e) {
      let error: YupError | undefined;
      if (e instanceof Yup.ValidationError) {
        error = handleErrors(e);

        if (error['period[0]']) {
          error.period = error['period[0]'];
        } else if (error['period[1]']) {
          error.period = error['period[1]'];
        }
        formRef?.current?.setErrors(error);
      }
    }
  };

  return (
    <FormContainer
      data-testid="form"
      error
      formRef={formRef}
      fullWidth
      onSubmit={handleSubmit}
      sx={{ maxWidth: 250 }}
    >
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          classes={autoCompleteClasses}
          data-testid="markets"
          disabled={consolidatedMetrics.loading}
          disableValidateValInOpts
          label="Praça"
          ListboxProps={listboxSigleProps}
          loading={competitive.markets.loading}
          name={inputNames.market}
          onChange={handleMarketSelect}
          options={marketsOptions}
          required
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <DateRangePickerInput
          defaultCalendarMonth={subMonths(today, 1)}
          disabled={!market || consolidatedMetrics.loading}
          endText="Até"
          inputClassName="competitive-filter__datepicker"
          label="Período"
          maxDate={maxDate}
          minDate={minDate}
          name={inputNames.period}
          onChange={handlePeriodChange}
          startText="De"
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(sectors)}
          classes={autoCompleteClasses}
          data-testid="sectors"
          disableCloseOnSelect
          disabled={disabledInputs}
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Setor"
          limitTags={5}
          ListboxProps={listboxProps}
          loading={competitive.sectors.loading}
          multiple
          name={inputNames.sectors}
          onBlur={handleAutocompleteBlur(inputNames.sectors, setSectors)}
          onChange={handleAutocompleteChange(setSectors, inputNames.sectors, [
            inputNames.clients,
            inputNames.channels,
            inputNames.categories,
            inputNames.brands,
            inputNames.commercialTypes
          ])}
          onInputChange={handleInputChange(setInputValueSectors)}
          options={sectorsOptions}
          placeholderInput="Pesquisar..."
          returnObject
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(clients)}
          classes={autoCompleteClasses}
          data-testid="clients"
          disableCloseOnSelect
          disabled={disabledInputs}
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Cliente"
          limitTags={5}
          ListboxProps={listboxProps}
          loading={competitive.clients.loading}
          multiple
          name={inputNames.clients}
          onBlur={handleAutocompleteBlur(inputNames.clients, setClients)}
          onChange={handleAutocompleteChange(setClients, inputNames.clients, [
            inputNames.channels,
            inputNames.categories,
            inputNames.brands,
            inputNames.commercialTypes
          ])}
          onInputChange={handleInputChange(setInputValueClients)}
          options={clientsOptions}
          placeholderInput="Pesquisar..."
          returnObject
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <ListChannels
          data={channelsOptions}
          disabled={disabledInputs}
          loading={competitive.channels.loading}
          name={inputNames.channels}
          onChange={handleChannelsChange}
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          classes={autoCompleteClasses}
          data-testid="targets"
          disabled={!market || consolidatedMetrics.loading}
          disableValidateValInOpts
          label="Target"
          ListboxProps={listboxSigleProps}
          loading={competitive.targets.loading}
          name={inputNames.target}
          options={targetsOptions}
          required
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(categories)}
          classes={autoCompleteClasses}
          disableCloseOnSelect
          disabled={disabledInputs}
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Categoria"
          limitTags={5}
          ListboxProps={listboxProps}
          loading={competitive.categories.loading}
          multiple
          name={inputNames.categories}
          onBlur={handleAutocompleteBlur(inputNames.categories, setCategories)}
          onChange={handleAutocompleteChange(
            setCategories,
            inputNames.categories,
            [inputNames.brands, inputNames.commercialTypes]
          )}
          onInputChange={handleInputChange(setInputValueCategories)}
          options={categoriesOptions}
          placeholderInput="Pesquisar..."
          returnObject
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(brands)}
          classes={autoCompleteClasses}
          disableCloseOnSelect
          disabled={disabledInputs}
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Marca"
          limitTags={5}
          ListboxProps={listboxProps}
          loading={competitive.brands.loading}
          multiple
          name={inputNames.brands}
          onBlur={handleAutocompleteBlur(inputNames.brands, setBrands)}
          onChange={handleAutocompleteChange(setBrands, inputNames.brands, [
            inputNames.commercialTypes
          ])}
          onInputChange={handleInputChange(setInputValueBrands)}
          options={brandsOptions}
          placeholderInput="Pesquisar..."
          returnObject
          dataClarityMask
        />
      </ContainerField>
      <ContainerField classes={{ wrapper: 'mb-6' }}>
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(commercialTypes)}
          classes={autoCompleteClasses}
          disableCloseOnSelect
          disabled={disabledInputs}
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Tipo Comercial"
          limitTags={5}
          ListboxProps={listboxProps}
          loading={competitive.commercialTypes.loading}
          multiple
          name={inputNames.commercialTypes}
          onBlur={handleAutocompleteBlur(
            inputNames.commercialTypes,
            setCommercialTypes
          )}
          onChange={handleAutocompleteChange(
            setCommercialTypes,
            inputNames.commercialTypes,
            []
          )}
          onInputChange={handleInputChange(setInputValueCommercial)}
          options={commercialTypesOptions}
          placeholderInput="Pesquisar..."
          returnObject
          dataClarityMask
        />
      </ContainerField>
      <Stack alignItems="center" direction="row" spacing={1}>
        <ButtonApp
          classes={{
            button:
              'competitive-filter__buttons competitive-filter__buttons--primary',
            loading: 'competitive-filter__buttons--loading',
            tagManager: classesGTM.competitive.filter
          }}
          loading={consolidatedMetrics.loading}
          onClick={handleSendClick}
          title="Filtrar"
        />
        <ButtonApp
          classes={{
            button:
              'competitive-filter__buttons competitive-filter__buttons--secondary',
            tagManager: classesGTM.competitive.clearFilter
          }}
          disabled={consolidatedMetrics.loading}
          onClick={() => formRef.current?.reset()}
          title="Limpar Filtros"
        />
      </Stack>
    </FormContainer>
  );
};

export default CompetitiveFilter;
