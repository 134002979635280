import { Reducer } from 'redux';
import {
  CompetitiveChannelsState,
  CompetitiveChannelAction,
  CompetitiveChannelsTypes
} from './Competitive.channels.types';

const targetInitialState: CompetitiveChannelsState = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const reducer: Reducer<CompetitiveChannelsState, CompetitiveChannelAction> = (
  state = targetInitialState,
  action
): CompetitiveChannelsState => {
  switch (action.type) {
    case CompetitiveChannelsTypes.LOAD_CHANNELS_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        loading: false,
        error: false
      };
    }
    case CompetitiveChannelsTypes.LOAD_CHANNELS_ERROR: {
      return {
        ...state,
        data: [],
        count: 0,
        loading: false,
        error: true
      };
    }
    case CompetitiveChannelsTypes.LOAD_CHANNELS_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
