import React from 'react';
import { StyledTabPanel } from '../StyledTabs';

interface PanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const SceneryPanel: React.FC<PanelProps> = ({ children, index, value }) => {
  return (
    <StyledTabPanel index={index} value={value}>
      {children}
    </StyledTabPanel>
  );
};

export default SceneryPanel;
