import { Reducer } from 'redux';
import {
  SecondaryAction,
  SecondariesTypes,
  SecondaryState
} from './Secondaries.types';

const initialData: SecondaryState = {
  data: [],
  count: 0,
  error: false,
  loading: false
};
const reducer: Reducer<SecondaryState, SecondaryAction> = (
  state = initialData,
  action
): SecondaryState => {
  switch (action.type) {
    case SecondariesTypes.LOAD_SECONDARIES_SUCCESS:
      if (action.payload.rows && action.payload.count) {
        return {
          data: action.payload.rows,
          count: action.payload.count,
          loading: false,
          error: false
        };
      }
      return state;

    case SecondariesTypes.LOAD_SECONDARIES_ERROR:
      return {
        ...state,
        error: true,
        loading: false
      };
    case SecondariesTypes.LOAD_SECONDARIES_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
