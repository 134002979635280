export enum CommitmentsTypes {
  LOAD_COMMITMENTS_REQUEST = '@commitments/LOAD_COMMITMENTS_REQUEST',
  LOAD_COMMITMENTS_SUCCESS = '@commitments/LOAD_COMMITMENTS_SUCCESS',
  LOAD_COMMITMENTS_ERROR = '@commitments/LOAD_COMMITMENTS_ERROR'
}

export interface Commitment {
  id: number;
  userId: number;
  email: string;
  agency: string;
  createdAt: Date;
  updatedAt: Date;
  message: string;
}

export interface CommitmentData extends Partial<Commitment> {
  agency: string;
  message: string;
}

export interface CommitmentsAction {
  type: CommitmentsTypes;
  payload: {
    rows?: Commitment[] | [];
    count?: number;
    message?: string;
  };
}

export interface CommitmentsResponse {
  data: {
    rows: Commitment[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface CommitmentsState {
  readonly data: Commitment[] | [];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
  readonly message: string;
}
