import { AllParams } from '../../../../services/shared/types';

export interface Sector {
  sector: string;
}
export interface CompetitiveFilterSectorsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Sector[];
  readonly count: number;
}

export type SectorRequest = AllParams;

export interface SectorResponse {
  data: {
    rows: Sector[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum CompetitiveFiltersSectorsType {
  REQUEST = '@competitive/sectors/load',
  SUCESS = '@competitive/sectors/success',
  ERROR = '@competitive/sectors/error'
}

export interface CompetitiveFilterSectorsAction {
  type: CompetitiveFiltersSectorsType;
  payload: Omit<SectorResponse, 'status'>;
}
