import { useMemo } from 'react';
import { MetricsByChannels } from '../../../store/modules/Competitive/ComparativeMetrics/Competitive.comparative.types';
import BarChart from '../Charts/BarChart';
import { BarChartData } from '../Charts/BarChart/types';
import Legends, { LegendProps } from '../Charts/Legends';
import MetricCard from '../MetricCard';
import MetricChart from '../MetricChart';

interface Props {
  rawData: MetricsByChannels[];
  rawLegends: string[];
  metric:
    | 'avgFrequency'
    | 'affinity'
    | 'absoluteCoverage'
    | 'percentageCoverage'
    | 'cpm'
    | 'impacts';
  dataComplement: string;
  header: string;
  formatter?: (value: string | number) => string;
}

const colors: string[] = [
  '#6A52FA',
  '#20B9FC',
  '#FF6D03',
  '#FC228C',
  '#167FFD',
  '#FC343A',
  '#FEE043',
  '#1EC370'
];

export default function BarChartsCard({
  rawData,
  rawLegends,
  metric,
  dataComplement,
  header,
  formatter = (value: string | number) => value.toString()
}: Props) {
  const legends = useMemo((): LegendProps[] => {
    return rawLegends.map((key, index) => ({
      key,
      label: key,
      color: colors[index]
    }));
  }, [rawLegends]);

  const data: BarChartData[] = rawData.map((d, index) => {
    return {
      id: d.channelName,
      label: `${formatter(d[metric])} ${dataComplement}`,
      value: d[metric],
      color: colors[index],
      targetId: d.targetId
    };
  });

  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height="425px"
    >
      <MetricChart
        classes={{ container: 'bar-chart__container' }}
        renderGraph={() => <BarChart data={data} />}
      />
      <Legends
        legends={legends}
        width="80%"
        GridItemProps={{ paddingTop: '20px !important', xl: 2 }}
      />
    </MetricCard>
  );
}

BarChartsCard.defaultProps = {
  formatter: undefined
};
