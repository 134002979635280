const months = [
  {
    ds_periodo: 'JAN',
    num_trimestre: 1,
    num_semestre: 1,
    num_mes: 1
  },
  {
    ds_periodo: 'FEV',
    num_trimestre: 1,
    num_semestre: 1,
    num_mes: 2
  },
  {
    ds_periodo: 'MAR',
    num_trimestre: 1,
    num_semestre: 1,
    num_mes: 3
  },
  {
    ds_periodo: 'ABR',
    num_trimestre: 2,
    num_semestre: 1,
    num_mes: 4
  },
  {
    ds_periodo: 'MAI',
    num_trimestre: 2,
    num_semestre: 1,
    num_mes: 5
  },
  {
    ds_periodo: 'JUN',
    num_trimestre: 2,
    num_semestre: 1,
    num_mes: 6
  },
  {
    ds_periodo: 'JUL',
    num_trimestre: 3,
    num_semestre: 2,
    num_mes: 7
  },
  {
    ds_periodo: 'AGO',
    num_trimestre: 3,
    num_semestre: 2,
    num_mes: 8
  },
  {
    ds_periodo: 'SET',
    num_trimestre: 3,
    num_semestre: 2,
    num_mes: 9
  },
  {
    ds_periodo: 'OUT',
    num_trimestre: 4,
    num_semestre: 2,
    num_mes: 10
  },
  {
    ds_periodo: 'NOV',
    num_trimestre: 4,
    num_semestre: 2,
    num_mes: 11
  },
  {
    ds_periodo: 'DEZ',
    num_trimestre: 4,
    num_semestre: 2,
    num_mes: 12
  }
];

export default {
  months
};
