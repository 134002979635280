import './InfoCard.scss';
import { formatterNumber } from '../../../helpers/Formatters';
import MetricCard from '../MetricCard';

export enum MetricTypesEnum {
  PERCENT = 'percent',
  CURRENCY = 'currency',
  FIXED = 'fixed',
  INTEGER = 'integer'
}

interface MetricInfoProps {
  dataClarityMask?: boolean;
  header: string;
  info: number;
  infoType: MetricTypesEnum;
  fractionDigits?: number;
}

export default function MetricInfo({
  dataClarityMask,
  header,
  info,
  infoType,
  fractionDigits
}: MetricInfoProps) {
  return (
    <MetricCard
      classes={{ content: 'd-flex align-center' }}
      header={header}
      dataClarityMask={dataClarityMask}
    >
      {(() => {
        switch (infoType) {
          case MetricTypesEnum.FIXED:
          case MetricTypesEnum.INTEGER:
            return formatterNumber(info, { fractionDigits });
          case MetricTypesEnum.CURRENCY:
            return (
              <div>
                <span className="metric-info__symbol">R$ </span>
                {formatterNumber(info, { fractionDigits })}
              </div>
            );
          case MetricTypesEnum.PERCENT:
            return (
              <div>
                {formatterNumber(info, { fractionDigits })}
                <span className="metric-info__symbol ml-1">%</span>
              </div>
            );

          default:
            return info;
        }
      })()}
    </MetricCard>
  );
}

MetricInfo.defaultProps = {
  dataClarityMask: false,
  fractionDigits: 1
};
