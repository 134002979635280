import { useEffect } from 'react';
import {
  IAudienceByChannelsPayTvRadiographyFilterProps,
  TypePeriod
} from '../../types';
import { OptionsList } from '../../../../../../Form/types';

interface Props
  extends Partial<IAudienceByChannelsPayTvRadiographyFilterProps> {
  inputsConfig: {
    channels: {
      name: string;
      defaultValue: string;
    };
    period: {
      name: string;
      defaultValue: string;
    };
  };
  clearInputs: (names: string[]) => void;
  channelsOptions: OptionsList[];
  periodsOptions: TypePeriod[];
}

const useAudiencePayTvChannelsRadiographyFilterAutoSelect = ({
  channel,
  period,
  setChannel,
  setPeriod,
  channelsOptions,
  periodsOptions,
  clearInputs,
  inputsConfig
}: Props) => {
  useEffect(() => {
    if (!channel && channelsOptions.length) {
      if (setChannel)
        setChannel(
          channelsOptions?.find(
            (option) => option.value === inputsConfig.channels.defaultValue
          )
        );
      clearInputs([inputsConfig.period.name]);
    }
  }, [
    channel,
    setChannel,
    clearInputs,
    inputsConfig.channels.name,
    inputsConfig.period.name,
    inputsConfig.channels.defaultValue,
    channelsOptions
  ]);

  useEffect(() => {
    if (!period && periodsOptions.length) {
      if (setPeriod)
        setPeriod(
          periodsOptions?.find(
            (option) => option.value === inputsConfig.period.defaultValue
          )
        );
    }
  }, [
    period,
    setPeriod,
    clearInputs,
    inputsConfig.period.name,
    inputsConfig.period.defaultValue,
    periodsOptions
  ]);
};

export default useAudiencePayTvChannelsRadiographyFilterAutoSelect;
