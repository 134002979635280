import { ApiResponse, TypeTV } from '../../../services/shared/Api.service';
import { ActionThunk } from '../ActionThunk.type';

export enum BaseAudienceTypes {
  LOAD_BASE_AUDIENCE_LIMITS_REQUEST = '@baseAudience/LOAD_BASE_AUDIENCE_LIMITS_REQUEST',
  LOAD_BASE_AUDIENCE_LIMITS_SUCCESS = '@baseAudience/LOAD_BASE_AUDIENCE_LIMITS_SUCCESS',
  LOAD_BASE_AUDIENCE_LIMITS_ERROR = '@baseAudience/LOAD_BASE_AUDIENCE_LIMITS_ERROR'
}

export interface BaseLimitsAudience {
  dateMax: Date | null;
  dateMin: Date | null;
  loading: boolean;
  error: boolean;
}

interface BaseAudiencePayload extends BaseLimitsAudience {
  typeTV: TypeTV.OPEN | TypeTV.CLOSED;
  message?: string;
}

export type BaseAudienceAction = ActionThunk<BaseAudiencePayload>;

export interface BaseAudienceState {
  readonly openedTv: Readonly<BaseLimitsAudience>;
  readonly closedTv: Readonly<BaseLimitsAudience>;
}

export type BaseAudienceResponse = ApiResponse<BaseLimitsAudience>;
