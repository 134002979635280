import React, { InputHTMLAttributes, useState } from 'react';
import { EditorProps } from 'react-data-grid';
import getValueProp from 'lodash/get';
import setValueProp from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

interface Props<TRow>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (
    row: TRow,
    oldRow: TRow,
    onRowChange: EditorProps<TRow>['onRowChange']
  ) => void | undefined;
}

function autoFocusAndSelect(input: HTMLInputElement | null) {
  input?.focus();
  input?.select();
}

export default function TextEditor<TRow, TSummaryRow>({
  row,
  column,
  onRowChange,
  onClose,
  onChange,
  ...rest
}: EditorProps<TRow, TSummaryRow> & Props<TRow>) {
  const [value, setValue] = useState(getValueProp(row, column.key) || 0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);

    setValue((oldValue: number) =>
      Number.isNaN(newValue) ? oldValue : newValue
    );
  };

  const handleClose = () => {
    if (value !== Number(getValueProp(row, column.key))) {
      const copyRow = cloneDeep(row);
      const newRow = setValueProp(copyRow as any, column.key, value);
      if (onChange) {
        onChange(newRow, row, onRowChange);
      } else {
        onRowChange(newRow, true);
      }
    }
    onClose(true);
  };

  return (
    <input
      {...rest}
      ref={autoFocusAndSelect}
      onBlur={handleClose}
      onChange={handleChange}
      onKeyDown={(e: any) => {
        (e?.key === 'Enter' || e?.key === 'Tab') && handleClose();
      }}
      value={value}
    />
  );
}

TextEditor.defaultProps = {
  onChange: undefined
};
