import { apiV1 } from '../../shared/Api.service';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AvarageAudienceByAgeResponse } from '../../../store/modules/AudienceModule/PayTv/byChannels/Radiography/AvarageAudienceByAges/Audience.avarageAudienceByAges.types';

const path = '/audience/pay-tv/data';

interface IProps extends IAudienceByChannelsRadiographyRequestParams {
  fields: string[];
  table: string;
}

export default async function getPayTvAvarageAudienceByAgeByChannels({
  targets,
  timeRange,
  weekDays,
  programmingGenders,
  channel,
  channelsNotIn,
  period,
  fields,
  table
}: IProps): Promise<AvarageAudienceByAgeResponse> {
  const { data } = await apiV1.get<AvarageAudienceByAgeResponse>(path, {
    params: {
      fields,
      table,
      targets,
      timeRanges: [timeRange] ?? ['Total dia (06h-30H)'],
      weekDays: weekDays ?? ['TODOS'],
      programmingGenders: programmingGenders ?? ['TODOS'],
      channels: [channel],
      periods: [period],
      programs: ['TODOS'],
      channelsNotIn
    }
  });
  return data;
}
