export interface TimeRange {
  name: string;
}
export interface AudienceFilterTimeRangesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: TimeRange[];
  readonly count: number;
}

export interface TimeRangeResponse {
  data: {
    rows: TimeRange[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersTimeRangesType {
  REQUEST = '@audience/payTv/timeRanges/load',
  SUCESS = '@audience/payTv/timeRanges/success',
  ERROR = '@audience/payTv/timeRanges/error'
}

export interface AudienceFilterTimeRangesAction {
  type: AudienceFiltersTimeRangesType;
  payload: Omit<TimeRangeResponse, 'status'>;
}
