import { useCallback, useMemo } from 'react';
import useAppSelector from '../../hooks/useAppSelector';
import { programsInitialState } from '../../store/modules/Programs/Programs.reducer';

export default function useRankingRedux() {
  const { programs, simulation } = useAppSelector((state) => state);

  const getProgramsByScenery = useCallback(
    (sceneryId: string) => {
      const curPrograms = programs.find(
        (program) => program.sceneryId === sceneryId
      );
      return curPrograms || programsInitialState[0];
    },
    [programs]
  );

  const currentPrograms = useMemo(
    () => getProgramsByScenery(simulation.currentSceneryId),
    [getProgramsByScenery, simulation.currentSceneryId]
  );

  return { getProgramsByScenery, currentPrograms };
}
