import { CompetitiveData } from '../Shared/Competitive.shared.types';

export enum CompetitiveNavTypes {
  COMPETITIVE_CHANGE_TAB = '@competitive/competitive_nav/change_tab',
  COMPETITIVE_SAVE_FILTERS_TAB = '@competitive/competitive_nav/save_filters_tab'
}

export interface CompetitiveNavState {
  readonly tab: number;
  readonly filters: CompetitiveData | undefined;
}
export interface CompetitiveNavAction {
  type: CompetitiveNavTypes;
  payload: {
    tab?: number;
    filters?: CompetitiveData;
  };
}
