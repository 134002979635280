import { toast } from 'react-toastify';
import {
  AudienceFilterTargetsAction,
  AudienceFiltersTargetsType,
  TargetResponse
} from './Audience.targets.types';
import getTargets from '../../../../../services/Audience/PayTv/Audience.payTv.targets.service';
import { AppThunk } from '../../../..';

const LoadAudienceFilterTargetsSuccess = (response: TargetResponse) => {
  const payload: AudienceFilterTargetsAction = {
    type: AudienceFiltersTargetsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterTargetsError = (error: string) => {
  const payload: AudienceFilterTargetsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersTargetsType.ERROR
  };

  return payload;
};

const LoadingAudienceTargetsFilter = () => ({
  type: AudienceFiltersTargetsType.REQUEST,
  payload: {}
});

const LoadAudienceFilterTargetsRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(LoadingAudienceTargetsFilter());
    const response = await getTargets();

    dispatch(LoadAudienceFilterTargetsSuccess(response));
  } catch (error) {
    dispatch(
      LoadAudienceFilterTargetsError(
        error instanceof Error ? error.message : ''
      )
    );
    toast.error('Ops, houve um problema ao tentar carregar os targets.');
  }
};

export default LoadAudienceFilterTargetsRequest;
