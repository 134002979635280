import { useCallback } from 'react';
import useAppSelector from '../../hooks/useAppSelector';

export default function useFilters() {
  const { filters, simulation } = useAppSelector((state) => state);

  const getFiltersByScenery = useCallback(
    (sceneryId: string) => {
      const form = filters.forms.find(
        (filter) => filter.sceneryId === sceneryId
      );
      return form;
    },
    [filters.forms]
  );

  const getCurrentFilters = useCallback(() => {
    return getFiltersByScenery(simulation.currentSceneryId);
  }, [getFiltersByScenery, simulation.currentSceneryId]);

  const getCurrentForm = useCallback(() => {
    const form = getFiltersByScenery(simulation.currentSceneryId);

    return form?.ref?.current || null;
  }, [getFiltersByScenery, simulation.currentSceneryId]);

  const submitCurrentFiltersForm = () => {
    const form = getCurrentFilters();

    form?.ref?.current?.submitForm();
  };

  return {
    submitCurrentFiltersForm,
    getCurrentFilters,
    getCurrentForm,
    getFiltersByScenery
  };
}
