import './MetricCard.scss';
import Card, { CardProps } from '@mui/material/Card';
import React from 'react';
import MetricCardHeader from './MetricCardHeader';
import MetricCardContent from './MetricCardContent';

export interface MetricCardProps extends CardProps {
  dataClarityMask?: boolean;
  children: React.ReactNode | React.ReactNode[];
  header?: React.ReactNode | string;
  width?: number | string;
  height?: number | string;
  classes?: {
    header?: string;
    content?: string;
  };
}

export default function MetricCard({
  children,
  width = '100%',
  height = 97,
  header,
  classes,
  dataClarityMask,
  ...rest
}: MetricCardProps) {
  return (
    <Card
      {...rest}
      className={`metric-card ${rest.className || ''}`.trim()}
      sx={{ ...rest.sx, width, height }}
    >
      {header ? (
        <MetricCardHeader
          className={classes?.header}
          dataClarityMask={dataClarityMask}
        >
          {header}
        </MetricCardHeader>
      ) : null}
      <MetricCardContent
        className={classes?.content}
        dataClarityMask={dataClarityMask}
      >
        {children}
      </MetricCardContent>
    </Card>
  );
}

MetricCard.defaultProps = {
  classes: undefined,
  dataClarityMask: false,
  header: undefined,
  height: 97,
  width: '100%'
};
