import { apiV1 } from '../../shared/Api.service';
import { WeekDayResponse } from '../../../store/modules/AudienceModule/OpenTv/WeekDays/Audience.weekDays.types';

const path = '/audience/pay-tv/week-days';

export default async function getPayTvWeekDays(): Promise<WeekDayResponse> {
  const { data } = await apiV1.get<WeekDayResponse>(path);

  return data;
}
