import './DonutChart.scss';
import React from 'react';
import { animated } from '@react-spring/web';
import { ResponsivePie, CommonPieProps, PieTooltipProps } from '@nivo/pie';
import { DonutChartData } from './types';
import { formatterNumber } from '../../../../helpers/Formatters';

type ArcLabelsComponent = CommonPieProps<DonutChartData>['arcLabelsComponent'];
interface Props {
  data: DonutChartData[];
  dataComplement?: string;
  tooltipValueFormat?: (value: string | number) => string;
}

const customTooltip =
  (
    dataComplement?: string,
    formatter?: (value: string | number) => string
  ): React.FC<PieTooltipProps<DonutChartData>> =>
  ({ datum }) => {
    const text = `${
      formatter ? formatter(datum.data.value) : datum.data.value
    } ${dataComplement || ''}`;

    return (
      <div className="donut-chart__tooltip">
        <div className="donut-chart__tooltip__title">{datum.data.id}</div>
        <div className="donut-chart__tooltip__text">{text}</div>
      </div>
    );
  };

const customLabel =
  (): ArcLabelsComponent =>
  ({ datum, label, style }) => {
    if (datum.data.percentage < 3.5) return <span />;

    return (
      <animated.g
        style={{ pointerEvents: 'none' }}
        transform={style.transform as any}
      >
        <text
          className="donut-chart__chart-label"
          dominantBaseline="central"
          textAnchor="middle"
        >
          {`${label}%`}
        </text>
      </animated.g>
    );
  };

export default function DonutChart({
  data,
  tooltipValueFormat,
  dataComplement
}: Props) {
  return (
    <div className="donut-chart__container">
      <ResponsivePie
        activeOuterRadiusOffset={8}
        arcLabel={(e) =>
          formatterNumber(e.data.percentage, { fractionDigits: 0 })
        }
        arcLabelsComponent={customLabel()}
        borderColor={{
          from: 'color'
        }}
        borderWidth={2}
        colors={{ datum: 'data.color' }}
        data={data}
        enableArcLinkLabels={false}
        innerRadius={0.5}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        padAngle={0.7}
        tooltip={customTooltip(dataComplement, tooltipValueFormat)}
      />
    </div>
  );
}

DonutChart.defaultProps = {
  dataComplement: '',
  tooltipValueFormat: undefined
};
