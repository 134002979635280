import './Legends.scss';
import { Grid, GridProps } from '@mui/material';
import { LegendProps } from './types';

interface Props extends GridProps {
  legends: LegendProps[];
  GridItemProps?: GridProps;
}

export default function Legends({ legends, GridItemProps, ...rest }: Props) {
  return (
    <Grid container justifyContent="space-evenly" spacing={1} {...rest}>
      {legends.map((legend) => {
        return (
          <Grid
            key={legend.key}
            className="legends"
            item
            xs={3}
            minWidth={110}
            textAlign="center"
            {...GridItemProps}
          >
            <span
              className="legends__label-dot"
              style={{ backgroundColor: legend.color }}
            />
            <span className="legends__label-text">{legend.label}</span>
          </Grid>
        );
      })}
    </Grid>
  );
}

Legends.defaultProps = {
  GridItemProps: undefined
};
