export interface ByMonth {
  label: string;
  labelMes: string;
  value: number;
}

export interface Period {
  label: string;
  value: number;
}

export interface Absolute {
  byMonth: ByMonth[];
  period: Period[];
}
export interface AudienceAbsoluteState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Absolute[];
  readonly count: number;
}

export interface AbsoluteResponse {
  data: {
    rows: Absolute[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceAbsoluteType {
  REQUEST = '@audience/openTv/absolute/load',
  SUCESS = '@audience/openTv/absolute/success',
  ERROR = '@audience/openTv/absolute/error',
  CLEAR = '@audience/openTv/absolute/clear'
}

export interface AudienceAbsoluteAction {
  type: AudienceAbsoluteType;
  payload: Omit<AbsoluteResponse, 'status'>;
}
