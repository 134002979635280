import { apiV1, TypeTV } from '../shared/Api.service';
import { PriceByDate } from '../../store/modules/Programs/Programs.types';
import { Market } from '../../store/modules/Markets/Markets.types';
import { Channel } from '../../store/modules/Channels/Channels.types';

export type Price = {
  initials: string;
  pricesByDate: (Omit<PriceByDate, 'secondary'> & { secondary: number })[];
};

export interface PriceResponse {
  data: {
    rows: Price[];
    count: number;
  };
  status: number;
  msg: string;
}

export type PriceParams = {
  market: Omit<Market, 'name'>;
  mnemonico: string;
  name: string;
  dateCampaignFrom: Date | string;
  dateCampaignTo: Date | string;
  secondary?: number;
  dateBuy: Date | string;
  channel: Channel;
  typeTV: TypeTV.OPEN | TypeTV.CLOSED;
};

export type PriceRequest = {
  market: Omit<Market, 'name'>;
  mnemonico: string;
  name: string;
  secondary?: number;
  dates: {
    campaign: {
      to: Date | string;
      from: Date | string;
    };
    buy: Date | string;
  };
  channel: Channel;
  typeTV: TypeTV.OPEN | TypeTV.CLOSED;
};

const path = '/price';

export default async function getProgramPrice(
  params: PriceRequest
): Promise<PriceResponse> {
  const { market, secondary, dates, mnemonico, channel, typeTV, name } = params;

  const newParams: PriceParams = {
    market: {
      signal: market.signal,
      ibopeName: market.ibopeName,
      id: market.id,
      notebook: market.notebook,
      minDate: market.minDate,
      maxDate: market.maxDate
    },
    secondary,
    dateCampaignFrom: dates.campaign.from,
    dateCampaignTo: dates.campaign.to,
    mnemonico,
    name,
    dateBuy: dates.buy,
    channel,
    typeTV
  };

  const { data } = await apiV1.get<PriceResponse>(path, {
    params: newParams
  });
  return data;
}
