import { Reducer } from 'redux';
import {
  AudienceFilterAvarageAudienceByTimeRangesAction,
  AudienceFilterAvarageAudienceByTimeRangesState,
  AudienceFiltersAvarageAudienceByTimeRangesType
} from './Audience.avarageAudienceByTimeRanges.types';

const avarageAudienceByTimeRangeInitialState: AudienceFilterAvarageAudienceByTimeRangesState =
  {
    error: false,
    loading: false,
    data: [],
    count: 0
  };

const reducer: Reducer<
  AudienceFilterAvarageAudienceByTimeRangesState,
  AudienceFilterAvarageAudienceByTimeRangesAction
> = (
  state = avarageAudienceByTimeRangeInitialState,
  action
): AudienceFilterAvarageAudienceByTimeRangesState => {
  switch (action.type) {
    case AudienceFiltersAvarageAudienceByTimeRangesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageAudienceByTimeRangesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageAudienceByTimeRangesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageAudienceByTimeRangesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
