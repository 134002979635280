import { Reducer } from 'redux';
import {
  AudienceFilterTargetsAction,
  AudienceFilterTargetsState,
  AudienceFiltersTargetsType
} from './Audience.targets.types';

const targetInitialState: AudienceFilterTargetsState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<
  AudienceFilterTargetsState,
  AudienceFilterTargetsAction
> = (state = targetInitialState, action): AudienceFilterTargetsState => {
  switch (action.type) {
    case AudienceFiltersTargetsType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersTargetsType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: 0
      };
    }

    case AudienceFiltersTargetsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
