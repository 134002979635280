import { Stack } from '@mui/material';
import { useMemo } from 'react';
import {
  formatterCurrency,
  formatterNumber
} from '../../../helpers/Formatters';
import {
  MetricsByChannels,
  MetricsByPeriod
} from '../../../store/modules/Competitive/ConsolidatedMetrics/Competitive.consolidated.types';
import DonutChart from '../Charts/DonutChart';
import { DonutChartData } from '../Charts/DonutChart/types';
import Legends, { LegendProps } from '../Charts/Legends';
import MetricCard from '../MetricCard';
import { MetricCardProps } from '../MetricCard/MetricCard';
import MetricChart from '../MetricChart';

interface Props extends Partial<MetricCardProps> {
  getMetricId: (x: any, percentage: number) => string;
  getMetricLabel: (x: any) => string;
  rawData: MetricsByPeriod[] | MetricsByChannels[];
  rawLegends: string[];
  targetName: string;
  totalLiquidInvestment: number;
  totalInsertions: number;
  totalGrpOrTrp: number;
  direction?: 'row' | 'column';
}

const colors: string[] = [
  '#6A52FA',
  '#20B9FC',
  '#FF6D03',
  '#FC228C',
  '#167FFD',
  '#FC343A',
  '#FEE043',
  '#1EC370'
];

export default function DonutsChartsCard({
  getMetricId,
  getMetricLabel,
  rawData,
  rawLegends,
  targetName,
  totalLiquidInvestment,
  totalInsertions,
  totalGrpOrTrp,
  direction = 'row',
  ...rest
}: Props) {
  const insertionsData: DonutChartData[] = rawData.map((d, index) => {
    return {
      id: getMetricId(d, d.insertionsPercentage),
      label: getMetricLabel(d),
      value: d.insertions,
      color: colors[index],
      percentage: d.insertionsPercentage
    };
  });

  const investmentsData: DonutChartData[] = rawData.map((d, index) => {
    return {
      id: getMetricId(d, d.investmentPercentage),
      label: getMetricLabel(d),
      value: d.investment,
      color: colors[index],
      percentage: d.investmentPercentage
    };
  });

  const grpData: DonutChartData[] = rawData.map((d, index) => {
    return {
      id: getMetricId(d, d.grpOrTrpPercentage),
      label: getMetricLabel(d),
      value: d.grpOrTrp,
      color: colors[index],
      percentage: d.grpOrTrpPercentage
    };
  });

  const legends = useMemo((): LegendProps[] => {
    return rawLegends.map((key, index) => ({
      key,
      label: key,
      color: colors[index]
    }));
  }, [rawLegends]);

  const tooltipValueFormatInvestiment = (value: number | string) =>
    formatterCurrency(value, { fractionDigits: 1 });

  const tooltipValueFormatGrpOrTrp = (value: number | string) =>
    formatterNumber(value, { fractionDigits: 4 });

  return (
    <MetricCard
      classes={{ content: 'fill-height' }}
      className="pa-10"
      height={direction === 'row' ? '425px' : '1020px'}
      {...rest}
    >
      <Stack
        alignItems="center"
        direction={direction}
        justifyContent="space-between"
      >
        <MetricChart
          header="TOTAL DE"
          highlightHeader={`${formatterNumber(totalInsertions, {
            fractionDigits: 0
          })} inserções`}
          renderGraph={() => (
            <DonutChart data={insertionsData} dataComplement="de Inserções" />
          )}
        />
        <MetricChart
          header="INVESTIMENTO DE"
          highlightHeader={`R$ ${formatterNumber(totalLiquidInvestment, {
            fractionDigits: 1
          })}`}
          renderGraph={() => (
            <DonutChart
              data={investmentsData}
              dataComplement="de Investimento"
              tooltipValueFormat={tooltipValueFormatInvestiment}
            />
          )}
        />
        <MetricChart
          header={`GRP/TRP (${targetName})`}
          highlightHeader={formatterNumber(totalGrpOrTrp, {
            fractionDigits: 1
          })}
          renderGraph={() => (
            <DonutChart
              data={grpData}
              dataComplement="de GRP/TRP"
              tooltipValueFormat={tooltipValueFormatGrpOrTrp}
            />
          )}
        />
      </Stack>
      <div className="fill-width">
        <Legends
          legends={legends}
          GridItemProps={{
            lg: 2,
            paddingTop: '20px !important',
            minWidth: 112,
            maxWidth: '112px !important'
          }}
        />
      </div>
    </MetricCard>
  );
}

DonutsChartsCard.defaultProps = {
  direction: undefined
};
