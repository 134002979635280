import { Reducer } from 'redux';
import { ProgramsState, ProgramAction, ProgramsTypes } from './Programs.types';

export const programsInitialState: ProgramsState[] = [
  {
    data: [],
    loading: false,
    error: false,
    count: 0,
    message: '',
    hideDaysOfWeek: false,
    sceneryId: '',
    sortedColumns: []
  }
];

const reducer: Reducer<ProgramsState[], ProgramAction> = (
  state = programsInitialState,
  action
): ProgramsState[] => {
  switch (action.type) {
    case ProgramsTypes.RESET_RANKING: {
      return programsInitialState;
    }
    case ProgramsTypes.SET_HIDE_DAYS_OF_WEEK: {
      const programsNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNotCurrentScenery,
          { ...currentState, hideDaysOfWeek: !!action.payload.hideDaysOfWeek }
        ];
      }
      return state;
    }
    case ProgramsTypes.LOAD_PROGRAMS_REQUEST: {
      const programsNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState =
        state.find((s) => s.sceneryId === action.payload.sceneryId) ||
        programsInitialState[0];

      return [
        ...programsNotCurrentScenery,
        {
          ...currentState,
          loading: true,
          message: '',
          data: [],
          error: false,
          sceneryId: action.payload.sceneryId
        }
      ];
    }
    case ProgramsTypes.LOAD_PROGRAMS_SUCCESS: {
      const programsNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNotCurrentScenery,
          {
            ...currentState,
            data: action.payload.rows || [],
            count: action.payload.count || 0,
            error: false,
            loading: false,
            message: action.payload.message,
            sceneryId: action.payload.sceneryId
          }
        ];
      }
      return state;
    }
    case ProgramsTypes.LOAD_PROGRAMS_ERROR: {
      const programsNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNotCurrentScenery,
          {
            ...currentState,
            error: true,
            loading: false,
            message: action.payload.message,
            count: 0,
            data: [],
            sceneryId: action.payload.sceneryId
          }
        ];
      }
      return state;
    }
    case ProgramsTypes.CLEAR_PROGRAMS: {
      const programsNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNotCurrentScenery,
          {
            ...currentState,
            ...programsInitialState,
            sceneryId: action.payload.sceneryId
          }
        ];
      }
      return state;
    }
    case ProgramsTypes.REMOVE_PROGRAMS_SCENERY: {
      return state.filter((s) => s.sceneryId !== action.payload.sceneryId);
    }
    case ProgramsTypes.SET_PROGRAMS_SORTED_COLUMNS: {
      const programsNotCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState && action?.payload?.sortedColumns) {
        return [
          ...programsNotCurrentScenery,
          {
            ...currentState,
            sortedColumns: action.payload.sortedColumns,
            sceneryId: action.payload.sceneryId
          }
        ];
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
export default reducer;
