// SCSS
import '../Menu.scss';
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import MenuNavItem from './Item/MenuNavItem';
import { ItemProps, ItemClassesProps } from './Item/types';
import MenuUserItem, { MenuUserItemProps } from './Item/MenuUserItem';

export interface MenuNavProps {
  readonly itemsMenu: Readonly<ItemProps>[];
  readonly itemsMenuUser?: Readonly<MenuUserItemProps>[];
  classes?: {
    nav?: string;
    item?: ItemClassesProps;
  };
  onClick?(itemId: number): void;
}

const MenuNav: React.FC<MenuNavProps> = ({
  itemsMenu,
  classes,
  onClick,
  itemsMenuUser
}) => {
  const location = useLocation();

  const initialItem = useCallback(() => {
    const otherPaths = (item: ItemProps) => {
      if (item.childrensPath?.length) {
        return item.childrensPath.map(
          (path) => new RegExp(item.path.concat('/', path), 'gi')
        );
      }

      return [];
    };

    return (
      itemsMenu.find(
        (el) =>
          el.path === location.pathname ||
          otherPaths(el).some((p) => location.pathname.match(p))
      ) ||
      itemsMenuUser?.find((el) => el.path === location.pathname) ||
      itemsMenu.find((el) => el.selected)
    );
  }, [itemsMenu, itemsMenuUser, location]);

  const [itemSelected, setItemSelected] = useState(initialItem());

  const handleClick = (
    id: number,
    items: (ItemProps | MenuUserItemProps)[]
  ) => {
    const item = items.find((el: { itemId: number }) => el.itemId === id);
    if (item) {
      if (item.onClick) {
        item.onClick();
      }
      if (onClick) onClick(id);
      setItemSelected(item);
    }
  };

  const getItemWithSelection = (item: any) =>
    item.itemId === itemSelected?.itemId
      ? { ...item, selected: true }
      : { ...item, selected: false };

  useEffect(() => setItemSelected(initialItem()), [initialItem]);

  return (
    <>
      <nav className={classes?.nav || 'main-menu'} data-testid="main-menu">
        <ul>
          {itemsMenu
            .filter((item) => item.visibled)
            .map((item) => (
              <MenuNavItem
                key={item.itemId}
                classes={{ ...classes?.item, ...item.classes }}
                item={getItemWithSelection(item)}
                onClick={(id: number) => handleClick(id, itemsMenu)}
              />
            ))}
        </ul>
      </nav>
      {itemsMenuUser?.length ? (
        <div className="user-menu" data-testid="user-menu">
          {itemsMenuUser
            .filter((item) => item.visibled)
            .map((item) => (
              <MenuUserItem
                key={item.itemId}
                onClick={(id: number) => handleClick(id, itemsMenuUser)}
                {...getItemWithSelection(item)}
              />
            ))}
        </div>
      ) : null}
    </>
  );
};

MenuNav.defaultProps = {
  classes: undefined,
  itemsMenuUser: undefined,
  onClick: undefined
};

export default MenuNav;
