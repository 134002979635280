import { Reducer } from 'redux';
import {
  AudienceFilterAvarageAudienceByGendersAction,
  AudienceFilterAvarageAudienceByGendersState,
  AudienceFiltersAvarageAudienceByGendersType
} from './Audience.avarageAudienceByGenders.types';

const avarageAudienceByGenderInitialState: AudienceFilterAvarageAudienceByGendersState =
  {
    error: false,
    loading: false,
    data: [],
    count: 0
  };

const reducer: Reducer<
  AudienceFilterAvarageAudienceByGendersState,
  AudienceFilterAvarageAudienceByGendersAction
> = (
  state = avarageAudienceByGenderInitialState,
  action
): AudienceFilterAvarageAudienceByGendersState => {
  switch (action.type) {
    case AudienceFiltersAvarageAudienceByGendersType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageAudienceByGendersType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageAudienceByGendersType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageAudienceByGendersType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
