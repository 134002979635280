import { AppThunk } from '../..';
import { YupError } from '../../../helpers/HandleErrors';
import { Filter, FiltersData, FiltersTypes } from './Filters.types';

export const SetFiltersFormRef = (data: Pick<Filter, 'ref' | 'sceneryId'>) => ({
  type: FiltersTypes.SET_FILTERS_FORMREF,
  payload: {
    ref: data.ref,
    sceneryId: data.sceneryId
  }
});

export const SavePartialFormData = (
  sceneryId: string,
  partialData?: Partial<FiltersData>
) => ({
  type: FiltersTypes.SAVE_PARTIAL_FILTERS_FORM_DATA,
  payload: {
    sceneryId,
    partialData
  }
});

export const SaveFiltersFormData =
  (filterData: Pick<Filter, 'data' | 'sceneryId'>): AppThunk =>
  async (dispatch) => {
    dispatch({
      type: FiltersTypes.SAVE_FILTERS_FORM_DATA,
      payload: {
        data: filterData.data,
        sceneryId: filterData.sceneryId
      }
    });
  };

export const SaveFiltersFormDataError = (
  sceneryId: string,
  erros?: YupError
) => ({
  type: FiltersTypes.SAVE_FILTERS_FORM_DATA_ERROR,
  payload: { sceneryId, erros }
});

export const ClearFiltersFormData = (sceneryId: string) => ({
  type: FiltersTypes.CLEAR_FILTERS_FORM_DATA,
  payload: { sceneryId }
});

export const RemoveFiltersScenery = (sceneryId: string) => ({
  type: FiltersTypes.REMOVE_FILTERS_SCENERY,
  payload: { sceneryId }
});

export const RemoveFiltersFormError = (sceneryId: string) => ({
  type: FiltersTypes.REMOVE_FILTERS_FORM_ERROR,
  payload: { sceneryId }
});

export const ResetFilters = () => ({
  type: FiltersTypes.RESET_FILTERS
});
