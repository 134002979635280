import { isArray } from 'lodash';
import { apiV1 } from '../../shared/Api.service';

import { ProgrammingGenderResponse } from '../../../store/modules/AudienceModule/OpenTv/ProgrammingGenders/Audience.programmingGenders.types';
import { ICommonsFilter as ICommonsFilterChannel } from '../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import { IAudienceByProgramsRankingFilterParams as ICommonsFilterPrograms } from '../../../components/Audience/AudiencePanels/OpenTv/Programs/types';
import { IAudienceByProgramsRankingFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Programs/types';

const path = '/audience/pay-tv/programming-genders';
const programsPath = '/audience/pay-tv/data';

async function getPayTvProgrammingGenders({
  target,
  timeRange,
  weekDays,
  channels,
  plaza,
  channelsNotIn
}:
  | ICommonsFilterChannel
  | ICommonsFilterPrograms): Promise<ProgrammingGenderResponse> {
  const { data } = await apiV1.get<ProgrammingGenderResponse>(path, {
    params: {
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value ?? 'TODOS',
      channels: channels?.map((channel) => channel.value) ?? undefined,
      plaza: plaza?.value ?? undefined,
      channelsNotIn
    }
  });

  return data;
}

async function getPayTvProgrammingGendersByPrograms({
  channelGeneres,
  programmers,
  program,
  channels,
  target,
  timeRange,
  timeRanges,
  channelsNotIn
}: IAudienceByProgramsRankingFilterParams): Promise<ProgrammingGenderResponse> {
  const { data } = await apiV1.get<ProgrammingGenderResponse>(programsPath, {
    params: {
      fields: ['nm_genero'],
      table: 'anual',
      targets: target?.value ? [target?.value] : undefined,
      timeRanges: timeRange?.value
        ? [timeRange?.value]
        : timeRanges?.map((timeRangeOption) => timeRangeOption.value),
      weekDays: ['TODOS'],
      channelGenres: channelGeneres?.map((genere) => genere.value),
      channelCompanies: programmers?.map((prog) => prog.value),
      channels: channels?.map((channel) => channel.value),
      channelsNotIn,
      programs: isArray(program)
        ? program?.map((prog) => prog.value)
        : program?.value ?? ['TODOS']
    }
  });

  return data;
}

export { getPayTvProgrammingGenders, getPayTvProgrammingGendersByPrograms };
