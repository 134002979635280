import { Reducer } from 'redux';
import hasProp from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import { getUuid } from '../../../helpers/UUID';
import {
  SimulationState,
  SimulationAction,
  SimulationTypes
} from './Simulation.types';
import scenariosLocalStorage from '../../../components/Scenarios/Scenarios.LocalStorage';
import paths from '../../../routes/paths';
import simualtionLocalStorage from '../../../components/Simulation/Simulation.LocalStorage';

const simulationStore = simualtionLocalStorage();
const scenariosStore = scenariosLocalStorage();

export const simulationInitialState: SimulationState = {
  uuid: simulationStore.get()?.uuid || getUuid(),
  name: simulationStore.get()?.name || 'Nova Simulação',
  loading: false,
  error: false,
  finished: false,
  currentScenery: scenariosStore.currentScenery.get() || 0,
  currentSceneryId: scenariosStore.currentSceneryId.get() || '',
  scenarios:
    scenariosStore.scenarios.get()?.map((s, idx) => ({
      ...s,
      sceneryNumber: idx,
      lastPath: paths.home.childrens.simulation.childrens.filters
    })) || []
};

const reducer: Reducer<SimulationState, SimulationAction> = (
  state = simulationInitialState,
  action
): SimulationState => {
  switch (action.type) {
    case SimulationTypes.RESET_SIMULATION: {
      return simulationInitialState;
    }
    case SimulationTypes.NEW_SIMULATION: {
      if (!action?.payload?.name) return state;

      const sceneryId = getUuid();

      return {
        loading: false,
        error: false,
        finished: false,
        uuid: action.payload.uuid || getUuid(),
        name: action.payload.name,
        me_filial_agencia: action.payload.me_filial_agencia,
        nu_cgc_cpf_agencia: action.payload.nu_cgc_cpf_agencia,
        oi_agencia: action.payload.oi_agencia,
        oi_cliente: action.payload.oi_cliente,
        me_cliente: action.payload.me_cliente,
        cpf_cnpj_cliente: action.payload.cpf_cnpj_cliente,
        currentScenery: 0,
        currentSceneryId: sceneryId,
        scenarios: [
          {
            uuid: sceneryId,
            sceneryNumber: 0,
            name: 'Cenário 1',
            lastPath: paths.home.childrens.simulation.childrens.filters
          }
        ]
      };
    }
    case SimulationTypes.ADD_SCENERY_IN_SIMULATION: {
      const copyScenarios = cloneDeep(state.scenarios);
      const [lastScenary] = copyScenarios.sort(
        (a, b) => b.sceneryNumber - a.sceneryNumber
      );

      const lastScenaryNumber = hasProp(lastScenary, 'sceneryNumber')
        ? lastScenary.sceneryNumber + 1
        : 0;

      const generateName = hasProp(lastScenary, 'sceneryNumber')
        ? `Cenário ${Number(lastScenary.name.split(' ')[1]) + 1}`
        : 'Cenário 1';

      const nameScenery = action.payload.name || generateName;

      const newScenarios = [
        ...state.scenarios,
        {
          uuid: action.payload.uuid || getUuid(),
          sceneryNumber: lastScenaryNumber,
          name: nameScenery,
          lastPath: paths.home.childrens.simulation.childrens.filters
        }
      ].sort((a, b) => a.sceneryNumber - b.sceneryNumber);

      return {
        ...state,
        scenarios: newScenarios
      };
    }
    case SimulationTypes.REMOVE_SCENERY_IN_SIMULATION: {
      if (!action.payload.uuid) return state;

      const indexScenery = state.scenarios.findIndex(
        (scenery) => scenery.uuid === action.payload.uuid
      );

      if (indexScenery === -1) return state;

      const newScenarios = state.scenarios
        .filter((s) => s.uuid !== action.payload.uuid)
        .map((val, idx) => ({ ...val, sceneryNumber: idx }))
        .sort((a, b) => a.sceneryNumber - b.sceneryNumber);

      return {
        ...state,
        scenarios: newScenarios
      };
    }
    case SimulationTypes.SET_SIMULATION: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SimulationTypes.SET_SCENERY_ID_IN_SIMULATION: {
      if (!action.payload.currentSceneryId) return state;

      return {
        ...state,
        currentSceneryId: action.payload.currentSceneryId
      };
    }
    case SimulationTypes.SET_CURRENT_SCENERY_IN_SIMULATION: {
      return {
        ...state,
        currentScenery: action.payload.currentScenery || 0
      };
    }
    default:
      return state;
  }
};

export default reducer;
