import { Reducer } from 'redux';
import {
  AudienceFilterProgrammersAction,
  AudienceFilterProgrammersState,
  AudienceFiltersProgrammersType
} from './Audience.programmers.types';

const programmerInitialState: AudienceFilterProgrammersState = {
  error: false,
  loading: false,
  data: [],
  count: null,
  requests: 0
};

const reducer: Reducer<
  AudienceFilterProgrammersState,
  AudienceFilterProgrammersAction
> = (
  state = programmerInitialState,
  action
): AudienceFilterProgrammersState => {
  switch (action.type) {
    case AudienceFiltersProgrammersType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count,
        requests: state.requests + 1
      };
    }

    case AudienceFiltersProgrammersType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null,
        requests: state.requests + 1
      };
    }

    case AudienceFiltersProgrammersType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersProgrammersType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
