import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';

export default function useAppMediaQuery(
  queryOrBreakpoint: string | 'xs' | 'sm' | 'md' | 'lg' | 'xl',
  direction: 'up' | 'down' = 'up'
) {
  const theme = useTheme();
  const breakpoint = theme.breakpoints.keys.find(
    (b) => b === queryOrBreakpoint
  );

  return useMediaQuery(
    breakpoint ? theme.breakpoints[direction](breakpoint) : queryOrBreakpoint
  );
}
