import { ClientsResponse } from '../../store/modules/Clients/Clients.types';
import { apiV1 } from '../shared/Api.service';

const path = '/clients';

export default async function getClients(
  nuCgcCpf: string,
  search = null
): Promise<ClientsResponse> {
  const { data } = await apiV1.get<ClientsResponse>(`${path}/${nuCgcCpf}`, {
    params: search ? { corporateName: search } : {}
  });
  return data;
}
