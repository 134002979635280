// SCSS
import '../../Menu.scss';
import React, { useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { MenuNavItemProps, ItemClassesProps } from './types';
import { attributeMaskOrUnmask } from '../../../../helpers/Utils';
import AppSVG from '../../../SVG/AppSVG';

const MenuNavItem: React.FC<MenuNavItemProps> = ({
  item,
  classes,
  onClick
}) => {
  const {
    itemId,
    icon,
    title,
    selected,
    disabled = false,
    path,
    badge,
    visibled = true,
    dataMask = false,
    stateRoute = null
  } = item;
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;

    if (onClick) onClick(itemId);
    if (path && location.pathname !== path) {
      navigate(path, { state: stateRoute });
    }
  };

  const fullClasses: ItemClassesProps = useMemo(
    () => ({
      icon: 'main-menu__icon',
      button: 'main-menu__item__button',
      li: 'main-menu__item',
      selected: 'main-menu--selected',
      ...classes
    }),
    [classes]
  );

  const liClassNames = useMemo(() => {
    let classesStr = 'no-select';
    if (disabled) classesStr = ' menu--disabled no-pointer-events';
    if (classes?.tagManager) classesStr += ` ${classes?.tagManager}`;

    if (selected) classesStr += ` ${fullClasses?.li} ${fullClasses?.selected}`;
    else classesStr += ` ${fullClasses?.li}` || '';

    return classesStr.trim();
  }, [disabled, fullClasses, selected, classes]);

  return visibled ? (
    <li
      key={itemId}
      className={liClassNames}
      data-testid="main-menu__item"
      onClick={handleClick}
      {...attributeMaskOrUnmask(dataMask)}
    >
      <div
        className={`no-pointer-events ${fullClasses?.button} ${
          disabled ? 'menu__item--button-disabled' : ''
        }`}
        data-testid="main-menu__item--button"
      >
        {badge}
        <div
          className={`no-pointer-events ${fullClasses?.icon} ${
            disabled ? 'menu__item--icon-disabled' : ''
          }`}
          data-testid="main-menu__item--icon"
        >
          <AppSVG
            className="no-pointer-events"
            loader={<Skeleton height={24} width={24} />}
            src={icon}
          />
        </div>
        <p className="no-pointer-events">{title}</p>
      </div>
    </li>
  ) : null;
};

export default MenuNavItem;
