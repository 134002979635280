import { Reducer } from 'redux';
import {
  AudienceFilterAvarageAudienceByFinancialClassesAction,
  AudienceFilterAvarageAudienceByFinancialClassesState,
  AudienceFiltersAvarageAudienceByFinancialClassesType
} from './Audience.avarageAudienceByFinancialClasses.types';

const avarageAudienceByFinancialClasseInitialState: AudienceFilterAvarageAudienceByFinancialClassesState =
  {
    error: false,
    loading: false,
    data: [],
    count: 0
  };

const reducer: Reducer<
  AudienceFilterAvarageAudienceByFinancialClassesState,
  AudienceFilterAvarageAudienceByFinancialClassesAction
> = (
  state = avarageAudienceByFinancialClasseInitialState,
  action
): AudienceFilterAvarageAudienceByFinancialClassesState => {
  switch (action.type) {
    case AudienceFiltersAvarageAudienceByFinancialClassesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageAudienceByFinancialClassesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageAudienceByFinancialClassesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageAudienceByFinancialClassesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
