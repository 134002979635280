import { ClosedPeriodTypesEnum } from '../../store/modules/Filters/Filters.types';

export interface OptionsPeriodTypesProps {
  value: ClosedPeriodTypesEnum;
  label: string;
  numOfMonths: number;
}

const CLOSED_PERIOD_CONFIG = {
  [ClosedPeriodTypesEnum.MONTH]: { label: 'Mês', numOfMonths: 1 },
  [ClosedPeriodTypesEnum.SEMESTER]: { label: 'Semestre', numOfMonths: 6 },
  [ClosedPeriodTypesEnum.QUARTER]: { label: 'Trimestre', numOfMonths: 3 },
  [ClosedPeriodTypesEnum.CLOSED_YEAR]: { label: 'Ano fechado', numOfMonths: 12 }
};

export const OPTIONS_PERIOD_TYPES: OptionsPeriodTypesProps[] = [
  { value: ClosedPeriodTypesEnum.MONTH, ...CLOSED_PERIOD_CONFIG.month },
  { value: ClosedPeriodTypesEnum.QUARTER, ...CLOSED_PERIOD_CONFIG.quarter },
  { value: ClosedPeriodTypesEnum.SEMESTER, ...CLOSED_PERIOD_CONFIG.semester },
  {
    value: ClosedPeriodTypesEnum.CLOSED_YEAR,
    ...CLOSED_PERIOD_CONFIG.closedYear
  }
];

export const getOptionPeriodType = (value: ClosedPeriodTypesEnum) =>
  OPTIONS_PERIOD_TYPES.find((opt) => value === opt.value);

export default CLOSED_PERIOD_CONFIG;
