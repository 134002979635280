import { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../hooks';
import {
  IAudienceByProgramsPayTvRadiographyFilterProps,
  TypePeriod
} from '../../types';
import { LoadAudienceFilterPeriodsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';
import { OptionsList } from '../../../../../../Form/types';
import { LoadAudienceFilterProgramsRequest } from '../../../../../../../store/modules/AudienceModule/PayTv/Programs/Audience.programs.actions';

const useAudiencePayTvProgramsRadiographyFilterLoadData = ({
  channel,
  program,
  period,
  target
}: Partial<IAudienceByProgramsPayTvRadiographyFilterProps>) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);

  useEffect(() => {
    if (
      channel &&
      audience?.payTv?.channels?.count !== 0 &&
      audience?.payTv?.channels?.count !== null &&
      audience?.payTv?.channels?.loading === false &&
      target &&
      audience?.payTv?.programs?.count === null &&
      !audience?.payTv?.programs?.error &&
      !audience?.payTv?.programs?.loading
    ) {
      dispatch(
        LoadAudienceFilterProgramsRequest({
          target,
          channels: [channel]
        })
      );
    }
  }, [
    target,
    audience?.payTv?.channels?.count,
    audience?.payTv?.channels?.loading,
    audience?.payTv?.programs?.count,
    audience?.payTv?.programs?.error,
    audience?.payTv?.programs?.loading,
    channel,
    dispatch,
    period
  ]);

  useEffect(() => {
    if (
      program &&
      channel &&
      audience?.payTv?.programs?.count !== 0 &&
      audience?.payTv?.programs?.count !== null &&
      audience?.payTv?.programs?.loading === false &&
      target &&
      audience?.payTv?.periods?.count === null &&
      !audience?.payTv?.periods?.error &&
      !audience?.payTv?.periods?.loading
    ) {
      dispatch(
        LoadAudienceFilterPeriodsRequest({
          target,
          program,
          channels: [channel],
          period
        })
      );
    }
  }, [
    target,
    audience?.payTv?.programs?.count,
    audience?.payTv?.programs?.loading,
    audience?.payTv?.periods?.count,
    audience?.payTv?.periods?.error,
    audience?.payTv?.periods?.loading,
    channel,
    program,
    dispatch,
    period
  ]);

  const channelsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.channels.count) {
      return audience?.payTv?.channels.data
        .filter((channelOption) => channelOption.nm_emissora)
        .map((channelOption) => ({
          label: channelOption.nm_emissora,
          value: channelOption.nm_emissora
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label));
    }

    return [];
  }, [audience?.payTv?.channels.data, audience?.payTv?.channels.count]);

  const programsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.payTv?.programs.count) {
      return audience?.payTv?.programs.data
        .filter((programOption) => programOption.nm_programa)
        .map((programOption) => ({
          label: programOption.nm_programa,
          value: programOption.nm_programa
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    }

    return [];
  }, [audience?.payTv?.programs.data, audience?.payTv?.programs.count]);

  const periodsOptions = useMemo<TypePeriod[]>(() => {
    if (audience?.payTv?.periods.count) {
      return audience?.payTv?.periods.data
        .filter((timeRangeOption) => timeRangeOption.ds_periodo)
        .map((timeRangeOption) => ({
          label: timeRangeOption.ds_periodo,
          value: timeRangeOption.ds_periodo,
          tp_dado: timeRangeOption.tp_dado
        }))
        .sort((a, b) => {
          if (a?.value?.includes('SEM') && !b?.value?.includes('SEM')) {
            return 1;
          }

          if (!a?.value?.includes('SEM') && b?.value?.includes('SEM')) {
            return -1;
          }

          if (a?.value?.includes('TRI') && !b?.value?.includes('TRI')) {
            return 1;
          }

          if (!a?.value?.includes('TRI') && b?.value?.includes('TRI')) {
            return -1;
          }

          return a?.value?.localeCompare(b?.value);
        });
    }

    return [];
  }, [audience?.payTv?.periods.data, audience?.payTv?.periods.count]);

  return {
    periodsOptions,
    programsOptions,
    channelsOptions
  };
};

export default useAudiencePayTvProgramsRadiographyFilterLoadData;
