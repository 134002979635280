import { useMemo } from 'react';
import { useAppSelector } from '../../hooks';
import { TypeTV } from '../../services/shared/Api.service';

export default function useFiltersRedux(typeTV: TypeTV | undefined) {
  const { targets, markets, channels, baseAudience, simulation } =
    useAppSelector((state) => state);

  const currentBaseAudience = useMemo(
    () =>
      typeTV === TypeTV.OPEN ? baseAudience.openedTv : baseAudience.closedTv,
    [typeTV, baseAudience]
  );

  const currentTargets = useMemo(
    () => (typeTV === TypeTV.OPEN ? targets.openedTv : targets.closedTv),
    [typeTV, targets]
  );

  const currentMarkets = useMemo(
    () => (typeTV === TypeTV.OPEN ? markets.openedTv : markets.closedTv),
    [typeTV, markets]
  );

  const currentChannels = useMemo(
    () => (typeTV === TypeTV.OPEN ? channels.openedTv : channels.closedTv),
    [typeTV, channels]
  );

  const isLoadedInputs = useMemo(
    () =>
      !simulation.loading &&
      !currentBaseAudience.loading &&
      !currentBaseAudience.loading &&
      currentMarkets.count > 0 &&
      currentChannels.count > 0,
    [
      simulation.loading,
      currentMarkets.count,
      currentChannels.count,
      currentBaseAudience.loading
    ]
  );

  const disableLoadTargets = useMemo(
    () =>
      currentTargets.error ||
      currentTargets.loading ||
      currentTargets.count > 0,
    [currentTargets.error, currentTargets.loading, currentTargets.count]
  );

  const disableLoadChannels = useMemo(
    () =>
      currentChannels.error ||
      currentChannels.loading ||
      currentChannels.count > 0,
    [currentChannels.error, currentChannels.loading, currentChannels.count]
  );

  const disableLoadMarkets = useMemo(
    () =>
      currentMarkets.error ||
      currentMarkets.loading ||
      currentMarkets.count > 0,
    [currentMarkets.error, currentMarkets.loading, currentMarkets.count]
  );

  const disableLoadBaseAudience = useMemo(
    () => currentBaseAudience.error || currentBaseAudience.loading,
    [currentBaseAudience.error, currentBaseAudience.loading]
  );

  const disableLoadAllInputs = useMemo(
    () =>
      disableLoadTargets ||
      disableLoadChannels ||
      disableLoadMarkets ||
      disableLoadBaseAudience,
    [
      disableLoadTargets,
      disableLoadChannels,
      disableLoadMarkets,
      disableLoadBaseAudience
    ]
  );

  return {
    currentBaseAudience,
    currentTargets,
    currentMarkets,
    currentChannels,
    isLoadedInputs,
    disableLoadAllInputs
  };
}
