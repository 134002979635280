import { HTMLAttributes } from 'react';

export interface MetricChartProps extends HTMLAttributes<HTMLDivElement> {
  header: string;
  highlightHeader?: string;
}

export default function MetricChartHeader({
  header,
  highlightHeader,
  className = '',
  ...rest
}: MetricChartProps) {
  return (
    <>
      <div
        className={'metric-chart__title'.concat(' ', className).trim()}
        {...rest}
      >
        {header}
      </div>
      {highlightHeader ? (
        <div className="metric-chart__highlight">{highlightHeader}</div>
      ) : null}
    </>
  );
}

MetricChartHeader.defaultProps = {
  highlightHeader: ''
};
