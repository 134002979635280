import './InformationAlert.scss';
import IconWarning from '../../assets/images/warning-icon.svg';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import AppSVG from '../SVG/AppSVG';

interface Props {
  message: string;
  dataClarityMask?: boolean;
}

export default function InformationAlert({
  message,
  dataClarityMask = false
}: Props) {
  return (
    <div className="d-flex" {...attributeMaskOrUnmask(dataClarityMask)}>
      <div className="info-alert align-center">
        <AppSVG
          className="mr-0 info-alert__icon no-pointer-events"
          height={20}
          src={IconWarning}
          width={20}
        />
        <span className="info-alert__message mx-2">{message}</span>
      </div>
    </div>
  );
}

InformationAlert.defaultProps = {
  dataClarityMask: undefined
};
