import { toast } from 'react-toastify';
import {
  AudienceFilterYearsAction,
  AudienceFiltersYearsType,
  YearResponse
} from './Audience.years.types';
import getYears from '../../../../../services/Audience/PayTv/Audience.payTv.years.service';
import { AppThunk } from '../../../..';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const LoadAudienceFilterYearsSuccess = (response: YearResponse) => {
  const payload: AudienceFilterYearsAction = {
    type: AudienceFiltersYearsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterYearsError = (error: string) => {
  const payload: AudienceFilterYearsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersYearsType.ERROR
  };

  return payload;
};

const LoadingAudienceYearsFilter = () => ({
  type: AudienceFiltersYearsType.REQUEST,
  payload: {}
});

export const ClearAudienceYearsData = () => ({
  type: AudienceFiltersYearsType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterYearsRequest =
  (props: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceYearsFilter());
      const response = await getYears({ ...props });

      dispatch(LoadAudienceFilterYearsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterYearsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os anos disponíveis'
      );
    }
  };
