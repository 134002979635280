import { Reducer } from 'redux';
import { TypeTV } from '../../../services/shared/Api.service';
import { MarketsState, MarketsTypes, MarketAction } from './Markets.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const targetInitialState: MarketsState = {
  openedTv: { ...defaultData },
  closedTv: { ...defaultData }
};

const reducer: Reducer<MarketsState, MarketAction> = (
  state = targetInitialState,
  action
): MarketsState => {
  switch (action.type) {
    case MarketsTypes.LOAD_MARKETS_SUCCESS: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            data: action.payload.rows,
            count: action.payload.count,
            error: false,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          data: action.payload.rows,
          count: action.payload.count,
          error: false,
          loading: false
        }
      };
    }
    case MarketsTypes.LOAD_MARKETS_ERROR: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            data: [],
            error: true,
            loading: false
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          data: [],
          error: true,
          loading: false
        }
      };
    }
    case MarketsTypes.LOAD_MARKETS_REQUEST: {
      if (action.payload.typeTV === TypeTV.OPEN) {
        return {
          ...state,
          openedTv: {
            ...state.openedTv,
            error: false,
            loading: true
          }
        };
      }
      return {
        ...state,
        closedTv: {
          ...state.closedTv,
          error: false,
          loading: true
        }
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
