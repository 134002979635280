import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import AudienceByChannelsComparative from './Comparative/AudiencePayTvChannelsComparativePanel';
import AudiencebyChannelsContainer from './AudiencePayTvChannelsContainer';
import AudienceByChannelsRadiography from './Radiography/AudiencePayTvChannelsRadiographyPanel';
import { OptionsList } from '../../../../Form/types';

const AudiencebyPayTvChannels: React.FC = () => {
  const [target, setTarget] = React.useState<OptionsList | undefined>();
  const [timeRange, setTimeRange] = React.useState<OptionsList | undefined>();
  const [timeRanges, setTimeRanges] = React.useState<
    OptionsList[] | undefined
  >();
  const [weekDays, setWeekDays] = React.useState<OptionsList | undefined>();
  const [programmingGenders, setProgrammingGenders] = React.useState<
    OptionsList | undefined
  >();
  const [newFilter, setNewFilter] = React.useState<boolean>(false);

  const params = useParams() as {
    tab: 'channels';
    subtab: 'comparative' | 'radiography';
  };

  const subtab = useMemo(() => {
    if (params.tab === 'channels') {
      return params.subtab || 'comparative';
    }

    return params.subtab;
  }, [params.tab, params.subtab]);

  const globalProps = {
    target,
    timeRange,
    timeRanges,
    weekDays,
    programmingGenders,
    newFilter,
    setNewFilter
  };

  const additionalProps = {
    ...globalProps,
    setTarget,
    setTimeRange,
    setTimeRanges,
    setWeekDays,
    setProgrammingGenders
  };

  return (
    <AudiencebyChannelsContainer
      {...additionalProps}
      tab={params?.tab}
      subtab={params?.subtab}
    >
      {subtab === 'radiography' ? (
        <AudienceByChannelsRadiography {...additionalProps} />
      ) : (
        <AudienceByChannelsComparative {...additionalProps} />
      )}
    </AudiencebyChannelsContainer>
  );
};

export default AudiencebyPayTvChannels;
