import 'react-data-grid/lib/styles.css';
import './GridPagination.scss';
import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  memo,
  RefObject
} from 'react';
import DataGrid, {
  DataGridProps,
  SortColumn,
  Column,
  DataGridHandle
} from 'react-data-grid';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import InputAdornment from '@mui/material/InputAdornment';
import omitProps from 'lodash/omit';
import hasProp from 'lodash/has';
import Skeleton from '@mui/material/Skeleton';
import magnifier from '../../assets/images/magnifier.svg';
import Loading from '../Loading';
import { useAppMediaQuery } from '../../hooks';
import FileDownload from '../../assets/images/file_download.svg';
import {
  exportToXlsx,
  exportToXlsxWithMultipleSheets
} from '../../helpers/Export';
import { GridColumn } from './types';
import { attributeMaskOrUnmask, calcPage } from '../../helpers/Utils';
import { ButtonIcon } from '../ButtonApp';
import AppSVG from '../SVG/AppSVG';

interface Props extends DataGridProps<any, unknown, React.Key> {
  columns: Readonly<GridColumn<any>[]>;
  page?: number;
  rowsPerPage?: number;
  search?: string;
  xlsxSheetName?: string | string[];
  hasExportGridToXlxs?: boolean;
  exportXlsxFileName?: string;
  loading?: boolean;
  hasPagination?: boolean;
  hasSearch?: boolean;
  countData?: number;
  headerContainer?: React.ReactNode;
  gridRef?: RefObject<DataGridHandle>;
  rowsPerPageOptions?: Array<number | { label: string; value: number }>;
  classTagManagerDownXlxs?: string;
  getColumnsSheet?(name: string): readonly GridColumn<any>[];
  getRowsSheet?(name: string): readonly any[];
  onPageChange?(page: number): void;
  onRowsPerPageChange?(limit: number): void;
  onSortColumnsChange?(sortedColumn: SortColumn[]): void;
  onSearchChange?(search: string): void;
}

const GridPagination = ({
  hasPagination,
  hasSearch = true,
  page,
  rowsPerPage,
  search,
  countData = 0,
  onPageChange,
  onRowsPerPageChange,
  onSortColumnsChange,
  onSearchChange,
  className,
  loading,
  headerContainer,
  rowsPerPageOptions,
  columns,
  hasExportGridToXlxs = false,
  exportXlsxFileName = 'GridData',
  xlsxSheetName,
  getColumnsSheet,
  getRowsSheet,
  gridRef,
  classTagManagerDownXlxs = '',
  ...rest
}: Props) => {
  const [searchState, setSearchState] = useState(search);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [loadingExportXlsx, setLoadingExportXlsx] = useState<boolean>(false);

  const handlesPagination = useRef({
    onRowsPerPageChange,
    onPageChange,
    onSearchChange,
    onSortColumnsChange
  });

  const getPage = useMemo(
    () => calcPage(countData, page, rowsPerPage),
    [rowsPerPage, countData, page]
  );

  const getColumns = (): readonly Column<any>[] => {
    const newCols = columns
      .filter((col) => !hasProp(col, 'hideColumn') || !col.hideColumn)
      .map((col) => omitProps(col, ['hideColumn']));

    return newCols;
  };

  const handleChangePage = (e: any, newPage: number) => {
    if (onPageChange) onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (onPageChange) onPageChange(0);
    if (onRowsPerPageChange) onRowsPerPageChange(+event.target.value);
  };

  const handleSortColumnsChange = (sortedColumn: SortColumn[]) => {
    if (onPageChange) onPageChange(0);
    if (onSortColumnsChange) onSortColumnsChange(sortedColumn);
  };

  const handleExportXlsxClick = async () => {
    setLoadingExportXlsx(true);
    if (Array.isArray(xlsxSheetName)) {
      if (!getRowsSheet || !getColumnsSheet) {
        throw new Error(
          'Error: getRowsSheet and getColumnsSheet, required for multiple sheets.'
        );
      }

      const data = xlsxSheetName.map((name) => {
        const columnsSheet = getColumnsSheet(name);
        const rowsSheet = getRowsSheet(name);

        return { sheetName: name, columns: columnsSheet, rows: rowsSheet };
      });

      await exportToXlsxWithMultipleSheets(data, `${exportXlsxFileName}.xlsx`);
    } else {
      await exportToXlsx(
        columns,
        rest.rows,
        `${exportXlsxFileName}.xlsx`,
        xlsxSheetName
      );
    }
    setLoadingExportXlsx(false);
  };

  const setValueSearch = useCallback((val: string) => {
    setSearchLoading(false);

    if (handlesPagination.current.onSearchChange)
      handlesPagination.current.onSearchChange(val);
  }, []);

  const debounceSearch = useMemo(
    () => debounce(setValueSearch, 1300),
    [setValueSearch]
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchState(event.target.value);
    setSearchLoading(true);
    debounceSearch(event.target.value);
  };

  const getEndAdorment = useMemo(() => {
    if (!searchLoading)
      return (
        <InputAdornment position="end">
          <AppSVG
            loader={<Skeleton height={24} width={24} />}
            src={magnifier}
          />
        </InputAdornment>
      );
    return (
      <InputAdornment position="end">
        <CircularProgress size={15} />
      </InputAdornment>
    );
  }, [searchLoading]);

  const matches = useAppMediaQuery('sm', 'down');

  return (
    <div className="grid-pagination__wrapper">
      <div
        className={`grid-pagination__header mt-0 mb-2 ${
          matches ? 'flex-column' : ''
        }`}
      >
        {hasSearch ? (
          <TextField
            InputProps={{ endAdornment: getEndAdorment }}
            label="Pesquisar"
            name="searchGrid"
            onChange={handleSearchChange}
            placeholder=" "
            size="small"
            sx={{ minWidth: '250px' }}
            value={searchState}
            {...attributeMaskOrUnmask(true)}
          />
        ) : null}
        {headerContainer}
        {countData > 0 || hasExportGridToXlxs ? (
          <div
            className="d-flex justify-end align-end fill-width"
            {...attributeMaskOrUnmask(false)}
          >
            {countData > 0 && (
              <span className="text-right grid-header__num--rows">
                {countData}&nbsp;{countData > 1 ? 'encontrados' : 'encontrado'}
              </span>
            )}
            {hasExportGridToXlxs ? (
              <ButtonIcon
                className={`${classTagManagerDownXlxs} grid-header__icon--export ml-1 pb0 pt0`}
                icon={FileDownload}
                id="btn_export_to_xlsx"
                info="Exportar xlsx"
                loading={loadingExportXlsx}
                onClick={handleExportXlsxClick}
                size="small"
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <DataGrid
        ref={gridRef}
        className={`rdg-light ${className || ''} ${
          !rest.rows.length && 'overflow__hidden'
        }`}
        columns={getColumns()}
        headerRowHeight={40}
        onSortColumnsChange={handleSortColumnsChange}
        {...rest}
      />
      {hasPagination ? (
        <TablePagination
          classes={{
            selectLabel: matches ? 'd-none' : undefined,
            spacer: matches ? 'd-none' : undefined,
            actions: matches ? 'ml-2' : undefined,
            toolbar: matches
              ? 'grid-pagination__toolbar justify-center pl-0'
              : undefined
          }}
          component="div"
          count={countData}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={getPage}
          rowsPerPage={rowsPerPage || 10}
          rowsPerPageOptions={rowsPerPageOptions}
          showFirstButton
          showLastButton
          {...attributeMaskOrUnmask(false)}
        />
      ) : null}
      {loading ? (
        <Loading classes={{ container: 'grid-pagination__loading' }} />
      ) : null}
    </div>
  );
};

GridPagination.defaultProps = {
  classTagManagerDownXlxs: undefined,
  countData: 0,
  exportXlsxFileName: undefined,
  getColumnsSheet: undefined,
  getRowsSheet: undefined,
  gridRef: undefined,
  hasExportGridToXlxs: false,
  hasPagination: false,
  hasSearch: true,
  headerContainer: undefined,
  loading: false,
  onPageChange: undefined,
  onRowsPerPageChange: undefined,
  onSearchChange: undefined,
  onSortColumnsChange: undefined,
  page: 0,
  rowsPerPage: 10,
  rowsPerPageOptions: undefined,
  search: '',
  xlsxSheetName: undefined
};

export default memo(GridPagination);
