import React from 'react';
import { Grid } from '@mui/material';

import paths from '../../../../../routes/paths';
import SubMenuTabs from '../../../Tabs/OpenTv/SubMenuTabs';
import IconComparative from '../../../../../assets/images/channels-comparative-icon.svg';
import IconSummary from '../../../../../assets/images/summary.svg';

import { IAudienceByProgramsContainerProps } from './types';
import AudienceByProgramsFilter from './AudienceOpenTvRegularPlazasProgramsFilter';
import classesGTM from '../../../../../config/classesGTM';

const { childrens, path } = paths.home.childrens.audience;

interface IAudienceByProgramsContainerExtendedProps
  extends IAudienceByProgramsContainerProps {
  showTimeRange?: boolean;
}

const AudienceByProgramsContainer: React.FC<
  IAudienceByProgramsContainerExtendedProps
> = ({
  target,
  timeRange,
  newFilter,
  setNewFilter,
  setTarget,
  setTimeRange,
  showTimeRange = true,
  children
}) => {
  const additionalProps = {
    target,
    timeRange,
    newFilter,
    setNewFilter,
    setTarget,
    setTimeRange,
    showTimeRange
  };

  return (
    <>
      <SubMenuTabs
        items={[
          {
            icon: IconComparative,
            itemId: 1,
            title: 'Ranking de Programas',
            path: `${path}/${childrens.openTv.path}/${childrens.openTv.childrens.regularPlazas.path}/${childrens.openTv.childrens.regularPlazas.childrens.programs.path}/${childrens.openTv.childrens.regularPlazas.childrens.programs.childrens.ranking.path}`,
            visibled: true,
            classes: { tagManager: classesGTM.audience.openTV.programs.ranking }
          },
          {
            icon: IconSummary,
            itemId: 2,
            title: 'Radiografia no Período',
            path: `${path}/${childrens.openTv.path}/${childrens.openTv.childrens.regularPlazas.path}/${childrens.openTv.childrens.regularPlazas.childrens.programs.path}/${childrens.openTv.childrens.regularPlazas.childrens.programs.childrens.radiography.path}`,
            visibled: true,
            classes: {
              tagManager: classesGTM.audience.openTV.programs.radiography
            }
          }
        ]}
      />
      <Grid container height="100%" padding={5} paddingTop={0} spacing={0}>
        <Grid
          className="competitive__container"
          item
          width="100%"
          direction="row"
          border="none"
        >
          <AudienceByProgramsFilter {...additionalProps} />
        </Grid>
        <Grid container direction="column" item width="calc(100%)">
          <Grid height="calc(100% - 60px)" item width="100%" paddingTop={5}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

AudienceByProgramsContainer.defaultProps = {
  showTimeRange: true
};

export default AudienceByProgramsContainer;
