export interface AvarageAudienceByTimeRange {
  pc_audiencia: number;
  ds_faixa_horaria: string;
}
export interface AudienceFilterAvarageAudienceByTimeRangesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByTimeRange[];
  readonly count: number | null;
}

export interface AvarageAudienceByTimeRangeResponse {
  data: {
    rows: AvarageAudienceByTimeRange[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByTimeRangesType {
  REQUEST = '@audience/openTv/byChannels/avarageAudienceByTimeRanges/load',
  SUCESS = '@audience/openTv/byChannels/avarageAudienceByTimeRanges/success',
  ERROR = '@audience/openTv/byChannels/avarageAudienceByTimeRanges/error',
  CLEAR = '@audience/openTv/byChannels/avarageAudienceByTimeRanges/clear'
}

export interface AudienceFilterAvarageAudienceByTimeRangesAction {
  type: AudienceFiltersAvarageAudienceByTimeRangesType;
  payload: Omit<AvarageAudienceByTimeRangeResponse, 'status'>;
}
