import * as Yup from 'yup';
import { Roles, User } from '../../store/modules/Users/Users.types';

const schema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[A-Za-z0-9._%+-]+(@g.globo|@prestador.globo)$/,
      'E-mail deve ser @g.globo ou @prestador.globo'
    )
    .required('Digite um e-mail válido')
    .email('Digite um e-mail válido'),
  name: Yup.string()
    .min(3, 'Nome deve conter no mínimo 3 caracteres')
    .max(128, 'Nome deve conter no máximo 128 caracteres'),
  role: Yup.string().required('Tipo deve ser selecionado'),
  central: Yup.string().when('role', {
    is: (value: any) => value !== Roles.AGENCY,
    then: Yup.string().required('Central deve ser selecionada')
  }),
  area: Yup.string().when('role', {
    is: (value: any) => value !== Roles.AGENCY,
    then: Yup.string().required('Área deve ser selecionada')
  })
});

const modalUserValidateFields = (formData: User) => {
  return schema.validate(formData, {
    abortEarly: false
  });
};

export default modalUserValidateFields;
