import './DateRangePickerInput.scss';
import { useRef, useEffect, memo, useState, useCallback } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { useField } from '@unform/core';
import setDate from 'date-fns/set';
import DateRangePicker, {
  DateRange,
  DateRangePickerProps
} from '@mui/lab/DateRangePicker';
import DateRangePickerDay from '@mui/lab/DateRangePickerDay';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import brLocale from 'date-fns/locale/pt-BR';
import isDate from 'date-fns/isDate';
import isWithinInterval from 'date-fns/isWithinInterval';
import { Box } from '@mui/material';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import { stringToDate } from '../../../helpers/Dates';

interface Props
  extends Omit<DateRangePickerProps<Date>, 'renderInput' | 'value'> {
  name: string;
  inputClassName?: string;
  dataClarityMask?: boolean;
  onClearError?(name: string): void;
}

const configTime = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0
};

const DateRangePickerInput = ({
  name,
  onChange,
  maxDate,
  minDate,
  onClearError,
  dataClarityMask,
  inputClassName,
  ...rest
}: Props) => {
  const datePikerRef = useRef<HTMLInputElement>(null);
  const [stateValue, setStateValue] = useState<DateRange<Date>>([null, null]);
  const { fieldName, registerField, error, clearError } = useField(name);

  const isValidMaxMinInterval = useCallback(
    (value: Date) => {
      const valueTmp = setDate(value, configTime);
      if (minDate && !maxDate) {
        const minDateTmp = setDate(minDate, configTime);

        if (valueTmp < minDateTmp) {
          return false;
        }
      }
      if (maxDate && !minDate) {
        const maxDateTmp = setDate(maxDate, configTime);

        if (valueTmp > maxDateTmp) {
          return false;
        }
      }
      if (minDate && maxDate) {
        const minDateTmp = setDate(minDate, configTime);
        const maxDateTmp = setDate(maxDate, configTime);
        if (
          minDateTmp > maxDateTmp ||
          !isWithinInterval(valueTmp, { start: minDateTmp, end: maxDateTmp })
        ) {
          return false;
        }
      }

      return true;
    },
    [maxDate, minDate]
  );

  const validDate = (date: Date) =>
    !Number.isNaN(date.getTime()) &&
    isDate(date) &&
    isValidMaxMinInterval(date);

  const getFormatLength = () => {
    return 10; // format dd/mm/yyyy
  };

  const handleChange = (
    range: DateRange<Date>,
    keyboardInputValue: string | undefined
  ) => {
    const formatLength = getFormatLength();
    if (keyboardInputValue && keyboardInputValue.length < formatLength) return;

    const newRange: DateRange<Date> = [null, null];

    if (range[0] && validDate(range[0])) {
      newRange.push(range[0]);
    } else {
      newRange.push(null);
    }

    if (range[1] && validDate(range[1])) {
      newRange.push(range[1]);
    } else {
      newRange.push(null);
    }

    clearError();
    if (onChange) onChange(range);
    setStateValue(range);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePikerRef.current,
      getValue: () => {
        return stateValue;
      },
      setValue: (_, newValue: DateRange<Date>) => {
        clearError();
        let v1 = null;
        let v2 = null;

        if (newValue?.length) {
          v1 =
            typeof newValue[0] === 'string'
              ? stringToDate(newValue[0])
              : newValue[0];

          v2 =
            typeof newValue[1] === 'string'
              ? stringToDate(newValue[1])
              : newValue[1];
        }

        setStateValue([v1, v2]);
      },
      clearValue: () => {
        setStateValue([null, null]);
        if (onChange) onChange([null, null]);
      }
    });
  }, [fieldName, registerField, clearError, stateValue, onChange]);

  const handleClearError = () => {
    if (onClearError) onClearError(name);
  };

  const handleClose = () => {
    handleClearError();
  };

  const getValidClasses = useCallback(
    (className: string | undefined) => {
      const validClasses = [className, inputClassName].filter((f) => f);
      if (validClasses.length) {
        return validClasses.join(' ');
      }

      return undefined;
    },
    [inputClassName]
  );

  const handleClick = (e: any) => {
    e?.preventDefault();
    e?.stopPropagation();

    e.target?.focus();
  };

  return (
    <Stack {...attributeMaskOrUnmask(dataClarityMask)}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={brLocale}>
        <DateRangePicker
          calendars={2}
          defaultCalendarMonth={maxDate || minDate || new Date()}
          inputRef={datePikerRef}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleChange}
          onClose={handleClose}
          renderDay={(date, dateRangePickerDayProps) => (
            <DateRangePickerDay
              {...dateRangePickerDayProps}
              classes={{
                rangeIntervalDayHighlightStart:
                  'date-range-picker__calendar--highlight',
                rangeIntervalDayHighlightEnd:
                  'date-range-picker__calendar--highlight'
              }}
            />
          )}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                {...startProps}
                className={getValidClasses(startProps.className)}
                error={!!error}
                inputProps={{
                  ...startProps.inputProps,
                  onClick: handleClick
                }}
              />
              <Box sx={{ mx: 0.5 }} />
              <TextField
                {...endProps}
                className={getValidClasses(startProps.className)}
                error={!!error}
                inputProps={{
                  ...endProps.inputProps,
                  onClick: handleClick
                }}
              />
            </>
          )}
          value={stateValue}
          {...rest}
        />
      </LocalizationProvider>
      <FormHelperText>{error}</FormHelperText>
    </Stack>
  );
};

DateRangePickerInput.defaultProps = {
  dataClarityMask: undefined,
  inputClassName: undefined,
  onClearError: undefined
};

export default memo(DateRangePickerInput);
