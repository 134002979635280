import { toast } from 'react-toastify';
import {
  CompetitiveFilterSectorsAction,
  CompetitiveFiltersSectorsType,
  SectorRequest,
  SectorResponse
} from './Competitive.sectors.types';
import { getSectors } from '../../../../services/MediaSimulator/Competitive.service';
import { AppThunk } from '../../..';

const LoadCompetitiveFilterSectorsSuccess = (response: SectorResponse) => {
  const payload: CompetitiveFilterSectorsAction = {
    type: CompetitiveFiltersSectorsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadCompetitiveFilterSectorsError = (error: string) => {
  const payload: CompetitiveFilterSectorsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: CompetitiveFiltersSectorsType.ERROR
  };

  return payload;
};

const LoadingCompetitiveSectorsFilter = () => ({
  type: CompetitiveFiltersSectorsType.REQUEST,
  payload: {}
});

const LoadCompetitiveFilterSectorsRequest =
  (request: SectorRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveSectorsFilter());
      const response = await getSectors(request);
      dispatch(LoadCompetitiveFilterSectorsSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveFilterSectorsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar os setores.');
    }
  };

export default LoadCompetitiveFilterSectorsRequest;
