export enum SecondariesTypes {
  LOAD_SECONDARIES_REQUEST = '@secondaries/LOAD_SECONDARIES_REQUEST',
  LOAD_SECONDARIES_SUCCESS = '@secondaries/LOAD_SECONDARIES_SUCCESS',
  LOAD_SECONDARIES_ERROR = '@secondaries/LOAD_SECONDARIES_ERROR'
}

export interface SecondaryAction {
  type: SecondariesTypes;
  payload: {
    rows?: string[];
    count?: number;
    message?: string;
  };
}

export interface SecondaryState {
  readonly data: string[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface SecondaryResponse {
  data: {
    rows: string[];
    count: number;
  };
  status: number;
  msg: string;
}
