import { toast } from 'react-toastify';

import { AppThunk } from '../../../../..';
import {
  AudienceFilterAvarageAudienceByFinancialClassesAction,
  AudienceFiltersAvarageAudienceByFinancialClassesType,
  AvarageAudienceByFinancialClasseResponse
} from './Audience.avarageAudienceByFinancialClasses.types';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';
import getRadiographies from '../../../../../../services/Audience/OpenTv/RegularPlazas/Audience.openTv.regularPlazas.radiographies.service';

const LoadAudienceFilterAvarageAudienceByFinancialClassesSuccess = (
  response: AvarageAudienceByFinancialClasseResponse
) => {
  const payload: AudienceFilterAvarageAudienceByFinancialClassesAction = {
    type: AudienceFiltersAvarageAudienceByFinancialClassesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterAvarageAudienceByFinancialClassesError = (
  error: string
) => {
  const payload: AudienceFilterAvarageAudienceByFinancialClassesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersAvarageAudienceByFinancialClassesType.ERROR
  };

  return payload;
};

const LoadingAudienceAvarageAudienceByFinancialClassesFilter = () => ({
  type: AudienceFiltersAvarageAudienceByFinancialClassesType.REQUEST,
  payload: {}
});

export const ClearAudienceAvarageAudienceByFinancialClassesData = () => ({
  type: AudienceFiltersAvarageAudienceByFinancialClassesType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterAvarageAudienceByFinancialClassesRequest =
  ({
    targets,
    timeRanges,
    weekDays,
    programmingGenders,
    channels,
    plazas,
    periods,
    table
  }: IAudienceByChannelsRadiographyRequestParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceAvarageAudienceByFinancialClassesFilter());
      const response = await getRadiographies({
        targets,
        timeRanges,
        weekDays,
        programmingGenders,
        channels,
        plazas,
        periods,
        table: table ?? 'anual',
        fields: ['pc_adh', 'nm_target']
      });

      dispatch(
        LoadAudienceFilterAvarageAudienceByFinancialClassesSuccess(response)
      );
    } catch (error) {
      dispatch(
        LoadAudienceFilterAvarageAudienceByFinancialClassesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os dados de audiência por classe financeira'
      );
    }
  };
