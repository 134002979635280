import React, { useState, useLayoutEffect, useEffect } from 'react';
import './ModalShell.scss';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import { useAppMediaQuery } from '../../hooks';
import ModalShellAnimation from './ModalShellAnimation';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import ButtonApp from '../ButtonApp';

export interface ModalShellProps {
  children: React.ReactNode;
  title: string;
  open: boolean;
  disableActions?: boolean;
  disableBtnOk?: boolean;
  disableBtnCancel?: boolean;
  labelBtnOK?: string;
  disableBtnSubmit?: boolean;
  labelBtnSubmit?: string;
  labelBtnCancel?: string;
  disableEscapeKeyDown?: boolean;
  disableBackdropClick?: boolean;
  classTagManagerButtons?: {
    btnOk?: string;
    btnCancel?: string;
  };
  onClickCancel?(): void;
  onClickOK?(): void;
  onModalSubmit?(): any;
  showBtnOk?: boolean;
  showBtnCancel?: boolean;
  showBtnSubmit?: boolean;
}

const ModalShell: React.FC<ModalShellProps> = ({
  children,
  title,
  open = false,
  disableActions = false,
  labelBtnCancel = 'cancelar',
  labelBtnOK = 'ok',
  onClickCancel,
  onClickOK,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  disableBtnCancel = false,
  disableBtnOk = false,
  showBtnOk = true,
  showBtnCancel = true,
  classTagManagerButtons
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  useLayoutEffect(() => {
    if (openModal) {
      document.body.classList.add('overflow__hidden');
      return;
    }
    document.body.classList.remove('overflow__hidden');
  }, [openModal]);

  const handleCloseClick = () => {
    setOpenModal(false);
    if (onClickCancel) onClickCancel();
  };

  const handleClickOK = () => {
    if (onClickOK) onClickOK();
  };

  const handleOnClose = (
    event: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return;
    }
    handleCloseClick();
  };

  return (
    <Dialog
      aria-describedby="scroll-dialog-description"
      aria-labelledby="scroll-dialog-title"
      classes={{ paper: 'modal-container__wrapper' }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={useAppMediaQuery('sm', 'down')}
      fullWidth
      maxWidth={useAppMediaQuery('sm', 'down') ? 'sm' : 'xs'}
      onClose={handleOnClose}
      open={openModal}
      scroll="paper"
      TransitionComponent={ModalShellAnimation}
    >
      <DialogTitle
        className="modal-container__title"
        {...attributeMaskOrUnmask(false)}
      >
        {title}
      </DialogTitle>
      <DialogContent className="pt-2">{children}</DialogContent>
      {!disableActions && (
        <DialogActions>
          <div className="modal-footer py-5" {...attributeMaskOrUnmask(false)}>
            <ButtonApp
              classes={{
                button: `btn--zero btn--mini btn--gray ${
                  classTagManagerButtons?.btnCancel || ''
                }`
              }}
              disabled={disableBtnCancel}
              onClick={handleCloseClick}
              title={labelBtnCancel}
              visible={showBtnCancel}
            />
            <ButtonApp
              classes={{
                button: `btn--med btn--transparent ml-4 ${
                  classTagManagerButtons?.btnOk || ''
                }`
              }}
              disabled={disableBtnOk}
              onClick={handleClickOK}
              title={labelBtnOK}
              visible={showBtnOk}
            />
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
};

ModalShell.defaultProps = {
  classTagManagerButtons: undefined,
  disableActions: false,
  disableBackdropClick: false,
  disableBtnCancel: false,
  disableBtnOk: false,
  disableBtnSubmit: undefined,
  disableEscapeKeyDown: false,
  labelBtnCancel: undefined,
  labelBtnOK: undefined,
  labelBtnSubmit: undefined,
  onClickCancel: undefined,
  onClickOK: undefined,
  onModalSubmit: undefined,
  showBtnCancel: undefined,
  showBtnOk: undefined,
  showBtnSubmit: undefined
};

export default ModalShell;
