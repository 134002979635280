import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { IAudienceByProgramsRakingsRequestProps } from '../types';
import { LoadAudienceRankingsRequest } from '../../../../../../store/modules/AudienceModule/ProgramRanking/Audience.programRanking.actions';

const useAudienceOpenTvRegularPlazasProgramsRankingData = ({
  channels,
  plaza,
  period,
  target,
  timeRange,
  programmingGenders,
  order,
  limit,
  offset
}: IAudienceByProgramsRakingsRequestProps) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const targetRef = useRef(target);
  const timeRangeRef = useRef(timeRange);
  const programmingGendersRef = useRef(programmingGenders);

  useEffect(() => {
    targetRef.current = target;
    timeRangeRef.current = timeRange;
    programmingGendersRef.current = programmingGenders;
  }, [target, timeRange, programmingGenders]);

  useEffect(() => {
    if (
      plaza &&
      channels &&
      period &&
      targetRef?.current &&
      timeRangeRef?.current &&
      programmingGendersRef?.current
    ) {
      const request = {
        plaza,
        channels,
        period,
        target: targetRef.current,
        timeRange: timeRangeRef.current,
        programmingGenders: programmingGendersRef.current,
        order,
        limit,
        offset
      };
      dispatch(LoadAudienceRankingsRequest(request));
    }
  }, [
    plaza,
    channels,
    period,
    order,
    limit,
    offset,
    audience?.openTv?.targets?.data,
    audience?.openTv?.timeRanges?.data,
    audience?.openTv?.weekDays?.data,
    audience?.openTv?.programmingGenders?.data,
    dispatch
  ]);
};

export default useAudienceOpenTvRegularPlazasProgramsRankingData;
