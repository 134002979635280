import { toast } from 'react-toastify';
import {
  CompetitiveFilterClientsAction,
  CompetitiveFiltersClientsType,
  ClientRequest,
  ClientResponse
} from './Competitive.clients.types';
import { getClients } from '../../../../services/MediaSimulator/Competitive.service';
import { AppThunk } from '../../..';

const LoadCompetitiveFilterClientsSuccess = (response: ClientResponse) => {
  const payload: CompetitiveFilterClientsAction = {
    type: CompetitiveFiltersClientsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadCompetitiveFilterClientsError = (error: string) => {
  const payload: CompetitiveFilterClientsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: CompetitiveFiltersClientsType.ERROR
  };

  return payload;
};

const LoadingCompetitiveClientsFilter = () => ({
  type: CompetitiveFiltersClientsType.REQUEST,
  payload: {}
});

const LoadCompetitiveFilterClientsRequest =
  (request: ClientRequest): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveClientsFilter());
      const response = await getClients(request);
      dispatch(LoadCompetitiveFilterClientsSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveFilterClientsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar os clientes.');
    }
  };

export default LoadCompetitiveFilterClientsRequest;
