const channels = [
  { channel: 'globo', discount: 0 },
  { channel: 'rede tv!', discount: 0 },
  { channel: 'record tv', discount: 0 },
  { channel: 'sbt', discount: 0 },
  { channel: 'tv band', discount: 0 },
  { channel: 'tv cultura', discount: 0 },
  { channel: 'tv gazeta', discount: 0 },
  { channel: 'cnt', discount: 0 }
];

const getDiscountByChannel = (channel: string) =>
  channels.find((ch) => ch.channel === channel.toLowerCase())?.discount || 0;

export default getDiscountByChannel;
