export interface AvarageAudienceByWeekday {
  pc_audiencia: number;
  nm_dia_semana: string;
}
export interface AudienceFilterAvarageAudienceByWeekdaysState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByWeekday[];
  readonly count: number | null;
}

export interface AvarageAudienceByWeekdayResponse {
  data: {
    rows: AvarageAudienceByWeekday[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByWeekdaysType {
  REQUEST = '@audience/payTv/byChannels/avarageAudienceByWeekdays/load',
  SUCESS = '@audience/payTv/byChannels/avarageAudienceByWeekdays/success',
  ERROR = '@audience/payTv/byChannels/avarageAudienceByWeekdays/error',
  CLEAR = '@audience/payTv/byChannels/avarageAudienceByWeekdays/clear'
}

export interface AudienceFilterAvarageAudienceByWeekdaysAction {
  type: AudienceFiltersAvarageAudienceByWeekdaysType;
  payload: Omit<AvarageAudienceByWeekdayResponse, 'status'>;
}
