import { SimulationScenery } from '../../store/modules/Simulation/Simulation.types';

const getNewSelectedScenery = (
  scenarios: SimulationScenery[],
  sceneryDeleted: number,
  currentScenery: number
) => {
  const newScenarios = scenarios
    .filter((scenery) => scenery.sceneryNumber !== sceneryDeleted)
    .map((scenery, idx) => ({ ...scenery, sceneryNumber: idx }));

  if (currentScenery === 0 && sceneryDeleted === 0) {
    return newScenarios[0];
  }
  if (sceneryDeleted > currentScenery) {
    return newScenarios[currentScenery];
  }

  return newScenarios[currentScenery - 1];
};

export default getNewSelectedScenery;
