import { toast } from 'react-toastify';
import {
  AudienceFilterChannelGeneresAction,
  AudienceFiltersChannelGeneresType,
  ChannelGeneresResponse
} from './Audience.channelGeneres.types';
import getChannelGeneres from '../../../../../services/Audience/PayTv/Audience.payTv.channelGeneres.service';
import { AppThunk } from '../../../..';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';

const LoadAudienceFilterChannelGeneresSuccess = (
  response: ChannelGeneresResponse
) => {
  const payload: AudienceFilterChannelGeneresAction = {
    type: AudienceFiltersChannelGeneresType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterChannelGeneresError = (error: string) => {
  const payload: AudienceFilterChannelGeneresAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersChannelGeneresType.ERROR
  };

  return payload;
};

const LoadingAudienceChannelGeneresFilter = () => ({
  type: AudienceFiltersChannelGeneresType.REQUEST,
  payload: {}
});

export const ClearAudienceChannelGeneresData = () => ({
  type: AudienceFiltersChannelGeneresType.CLEAR,
  payload: {}
});

export const LoadPayTvAudienceFilterChannelGeneresRequest =
  (props: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceChannelGeneresFilter());
      const response = await getChannelGeneres({
        ...props
      });

      dispatch(LoadAudienceFilterChannelGeneresSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterChannelGeneresError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de canais.'
      );
    }
  };
