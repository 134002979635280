import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { useAppMediaQuery, useAppSelector } from '../../../hooks';
import BarChartsCard from '../ChartsCard/BarChartsCard';
import PanelContainer, { PanelHeader, SectionHeader } from '../PanelContainer';
import { defaultTarget } from './constants';
import {
  formatterCurrency,
  formatterNumber
} from '../../../helpers/Formatters';

export default function ComparativeMetrics() {
  const matches = useAppMediaQuery('lg');
  const direction = useMemo(() => (!matches ? 'column' : 'row'), [matches]);
  const { comparativeMetrics, competitive } = useAppSelector((state) => state);

  const generalMetrics = useMemo(
    () => comparativeMetrics?.data?.filter((x) => x.targetId === defaultTarget),
    [comparativeMetrics]
  );

  const targetMetrics = useMemo(() => {
    if (
      comparativeMetrics.data &&
      comparativeMetrics.data.length > 1 &&
      comparativeMetrics.data.filter((x) => x.targetId !== defaultTarget)
        .length > 0
    ) {
      return comparativeMetrics.data.filter(
        (x) => x.targetId !== defaultTarget
      );
    }

    return generalMetrics;
  }, [generalMetrics, comparativeMetrics.data]);

  const targetName = useMemo((): string => {
    if (targetMetrics) {
      return competitive.targets.data.filter(
        (t) => t.id === targetMetrics[0]?.targetId
      )[0]?.name;
    }
    return '-';
  }, [competitive.targets.data, targetMetrics]);

  const rawLegends = generalMetrics?.map((x) => x.channelName) ?? [];

  return (
    <PanelContainer>
      <PanelHeader text="Informações gerais" />
      <SectionHeader text="Frequência e Afinidade" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <BarChartsCard
          dataComplement="de Frequência"
          header={`Frequência Média: ${targetName}`}
          metric="avgFrequency"
          rawData={targetMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterNumber(val, { fractionDigits: 1 })}
        />
        <BarChartsCard
          dataComplement="de Afinidade"
          header={`Afinidade: ${targetName}`}
          metric="affinity"
          rawData={targetMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterNumber(val, { fractionDigits: 0 })}
        />
      </Stack>
      <SectionHeader text="Alcance" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <BarChartsCard
          dataComplement="de Alcance"
          header="TOTAL INDIVÍDUOS"
          metric="absoluteCoverage"
          rawData={generalMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterNumber(val, { fractionDigits: 0 })}
        />
        <BarChartsCard
          dataComplement="de Alcance"
          header={targetName}
          metric="absoluteCoverage"
          rawData={targetMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterNumber(val, { fractionDigits: 0 })}
        />
      </Stack>
      <SectionHeader text="Impactos" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <BarChartsCard
          dataComplement="de Impactos"
          header="TOTAL INDIVÍDUOS"
          metric="impacts"
          rawData={generalMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterNumber(val, { fractionDigits: 0 })}
        />
        <BarChartsCard
          dataComplement="de Impactos"
          header={targetName}
          metric="impacts"
          rawData={targetMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterNumber(val, { fractionDigits: 0 })}
        />
      </Stack>
      <SectionHeader text="CPM" />
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        spacing={1}
      >
        <BarChartsCard
          dataComplement="de CPM"
          header="TOTAL INDIVÍDUOS"
          metric="cpm"
          rawData={generalMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterCurrency(val, { fractionDigits: 1 })}
        />
        <BarChartsCard
          dataComplement="de CPM"
          header={targetName}
          metric="cpm"
          rawData={targetMetrics ?? []}
          rawLegends={rawLegends}
          formatter={(val) => formatterCurrency(val, { fractionDigits: 1 })}
        />
      </Stack>
    </PanelContainer>
  );
}
