import { Stack } from '@mui/material';
import './AudiencePayTvProgramsRadiographyPanel.scss';
import React, { useMemo } from 'react';

import PanelContainer from '../../../../../Competitive/PanelContainer';
import AudienceByProgramsRadiographyFilter from './AudiencePayTvProgramsRadiographyFilter';
import useAudiencePayTvProgramsRadiographyData from './useAudiencePayTvProgramsRadiographyData';
import KantarDescription from '../../../../KantarDescription';
import { ICommonsFilter, TypePeriod } from '../types';
import { OptionsList } from '../../../../../Form/types';
import {
  formatterNumber,
  formatterNumberShort
} from '../../../../../../helpers/Formatters';
import InfoCard from '../../../../Cards/InfoCard';
import BarChartsCard from '../../../../Charts/ChartsCard/BarChartsCard';
import DonutChart from '../../../../Charts/DonutChart';
import LineChart from '../../../../Charts/LineChart';
import { useAppMediaQuery, useAppSelector } from '../../../../../../hooks';
import Loading from '../../../../../Loading';
import AlertInfoCard from '../../../../Cards/AlertInfoCard';

const AudienceByPayTvProgramsRadiography: React.FC<ICommonsFilter> = ({
  target,
  newFilter,
  setNewFilter
}) => {
  const [channel, setChannel] = React.useState<OptionsList | undefined>();
  const [program, setProgram] = React.useState<OptionsList | undefined>();
  const [period, setPeriod] = React.useState<TypePeriod | undefined>();
  const [currentTarget, setCurrentTarget] = React.useState(target?.value);

  const matches = useAppMediaQuery('lg');
  const direction = useMemo(() => (!matches ? 'column' : 'row'), [matches]);

  const { audience } = useAppSelector((state) => state);

  const {
    audienceAvarageDataByAges,
    audienceAvarageDataByFinancialClasses,
    audienceAvarageDataByGenders,
    audienceAvarageDataByMonths
  } = useAudiencePayTvProgramsRadiographyData({
    target,
    channel,
    period,
    program,
    setChannel,
    setPeriod,
    setProgram,
    setCurrentTarget
  });

  if (
    !audience?.payTv?.byPrograms?.avarageAudienceByAges?.data?.length &&
    !audience?.payTv?.byPrograms?.avarageAudienceByAges?.error &&
    !audience?.payTv?.byPrograms?.avarageAudienceByFinancialClasses?.data
      ?.length &&
    !audience?.payTv?.byPrograms?.avarageAudienceByFinancialClasses?.error &&
    !audience?.payTv?.byPrograms?.avarageAudienceByGenders?.data?.length &&
    !audience?.payTv?.byPrograms?.avarageAudienceByGenders?.error &&
    !audience?.payTv?.byPrograms?.avarageAudienceByMonths?.data?.length &&
    !audience?.payTv?.byPrograms?.avarageAudienceByMonths?.error &&
    !audience?.payTv?.byPrograms?.avarageDatas?.data?.length &&
    !audience?.payTv?.byPrograms?.avarageDatas?.error
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudienceByProgramsRadiographyFilter
          {...{
            channel,
            program,
            period,
            setChannel,
            setProgram,
            setPeriod,
            target,
            newFilter,
            setNewFilter
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  if (
    audience?.payTv?.byPrograms?.avarageAudienceByAges?.loading &&
    audience?.payTv?.byPrograms?.avarageAudienceByFinancialClasses?.loading &&
    audience?.payTv?.byPrograms?.avarageAudienceByGenders?.loading &&
    audience?.payTv?.byPrograms?.avarageAudienceByMonths?.loading &&
    audience?.payTv?.byPrograms?.avarageDatas?.loading
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudienceByProgramsRadiographyFilter
          {...{
            channel,
            program,
            period,
            setChannel,
            setProgram,
            setPeriod,
            target,
            newFilter,
            setNewFilter
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  const isCurrentYearSelected = () => {
    return `${new Date().getFullYear()}` === `${period?.label}`;
  };

  return (
    <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
      <AudienceByProgramsRadiographyFilter
        {...{
          channel,
          program,
          period,
          setChannel,
          setProgram,
          setPeriod,
          target,
          newFilter,
          setNewFilter
        }}
      />
      <>
        {isCurrentYearSelected() && (
          <AlertInfoCard text="O período selecionado exibe dados acumulados do ano até o último mês completo." />
        )}
      </>
      <Stack
        alignItems="center"
        justifyContent="space-around"
        columnGap={2}
        rowGap={4}
        direction="row"
        flexWrap="wrap"
        gridTemplateColumns="repeat(auto-fill, minmax(250px, 1fr))"
      >
        <InfoCard
          content={`${formatterNumber(
            audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]
              ?.pc_audiencia ?? 0,
            { fractionDigits: 3 }
          )}`}
          header="Audiência média"
          description={currentTarget}
          metric=" %"
          opacity={1}
          color="rgba(93, 67, 250, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.payTv?.byPrograms?.avarageDatas.loading}
        />
        {/* <InfoCard
          content={
            `${formatterNumber(
              audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]
                ?.pc_share_tle ?? 0,
              { fractionDigits: 2 }
            )}` ?? '0'
          }
          header="Share"
          description={currentTarget}
          metric=" %"
          opacity={1}
          color="rgba(93, 67, 250, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.payTv?.byPrograms?.avarageDatas.loading}
        /> */}
        <InfoCard
          content={`${formatterNumberShort(
            audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]
              ?.vl_audiencia_abs,
            true,
            false,
            3
          )}`}
          header="Alcance médio por minuto"
          description={currentTarget}
          metric={` ${formatterNumberShort(
            audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]
              ?.vl_audiencia_abs,
            false,
            true
          )}`}
          opacity={1}
          color="rgba(93, 67, 250, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.payTv?.byPrograms?.avarageDatas.loading}
        />
        <InfoCard
          hidden
          content={`${formatterNumberShort(
            audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]
              ?.vl_alcance_abs,
            true,
            false,
            3
          )}`}
          header="Alcance médio diário"
          metric={`${formatterNumberShort(
            audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]
              ?.vl_alcance_abs,
            false,
            true
          )}`}
          description={currentTarget}
          opacity={1}
          color="rgba(5, 166, 255, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.payTv?.byPrograms?.avarageDatas.loading}
        />
        <InfoCard
          hidden
          content={
            audience?.payTv?.byPrograms?.avarageDatas?.data?.[0]?.hr_ats ?? 0
          }
          header="Tempo médio por exibição"
          description={currentTarget}
          opacity={1}
          metric=" Horas"
          color="rgba(5, 166, 255, 0.1)"
          style={{
            gridColumn: 'span 2'
          }}
          loading={audience?.payTv?.byPrograms?.avarageDatas.loading}
        />
      </Stack>
      <Stack
        alignItems="center"
        columnGap={2}
        direction={direction}
        rowGap={4}
        spacing={1}
        flexWrap="nowrap"
      >
        <Stack width={matches ? '33%' : '100%'}>
          <DonutChart
            data={audienceAvarageDataByGenders}
            header="Gênero"
            valueFormat={(number) =>
              formatterNumber(number, { fractionDigits: 3 })
            }
          />
        </Stack>
        <Stack width={matches ? '33%' : '100%'}>
          <BarChartsCard
            dataComplement="% do total"
            header="Idade"
            type="collumn"
            rawData={audienceAvarageDataByAges}
            padding={0.07}
            formatter={(val) => formatterNumber(val, { fractionDigits: 2 })}
            valueFormat={(val) =>
              `${formatterNumber(val, { fractionDigits: 3 })}%`
            }
          />
        </Stack>
        <Stack width={matches ? '33%' : '100%'}>
          <DonutChart
            data={audienceAvarageDataByFinancialClasses}
            header="Classe"
            valueFormat={(number) =>
              formatterNumber(number, { fractionDigits: 3 })
            }
          />
        </Stack>
      </Stack>
      {audienceAvarageDataByMonths &&
        audienceAvarageDataByMonths?.length &&
        audienceAvarageDataByMonths?.length > 0 && (
          <LineChart
            header={`Audiência | Evolução por mês `}
            data={[
              {
                id: 'Audiência',
                color: '#5D43FA',
                data: audienceAvarageDataByMonths ?? []
              }
            ]}
          />
        )}
      <KantarDescription />
    </PanelContainer>
  );
};

export default AudienceByPayTvProgramsRadiography;
