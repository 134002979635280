import { FILL_DEFAULT } from './constants';
import { MapCell, MapCol, MapColData } from './types';

export const encodeCell = (rowIdx: number, col: string) =>
  `${col}${rowIdx + 1}`;

export const encodeInterval = (
  colFrom: string,
  rowIdxFrom: number,
  colTo: string,
  rowIdxTo = rowIdxFrom
) => `${encodeCell(rowIdxFrom, colFrom)}:${encodeCell(rowIdxTo, colTo)}`;

export const formulaSUM = (
  colFrom: string,
  colTo: string,
  rowIdxFrom: number,
  rowIdxTo = rowIdxFrom
) => `SUM(${encodeInterval(colFrom, rowIdxFrom, colTo, rowIdxTo)})`;

export const formulaMultiplication = (
  colFrom: string,
  colTo: string,
  rowIdxFrom: number,
  rowIdxTo = rowIdxFrom
) => `${encodeCell(rowIdxFrom, colFrom)} * ${encodeCell(rowIdxTo, colTo)}`;

export const formulaIF = (
  condition: string,
  formulaTrue: string,
  formulaFalse: string
) => `IF(${condition}, ${formulaTrue}, ${formulaFalse})`;

const isColIncludeInRange =
  (rowIdx: number, col: MapCol, mapColsXlsx: Readonly<MapCol[]>) =>
  (model: MapColData) =>
    model.range &&
    model.range.s.r <= rowIdx &&
    model.range.e.r >= rowIdx &&
    mapColsXlsx
      .slice(model.range.s.c, (model.range.e.c || 0) + 1)
      .includes(col);

export const applyAllConfig = (
  modelAllCells: MapCell[],
  mapColsXlsx: Readonly<MapCol[]>
): MapCell[] => {
  const modelXlsx = modelAllCells.map((model) => model.rowData);
  const flatModels = modelXlsx.flat().filter((model) => model.range);

  return modelXlsx.map((model, rowIdx) => {
    const rowData = mapColsXlsx.map<MapColData>((col, idx) => {
      const row: MapColData | undefined = model.find((r) => r.cell.c === idx);

      const modelIncludeRange: MapColData | undefined = flatModels.find(
        isColIncludeInRange(rowIdx, col, mapColsXlsx)
      );

      if (row && row.cellStyle) {
        return {
          ...row,
          cellStyle: {
            ...row.cellStyle,
            border: {
              ...(row.cellStyle.border || {}),
              ...(modelIncludeRange?.cellStyle?.border || {})
            },
            fill: {
              ...FILL_DEFAULT,
              ...(row.cellStyle.fill || {})
            },
            alignment: {
              vertical: 'center',
              ...(row.cellStyle.alignment || {})
            }
          }
        };
      }

      return {
        cell: { r: rowIdx, c: idx },
        cellStyle: {
          border: modelIncludeRange?.cellStyle?.border || undefined,
          fill: FILL_DEFAULT,
          alignment: { vertical: 'center' }
        },
        ...(row || {})
      };
    });

    return { rowInfo: modelAllCells[rowIdx].rowInfo, rowData };
  });
};
