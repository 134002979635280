export interface AvarageAudienceByProgrammingGender {
  pc_audiencia: number;
  nm_genero: string;
}
export interface AudienceFilterAvarageAudienceByProgrammingGendersState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByProgrammingGender[];
  readonly count: number | null;
}

export interface AvarageAudienceByProgrammingGenderResponse {
  data: {
    rows: AvarageAudienceByProgrammingGender[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByProgrammingGendersType {
  REQUEST = '@audience/payTv/byChannels/avarageAudienceByProgrammingGenders/load',
  SUCESS = '@audience/payTv/byChannels/avarageAudienceByProgrammingGenders/success',
  ERROR = '@audience/payTv/byChannels/avarageAudienceByProgrammingGenders/error',
  CLEAR = '@audience/payTv/byChannels/avarageAudienceByProgrammingGenders/clear'
}

export interface AudienceFilterAvarageAudienceByProgrammingGendersAction {
  type: AudienceFiltersAvarageAudienceByProgrammingGendersType;
  payload: Omit<AvarageAudienceByProgrammingGenderResponse, 'status'>;
}
