import * as Yup from 'yup';
import { CompetitiveFormData } from '../../../store/modules/Competitive/Shared/Competitive.shared.types';

const schemaCompetitive = Yup.object().shape({
  channels: Yup.array(
    Yup.object().shape({
      channelName: Yup.string().required(),
      discount: Yup.number().required(),
      id: Yup.number().required(),
      selected: Yup.boolean().required()
    })
  )
    .min(1, 'Obrigatório selecionar no mínimo 1 canal.')
    .required('Canais são obrigatórios.'),
  market: Yup.string().required('Praça é obrigatória.'),
  period: Yup.array(
    Yup.date()
      .typeError('Informe um período válido.')
      .required('Periodo é obrigatório.')
  ).required('Periodo é obrigatório.'),
  target: Yup.string().required('Target é obrigatório.')
});

const validateFields = (formData: Partial<CompetitiveFormData>) => {
  return schemaCompetitive.validate(formData, {
    abortEarly: false
  });
};

export default validateFields;
