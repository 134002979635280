import { Reducer } from 'redux';
import {
  AudienceFilterAvarageDatasAction,
  AudienceFilterAvarageDatasState,
  AudienceFiltersAvarageDatasType
} from './Audience.avarageDatas.types';

const avarageDataInitialState: AudienceFilterAvarageDatasState = {
  error: false,
  loading: false,
  data: [],
  count: 0
};

const reducer: Reducer<
  AudienceFilterAvarageDatasState,
  AudienceFilterAvarageDatasAction
> = (
  state = avarageDataInitialState,
  action
): AudienceFilterAvarageDatasState => {
  switch (action.type) {
    case AudienceFiltersAvarageDatasType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageDatasType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageDatasType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageDatasType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
