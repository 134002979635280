import { isSameMonth } from 'date-fns';
import {
  MetricsByCompetency,
  PlanningInsertionByDay,
  PlanningRow
} from '../store/modules/Planning/Planning.types';
import {
  PriceByDate,
  ProgramMetricsPerTarget
} from '../store/modules/Programs/Programs.types';
import { stringToDate } from './Dates';

const isValidValue = (v: any) =>
  v !== undefined && !Number.isNaN(v) && v !== null;

export const calcTRP = (insertions: number, percentageAudience: number) => {
  if (isValidValue(insertions) && isValidValue(percentageAudience)) {
    const audienceFixed = Number(percentageAudience.toFixed(5));
    return insertions * audienceFixed;
  }
  return 0;
};

export const calcTotalImpacts = (trp: number, univTarget: number) => {
  if (isValidValue(trp) && isValidValue(univTarget)) {
    const trpFixed = Number(trp.toFixed(5));
    const univTargetFixed = Number(univTarget.toFixed(5));
    return Number(((trpFixed * univTargetFixed) / 100).toFixed(0));
  }
  return 0;
};

export const calcCPP = (priceMedia: number, trp: number) => {
  if (trp === 0) return 0;

  if (isValidValue(priceMedia) && isValidValue(trp)) {
    return priceMedia / trp;
  }
  return 0;
};

export const calcCPM = (priceMedia: number, totalImpacts: number) => {
  if (totalImpacts === 0) return 0;

  if (isValidValue(priceMedia) && isValidValue(totalImpacts)) {
    return (priceMedia / totalImpacts) * 1000;
  }
  return 0;
};

export const calcTotalAmount = (
  insertionsByDay: PlanningInsertionByDay[],
  pricesByDate: PriceByDate[]
) => {
  const competencies = pricesByDate.map((price) => ({
    ...price,
    competency: stringToDate(price.competency)
  }));

  return insertionsByDay
    .map((inserts) => ({ ...inserts, day: new Date(inserts.day) }))
    .reduce((acc, insertions) => {
      const competency = competencies.find(
        (compet) =>
          compet.competency.getFullYear() === insertions.day.getFullYear() &&
          compet.competency.getMonth() === insertions.day.getMonth()
      );
      if (competency) {
        return acc + competency.price * insertions.value;
      }
      return acc;
    }, 0);
};

export const calcAvgDiscount = (
  totalAmount: number,
  totalNegotiated: number
) => {
  if (totalAmount === 0) return 0;

  if (isValidValue(totalNegotiated) && isValidValue(totalAmount))
    return (1 - Number(totalNegotiated / totalAmount)) * 100;

  return 0;
};

export const calcDiscount = (totalAmount: number, discont: number | string) => {
  if (isValidValue(totalAmount) && isValidValue(discont)) {
    let newDiscont = 0;

    if (typeof discont === 'string') {
      newDiscont = Number(discont.replace(',', '.'));
    } else {
      newDiscont = discont;
    }

    return Number((totalAmount - totalAmount * (newDiscont / 100)).toFixed(3));
  }

  return 0;
};

export const calcMetricsOfInsertions = (
  metricsPerTarget: ProgramMetricsPerTarget[],
  pricesByDate: PriceByDate[],
  insertionsByDay: PlanningInsertionByDay[],
  discont: number,
  target: string
) => {
  const metricPerTarget = metricsPerTarget.find(
    (metrics) => metrics.target === target
  );
  const insertions = insertionsByDay.reduce((acc, ins) => acc + ins.value, 0);

  const trp = calcTRP(insertions, metricPerTarget?.avgAudiencePercent || 0);

  const metricsByCompetency: MetricsByCompetency[] = pricesByDate.map(
    (priceByDate) => {
      const insertionsByCompetencie = insertionsByDay
        .filter((ins) =>
          isSameMonth(new Date(ins.day), stringToDate(priceByDate.competency))
        )
        .reduce((acc, ins) => acc + ins.value, 0);

      const trpByCompetency = calcTRP(
        insertionsByCompetencie,
        metricPerTarget?.avgAudiencePercent || 0
      );

      const impactsByCompetency = calcTotalImpacts(
        trpByCompetency,
        metricPerTarget?.univTarget || 0
      );

      const totalAmount = priceByDate.price * insertionsByCompetencie;
      const price = calcDiscount(totalAmount, discont);

      return {
        cpm: calcCPM(price, impactsByCompetency),
        competency: stringToDate(priceByDate.competency)
      };
    }
  );

  return {
    totalImpacts: calcTotalImpacts(trp, metricPerTarget?.univTarget || 0),
    metricsByCompetency
  };
};

export const calcTotalizers = (
  planningPrograms: PlanningRow[],
  target: string
) => {
  const { totalAmount, totalNegotiated, totalInsertions, totalTrpOrGrp } =
    planningPrograms.reduce(
      (sum, row) => {
        const metricsOfTarget = row.metricsPerTarget.find(
          (t) => t.target === target
        );

        return {
          totalAmount: sum.totalAmount + Number(row.amount),
          totalNegotiated: sum.totalNegotiated + Number(row.negotiatedValue),
          totalInsertions: sum.totalInsertions + Number(row.insertions),
          totalTrpOrGrp:
            sum.totalTrpOrGrp +
            calcTRP(row.insertions, metricsOfTarget?.avgAudiencePercent || 0)
        };
      },
      {
        totalAmount: 0,
        totalNegotiated: 0,
        totalInsertions: 0,
        totalTrpOrGrp: 0
      }
    );

  const avgDiscount = calcAvgDiscount(totalAmount, totalNegotiated);

  return {
    totalAmount,
    totalNegotiated,
    avgDiscount,
    totalInsertions,
    totalTrpOrGrp
  };
};
