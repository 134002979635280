export interface ShareAvarage {
  pc_share_tle: number;
  nm_emissora: string;
}
export interface AudienceFilterShareAvaragesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: ShareAvarage[];
  readonly count: number | null;
}

export interface ShareAvarageResponse {
  data: {
    rows: ShareAvarage[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersShareAvaragesType {
  REQUEST = '@audience/payTv/byChannels/shareAvarages/load',
  SUCESS = '@audience/payTv/byChannels/shareAvarages/success',
  ERROR = '@audience/payTv/byChannels/shareAvarages/error',
  CLEAR = '@audience/payTv/byChannels/shareAvarages/clear'
}

export interface AudienceFilterShareAvaragesAction {
  type: AudienceFiltersShareAvaragesType;
  payload: Omit<ShareAvarageResponse, 'status'>;
}
