import { isArray } from 'lodash';

import { apiV1 } from '../../shared/Api.service';
import { IAudienceByChannelsPayTvComparativeFilterParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { ProgrammerResponse } from '../../../store/modules/AudienceModule/PayTv/Programmers/Audience.programmers.types';

const path = '/audience/pay-tv/data';

export default async function getPayTvProgrammers({
  target,
  timeRange,
  timeRanges,
  weekDays,
  programmingGenders,
  channelsNotIn
}: IAudienceByChannelsPayTvComparativeFilterParams): Promise<ProgrammerResponse> {
  const { data } = await apiV1.get<ProgrammerResponse>(path, {
    params: {
      fields: ['nm_programadora'],
      table: 'anual',
      targets: target?.value ? [target?.value] : undefined,
      timeRanges: timeRange?.value
        ? [timeRange?.value]
        : timeRanges?.map((timeRangeOption) => timeRangeOption.value),
      weekDays: weekDays?.value ? [weekDays?.value] : ['TODOS'],
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : [programmingGenders?.value ?? 'TODOS'],
      programs: ['TODOS'],
      channelsNotIn
    }
  });
  return data;
}
