import { apiV1 } from '../shared/Api.service';
import {
  CommitmentData,
  CommitmentsResponse
} from '../../store/modules/Commitments/Commitments.types';

const path = '/commitments';

export async function getAllCommitments(): Promise<CommitmentsResponse> {
  const { data } = await apiV1.get<CommitmentsResponse>(path);

  return data;
}

export async function saveCommitment(
  commitment: CommitmentData
): Promise<CommitmentsResponse> {
  const { message, agency } = commitment;

  const { data } = await apiV1.post<CommitmentsResponse>(path, {
    message,
    agency
  });

  return data;
}
