import { ValidationError } from 'yup';

export interface YupError {
  [key: string]: string;
}

const handleErrors = (validation: ValidationError): YupError => {
  const validationErrors: YupError = {};
  validation.inner.forEach((error: any) => {
    validationErrors[error.path] = error.message;
  });
  return validationErrors;
};

export default handleErrors;
