import './ContainerField.scss';
import React, { useMemo } from 'react';

interface Props {
  classes?: {
    wrapper?: string;
    divisorSelect?: string;
    divisorColumn?: string;
  };
  children: React.ReactNode;
}

export default function ContainerField({ children, classes }: Props) {
  const processClasses = useMemo(
    () => ({
      wrapper: `input-wrapper ${classes?.wrapper || ''}`.trim(),
      divisorSelect: `input-wrapper__divisor_select ${
        classes?.divisorSelect || ''
      }`.trim(),
      divisorColumn: `input-wrapper__divisor__column ${
        classes?.divisorColumn || ''
      }`.trim()
    }),
    [classes]
  );

  return (
    <div className={processClasses.wrapper}>
      <div className={processClasses.divisorSelect}>
        <div className={processClasses.divisorColumn}>{children}</div>
      </div>
    </div>
  );
}

ContainerField.defaultProps = {
  classes: undefined
};
