import { Reducer } from 'redux';
import {
  AudienceFilterAvarageAudienceByAgesAction,
  AudienceFilterAvarageAudienceByAgesState,
  AudienceFiltersAvarageAudienceByAgesType
} from './Audience.avarageAudienceByAges.types';

const avarageAudienceByAgeInitialState: AudienceFilterAvarageAudienceByAgesState =
  {
    error: false,
    loading: false,
    data: [],
    count: 0
  };

const reducer: Reducer<
  AudienceFilterAvarageAudienceByAgesState,
  AudienceFilterAvarageAudienceByAgesAction
> = (
  state = avarageAudienceByAgeInitialState,
  action
): AudienceFilterAvarageAudienceByAgesState => {
  switch (action.type) {
    case AudienceFiltersAvarageAudienceByAgesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersAvarageAudienceByAgesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersAvarageAudienceByAgesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersAvarageAudienceByAgesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
