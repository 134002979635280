import './Users.scss';
import { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { SortColumn } from 'react-data-grid';
import Skeleton from '@mui/material/Skeleton';
import Info from '../../assets/images/icon-info.svg';
import GridPagination from '../../components/GridPagination';
import {
  LoadUsersRequest,
  DeleteUserRequest,
  SetUsersSortColumns,
  SetUsersPage,
  SetUsersRowsPerPage,
  SetUsersSearch
} from '../../store/modules/Users/Users.actions';
import { User } from '../../store/modules/Users/Users.types';
import SubHeaderUsers from '../../components/SubHeaderUsers';
import SubHeader from '../../components/SubHeader';
import ModalUser from '../../components/ModalUser';
import ContainerWrapper from '../../components/ContainerWrapper/ContainerWrapper';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
  getCentral,
  getAreaPerCentral,
  getBaseAudience
} from '../../components/ModalUser/contants';
import { ModalUserEdit } from '../../components/ModalUser/ModalUser';
import useUsers from './useUsers';
import { confirmAlertApp } from '../../components/ModalShell';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import AppSVG from '../../components/SVG/AppSVG';

const Users = () => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state);

  const [openModal, setOpenModal] = useState(false);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [search, setSearch] = useState('');
  // const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState<string | undefined>(
    users.sortColumns.length ? users.sortColumns[0].columnKey : undefined
  );
  const [orderDir, setOrderDir] = useState<string | undefined>(
    users.sortColumns.length ? users.sortColumns[0].direction : undefined
  );
  // const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [userEdit, setUserEdit] = useState<ModalUserEdit | undefined>();

  const reloadUsers = useCallback(() => {
    dispatch(
      LoadUsersRequest({
        limit: users.rowsPerPage,
        offset: users.page > 0 ? users.page * users.rowsPerPage : 0,
        orderBy,
        orderDir,
        search: users.search
      })
    );
  }, [
    dispatch,
    users.rowsPerPage,
    users.page,
    users.search,
    orderBy,
    orderDir
  ]);

  useEffect(() => {
    if (!users.data.length && users.reload) {
      reloadUsers();
      return;
    }
    if (users.reload) reloadUsers();
  }, [users.reload, users.data.length, reloadUsers]);

  // useEffect(() => {
  //   reloadUsers();
  // }, [reloadUsers]);

  useEffect(() => {
    if (users.message) {
      if (users.error) toast.error(users.message);
      else toast.success(users.message);
    }
  }, [users.message, users.error]);

  const handleUpdate = (row: User) => {
    setUserEdit(() => {
      const objCentral = getCentral(row.central);
      const objBaseAudience = getBaseAudience(row.baseAudience);
      return {
        ...row,
        central: { label: objCentral.label, value: objCentral.value },
        area: getAreaPerCentral(row.area, row.central),
        baseAudience: {
          label: objBaseAudience.label,
          value: objBaseAudience.value
        }
      };
    });
    setOpenModal(true);
  };

  const handleDelete = (row: User) => {
    confirmAlertApp({
      title: '',
      message: (
        <>
          <span>Tem certeza que deseja excluir o usuário&nbsp;</span>
          <b {...attributeMaskOrUnmask(true)}>{row.name}</b>
          <span>?</span>
        </>
      ),
      onClickOK: () => {
        if (row.id !== null) {
          dispatch(DeleteUserRequest(row.id));
        }
      }
    });
  };

  const handleClick = () => {
    setOpenModal(false);
  };

  const handleSortColumnsChange = (sortedColumn: SortColumn[]) => {
    setOrderBy(sortedColumn[0]?.columnKey || undefined);
    setOrderDir(sortedColumn[0]?.direction || undefined);
    dispatch(SetUsersSortColumns(sortedColumn.slice(-1)));
  };

  const handlePageChange = useCallback(
    (newPage: number) => {
      dispatch(SetUsersPage(newPage));
    },
    [dispatch]
  );

  const handleRowsPerPageChange = useCallback(
    (newRows: number) => {
      dispatch(SetUsersRowsPerPage(newRows));
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(
    (str: string) => {
      dispatch(SetUsersSearch(str));
    },
    [dispatch]
  );

  const { columns } = useUsers(handleUpdate, handleDelete);

  const MessageNoRows = users.count === 0 && !users.loading && (
    <div className="pr-5 pl-5 no-message" {...attributeMaskOrUnmask(false)}>
      <div>
        <AppSVG
          height={25}
          loader={<Skeleton height={25} width={24} />}
          src={Info}
          width={24}
        />
      </div>
      <div>Nenhum usuário encontrado.</div>
    </div>
  );

  return (
    <div>
      <SubHeader>
        <SubHeaderUsers
          onClick={() => {
            setUserEdit(undefined);
            setOpenModal(!openModal);
          }}
        />
      </SubHeader>
      <ContainerWrapper>
        <GridPagination
          className="users__grid--height users__grid--width"
          columns={columns}
          countData={users.count}
          data-testid="userList"
          defaultColumnOptions={{ resizable: true }}
          hasPagination
          loading={users.loading}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          onSearchChange={handleSearchChange}
          onSortColumnsChange={handleSortColumnsChange}
          renderers={{ noRowsFallback: MessageNoRows }}
          page={users.page}
          rows={users.data}
          rowsPerPage={users.rowsPerPage}
          search={users.search}
          sortColumns={users.sortColumns}
        />
      </ContainerWrapper>
      <ModalUser
        onCancelClick={() => setOpenModal(false)}
        onSaveClick={handleClick}
        open={openModal}
        userEdit={userEdit}
      />
    </div>
  );
};

export default Users;
