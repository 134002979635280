import { Reducer } from 'redux';
import {
  AudienceFilterChannelGeneresAction,
  AudienceFilterChannelGeneresState,
  AudienceFiltersChannelGeneresType
} from './Audience.channelGeneres.types';

const channelGenereInitialState: AudienceFilterChannelGeneresState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterChannelGeneresState,
  AudienceFilterChannelGeneresAction
> = (
  state = channelGenereInitialState,
  action
): AudienceFilterChannelGeneresState => {
  switch (action.type) {
    case AudienceFiltersChannelGeneresType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersChannelGeneresType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersChannelGeneresType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersChannelGeneresType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
