import React from 'react';
import Info from '../../assets/images/icon-info.svg';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import AppSVG from '../SVG/AppSVG';

interface Props {
  className?: string;
  message: string | React.ReactNode;
  icon?: string;
  dataClarityMask?: boolean;
  visible?: boolean;
}

const Information = ({
  message,
  icon = Info,
  className = '',
  dataClarityMask = false,
  visible = true
}: Props) => {
  return visible ? (
    <span
      className={`text-info ${className}`}
      {...attributeMaskOrUnmask(dataClarityMask)}
    >
      <AppSVG className="no-pointer-events" src={icon} />
      {message}
    </span>
  ) : null;
};

Information.defaultProps = {
  className: undefined,
  dataClarityMask: undefined,
  icon: undefined,
  visible: true
};

export default Information;
