import { useRef, useEffect, memo, useState, useCallback } from 'react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterDateFns';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useField } from '@unform/core';
import setDate from 'date-fns/set';
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';
import Stack from '@mui/material/Stack';
import brLocale from 'date-fns/locale/pt-BR';
import isDate from 'date-fns/isDate';
import isWithinInterval from 'date-fns/isWithinInterval';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import { stringToDate } from '../../../helpers/Dates';

interface Props
  extends Omit<DatePickerProps<Date>, 'renderInput' | 'onChange' | 'value'> {
  name: string;
  required?: boolean;
  defaultValue?: Date | null;
  dataClarityMask?: boolean;
  onChange?(newValue: Date | null): void;
  onClearError?(name: string): void;
}

const configTime = {
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0
};

const DatePickerInput = ({
  name,
  required,
  onChange,
  maxDate,
  minDate,
  defaultValue = null,
  views,
  onClearError,
  dataClarityMask,
  className,
  ...rest
}: Props) => {
  const datePikerRef = useRef<HTMLInputElement>(null);
  const [stateValue, setStateValue] = useState<Date | null>(defaultValue);
  const { fieldName, registerField, error, clearError } = useField(name);

  const isValidMaxMinInterval = useCallback(
    (value: Date) => {
      const valueTmp = setDate(value, configTime);
      if (minDate && !maxDate) {
        const minDateTmp = setDate(minDate, configTime);

        if (valueTmp < minDateTmp) {
          return false;
        }
      }
      if (maxDate && !minDate) {
        const maxDateTmp = setDate(maxDate, configTime);

        if (valueTmp > maxDateTmp) {
          return false;
        }
      }
      if (minDate && maxDate) {
        const minDateTmp = setDate(minDate, configTime);
        const maxDateTmp = setDate(maxDate, configTime);
        if (
          minDateTmp > maxDateTmp ||
          !isWithinInterval(valueTmp, { start: minDateTmp, end: maxDateTmp })
        ) {
          return false;
        }
      }

      return true;
    },
    [maxDate, minDate]
  );

  const validDate = (date: Date) =>
    !Number.isNaN(date.getTime()) &&
    isDate(date) &&
    isValidMaxMinInterval(date);

  const getFormatLength = () => {
    if (views && views[0] === 'year') return 4; // format yyyy
    if (views && views.includes('month') && views.includes('year')) return 7; // format mm/yyyy
    return 10; // format dd/mm/yyyy
  };

  const handleChange = (
    newValue: Date | null,
    keyboardInputValue: string | undefined
  ) => {
    const formatLength = getFormatLength();
    if (keyboardInputValue && keyboardInputValue.length < formatLength) return;
    if (newValue && validDate(newValue)) {
      clearError();
    }
    const newDate = newValue && validDate(newValue) ? newValue : null;

    if (onChange) onChange(newDate);
    setStateValue(newDate);
  };

  useEffect(() => {
    if (defaultValue) {
      setStateValue(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (stateValue) {
      if (!isValidMaxMinInterval(stateValue)) {
        setStateValue(null);
        if (onChange) onChange(null);
      }
    }
  }, [stateValue, isValidMaxMinInterval, onChange]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePikerRef.current,
      getValue: () => {
        if (datePikerRef?.current?.value && stateValue) {
          if (views?.length && views[0] === 'year') {
            return new Date(Number(datePikerRef.current.value), 0, 1);
          }

          const mountDate = datePikerRef.current.value.split('/').reverse();
          return new Date(
            Number(mountDate[0]),
            Number(mountDate[1]) - 1,
            Number(mountDate[2])
          );
        }
        return null;
      },
      setValue: (_, newValue: any) => {
        clearError();
        const v =
          typeof newValue === 'string' ? stringToDate(newValue) : newValue;
        setStateValue(v || null);
      },
      clearValue: () => setStateValue(null)
    });
  }, [fieldName, registerField, views, clearError, stateValue]);

  const handleClearError = () => {
    if (onClearError) onClearError(name);
  };

  const handleClose = () => {
    handleClearError();
  };

  return (
    <Stack {...attributeMaskOrUnmask(dataClarityMask)}>
      <LocalizationProvider dateAdapter={DateAdapter} locale={brLocale}>
        <DatePicker
          allowSameDateSelection
          className={className}
          defaultCalendarMonth={maxDate || minDate || new Date()}
          inputRef={datePikerRef}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleChange}
          onClose={handleClose}
          renderInput={(params: TextFieldProps) => (
            <TextField
              {...params}
              className={className}
              error={!!error}
              inputProps={{
                ...params.inputProps,
                ...attributeMaskOrUnmask(dataClarityMask)
              }}
              onBlur={handleClearError}
              required={required}
            />
          )}
          showTodayButton
          todayText="Hoje"
          value={stateValue}
          views={views}
          {...rest}
        />
      </LocalizationProvider>
      <FormHelperText>{error}</FormHelperText>
    </Stack>
  );
};

DatePickerInput.defaultProps = {
  dataClarityMask: undefined,
  defaultValue: null,
  onChange: undefined,
  onClearError: undefined,
  required: false
};

export default memo(DatePickerInput);
