import React from 'react';

interface PanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

const StyledTabPanel: React.FC<PanelProps> = ({ children, index, value }) => {
  return (
    <div
      aria-labelledby={`tab-${index}`}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      role="tabpanel"
    >
      {value === index && children}
    </div>
  );
};

export default StyledTabPanel;
