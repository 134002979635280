import React from 'react';
import ModalShell from '../ModalShell';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import classesGTM from '../../config/classesGTM';

interface Props {
  onClickCancel(): void;
  onClickOk(): void;
  open: boolean;
}

const ModalRedirectToAds: React.FC<Props> = ({
  onClickCancel,
  onClickOk,
  open = false
}) => {
  const handleClickOk = () => {
    onClickOk();
  };

  const handleClickCancel = () => {
    onClickCancel();
  };

  return (
    <ModalShell
      labelBtnCancel="fechar"
      labelBtnOK="acessar área de compra"
      onClickCancel={handleClickCancel}
      onClickOK={handleClickOk}
      classTagManagerButtons={{
        btnOk: `btn--blue btn--full ${classesGTM.simulation.redirect_ads}`
      }}
      open={open}
      title="Arquivo exportado com sucesso!"
    >
      <span {...attributeMaskOrUnmask(true)}>
        Para finalizar a compra do Mapa, acesse à Área de Compra e faça o upload
        do arquivo.
      </span>
    </ModalShell>
  );
};

export default ModalRedirectToAds;
