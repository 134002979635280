import { Reducer } from 'redux';
import { FormatsTypes, FormatsAction, FormatsState } from './Formats.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const reducer: Reducer<FormatsState, FormatsAction> = (
  state = defaultData,
  action
): FormatsState => {
  switch (action.type) {
    case FormatsTypes.LOAD_FORMATS_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        error: false,
        loading: false
      };
    }

    case FormatsTypes.LOAD_FORMATS_ERROR: {
      return {
        ...state,
        data: [],
        error: true,
        loading: false
      };
    }
    case FormatsTypes.LOAD_FORMATS_REQUEST: {
      return {
        ...state,
        error: false,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
