import { toast } from 'react-toastify';
import {
  BaseAudienceTypes,
  BaseAudienceResponse,
  BaseAudienceAction
} from './BaseAudience.types';
import { TypeTV } from '../../../services/shared/Api.service';
import { AppThunk } from '../..';
import getBaseAudience from '../../../services/MediaSimulator/Audience.service';

const LoadBaseAudienceLimitsFailure = (typeTV: TypeTV) => ({
  type: BaseAudienceTypes.LOAD_BASE_AUDIENCE_LIMITS_ERROR,
  payload: { typeTV }
});

const LoadBaseAudienceLimitsSuccess = (
  response: BaseAudienceResponse,
  typeTV: TypeTV
) => {
  const payload: BaseAudienceAction = {
    payload: {
      ...response.data,
      typeTV,
      message: response.msg
    },
    type: BaseAudienceTypes.LOAD_BASE_AUDIENCE_LIMITS_SUCCESS
  };

  return payload;
};

const LoadingRequest = (typeTV: TypeTV) => ({
  type: BaseAudienceTypes.LOAD_BASE_AUDIENCE_LIMITS_REQUEST,
  payload: { typeTV }
});

const GetLimitsBaseAudience =
  (typeTV: TypeTV.OPEN | TypeTV.CLOSED): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingRequest(typeTV));
      const result = await getBaseAudience(typeTV);
      if (result.status !== 200) {
        throw new Error(result.msg);
      }
      dispatch(LoadBaseAudienceLimitsSuccess(result, typeTV));
    } catch (error) {
      dispatch(LoadBaseAudienceLimitsFailure(typeTV));
      toast.error(
        'Ops, houve um problema ao tentar verificar o limite da base de audiência.'
      );
    }
  };

export default GetLimitsBaseAudience;
