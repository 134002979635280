export interface AudienceAvarage {
  pc_audiencia: number;
  nm_emissora: string;
}
export interface AudienceFilterAudienceAvaragesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AudienceAvarage[];
  readonly count: number | null;
}

export interface AudienceAvarageResponse {
  data: {
    rows: AudienceAvarage[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAudienceAvaragesType {
  REQUEST = '@audience/payTv/byChannels/audienceAvarages/load',
  SUCESS = '@audience/payTv/byChannels/audienceAvarages/success',
  ERROR = '@audience/payTv/byChannels/audienceAvarages/error',
  CLEAR = '@audience/payTv/byChannels/audienceAvarages/clear'
}

export interface AudienceFilterAudienceAvaragesAction {
  type: AudienceFiltersAudienceAvaragesType;
  payload: Omit<AudienceAvarageResponse, 'status'>;
}
