import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import {
  IAudienceByProgramsRakingsRequestProps,
  IAudienceByProgramsRakingsRequest
} from '../types';
import { LoadAudienceRankingsRequest } from '../../../../../../store/modules/AudienceModule/PayTv/ProgramRanking/Audience.programRanking.actions';

const useAudiencePayTvProgramsRankingData = ({
  target,
  timeRange,
  programmers,
  channelGeneres,
  channels,
  programmingGenders,
  period,
  order,
  limit,
  offset,
  setPeriod
}: IAudienceByProgramsRakingsRequestProps) => {
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const targetRef = useRef(target);
  const timeRangeRef = useRef(timeRange);

  useEffect(() => {
    targetRef.current = target;
    timeRangeRef.current = timeRange;
  }, [target, timeRange]);

  useEffect(() => {
    if (
      programmers &&
      channelGeneres &&
      channels &&
      period &&
      targetRef?.current &&
      timeRangeRef?.current &&
      programmingGenders
    ) {
      const request: IAudienceByProgramsRakingsRequest = {
        programmers,
        channelGeneres,
        channels,
        period,
        target: targetRef.current,
        timeRange: timeRangeRef.current,
        programmingGenders,
        order,
        limit,
        offset
      };
      dispatch(LoadAudienceRankingsRequest(request));
    }
  }, [
    programmers,
    channelGeneres,
    channels,
    period,
    order,
    limit,
    offset,
    audience?.openTv?.targets?.data,
    audience?.openTv?.timeRanges?.data,
    audience?.openTv?.weekDays?.data,
    programmingGenders,
    setPeriod,
    dispatch
  ]);
};

export default useAudiencePayTvProgramsRankingData;
