import { Reducer } from 'redux';
import {
  CommitmentsAction,
  CommitmentsState,
  CommitmentsTypes
} from './Commitments.types';

const defaultData = {
  data: [],
  loading: false,
  error: false,
  count: 0,
  message: ''
};

const targetInitialState: CommitmentsState = {
  ...defaultData
};

const reducer: Reducer<CommitmentsState, CommitmentsAction> = (
  state = targetInitialState,
  action
): CommitmentsState => {
  switch (action?.type) {
    case CommitmentsTypes.LOAD_COMMITMENTS_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.rows || [],
        count: action?.payload?.count || 0,
        error: false,
        loading: false,
        message: action?.payload?.message || ''
      };
    }
    case CommitmentsTypes.LOAD_COMMITMENTS_ERROR: {
      return {
        ...state,
        data: [],
        error: false,
        loading: true,
        message: action?.payload?.message || ''
      };
    }
    case CommitmentsTypes.LOAD_COMMITMENTS_REQUEST: {
      return {
        ...state,
        loading: true
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
