import { ThunkAction, Action } from '@reduxjs/toolkit';
import createStore from './Create.store';

const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type ApplicationState = ReturnType<typeof store.getState>;

export default store;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  ApplicationState,
  unknown,
  Action<string>
>;
