import './SubMenuTabs.scss';
import MenuNav from '../../../Menu/Nav/MenuNav';

interface TabItem {
  icon: string;
  itemId: number;
  title: string;
  path: string;
  visibled: boolean;
  classes?: {
    tagManager: string;
  };
  onClick?: () => void;
}

interface Props {
  items: TabItem[];
  handleClick?: (id: any) => void;
}

const SubMenuTabs = ({ items, handleClick }: Props) => {
  return (
    <div className="sub-menu-wrapper">
      <MenuNav
        classes={{
          nav: 'sub-menu mb-4',
          item: {
            li: 'sub-menu__item',
            selected: 'sub-menu--selected',
            icon: 'sub-menu__icon',
            button: 'sub-menu__item__button'
          }
        }}
        itemsMenu={items}
        onClick={handleClick}
      />
      <hr className="sub-menu__separator" />
    </div>
  );
};

SubMenuTabs.defaultProps = {
  handleClick: undefined
};

export default SubMenuTabs;
