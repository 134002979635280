import * as Yup from 'yup';
import { FiltersData } from '../../store/modules/Filters/Filters.types';
import { TypeTV } from '../../services/shared/Api.service';

const schemaOpenedTV = Yup.object().shape({
  channels: Yup.array(
    Yup.object().shape({
      channelId: Yup.number().required(),
      description: Yup.string().required()
    })
  )
    .min(1, 'Obrigatório informar no mínimo 1 canal.')
    .required('Canais são obrigatórios.'),
  dates: Yup.object()
    .shape({
      audience: Yup.object().shape({
        from: Yup.date().when(
          'typeTV',
          (typeTv: TypeTV.CLOSED | TypeTV.OPEN) => {
            return Yup.date()
              .typeError(
                typeTv === TypeTV.OPEN
                  ? 'Informe uma data válida.'
                  : 'Período fechado obrigatório para tv fechada.'
              )
              .required('Data "de" do periodo audiência é obrigatória.');
          }
        ),
        to: Yup.date().when('typeTV', (typeTv: TypeTV.CLOSED | TypeTV.OPEN) => {
          return Yup.date()
            .typeError(
              typeTv === TypeTV.OPEN
                ? 'Informe uma data válida.'
                : 'Período fechado obrigatório para tv fechada.'
            )
            .required('Data "até" de audiência é obrigatória.');
        })
      }),
      buy: Yup.date()
        .typeError('Informe uma data válida.')
        .required('Data de compra é obrigatória.'),
      campaign: Yup.object().shape({
        from: Yup.date()
          .typeError('Informe uma data válida.')
          .required('Data "de" da campanha é obrigatória.'),
        to: Yup.date()
          .typeError('Informe uma data válida.')
          .required('Data "até" da campanha é obrigatória.')
      })
    })
    .required(),
  market: Yup.object()
    .shape({
      name: Yup.string().required(),
      signal: Yup.string().required()
    })
    .nullable()
    .required('Praça é obrigatória.'),
  targets: Yup.array(Yup.string())
    .max(2, 'Selecione no máximo 2 targets (primário e secundário).')
    .min(1, 'Selecione no mínimo 1 targets (primário e secundário).')
    .required('Selecione targets primário e secundário.'),
  typeTV: Yup.string().required('Selecione uma opção de TV.')
});

const validateFields = (formData: Partial<FiltersData>) => {
  return schemaOpenedTV.validate(formData, {
    abortEarly: false
  });
};

export default validateFields;
