import { PointTooltipProps, ResponsiveLine } from '@nivo/line';
import { LineChartData } from './types';
import MetricCard from '../MetricCard';
import { formatterNumberShort } from '../../../../helpers/Formatters';

interface Props {
  data: LineChartData[];
  header: string;
}
const customColors = [
  '#1EC370',
  '#F44DFC',
  '#FDA529',
  '#FC343A',
  '#20B9FC',
  '#5D43FA',
  '#000000'
];

// enum com os ids encurtados
const EncurtedIds: { [key: string]: string } = {
  'TOTAL LI...': 'TOTAL LIGADOS'
};

const customTooltip = ({ point }: PointTooltipProps) => {
  return (
    <div className="collumn-chart__tooltip">
      <div className="collumn-chart__tooltip__title">
        {EncurtedIds?.[point.serieId] ?? point.serieId}
      </div>
      <div className="collumn-chart__tooltip__text">{`${
        point.data.x
      }: ${formatterNumberShort(Number(point?.data?.y) ?? 0)}`}</div>
    </div>
  );
};

export default function LineChartAbs({ data, header }: Props) {
  const reversedData = [...data].reverse();
  const colors = reversedData.map((e) => e.color);
  return (
    <MetricCard
      classes={{ content: 'fill-height d-flex flex-column align-center' }}
      header={header}
      height="450px"
    >
      <ResponsiveLine
        data={reversedData}
        margin={{ top: 50, right: 110, bottom: 100, left: 80 }}
        xScale={{ type: 'point' }}
        colors={colors || customColors}
        crosshairType="bottom"
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false
        }}
        yFormat=" >-.0f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendOffset: -40,
          legendPosition: 'middle',
          truncateTickAt: 0,
          format: (value) => `${formatterNumberShort(value)}`
        }}
        tooltip={customTooltip}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'color', modifiers: [] }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableTouchCrosshair
        useMesh
        enableGridX={false}
        theme={{
          axis: {
            ticks: {
              text: {
                fontSize: 12,
                fontFamily: 'inherit',
                fontWeight: 600,
                fill: '#77808B'
              }
            }
          },
          legends: {
            text: {
              fontSize: 12,
              fontFamily: 'inherit',
              fontWeight: 520,
              fill: '#5A6066'
            }
          },
          grid: {
            line: {
              stroke: '#e0e0e0',
              strokeDasharray: '4 4'
            }
          }
        }}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 60,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 20,
            itemOpacity: 1,
            symbolSize: 14,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
      />
    </MetricCard>
  );
}

LineChartAbs.defaultProps = {};
