import { Reducer } from 'redux';
import paths from '../../../../routes/paths';
import {
  CompetitiveNavAction,
  CompetitiveNavState,
  CompetitiveNavTypes
} from './Competitive.navigation.types';

const { childrens } = paths.home.childrens.competitive;

const targetInitialState: CompetitiveNavState = {
  tab: childrens.consolidated.tab,
  filters: undefined
};

const reducer: Reducer<CompetitiveNavState, CompetitiveNavAction> = (
  state = targetInitialState,
  action
): CompetitiveNavState => {
  switch (action.type) {
    case CompetitiveNavTypes.COMPETITIVE_CHANGE_TAB: {
      return {
        ...state,
        tab: action.payload?.tab || 0
      };
    }
    case CompetitiveNavTypes.COMPETITIVE_SAVE_FILTERS_TAB: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
