export enum AgenciesTypes {
  LOAD_AGENCIES_REQUEST = '@agencies/LOAD_AGENCIES_REQUEST',
  LOAD_AGENCIES_SUCCESS = '@agencies/LOAD_AGENCIES_SUCCESS',
  LOAD_AGENCIES_ERROR = '@agencies/LOAD_AGENCIES_ERROR'
}

export interface Agency {
  oi_agencia: number;
  oi_endereco_agencia: number;
  me_filial_agencia: string;
  nu_cgc_cpf: string;
  me_agencia: string;
  nome_agencia: string;
}

export interface AgenciesAction {
  type: AgenciesTypes;
  payload: {
    rows?: Agency[] | [];
    count?: number;
    message?: string;
  };
}

export interface AgenciesResponse {
  data: {
    rows: Agency[];
    count: number;
  };
  status: number;
  msg: string;
}

export interface AgenciesState {
  readonly data: Agency[] | [];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
  readonly message: string;
}
