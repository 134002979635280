import React, { useRef } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import ModalShell from '../ModalShell';
import Input from '../Form/Input';
import handleErrors from '../../helpers/HandleErrors';
import FormContainer from '../Form/FormContainer/FormContainer';
import { SimulationProps } from '../../store/modules/Simulation/Simulation.types';
import paths from '../../routes/paths';
import { useAppSelector } from '../../hooks';
import { attributeMaskOrUnmask } from '../../helpers/Utils';
import classesGTM from '../../config';

export interface ModalSimulationData {
  name: string;
}

interface Props {
  dataModal?: SimulationProps;
  onClickCancel?(): void;
  onClickInitSimulation?(namaSimulation: string): void;
  label: string;
  open: boolean;
}

const ModalSimulation: React.FC<Props> = ({
  onClickCancel,
  onClickInitSimulation,
  label,
  open = false,
  dataModal
}) => {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);
  const { simulation } = useAppSelector((state) => state);

  const handleSubmit = async (formData: SimulationProps) => {
    const schema = Yup.object().shape({
      name: Yup.string().max(50, 'Informe um nome com no máximo 50 caracteres')
    });

    try {
      formRef.current?.setErrors({});

      await schema.validate(formData, {
        abortEarly: false
      });

      const data = {
        ...dataModal,
        ...formData,
        quantityScenarios: simulation.scenarios.length
      };

      if (onClickInitSimulation) onClickInitSimulation(data.name);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = handleErrors(error);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  };

  const handleClickOk = () => {
    if (formRef?.current) {
      formRef.current.submitForm();
    }
  };

  const handleClickCancel = () => {
    if (!dataModal?.uuid) navigate(paths.home.childrens.simulations);
    if (onClickCancel) onClickCancel();
  };

  return (
    <ModalShell
      classTagManagerButtons={{
        btnOk: !dataModal?.uuid ? classesGTM.home.initSimulation : undefined
      }}
      labelBtnOK="duplicar simulação"
      onClickCancel={handleClickCancel}
      onClickOK={handleClickOk}
      open={open}
      title="Duplicar Simulação"
    >
      <span>
        Tem certeza que deseja duplicar a simulação <b>{label}</b>?
      </span>
      <FormContainer
        data-testid="form"
        formRef={formRef}
        fullWidth
        initialData={dataModal}
        onSubmit={handleSubmit}
      >
        <div className="input-wrapper">
          <div className="input-wrapper__divisor__column">
            <Input
              autoComplete="name"
              className="mt-2"
              data-testid="name"
              inputProps={{ maxLength: 50 }}
              label="Nome"
              name="name"
              placeholder={`${label}_copy`}
              required
              {...attributeMaskOrUnmask(true)}
            />
          </div>
        </div>
      </FormContainer>
    </ModalShell>
  );
};

ModalSimulation.defaultProps = {
  dataModal: undefined,
  onClickCancel: () => undefined,
  onClickInitSimulation: () => undefined
};

export default ModalSimulation;
