import { Reducer } from 'redux';
import omitProps from 'lodash/omit';
import { sortDate } from '../../../helpers/Sort';
import { getUuid } from '../../../helpers/UUID';
import { calcMetricsOfInsertions } from '../../../helpers/CalcsSimulation';
import {
  PlanningAction,
  PlanningState,
  PlanningTypes,
  PlanningRow
} from './Planning.types';

export const planningInitialState: PlanningState[] = [
  {
    hideDaysOfWeek: false,
    planningPrograms: [],
    target: '',
    message: '',
    error: false,
    loading: false,
    sceneryId: '',
    totalAmount: 0,
    totalNegotiated: 0,
    avgDiscount: 0,
    totalInsertions: 0,
    totalGrpOrTrp: 0,
    sortedColumns: []
  }
];

const reducer: Reducer<PlanningState[], PlanningAction> = (
  state = planningInitialState,
  action
): PlanningState[] => {
  switch (action.type) {
    case PlanningTypes.RESET_PLANNING: {
      return planningInitialState;
    }
    case PlanningTypes.SET_HIDE_DAYS_OF_WEEK: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            hideDaysOfWeek: !!action.payload.hideDaysOfWeek,
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }
      return state;
    }
    case PlanningTypes.SET_PLANNING_PROGRAMS: {
      if (action.payload.planningPrograms) {
        const programsNoCurrentScenery = state.filter(
          (s) => s.sceneryId !== action.payload.sceneryId
        );

        const currentState =
          state.find((s) => s.sceneryId === action.payload.sceneryId) ||
          planningInitialState[0];

        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            planningPrograms: action.payload.planningPrograms.sort((a, b) =>
              sortDate(
                a.addAt instanceof Date ? a.addAt : new Date(a.addAt),
                b.addAt instanceof Date ? b.addAt : new Date(b.addAt)
              )
            ),
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }

      return state;
    }
    case PlanningTypes.ADD_PLANNING_PROGRAM: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState =
        state.find((s) => s.sceneryId === action.payload.sceneryId) ||
        planningInitialState[0];

      const { program, insertionsByDay } = action.payload;
      if (program && insertionsByDay) {
        const metrics = calcMetricsOfInsertions(
          program.metricsPerTarget,
          program.pricesByDate,
          insertionsByDay,
          0,
          currentState.target
        );

        const newProgram: PlanningRow = {
          ...omitProps(program, 'type'),
          type: 'planningRow',
          id: getUuid(),
          format: 'comercial break',
          insertions: 0,
          amount: 0,
          negotiatedValue: 0,
          discount: 0,
          secondary: '30s',
          totalImpacts: metrics.totalImpacts,
          addAt: new Date(),
          metricsByCompetency: metrics.metricsByCompetency,
          insertionsByDay
        };

        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            planningPrograms: [...currentState.planningPrograms, newProgram],
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }

      return state;
    }
    case PlanningTypes.COPY_PLANNING_PROGRAM: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      const program = action.payload?.planningRow;
      if (currentState && program) {
        const newProgram = { ...program, id: getUuid(), addAt: new Date() };

        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            planningPrograms: [...currentState.planningPrograms, newProgram]
          }
        ];
      }

      return state;
    }
    case PlanningTypes.SELECT_TARGET_PLANNING: {
      const { target } = action.payload;

      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState =
        state.find((s) => s.sceneryId === action.payload.sceneryId) ||
        planningInitialState[0];

      if (target) {
        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            target,
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }

      return state;
    }

    case PlanningTypes.CLEAR_PLANNING_PROGRAMS: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            planningPrograms: [],
            target: '',
            hideDaysOfWeek: false,
            loading: false
          }
        ];
      }

      return state;
    }
    case PlanningTypes.REMOVE_PLANNING_PROGRAMS_SCENERY: {
      return state.filter((s) => s.sceneryId !== action.payload.sceneryId);
    }
    case PlanningTypes.SET_LOADING_PRICE_PROGRAM: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState) {
        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            loading: action.payload.loading || false,
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }

      return state;
    }
    case PlanningTypes.SET_TOTALIZERS: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (
        currentState &&
        action.payload.totalAmount !== undefined &&
        action.payload.totalNegotiated !== undefined &&
        action.payload.avgDiscount !== undefined &&
        action.payload.totalGrpOrTrp !== undefined &&
        action.payload.totalInsertions !== undefined
      ) {
        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            totalAmount: action.payload.totalAmount,
            totalNegotiated: action.payload.totalNegotiated,
            avgDiscount: action.payload.avgDiscount,
            totalInsertions: action.payload.totalInsertions,
            totalGrpOrTrp: action.payload.totalGrpOrTrp,
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }

      return state;
    }
    case PlanningTypes.SET_PLANNING_SORTED_COLUMNS: {
      const programsNoCurrentScenery = state.filter(
        (s) => s.sceneryId !== action.payload.sceneryId
      );

      const currentState = state.find(
        (s) => s.sceneryId === action.payload.sceneryId
      );

      if (currentState && action?.payload?.sortedColumns) {
        return [
          ...programsNoCurrentScenery,
          {
            ...currentState,
            sortedColumns: action.payload.sortedColumns,
            sceneryId: action.payload.sceneryId || ''
          }
        ];
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
export default reducer;
