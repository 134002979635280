import { format } from 'date-fns';
import { GridPaginationHeader } from '../../components/GridPagination';
import ActionColumn from '../../components/ActionColumn';
import close from '../../assets/images/remove.svg';
import edit from '../../assets/images/edit_b.svg';
import copy from '../../assets/images/copy-gray.svg';
import { Simulation } from '../../store/modules/Simulations/Simulations.types';
import { GridColumn } from '../../components/GridPagination/types';
import { classMaskOrUnmask } from '../../helpers/Utils';
import classesGTM from '../../config';

export default function useSimulations(
  handleUpdate: (row: Simulation) => void,
  handleCopy: (row: Simulation) => void,
  handleDelete: (row: Simulation) => void
) {
  const columns: GridColumn<Simulation>[] = [
    {
      key: 'name',
      name: 'Nome',
      sortable: true,
      resizable: true,
      cellClass: `${classMaskOrUnmask(true)} grid-col__separate--none`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      formatter: ({ row }) => {
        const date = row.updatedAt
          ? format(new Date(row.updatedAt), 'dd/MM/yyyy HH:mm:ss')
          : '';
        return row.isDraft ? (
          <span>
            {row.name}{' '}
            <span className="opacity-5 text-italic text-1">
              [Rascunho salvo em {date}]
            </span>
          </span>
        ) : (
          <span>{row.name}</span>
        );
      }
    },
    {
      key: 'createdAt',
      name: 'Criado em',
      sortable: true,
      resizable: true,
      width: 250,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: 'grid-col__separate--none',
      headerRenderer: (props) =>
        GridPaginationHeader({
          title: 'Criado em',
          classes: { title: 'plim-violet ' },
          ...props
        }),
      formatter: ({ row }) => {
        const date = new Date(row.createdAt);
        return date ? (
          <span className="plim-violet">
            {format(date, 'dd/MM/yyyy HH:mm:ss')}
          </span>
        ) : null;
      }
    },
    {
      key: 'updatedAt',
      name: 'Modificado em',
      sortable: true,
      resizable: true,
      width: 250,
      cellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`,
      headerCellClass: 'grid-col__separate--none',
      headerRenderer: (props) =>
        GridPaginationHeader({
          title: 'Modificado em',
          classes: { title: 'plim-violet ' },
          ...props
        }),
      formatter: ({ row }) => {
        const date = new Date(row.updatedAt);
        return date && !row.isDraft ? (
          <span className="plim-violet">
            {format(date, 'dd/MM/yyyy HH:mm:ss')}
          </span>
        ) : null;
      }
    },
    {
      key: 'action',
      name: 'Ações',
      width: 100,
      formatter: ({ row }) => {
        const isDraft = row?.isDraft;

        return (
          <>
            <ActionColumn
              classes={{ tagManager: classesGTM.home.editSimulation }}
              icon={edit}
              onClick={() => handleUpdate(row)}
            />
            {!isDraft && (
              <ActionColumn
                classes={{ tagManager: classesGTM.home.copySimulation }}
                icon={copy}
                onClick={() => handleCopy(row)}
              />
            )}
            <ActionColumn
              classes={{ tagManager: classesGTM.home.deleteSimulation }}
              icon={close}
              onClick={() => handleDelete(row)}
            />
          </>
        );
      },
      cellClass: `${classMaskOrUnmask(false)} action_column__container`,
      headerCellClass: `${classMaskOrUnmask(false)} grid-col__separate--none`
    }
  ];

  return { columns };
}
