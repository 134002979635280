import { apiV1 } from '../../shared/Api.service';
import { IAudienceByChannelsRadiographyRequestParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AvarageAudienceByTimeRangeResponse } from '../../../store/modules/AudienceModule/PayTv/byChannels/Radiography/AvarageAudienceByTimeRanges/Audience.avarageAudienceByTimeRanges.types';

const path = '/audience/pay-tv/data';

interface IProps extends IAudienceByChannelsRadiographyRequestParams {
  fields: string[];
  table: string;
}

export default async function getPayTvAvarageAudienceByTimeRangesByChannels({
  target,
  timeRanges,
  weekDays,
  programmingGenders,
  channel,
  channelsNotIn,
  period,
  fields,
  table
}: IProps): Promise<AvarageAudienceByTimeRangeResponse> {
  const { data } = await apiV1.get<AvarageAudienceByTimeRangeResponse>(path, {
    params: {
      fields,
      table,
      targets: [target],
      timeRanges: timeRanges ?? ['Total dia (06h-30H)'],
      weekDays,
      programmingGenders: programmingGenders ?? ['TODOS'],
      channels: [channel],
      periods: [period],
      programs: ['TODOS'],
      channelsNotIn
    }
  });
  return data;
}
