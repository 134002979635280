export interface Period {
  tp_dado: string;
  ds_periodo: string;
}
export interface AudienceFilterPeriodsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Period[];
  readonly count: number | null;
}

export interface PeriodResponse {
  data: {
    rows: Period[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersPeriodsType {
  REQUEST = '@audience/payTv/periods/load',
  SUCESS = '@audience/payTv/periods/success',
  ERROR = '@audience/payTv/periods/error',
  CLEAR = '@audience/payTv/periods/clear'
}

export interface AudienceFilterPeriodsAction {
  type: AudienceFiltersPeriodsType;
  payload: Omit<PeriodResponse, 'status'>;
}
