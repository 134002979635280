import React from 'react';
import './Unauthorized.scss';
import imageError from '../../../assets/images/logo-globoplim.svg';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import loginLocalStorage from '../LoginWrapper/Login.LocalStorage';

const {
  REACT_APP_UNAUT_ERROR_TITLE: title,
  REACT_APP_UNAUT_ERROR_SUBTITLE: subtitle,
  REACT_APP_UNAUT_ERROR_STEPS: step,
  REACT_APP_GLOBO_SERVICE: service
} = process.env;

const Unauthorized: React.FC<any> = () => {
  // se o usuário vier do backstage, ele aparece essa tela de unauthorized, se não aparece outra
  const loginStore = loginLocalStorage();
  const authenticateMethod = loginStore.authenticateMethod.get();

  return authenticateMethod !== 'backstage' ? (
    <div
      id="container-unauthorized__error"
      data-testid="page-unauthorized"
      {...attributeMaskOrUnmask(false)}
    >
      <img alt="Erro desconhecido" id="error-img" src={imageError} />
      <div className="container-unauthorized__error__content">
        <div>
          <h1 id="container-unauthorized__error__agency__title">:-(</h1>
          <h1 id="container-unauthorized__error__agency__title">
            Algo deu errado
          </h1>
        </div>
        <h3>
          Não é possível completar a sua solicitação no momento. <br />
          Por favor, tente novamente em alguns instantes.
        </h3>
        <div className="footer" />
        <button type="button" onClick={() => window.history.go(-1)}>
          Voltar
        </button>
      </div>
    </div>
  ) : (
    <div
      className="container-unauthorized__error"
      data-testid="page-unauthorized"
      {...attributeMaskOrUnmask(false)}
    >
      <div className="header">
        <h1>{title}</h1>
        <p>
          <span>{subtitle}</span>
        </p>
        <p>
          Verifique os dados e tente novamente. Ou, solicite o seu acesso a
          plataforma através do &nbsp;
          <a href={service} rel="noopener noreferrer" target="_blank">
            Globo Service
          </a>
          : &nbsp;
          <br />
          {step}
        </p>
      </div>
      <img
        alt="Usuário não encontrado"
        className="error-img"
        src={imageError}
      />
      <div className="footer" />
    </div>
  );
};

export default Unauthorized;
