import { isArray } from 'lodash';
import { apiV1 } from '../../../shared/Api.service';
import { PercentageResponse } from '../../../../store/modules/AudienceModule/FreeTVRegularConc/GraphsPercentage/Audience.percentage.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/open-tv/regular-plazas/graphsConc/getPercentages';

export default async function getOpenTvRegularPlazasPercentages({
  target,
  timeRange,
  weekDays,
  programmingGenders,
  channels,
  plaza,
  year
}: IAudienceByChannelsComparativeFilterParams): Promise<PercentageResponse> {
  const { data } = await apiV1.get<PercentageResponse>(path, {
    params: {
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value,
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value,
      plaza: plaza?.value,
      channels: channels?.map((channel) => channel.value),
      year: year?.value
    }
  });
  return data;
}
