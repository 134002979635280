import { Reducer } from 'redux';
import {
  CompetitiveFilterMarketsAction,
  CompetitiveFilterMarketsState,
  CompetitiveFiltersMarketsType
} from './Competitive.markets.types';

const marketsInitialState: CompetitiveFilterMarketsState = {
  error: false,
  loading: true,
  data: [],
  count: 0
};

const reducer: Reducer<
  CompetitiveFilterMarketsState,
  CompetitiveFilterMarketsAction
> = (state = marketsInitialState, action): CompetitiveFilterMarketsState => {
  switch (action.type) {
    case CompetitiveFiltersMarketsType.SUCESS:
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };

    case CompetitiveFiltersMarketsType.ERROR:
      return {
        loading: false,
        error: true,
        data: [],
        count: 0
      };

    case CompetitiveFiltersMarketsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
