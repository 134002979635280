enum OpenTVColors {
  'GLOBO' = '#0000ff',
  'RECORD' = '#ff0000',
  'SBT' = '#008000',
  'TV BAND' = '#ff33cc',
  'REDE TV' = '#948a54',
  'CULTURA' = '#003f5c',
  'OAP' = '#660066',
  'OCN' = '#f58f3d',
  'TOTAL LIGADOS ESPECIAL' = '#000000',
  'TL' = '#000000',
  'OAB' = '#808080',
  'TV PAGA' = '#00b0f0',
  'NI' = '#2E4057'
}

export default OpenTVColors;
