import React from 'react';

import PanelContainer from '../../../../../Competitive/PanelContainer';

import AudiencebyChannelsComparativeFilter from './AudiencePayTvChannelsComparativeFilter';

import KantarDescription from '../../../../KantarDescription/KantarDescription';
import { ICommonsFilter } from '../types';
import { OptionsList } from '../../../../../Form/types';
import useAudiencePayTvChannelsComparativeData from './hooks/useAudiencePayTvChannelsComparativeData';
import BarChartsCard from '../../../../Charts/ChartsCard/BarChartsCard';
import {
  formatterNumber,
  formatterNumberShort
} from '../../../../../../helpers/Formatters';

import LineChartByPayTvChannels from '../../../../Charts/LineChart/LineChartByPayTvChannels';
import Loading from '../../../../../Loading';
import { useAppSelector } from '../../../../../../hooks';
import AlertInfoCard from '../../../../Cards/AlertInfoCard';

const AudienceByPayTvChannelsComparative: React.FC<ICommonsFilter> = ({
  target,
  timeRanges,
  weekDays,
  programmingGenders,
  newFilter,
  setNewFilter
}) => {
  const { audience } = useAppSelector((state) => state);
  const [programmers, setProgrammers] = React.useState<
    OptionsList[] | undefined
  >();
  const [channelGeneres, setChannelGeneres] = React.useState<
    OptionsList[] | undefined
  >();
  const [channels, setChannels] = React.useState<OptionsList[] | undefined>();
  const [year, setYear] = React.useState<OptionsList | undefined>();
  const {
    audienceEvolutionData,
    audienceAvaragesData,
    _shareAvaragesData,
    audienceAbsolutesData,
    rangeAvaragesData
  } = useAudiencePayTvChannelsComparativeData({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    programmers,
    channelGeneres,
    channels,
    year
  });

  if (
    !audience?.payTv?.byChannels?.audienceEvolutions?.data?.length &&
    !audience?.payTv?.byChannels?.audienceEvolutions?.error &&
    !audience?.payTv?.byChannels?.absoluteAudience?.data?.length &&
    !audience?.payTv?.byChannels?.absoluteAudience?.error &&
    !audience?.payTv?.byChannels?.avarageAudience?.data?.length &&
    !audience?.payTv?.byChannels?.avarageAudience?.error &&
    !audience?.payTv?.byChannels?.range?.data?.length &&
    !audience?.payTv?.byChannels?.range?.error &&
    !audience?.payTv?.byChannels?.share?.data?.length &&
    !audience?.payTv?.byChannels?.share?.error
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudiencebyChannelsComparativeFilter
          {...{
            programmers,
            channelGeneres,
            channels,
            year,
            setProgrammers,
            setChannelGeneres,
            setChannels,
            setYear,
            newFilter,
            setNewFilter,
            target,
            timeRanges,
            weekDays,
            programmingGenders
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  if (
    audience?.payTv?.byChannels?.audienceEvolutions?.loading &&
    audience?.payTv?.byChannels?.absoluteAudience?.loading &&
    audience?.payTv?.byChannels?.avarageAudience?.loading &&
    audience?.payTv?.byChannels?.range?.loading &&
    audience?.payTv?.byChannels?.share?.loading
  ) {
    return (
      <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
        <AudiencebyChannelsComparativeFilter
          {...{
            programmers,
            channelGeneres,
            channels,
            year,
            setProgrammers,
            setChannelGeneres,
            setChannels,
            setYear,
            newFilter,
            setNewFilter,
            target,
            timeRanges,
            weekDays,
            programmingGenders
          }}
        />
        <Loading
          classes={{
            container:
              'loading-container__relative loading-container__min-height'
          }}
        />
      </PanelContainer>
    );
  }

  const isCurrentYearSelected = () => {
    return `${new Date().getFullYear()}` === `${year?.label}`;
  };

  return (
    <PanelContainer className="panel-container__flex panel-container__border-all panel-container__margin-bottom">
      <AudiencebyChannelsComparativeFilter
        {...{
          programmers,
          channelGeneres,
          channels,
          year,
          setProgrammers,
          setChannelGeneres,
          setChannels,
          setYear,
          newFilter,
          setNewFilter,
          target,
          timeRanges,
          weekDays,
          programmingGenders
        }}
      />
      <>
        {isCurrentYearSelected() && (
          <AlertInfoCard text="O período selecionado exibe dados acumulados do ano até o último mês completo." />
        )}
      </>
      {audienceEvolutionData &&
      audienceEvolutionData?.length &&
      audienceEvolutionData.length > 0 ? (
        <LineChartByPayTvChannels
          header={`Evolução de audiência `}
          data={audienceEvolutionData}
          formatter={(val) => `${formatterNumber(val, { fractionDigits: 3 })}%`}
        />
      ) : null}
      <BarChartsCard
        dataComplement="% do total"
        header="Média de audiência no ano"
        height="480px"
        type="collumn-scroll"
        rawData={audienceAvaragesData}
        padding={0.01}
        formatter={(val) => formatterNumber(val, { fractionDigits: 3 })}
        valueFormat={(val) => `${formatterNumber(val, { fractionDigits: 3 })}%`}
      />
      {/*       <BarChartsCard
        dataComplement="% do total"
        header="Média de share no ano"
        type="collumn-scroll"
        height="480px"
        rawData={shareAvaragesData}
        padding={0.01}
        formatter={(val) => formatterNumber(val, { fractionDigits: 3 })}
        valueFormat={(val) =>
          `${formatterNumber(val, { fractionDigits: 3 })}%`
        }
      /> */}
      <BarChartsCard
        dataComplement="% do total"
        header="Alcançados por minuto no período (média)"
        type="collumn-scroll"
        height="480px"
        rawData={rangeAvaragesData}
        padding={0.01}
        formatter={(val) => formatterNumber(val, { fractionDigits: 3 })}
        valueFormat={(val) => `${formatterNumber(val, { fractionDigits: 3 })}%`}
      />
      {audienceAbsolutesData &&
      audienceAbsolutesData?.length &&
      audienceAbsolutesData?.length > 0 ? (
        <LineChartByPayTvChannels
          header="Audiência absoluta por mês"
          data={audienceAbsolutesData}
          formatter={(val) =>
            `${formatterNumberShort(val, true, false, 3)}${formatterNumberShort(
              val,
              false,
              true
            )}`
          }
        />
      ) : null}
      <KantarDescription />
    </PanelContainer>
  );
};

export default AudienceByPayTvChannelsComparative;
