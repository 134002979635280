import './MetricChart.scss';
import React from 'react';
import MetricChartContainer from './MetricChartContainer';
import MetricChartHeader from './MetricChartHeader';

interface Props {
  header?: string;
  highlightHeader?: string;
  renderGraph: () => React.ReactNode;
  classes?: {
    container?: string;
    header?: string;
  };
}

export default function MetricChart({
  header,
  highlightHeader,
  renderGraph,
  classes
}: Props) {
  return (
    <MetricChartContainer className={classes?.container}>
      {header ? (
        <MetricChartHeader
          className={classes?.header}
          header={header}
          highlightHeader={highlightHeader}
        />
      ) : null}
      {renderGraph()}
    </MetricChartContainer>
  );
}

MetricChart.defaultProps = {
  classes: undefined,
  header: undefined,
  highlightHeader: undefined
};
