import './SubHeaderUsers.scss';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface SubHeaderUsersProps {
  onClick(): void;
}

const SubHeaderUsers = ({ onClick }: SubHeaderUsersProps) => {
  return (
    <div {...attributeMaskOrUnmask(false)}>
      <h2 className="subheader__title">Usuários</h2>
      <button className="btn btn--med" onClick={onClick} type="button">
        novo usuário
      </button>
    </div>
  );
};

export default SubHeaderUsers;
