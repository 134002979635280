import { AllParams } from '../../../../services/shared/types';

export enum CompetitiveCommercialTypes {
  LOAD_COMMERCIAL_REQUEST = '@competitive/commercial_types/request',
  LOAD_COMMERCIAL_SUCCESS = '@competitive/commercial_types/success',
  LOAD_COMMERCIAL_ERROR = '@competitive/commercial_types/error'
}

export interface CompetitiveCommercial {
  commercialType: string;
}

export interface CompetitiveCommercialAction {
  type: CompetitiveCommercialTypes;
  payload: {
    rows: CompetitiveCommercial[];
    count: number;
    message: string;
  };
}

export interface CompetitiveCommercialState {
  readonly data: CompetitiveCommercial[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly count: number;
}

export interface CompetitiveCommercialResponse {
  data: {
    rows: CompetitiveCommercial[];
    count: number;
  };
  status: number;
  msg: string;
}
export interface CompetitiveCommercialQueryParams extends AllParams {
  sectors?: string[];
  clients?: string[];
  channelMnemonics?: string[];
  brands?: string[];
  categories?: string[];
}
