import { toast } from 'react-toastify';
import { AppThunk } from '../../..';
import {
  CompetitiveConsolidatedTokenResponse,
  CompetitiveConsolidatedTypes,
  ConsolidatedMetricsAction,
  ConsolidatedMetricsResponse
} from './Competitive.consolidated.types';
import {
  getCompetitiveMetricsResult,
  runConsolidatedMetricsJob,
  getConsolidatedMetricsStatus
} from '../../../../services/Competitive/Competitive.consolidated.service';
import {
  CompetitiveData,
  CompetitiveMetricsJobStatus,
  MetricsParams
} from '../Shared/Competitive.shared.types';
import {
  controllerActionsStatusMetrics,
  propsToastInfo,
  propsToastSuccess,
  updateToast
} from '../Shared/MetricsStatus.share.actions';
import { ApiErrorResponse } from '../../../../services/shared/Api.service';

const ConsolidatedMetricsError = (error: string) => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.CONSOLIDATED_METRICS_ERROR,
    payload: {
      progress: 0,
      token: '',
      message: error
    }
  };

  return payload;
};

const ClearConsolidatedMetrics = () => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.CLEAR_CONSOLIDATED_METRICS,
    payload: {
      progress: 0,
      token: '',
      message: ''
    }
  };

  return payload;
};

const LoadingConsolidatedMetricsStatus = (
  response: CompetitiveConsolidatedTokenResponse
) => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.STATUS_CONSOLIDATED_METRICS_REQUEST,
    payload: {
      progress: 0,
      token: response.data.row.jobToken,
      message: response.msg
    }
  };

  return payload;
};

const LoadConsolidatedMetricsStatisSuccess = (
  response: CompetitiveMetricsJobStatus,
  token: string
) => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.STATUS_CONSOLIDATED_METRICS_SUCCESS,
    payload: {
      progress: response.data.row.progress,
      token,
      message: response.msg
    }
  };

  return payload;
};

const LoadingConsolidatedMetrics = () => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.RESULT_CONSOLIDATED_METRICS_REQUEST,
    payload: {
      progress: 100,
      token: '',
      message: ''
    }
  };

  return payload;
};

const LoadingConsolidatedMetricsSuccess = (
  response: ConsolidatedMetricsResponse
) => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.RESULT_CONSOLIDATED_METRICS_SUCCESS,
    payload: {
      progress: 100,
      token: '',
      message: response.msg,
      metrics: response
    }
  };

  return payload;
};

const LoadingConsolidatedMetricsRun = () => {
  const payload: ConsolidatedMetricsAction = {
    type: CompetitiveConsolidatedTypes.RUN_CONSOLIDATED_METRICS_REQUEST,
    payload: {
      progress: 0,
      token: '',
      message: ''
    }
  };

  return payload;
};

const LoadRunConsolidatedMetricsRequest =
  (params: CompetitiveData): AppThunk =>
  async (dispatch) => {
    const toastId = toast.info('Processando as métricas...', propsToastInfo);

    try {
      dispatch(LoadingConsolidatedMetricsRun());

      const response = await runConsolidatedMetricsJob(params);

      dispatch(LoadingConsolidatedMetricsStatus(response));

      const token: MetricsParams = {
        jobToken: response.data.row.jobToken
      };

      await controllerActionsStatusMetrics(
        response.data.row.jobToken,
        toastId,
        {
          request: () => getConsolidatedMetricsStatus(token),
          success: (...args) => {
            dispatch(LoadConsolidatedMetricsStatisSuccess(...args));
          }
        }
      );

      dispatch(LoadingConsolidatedMetrics());

      updateToast(toastId, 0, 'Aplicando os cálculos...');

      const metrics = await getCompetitiveMetricsResult(token);

      updateToast(toastId, 0, 'Finalizado!', propsToastSuccess);

      dispatch(LoadingConsolidatedMetricsSuccess(metrics));
    } catch (error) {
      toast.dismiss(toastId);
      dispatch(
        ConsolidatedMetricsError(error instanceof Error ? error.message : '')
      );
      toast.error(
        (error as ApiErrorResponse)?.msg ||
          'Ops, houve um problema ao tentar carregar as métricas.'
      );
    }
  };

export { LoadRunConsolidatedMetricsRequest, ClearConsolidatedMetrics };
