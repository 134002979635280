import { Reducer } from 'redux';
import {
  AudienceFilterShareAvaragesAction,
  AudienceFilterShareAvaragesState,
  AudienceFiltersShareAvaragesType
} from './Audience.shareAvarages.types';

const shareAvarageInitialState: AudienceFilterShareAvaragesState = {
  error: false,
  loading: false,
  data: [],
  count: null
};

const reducer: Reducer<
  AudienceFilterShareAvaragesState,
  AudienceFilterShareAvaragesAction
> = (
  state = shareAvarageInitialState,
  action
): AudienceFilterShareAvaragesState => {
  switch (action.type) {
    case AudienceFiltersShareAvaragesType.SUCESS: {
      return {
        loading: false,
        error: false,
        data: action.payload.data.rows,
        count: action.payload.data.count
      };
    }

    case AudienceFiltersShareAvaragesType.ERROR: {
      return {
        loading: false,
        error: true,
        data: [],
        count: null
      };
    }

    case AudienceFiltersShareAvaragesType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersShareAvaragesType.CLEAR: {
      return {
        ...state,
        data: [],
        count: null,
        error: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
