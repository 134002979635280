import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import paths from './paths';

interface AdminRouteProps {
  rolesAllowed: string[];
  children: JSX.Element;
}

const RolesAllowedRouter: React.FC<AdminRouteProps> = ({
  children,
  rolesAllowed
}) => {
  const { auth } = useSelector((state: ApplicationState) => state);
  const location = useLocation();

  if (
    auth.loggedIn &&
    auth.profile &&
    rolesAllowed.includes(auth.profile.role)
  ) {
    return children;
  }

  return (
    <Navigate
      state={{ from: location }}
      to={paths.home.childrens.simulations}
    />
  );
};

export default RolesAllowedRouter;
