import { IAudienceByChannelsRadiographyRequestParams } from '../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { IAudienceByProgramsRadiographyRequestParams } from '../../../components/Audience/AudiencePanels/PayTv/Programs/types';
import { apiV1 } from '../../shared/Api.service';

const path = '/audience/pay-tv/radiographies';

export default async function getOpenTvRegularPlazasRadiographies(
  props:
    | IAudienceByProgramsRadiographyRequestParams
    | IAudienceByChannelsRadiographyRequestParams
): Promise<any> {
  const { data } = await apiV1.get(path, {
    params: {
      ...props
    }
  });

  return data;
}
