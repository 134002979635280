export default function returTranslatedWeekDay(weekDay: string) {
  switch (weekDay.toLowerCase()) {
    case 'sunday':
      return 'Dom';
    case 'monday':
      return 'Seg';
    case 'tuesday':
      return 'Ter';
    case 'wednesday':
      return 'Qua';
    case 'thursday':
      return 'Qui';
    case 'friday':
      return 'Sex';
    default:
      return 'Sab';
  }
}
export type WeekDay =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export const weekDaysEN: WeekDay[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const initialsWeekDaysBR = [
  'SEG',
  'TER',
  'QUA',
  'QUI',
  'SEX',
  'SAB',
  'DOM'
];
