import * as Yup from 'yup';
import { FormClosedPeriod } from './types';

const schemaAudiencePeriod = Yup.object().shape({
  type: Yup.string().required('Selecione o tipo de perído fechado.'),
  value: Yup.object().nullable().required('Selecione um valor válido.'),
  year: Yup.date().typeError('Informe um ano válido.').required()
});

const validateFields = (formData: Partial<FormClosedPeriod>) => {
  return schemaAudiencePeriod.validate(formData, {
    abortEarly: false
  });
};

export default validateFields;
