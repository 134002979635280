import { toast } from 'react-toastify';
import {
  AudienceFilterProgramsAction,
  AudienceFiltersProgramsType,
  ProgramResponse
} from './Audience.programs.types';
import { AppThunk } from '../../../..';
import getPrograms from '../../../../../services/Audience/PayTv/Audience.payTv.programs.service';
import { IAudienceByProgramsRankingFilterParams } from '../../../../../components/Audience/AudiencePanels/PayTv/Programs/types';

const LoadAudienceFilterProgramsSuccess = (response: ProgramResponse) => {
  const payload: AudienceFilterProgramsAction = {
    type: AudienceFiltersProgramsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterProgramsError = (error: string) => {
  const payload: AudienceFilterProgramsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersProgramsType.ERROR
  };

  return payload;
};

const LoadingAudienceProgramsFilter = () => ({
  type: AudienceFiltersProgramsType.REQUEST,
  payload: {}
});

export const ClearAudienceProgramsData = () => ({
  type: AudienceFiltersProgramsType.CLEAR,
  payload: {}
});

export const LoadAudienceFilterProgramsRequest =
  (props: IAudienceByProgramsRankingFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceProgramsFilter());
      const response = await getPrograms({
        ...props
      });

      dispatch(LoadAudienceFilterProgramsSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterProgramsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar as emissoras.');
    }
  };
