import { toast } from 'react-toastify';
import { isNotebook } from '../../../helpers/Utils';
import { TargetAction, TargetsResponse, TargetsTypes } from './Targets.types';
import {
  getNotebookTargets,
  getTargets
} from '../../../services/MediaSimulator/Targets.service';
import { TypeTV } from '../../../services/shared/Api.service';
import { AppThunk } from '../..';
import { Market } from '../Markets/Markets.types';

const LoadTargetSuccess = (
  response: TargetsResponse,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED,
  signal: string
) => {
  const payload: TargetAction = {
    type: TargetsTypes.LOAD_TARGETS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg,
      signal,
      typeTV
    }
  };

  return payload;
};

const LoadTargetError = (
  error: string,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: TargetAction = {
    type: TargetsTypes.LOAD_TARGETS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error,
      signal: '',
      typeTV
    }
  };

  return payload;
};

const LoadingTargets = (typeTV: TypeTV.OPEN | TypeTV.CLOSED) => ({
  type: TargetsTypes.LOAD_TARGETS_REQUEST,
  payload: { typeTV }
});

const LoadTargetRequest =
  (marketSelected: Market, typeTV: TypeTV.OPEN | TypeTV.CLOSED): AppThunk =>
  async (dispatch) => {
    try {
      let response;

      dispatch(LoadingTargets(typeTV));
      if (isNotebook(marketSelected)) {
        response = await getNotebookTargets(marketSelected.signal, typeTV);
      } else {
        response = await getTargets(typeTV);
      }

      dispatch(LoadTargetSuccess(response, typeTV, marketSelected.signal));
    } catch (error) {
      dispatch(
        LoadTargetError(error instanceof Error ? error.message : '', typeTV)
      );
      toast.error('Ops, houve um problema ao tentar carregar os targets.');
    }
  };

export default LoadTargetRequest;
