import './CheckboxInput.scss';
import React, { useMemo } from 'react';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, {
  FormControlLabelClasses,
  FormControlLabelProps
} from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';
import { attributeMaskOrUnmask } from '../../../helpers/Utils';

interface Props extends CheckboxProps {
  label: string;
  labelClasses?: Partial<FormControlLabelClasses>;
  formControlLabelProps?: FormControlLabelProps;
  dataClarityMask?: boolean;
  classTagManager?: string;
  error?: boolean;
}

const RedCheckbox = styled(Checkbox)({
  color: 'red',
  '&.Mui-checked': {
    color: 'red'
  }
});
const CheckboxInput: React.FC<Props> = ({
  labelClasses,
  label,
  formControlLabelProps,
  dataClarityMask = false,
  classTagManager = '',
  error = false,
  ...rest
}) => {
  const getLabelClasses = useMemo(() => {
    return {
      ...labelClasses,
      label: labelClasses?.label
        ? `${labelClasses.label} checkbox__label ${
            error ? 'checkbox--error__label' : ''
          }`
        : `checkbox__label ${error ? 'checkbox--error__label' : ''}`
    };
  }, [labelClasses, error]);

  const inputProps = {
    ...rest.inputProps,
    className: `${rest.inputProps?.className || ''} ${classTagManager} ${
      error ? 'checkbox--error__label' : ''
    }`.trim()
  };

  return (
    <FormControlLabel
      {...formControlLabelProps}
      {...attributeMaskOrUnmask(dataClarityMask)}
      classes={getLabelClasses}
      className={`checkbox ${error ? 'checkbox--error__label' : ''}`}
      control={
        error ? (
          <RedCheckbox {...rest} inputProps={inputProps} />
        ) : (
          <Checkbox {...rest} inputProps={inputProps} />
        )
      }
      label={label}
    />
  );
};

CheckboxInput.defaultProps = {
  classTagManager: undefined,
  dataClarityMask: undefined,
  formControlLabelProps: undefined,
  labelClasses: undefined,
  error: false
};

export default CheckboxInput;
