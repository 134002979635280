import TextField from '@mui/material/TextField';

import React, { useState, useMemo, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import SelectInput from '../Form/SelectInput';
import ModalShell from '../ModalShell';
import { OptionsList } from '../Form/types';
import PercentNumber from '../Input/PercentNumber/PercentNumber';
import usePlanningRedux from './usePlanningRedux';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface Props {
  onClickCancel(): void;
  onClickOk(channel: string, discount: string): void;
  open: boolean;
}

const ModalDiscount: React.FC<Props> = ({
  onClickCancel,
  onClickOk,
  open = false
}) => {
  const planning = usePlanningRedux().currentPlanning;
  const [channel, setChannel] = useState('');
  const [discount, setDiscount] = useState<string>('');

  const channels = useMemo(
    () =>
      planning.planningPrograms.reduce<OptionsList[]>((acc, program) => {
        if (!acc.find((c) => c.label === program.channel)) {
          acc.push({
            value: program.channel,
            label: program.channel
          });
        }

        return acc;
      }, []),
    [planning.planningPrograms]
  );

  useEffect(() => {
    if (channels.length) {
      setChannel(channels[0]?.value || '');
    }
  }, [channels]);

  const handleClickOk = () => {
    onClickOk(channel, discount);
    setChannel('');
  };

  const handleClickCancel = () => {
    onClickCancel();
  };

  return (
    <ModalShell
      disableBtnOk={discount === ''}
      labelBtnCancel="cancelar"
      labelBtnOK="aplicar"
      onClickCancel={handleClickCancel}
      onClickOK={handleClickOk}
      open={open}
      title="Distribuir desconto"
    >
      <FormControl fullWidth>
        <SelectInput
          dataClarityMask
          defaultValue={channels.length ? channels[0]?.value : undefined}
          label="Canal"
          name="channel"
          onChange={(e) => setChannel(e.target.value)}
          options={channels}
          placeholder="Escolha uma opção"
        />
        {!!channel?.length && channel.length > 0 ? (
          <TextField
            classes={{ root: 'my-2' }}
            InputProps={{
              inputComponent: PercentNumber as any,
              endAdornment: <span className="plim-black5">%</span>
            }}
            label="Desconto "
            onChange={(e) => setDiscount(e.target.value)}
            {...attributeMaskOrUnmask(false)}
          />
        ) : null}
      </FormControl>
    </ModalShell>
  );
};

export default ModalDiscount;
