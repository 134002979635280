import './Notifications.scss';
import { useEffect, useCallback } from 'react';
import close from '../../assets/images/close.svg';
import NotificationsList from './List/NotificationsList';
import { ApplicationState } from '../../store';
import { CloseNotificationsDrawer } from '../../store/modules/Notifications/Notifications.actions';
import { useAppDispatch, useAppSelector } from '../../hooks';

const Notifications = () => {
  const dispatch = useAppDispatch();
  const { openDrawer } = useAppSelector(
    (applicationState: ApplicationState) => applicationState.notifications
  );

  const closeDrawer = useCallback(
    () => dispatch(CloseNotificationsDrawer()),
    [dispatch]
  );

  const handleGlobalClick = useCallback(
    (ev: Event) => {
      const element = document.querySelector('.notifications');
      if (!element?.contains(ev.target as HTMLElement)) closeDrawer();
    },
    [closeDrawer]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') closeDrawer();
    },
    [closeDrawer]
  );

  const handleClick = () => closeDrawer();

  useEffect(() => {
    if (openDrawer) {
      document.addEventListener('keydown', handleKeyPress);
      document.addEventListener('click', handleGlobalClick);
      return;
    }
    document.removeEventListener('keydown', handleKeyPress);
    document.removeEventListener('click', handleGlobalClick);
  }, [openDrawer, handleGlobalClick, handleKeyPress, closeDrawer]);

  return (
    <div
      className={
        openDrawer ? 'notifications' : 'notifications notifications--closed'
      }
      data-testid={
        openDrawer ? 'notifications--opened' : 'notifications--closed'
      }
    >
      <div className="notifications__wrapper">
        <div
          className="btn-square img-close"
          data-testid="btn-close-notifications"
          onClick={handleClick}
          role="button"
          tabIndex={0}
        >
          <img alt="ícone fechar" src={close} />
        </div>
        <h2>Notificações</h2>
        <NotificationsList
          notifications={[
            {
              id: 1,
              title: 'Alerta de preço',
              message: 'Preço do programa XXX diminuiu!!!',
              dataClarityMask: true
            }
          ]}
        />
      </div>
    </div>
  );
};

export default Notifications;
