import { Reducer } from 'redux';
import {
  AudienceFilterRankingsAction,
  AudienceFilterRankingsState,
  AudienceFiltersRankingsType
} from './Audience.programRanking.types';

const rankingInitialState: AudienceFilterRankingsState = {
  data: [],
  loading: false,
  error: false,
  total: 0,
  count: 0,
  message: '',
  page: 0,
  rowsPerPage: 10,
  sortColumns: [],
  mustBeLoading: false,
  reload: false
};

const reducer: Reducer<
  AudienceFilterRankingsState,
  AudienceFilterRankingsAction
> = (state = rankingInitialState, action): AudienceFilterRankingsState => {
  switch (action.type) {
    case AudienceFiltersRankingsType.SUCESS: {
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.data.rows.map((row) => {
          return {
            ...row,
            audienciaPc: `${row.audienciaPc?.toLocaleString('pt-BR', {
              maximumFractionDigits: 1
            })}%`,
            share: `${row.share?.toLocaleString('pt-BR', {
              maximumFractionDigits: 1
            })}%`,
            audienciaAvg: row.audienciaAvg?.toLocaleString('pt-BR', {
              maximumFractionDigits: 0
            })
          };
        }),
        count: action.payload.data.count,
        total: action.payload.data.total
      };
    }

    case AudienceFiltersRankingsType.ERROR: {
      return {
        ...state,
        loading: false,
        error: true,
        data: [],
        count: 0
      };
    }

    case AudienceFiltersRankingsType.REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }

    case AudienceFiltersRankingsType.CLEAR: {
      return {
        ...state,
        loading: false,
        error: false,
        data: [],
        count: 0
      };
    }
    case AudienceFiltersRankingsType.PAGE: {
      return {
        ...state,
        page: action.payload.page || 0,
        reload: true
      };
    }
    case AudienceFiltersRankingsType.ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.payload.rowsPerPage || 10,
        reload: true
      };
    }
    case AudienceFiltersRankingsType.SORT_COLUMNS: {
      return {
        ...state,
        sortColumns: action.payload.sortColumns || [],
        reload: true
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
