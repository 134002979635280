import './Ranking.scss';
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import useRanking from './useRanking';
import GridPagination from '../GridPagination';
import Info from '../../assets/images/icon-info.svg';
import IconPlus from '../../assets/images/IconPlus.svg';
import IconMinus from '../../assets/images/IconMinus.svg';
import { useAppDispatch } from '../../hooks';
import { SetHideDaysOfWeekRanking } from '../../store/modules/Programs/Programs.actions';
import CheckboxInput from '../Form/CheckboxInput';
import Information from '../Information';
import useRankingRedux from './useRankingRedux';
import classesGTM from '../../config';
import AppSVG from '../SVG/AppSVG';

const Ranking = () => {
  const programs = useRankingRedux().currentPrograms;
  const dispatch = useAppDispatch();

  const { rows, onSortColumnsChange, columns } = useRanking();
  // const [search, setSearch] = useState('');

  // const handleSearch = useCallback((value: string) => {
  //   setSearch(value);
  // }, []);

  const MessageNoRows = programs?.count === 0 && !programs?.loading && (
    <div className="pr-5 pl-5 no-message">
      <div>
        <AppSVG
          height={25}
          loader={<Skeleton height={25} width={24} />}
          src={Info}
          width={24}
        />
      </div>
      <div>Nenhum programa encontrado.</div>
    </div>
  );

  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(SetHideDaysOfWeekRanking(event.target.checked));

  const HeaderGrid = (
    <CheckboxInput
      checked={programs?.hideDaysOfWeek}
      classes={{
        root: 'plim-gray3',
        checked: 'plim-gray5'
      }}
      classTagManager={classesGTM.ranking.hideDaysOfWeek}
      label="Ocultar os dias da semana"
      onChange={handleChecked}
      size="small"
    />
  );

  const messageInfo = (
    <>
      Clique no botão&nbsp;
      <AppSVG className="mr-0" height={20} src={IconPlus} width={20} />
      &nbsp;na tabela abaixo para adicionar a linha desejada ao Planejamento ou
      no botão&nbsp;
      <AppSVG className="mr-0" height={20} src={IconMinus} width={20} />
      &nbsp;para remover.
    </>
  );

  return (
    <>
      <Information message={messageInfo} />
      <GridPagination
        className="ranking__grid--height"
        classTagManagerDownXlxs={classesGTM.ranking.exportXlsx}
        columns={columns}
        countData={programs?.count}
        defaultColumnOptions={{ resizable: true }}
        exportXlsxFileName="Ranking"
        hasExportGridToXlxs
        hasSearch={false}
        headerContainer={HeaderGrid}
        headerRowHeight={40}
        loading={programs?.loading}
        renderers={{ noRowsFallback: MessageNoRows }}
        onSortColumnsChange={onSortColumnsChange}
        rows={rows}
        sortColumns={programs.sortedColumns}
        xlsxSheetName="Programs"
      />
    </>
  );
};

export default Ranking;
