enum PayTvColors {
  'A&E' = '#A0522D',
  'AMC' = '#8B4513',
  'ANIMAL PLANET' = '#556B2F',
  'AXN' = '#B22222',
  'BAND NEWS' = '#4682B4',
  'BANDSPORTS' = '#DC143C',
  'CARTOON NETWORK' = '#DAA520',
  'CINEMAX' = '#D2691E',
  'CNN BRASIL' = '#2F4F4F',
  'COMEDY CENTRAL' = '#FFD700',
  'DISCOVERY CHANNEL' = '#000080',
  'DISCOVERY HOME & HEALTH' = '#808000',
  'DISCOVERY ID' = '#696969',
  'DISCOVERY KIDS' = '#8B008B',
  'DISCOVERY TURBO' = '#FF4500',
  'DISNEY CHANNEL' = '#6495ED',
  'E! ENTERTAINMENT' = '#FF69B4',
  'ESPN' = '#CD5C5C',
  'ESPN2' = '#2E8B57',
  'ESPN 3' = '#483D8B',
  'ESPN4' = '#FF6347',
  'FILM & ARTS' = '#8A2BE2',
  'FOOD NETWORK' = '#D8BFD8',
  'FOX SPORTS 2' = '#A52A2A',
  'FX' = '#7FFF00',
  'GLOBONEWS' = '#00CED1',
  'GLOOB' = '#1E90FF',
  'GNT' = '#FFE4B5',
  'H2' = '#5F9EA0',
  'HBO' = '#9400D3',
  'HBO 2' = '#FF00FF',
  'HGTV' = '#32CD32',
  'HISTORY' = '#B8860B',
  'JOVEM PAN NEWS' = '#B03060',
  'LIFETIME' = '#E9967A',
  'MEGAPIX' = '#8B0000',
  'MTV' = '#008B8B',
  'MULTISHOW' = '#BA55D3',
  'NATIONAL GEOGRAPHIC' = '#FFA500',
  'NICK JR' = '#00FA9A',
  'NICKELODEON' = '#FF4500',
  'PARAMOUNT NETWORK' = '#4682B4',
  'PREMIERE 2' = '#FFB6C1',
  'PREMIERE 3' = '#CD853F',
  'PREMIERE 4' = '#708090',
  'PREMIERE 5' = '#D4AF37',
  'PREMIERE 6' = '#8F7A4E',
  'PREMIERE 7' = '#4169E1',
  'PREMIERE 8' = '#FF1493',
  'PREMIERE CLUBES' = '#BC8F8F',
  'PROGRAMADORA A&E' = '#2F6A47',
  'PROGRAMADORA AMC' = '#6B4F28',
  'PROGRAMADORA BOX BRASIL' = '#7B68EE',
  'PROGRAMADORA CNN BRASIL' = '#4F9A9E',
  'PROGRAMADORA GLOBO' = '#E89A7E',
  'PROGRAMADORA GRUPO DISNEY' = '#D32F2F',
  'PROGRAMADORA HBO' = '#004D4D',
  'PROGRAMADORA JOVEM PAN NEWS' = '#9932CC',
  'PROGRAMADORA GRUPO NEWCO' = '#00FF7F',
  'PROGRAMADORA SONY' = '#FF8C00'
}

export default PayTvColors;
