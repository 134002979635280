export interface AudienceAbsolute {
  vl_audiencia_abs: number;
  nm_emissora: string;
  ds_periodo: string;
}
export interface AudienceFilterAudienceAbsolutesState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AudienceAbsolute[];
  readonly count: number | null;
}

export interface AudienceAbsoluteResponse {
  data: {
    rows: AudienceAbsolute[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAudienceAbsolutesType {
  REQUEST = '@audience/payTv/byPrograms/audienceAbsolutes/load',
  SUCESS = '@audience/payTv/byPrograms/audienceAbsolutes/success',
  ERROR = '@audience/payTv/byPrograms/audienceAbsolutes/error',
  CLEAR = '@audience/payTv/byPrograms/audienceAbsolutes/clear'
}

export interface AudienceFilterAudienceAbsolutesAction {
  type: AudienceFiltersAudienceAbsolutesType;
  payload: Omit<AudienceAbsoluteResponse, 'status'>;
}
