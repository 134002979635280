import { SortDirection } from 'react-data-grid';
import { initialsWeekDaysBR } from './WeekDay';

const sortString = (
  value1: string,
  value2: string,
  direction: SortDirection | string = 'ASC'
) => {
  if (direction.toUpperCase() === 'DESC') {
    return value2.toString().localeCompare(value1.toString());
  }
  return value1.toString().localeCompare(value2.toString());
};

const sortNumber = (
  value1: number,
  value2: number,
  direction: SortDirection | string = 'ASC'
) => {
  return direction === 'ASC'
    ? (value1 ?? 0) - (value2 ?? 0)
    : (value2 ?? 0) - (value1 ?? 0);
};

const sortTime = (
  time1: string,
  time2: string,
  direction: SortDirection | string = 'ASC'
) => {
  const dateTime1 = new Date(`2021-11-11 ${time1}`);
  const dateTime2 = new Date(`2021-11-11 ${time2}`);

  return direction === 'ASC'
    ? dateTime1.getTime() - dateTime2.getTime()
    : dateTime2.getTime() - dateTime1.getTime();
};

const sortDate = (
  date1: Date,
  date2: Date,
  direction: SortDirection | string = 'ASC'
) => {
  return direction === 'ASC'
    ? date1.getTime() - date2.getTime()
    : date2.getTime() - date1.getTime();
};

const sortDaysOfWeek = (
  days1: string[],
  days2: string[],
  direction: SortDirection | string = 'ASC'
): number => {
  const index1 = initialsWeekDaysBR.indexOf(days1[0]);
  const index2 = initialsWeekDaysBR.indexOf(days2[0]);
  if (index1 !== -1 && index2 !== -1 && index1 === index2) {
    return sortDaysOfWeek(
      days1.slice(1, days1.length),
      days2.slice(1, days2.length),
      direction
    );
  }

  return direction === 'ASC' ? index1 - index2 : index2 - index1;
};

export { sortNumber, sortString, sortTime, sortDaysOfWeek, sortDate };
