enum CategoryColors {
  'AUDITORIO' = '#4b2e2a',
  'CARROS E MOTORES' = '#5b9bd5',
  'DOCUMENTARIO' = '#0056d2',
  'ENTREVISTA' = '#f4a460',
  'ESPORTE' = '#228b22',
  'FILME' = '#ffc000',
  'FUTEBOL' = '#e54b4b',
  'HUMORISTICO' = '#ff4d7f',
  'JORNALISMO' = '#5f6a77',
  'MINISSERIE' = '#adff2f',
  'MUSICAL' = '#ff6f00',
  'NOVELA' = '#dda0dd',
  'OUTROS' = '#ff337a',
  'POLITICO' = '#357f5b',
  'PREMIAÇÃO' = '#ff7400',
  'REALITY SHOW' = '#7b1fa2',
  'RELIGIOSO' = '#d3d3d3',
  'REPORTAGEM' = '#28a745',
  'SERIES' = '#6a0d91',
  'SHOW' = '#d020f0'
}

export default CategoryColors;
