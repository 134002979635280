import { toast } from 'react-toastify';
import {
  ChannelAction,
  ChannelsResponse,
  ChannelsTypes
} from './Channels.types';
import getChannels from '../../../services/MediaSimulator/Channels.service';
import { TypeTV } from '../../../services/shared/Api.service';
import { AppThunk } from '../..';

export const LoadChannelSuccess = (
  response: ChannelsResponse,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: ChannelAction = {
    type: ChannelsTypes.LOAD_CHANNELS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg,
      typeTV
    }
  };

  return payload;
};

const LoadChannelError = (
  error: string,
  typeTV: TypeTV.OPEN | TypeTV.CLOSED
) => {
  const payload: ChannelAction = {
    type: ChannelsTypes.LOAD_CHANNELS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error,
      typeTV
    }
  };

  return payload;
};

const LoadingChannels = (typeTV: TypeTV.OPEN | TypeTV.CLOSED) => ({
  type: ChannelsTypes.LOAD_CHANNELS_REQUEST,
  payload: { typeTV }
});

const LoadChannelRequest =
  (typeTV: TypeTV.OPEN | TypeTV.CLOSED): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingChannels(typeTV));
      const response = await getChannels(typeTV);
      dispatch(LoadChannelSuccess(response, typeTV));
    } catch (error) {
      dispatch(
        LoadChannelError(error instanceof Error ? error.message : '', typeTV)
      );
      toast.error('Ops, houve um problema ao tentar carregar os canais.');
    }
  };

export default LoadChannelRequest;
