import React from 'react';
import { IconButtonClasses } from '@mui/material/IconButton';
import './ActionColumn.scss';
import { ButtonIcon } from '../ButtonApp';

interface ActionProps {
  onClick(e: React.SyntheticEvent): void;
  disabled?: boolean;
  icon: string;
  id?: string;
  classes?: {
    svg?: string;
    button?: Partial<IconButtonClasses>;
    tagManager?: string;
  };
}

const ActionColumn = ({
  onClick,
  icon,
  classes,
  id,
  disabled = false
}: ActionProps) => {
  return (
    <ButtonIcon
      classes={classes?.button}
      className={classes?.tagManager}
      disabled={disabled}
      icon={icon}
      id={id}
      onClick={onClick}
      size="small"
      svgClassName={classes?.svg}
    />
  );
};

ActionColumn.defaultProps = {
  classes: undefined,
  disabled: false,
  id: undefined
};

export default ActionColumn;
