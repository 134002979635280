import { toast } from 'react-toastify';
import { AppThunk } from '../../..';
import {
  CompetitiveBrandsResponse,
  CompetitiveBrandAction,
  CompetitiveBrandsTypes,
  CompetitiveBrandsQueryParams
} from './Competitive.brands.types';
import getCompetitiveBrands from '../../../../services/Competitive/Competitive.brands.service';

export const LoadCompetitiveBrandsSuccess = (
  response: CompetitiveBrandsResponse
) => {
  const payload: CompetitiveBrandAction = {
    type: CompetitiveBrandsTypes.LOAD_BRANDS_SUCCESS,
    payload: {
      count: response.data.count,
      rows: response.data.rows,
      message: response.msg
    }
  };

  return payload;
};

const LoadCompetitiveBrandsError = (error: string) => {
  const payload: CompetitiveBrandAction = {
    type: CompetitiveBrandsTypes.LOAD_BRANDS_ERROR,
    payload: {
      count: 0,
      rows: [],
      message: error
    }
  };

  return payload;
};

const LoadingCompetitiveBrands = () => ({
  type: CompetitiveBrandsTypes.LOAD_BRANDS_REQUEST
});

const LoadCompetitiveBrandsRequest =
  (params: CompetitiveBrandsQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveBrands());

      const response = await getCompetitiveBrands(params);

      dispatch(LoadCompetitiveBrandsSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveBrandsError(error instanceof Error ? error.message : '')
      );
      toast.error('Ops, houve um problema ao tentar carregar os canais.');
    }
  };

export default LoadCompetitiveBrandsRequest;
