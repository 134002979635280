import { useRef, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import SelectInput from '../Form/SelectInput';
import Input from '../Form/Input';
import handleErrors from '../../helpers/HandleErrors';
import {
  SaveUserRequest,
  UpdateUser
} from '../../store/modules/Users/Users.actions';
import {
  roleOptions,
  getCentrals,
  getCentral,
  getAllBaseAudience
} from './contants';
import ModalShell from '../ModalShell';
import AutocompleteInput from '../Form/AutocompleteInput';
import { Roles, User } from '../../store/modules/Users/Users.types'; // Adicionar Roles
import FormContainer from '../Form/FormContainer/FormContainer';
import { useAppDispatch } from '../../hooks';
import modalUserValidateFields from './ModalUser.validations';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

export interface ModalUserEdit
  extends Omit<User, 'central' | 'area' | 'baseAudience'> {
  central: { label: string; value: string };
  area: { label: string; value: string };
  baseAudience: { label: string; value: string };
}
interface Props {
  open: boolean;
  userEdit?: ModalUserEdit | undefined;
  onCancelClick(): void;
  onSaveClick(): void;
}

const ModalUser = ({ onCancelClick, onSaveClick, open, userEdit }: Props) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();

  const [central, setCentral] = useState<string>('');
  const [baseAudience, setBaseAudience] = useState<string>('SP-BASE-SP');
  const [showDiv, setShowDiv] = useState(true);
  const area = useRef<string | undefined>(userEdit?.area?.value);

  useEffect(() => {
    if (userEdit?.id) {
      setShowDiv(userEdit.role !== Roles.AGENCY);
      area.current = userEdit.area.value;
      setCentral(userEdit.central.value);
      return;
    }
    setShowDiv(true);
    setCentral('');
  }, [userEdit]);

  useEffect(() => {
    if (!getCentral(central).areas.some((el) => el.value === area.current)) {
      formRef.current?.clearField('area');
    }
  }, [central]);

  const handleSubmit = async (data: User) => {
    const newData: User = { ...data };

    // if (newData.role === Roles.USER) {
    //   newData.baseAudience = 'SP-BASE-SP';
    //   newData.central = '';
    //   newData.area = '';
    // }

    try {
      formRef.current?.setErrors({});

      await modalUserValidateFields(newData);

      if (userEdit?.id) {
        dispatch(UpdateUser({ ...userEdit, ...newData }));
      } else {
        dispatch(SaveUserRequest(newData));
      }
      onSaveClick();
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = handleErrors(error);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  };

  const handleClickOk = () => {
    if (formRef?.current) formRef.current.submitForm();
  };

  const handleCentralChange = (selectedValue: any | null) => {
    if (selectedValue) setCentral(selectedValue.value);
  };

  const handleAreaChange = (selectedValue: any | null) => {
    if (selectedValue) area.current = selectedValue;
  };

  const handleBaseAudienceChange = (selectedValue: any | null) => {
    if (selectedValue) setBaseAudience(selectedValue.value);
  };

  const handleRoleChange = (selectedValue: any | null) => {
    const roleSelecionada = selectedValue.target.value;
    const shouldShowDiv = roleSelecionada !== Roles.AGENCY;
    setShowDiv(shouldShowDiv);
  };

  return (
    <ModalShell
      labelBtnOK={userEdit ? 'atualizar' : 'cadastrar'}
      onClickCancel={onCancelClick}
      onClickOK={handleClickOk}
      open={open}
      title={userEdit ? 'Atualizar Usuário' : 'Novo Usuário'}
    >
      <FormContainer
        data-testid="form"
        formRef={formRef}
        fullWidth
        initialData={userEdit}
        onSubmit={handleSubmit}
      >
        <div className="modal-user-container">
          <div className="input-wrapper">
            <div className="input-wrapper__divisor__column">
              <Input
                {...attributeMaskOrUnmask(true)}
                data-testid="email"
                label="E-mail"
                name="email"
                placeholder=" "
                required
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-wrapper__divisor__column">
              <Input
                {...attributeMaskOrUnmask(true)}
                data-testid="name"
                label="Nome"
                name="name"
                placeholder=" "
                required
              />
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-wrapper__divisor__column">
              <SelectInput
                data-testid="role"
                defaultValue={Roles.AGENCY}
                label="Tipo"
                name="role"
                options={roleOptions}
                placeholder=" "
                required
                onChange={handleRoleChange}
              />
            </div>
          </div>
          {showDiv && (
            <div>
              <div className="input-wrapper">
                <div className="input-wrapper__divisor__column">
                  <AutocompleteInput
                    data-testid="baseAudience"
                    disableClearable
                    label="Base de Audiência"
                    name="baseAudience"
                    defaultValue={baseAudience}
                    onChange={handleBaseAudienceChange}
                    options={getAllBaseAudience()}
                    required={showDiv}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-wrapper__divisor__column">
                  <AutocompleteInput
                    data-testid="central"
                    label="Central"
                    name="central"
                    onChange={handleCentralChange}
                    options={getCentrals()}
                    required={showDiv}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <div className="input-wrapper__divisor__column">
                  <AutocompleteInput
                    data-testid="area"
                    disableClearable
                    disabled={!central}
                    label="Área"
                    name="area"
                    onChange={handleAreaChange}
                    options={getCentral(central).areas}
                    required={showDiv}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </FormContainer>
    </ModalShell>
  );
};

ModalUser.defaultProps = {
  userEdit: undefined
};

export default ModalUser;
