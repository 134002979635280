import { Reducer } from 'redux';
import { AuthState, AuthAction, AuthTypes } from './Auth.types';
import loginLocalStorage from '../../../components/Auth/LoginWrapper/Login.LocalStorage';

const isLoggedUser = !!loginLocalStorage().authenticated.get();

const authInitialState: AuthState = {
  loggedIn: isLoggedUser,
  authenticated: false,
  profile: null,
  error: false,
  loading: false
};

const reducer: Reducer<AuthState, AuthAction> = (
  state = authInitialState,
  action
) => {
  switch (action.type) {
    case AuthTypes.RESET_PROFILE:
      return {
        ...state,
        profile: null,
        loggedIn: false,
        loading: false,
        error: false
      };
    case AuthTypes.SET_PROFILE:
    case AuthTypes.USER_SIGN_IN_SUCESS:
      return {
        ...state,
        loggedIn: true,
        error: false,
        profile: action.payload.userInfo,
        loading: false
      };
    case AuthTypes.USER_SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case AuthTypes.USER_SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        profile: null,
        loggedIn: false
      };
    case AuthTypes.REDIRECT_TO_LOGIN:
      return {
        ...state,
        loading: true,
        profile: null,
        loggedIn: false
      };

    case AuthTypes.AUTHENTICATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case AuthTypes.AUTHENTICATE_SUCCESS:
      return {
        ...state,
        loading: false,
        authenticated: true,
        error: false
      };
    case AuthTypes.AUTHENTICATE_FAILURE:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        error: true
      };
    case AuthTypes.SIGN_OUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case AuthTypes.SIGN_OUT_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case AuthTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true
      };

    default:
      return state;
  }
};

export default reducer;
