import { useLocation } from 'react-router-dom';

import { attributeMaskOrUnmask } from '../../../helpers/Utils';
import Breadcrumb from '../../Breadcrumb';
import SubHeaderTabs from '../Tabs/OpenTv/SubHeaderTabs';
import './AudienceSubHeader.scss';

interface MenuItem {
  index: number;
  label: string;
  path: string;
  className: string;
  id: string;
}

interface BreadCrumbItem {
  id: number;
  title: string;
  path: string;
}

interface SubHeaderAudienceProps {
  title: string;
  menuItems: MenuItem[];
  breadCrumbItems: BreadCrumbItem[];
}

const AudienceSubHeader: React.FC<SubHeaderAudienceProps> = ({
  title,
  menuItems,
  breadCrumbItems
}) => {
  const location = useLocation();

  return (
    <div className="flex-sb">
      <div className="title-container">
        <div className="title-version">
          <div className="flex-wrap">
            <h2 className="subheader__title" {...attributeMaskOrUnmask(true)}>
              {title}
            </h2>
          </div>
        </div>
        <Breadcrumb links={breadCrumbItems} />
      </div>
      <SubHeaderTabs
        items={menuItems}
        activeTab={menuItems.findIndex((item) =>
          location.pathname.includes(item.path)
        )}
      />
    </div>
  );
};

export default AudienceSubHeader;
