export default <GET = unknown, SET = GET>(key: string) => ({
  get: (): GET | undefined => {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : undefined;
  },
  set: (value: SET) => {
    try {
      if (value !== undefined && value !== null) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    } catch (error) {
      console.error(error);
    }
  },
  remove: () => localStorage.removeItem(key)
});

export interface LocalStorageProps<GET = unknown, SET = GET> {
  get(): GET | undefined;
  set(value: SET): void;
  remove(): void;
}

export const clearAll = (keys: string[]) =>
  keys.forEach((key) => localStorage.removeItem(key));
