export interface Plaza {
  id: string;
  name: string;
}
export interface AudienceFilterPlazasState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Plaza[];
  readonly count: number | null;
}

export interface PlazaResponse {
  data: {
    rows: Plaza[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersPlazasType {
  REQUEST = '@audience/openTv/plazas/load',
  SUCESS = '@audience/openTv/plazas/success',
  ERROR = '@audience/openTv/plazas/error'
}

export interface AudienceFilterPlazasAction {
  type: AudienceFiltersPlazasType;
  payload: Omit<PlazaResponse, 'status'>;
}
