import '../AudiencePayTvProgramsFilters.scss';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Grid } from '@mui/material';

import FormContainer from '../../../../../Form/FormContainer';
import {
  IAudienceByProgramsPayTvRadiographyFilterProps,
  TypePeriod
} from '../types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import useAudiencePayTvProgramsRadiographyFilterLoadData from './hooks/useAudiencePayTvProgramsRadiographyFilterLoadData';
import useAudiencePayTvProgramsRadiographyFilterAutoSelect from './hooks/useAudiencePayTvProgramsRadiographyFilterAutoSelect';
import { OptionsList } from '../../../../../Form/types';
import { ClearAudienceProgramsData } from '../../../../../../store/modules/AudienceModule/PayTv/Programs/Audience.programs.actions';
import { ClearAudiencePeriodsData } from '../../../../../../store/modules/AudienceModule/PayTv/Periods/Audience.periods.actions';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';
import { listboxSigleProps } from '../../../../../Competitive/CompetitiveFilter/constants';

const AudienceByPayTvProgramsRadiographyFilter: React.FC<
  IAudienceByProgramsPayTvRadiographyFilterProps
> = ({
  channel,
  program,
  period,
  setChannel,
  setProgram,
  setPeriod,
  newFilter,
  setNewFilter,
  target
}: IAudienceByProgramsPayTvRadiographyFilterProps) => {
  const formRef = useRef<FormHandles>(null);
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const inputsConfig = useMemo(
    () => ({
      channels: {
        name: 'channels',
        defaultValue: 'GloboNews'
      },
      program: {
        name: 'program',
        defaultValue: 'TODOS'
      },
      period: {
        name: 'period',
        defaultValue: '2024'
      }
    }),
    []
  );

  const { channelsOptions, periodsOptions, programsOptions } =
    useAudiencePayTvProgramsRadiographyFilterLoadData({
      channel,
      program,
      period,
      target
    });

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  useAudiencePayTvProgramsRadiographyFilterAutoSelect({
    channel,
    program,
    period,
    setChannel,
    setProgram,
    setPeriod,
    channelsOptions,
    programsOptions,
    periodsOptions,
    clearInputs,
    inputsConfig
  });

  const handleChannelChange = (option: OptionsList) => {
    dispatch(ClearAudienceProgramsData());
    clearInputs([inputsConfig.program.name, inputsConfig.period.name]);

    if (setChannel) setChannel(option);
  };

  const handleProgramChange = (option: OptionsList) => {
    dispatch(ClearAudiencePeriodsData());
    clearInputs([inputsConfig.period.name]);

    if (setProgram) setProgram(option);
  };

  const handlePeriodChange = (option: TypePeriod) => {
    setPeriod(option);
  };

  const handleSendClick = () => {
    return;
  };

  useEffect(() => {
    if (newFilter === true) {
      clearInputs([
        inputsConfig.channels.name,
        inputsConfig.program.name,
        inputsConfig.period.name
      ]);
      if (setChannel) setChannel(undefined);
      if (setProgram) setProgram(undefined);
      if (setPeriod) setPeriod(undefined);

      if (setNewFilter) setNewFilter(false);
    }
  }, [
    newFilter,
    setNewFilter,
    setChannel,
    setProgram,
    setPeriod,
    inputsConfig
  ]);

  useEffect(() => {
    if (periodsOptions?.length) {
      setPeriod(
        periodsOptions.find(
          (option) => option.value === inputsConfig.period.defaultValue
        ) || periodsOptions[0]
      );
    }
  }, [inputsConfig.period.defaultValue, periodsOptions, setPeriod]);

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Emissora"
          name={inputsConfig.channels?.name}
          data-testid="channel"
          disabled={
            audience?.payTv?.channels.loading ||
            audience?.payTv?.channels.count === 0
          }
          loading={audience?.payTv?.channels.loading}
          ListboxProps={listboxSigleProps}
          options={channelsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={channelsOptions.find(
            (option) => option.value === inputsConfig.channels.defaultValue
          )}
          onChange={handleChannelChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Programa"
          name={inputsConfig.program?.name}
          data-testid="program"
          disabled={
            audience?.payTv?.programs.loading ||
            audience?.payTv?.programs.count === 0
          }
          loading={audience?.payTv?.programs.loading}
          ListboxProps={listboxSigleProps}
          options={programsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={programsOptions.find(
            (option) => option.value === inputsConfig.program.defaultValue
          )}
          onChange={handleProgramChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Período"
          name={inputsConfig.period?.name}
          data-testid="mercado"
          disabled={
            audience?.payTv?.periods.loading ||
            audience?.payTv?.periods.count === 0
          }
          loading={audience?.payTv?.periods.loading}
          ListboxProps={listboxSigleProps}
          options={periodsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={periodsOptions.find(
            (option) => option.value === inputsConfig.period.defaultValue
          )}
          onChange={handlePeriodChange}
          sx={{
            width: '20%',
            marginLeft: 'auto',
            marginRight: '-70px'
          }}
        />
      </FormContainer>
    </Grid>
  );
};

export default AudienceByPayTvProgramsRadiographyFilter;
