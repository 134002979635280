import { Roles } from '../../store/modules/Users/Users.types';

export const centralsWithAreas = [
  {
    label: 'Comercial',
    value: 'COMERCIAL',
    areas: [
      { label: 'Financeiro e Auto', value: 'FINANCEIRO E AUTO' },
      { label: 'Comércio', value: 'COMÉRCIO' },
      { label: 'Serviços e Telecom', value: 'SERVIÇOS E TELECOM' },
      { label: 'Bens e Consumo', value: 'BENS E CONSUMO' },
      { label: 'Sales Excellence', value: 'SALES EXCELLENCE' },
      {
        label: 'Relacionamento com Agências',
        value: 'RELACIONAMENTO COM AGÊNCIAS'
      },
      { label: 'Multissetorial NO/NE', value: 'MULTISSETORIAL NO/NE' },
      { label: 'Multissetorial DF/BH/ES', value: 'MULTISSETORIAL DF/BH/ES' },
      { label: 'Multissetorial RJ/Sul', value: 'MULTISSETORIAL RJ/SUL' },
      {
        label: 'Governo e Multissetorial SP',
        value: 'GOVERNO E MULTISSETORIAL SP'
      }
    ]
  },
  {
    label: 'Gestão de Negócios',
    value: 'GESTÃO DE NEGÓCIOS',
    areas: [
      { label: 'Performance e Insights', value: 'PERFORMANCE E INSIGHTS' },
      { label: 'Planejamento de Negócios', value: 'PLANEJAMENTO DE NEGÓCIOS' },
      {
        label: 'Gestão e Operação de Vendas',
        value: 'GESTÃO E OPERAÇÃO DE VENDAS'
      },
      { label: 'Gestão do PIA', value: 'GESTÃO DO PIA' },
      { label: 'Gestão do Conhecimento', value: 'GESTÃO DO CONHECIMENTO' },
      { label: 'Gestão de Processos', value: 'GESTÃO DE PROCESSOS' }
    ]
  },
  {
    label: 'Inteligência de Mercado',
    value: 'INTELIGÊNCIA DE MERCADO',
    areas: [
      { label: 'Insights de Audiência', value: 'INSIGHTS DE AUDIÊNCIA' },
      {
        label: 'Insights do Consumidor e Mercado',
        value: 'INSIGHTS DO CONSUMIDOR E MERCADO'
      },
      {
        label: 'Especialista Setorial Bens de Consumo',
        value: 'ESPECIALISTA SETORIAL BENS DE CONSUMO'
      },
      {
        label: 'Especialista Setorial Automotivo',
        value: 'ESPECIALISTA SETORIAL AUTOMOTIVO'
      },
      {
        label: 'Especialista Setorial Financeiro',
        value: 'ESPECIALISTA SETORIAL FINANCEIRO'
      },
      {
        label: 'Especialista Setorial Serviços e Telecom',
        value: 'ESPECIALISTA SETORIAL SERVIÇOS E TELECOM'
      },
      { label: 'Especialista Varejo', value: 'ESPECIALISTA VAREJO' },
      { label: 'Criação de Serviços', value: 'CRIAÇÃO DE SERVIÇOS' }
    ]
  },
  {
    label: 'Produtos Publicitários Canais',
    value: 'PRODUTOS PUBLICITÁRIOS CANAIS',
    areas: [
      { label: 'Especiais e Territórios', value: 'ESPECIAIS E TERRITÓRIOS' },
      {
        label: 'Paytv, Entretenimento e Variedades',
        value: 'PAYTV, ENTRETENIMENTO E VARIEDADES'
      },
      { label: 'Regionais e Afiliadas', value: 'REGIONAIS E AFILIADAS' },
      {
        label: 'Governança e Planejamento de Produtos e Formatos - Canais',
        value: 'GOVERNANÇA E PLANEJAMENTO DE PRODUTOS E FORMATOS - CANAIS'
      },
      { label: 'Gestão de Elenco', value: 'GESTÃO DE ELENCO' },
      { label: 'Dramaturgia', value: 'DRAMATURGIA' },
      { label: 'Variedades', value: 'VARIEDADES' },
      { label: 'Realites e Shows', value: 'REALITES E SHOWS' },
      { label: 'Esporte e Noticias', value: 'ESPORTE E NOTICIAS' }
    ]
  },
  {
    label: 'Produtos Publicitários Digitais',
    value: 'PRODUTOS PUBLICITÁRIOS DIGITAIS',
    areas: [
      {
        label: 'Governança e Planejamento de Produtos - Digitais',
        value: 'GOVERNANÇA E PLANEJAMENTO DE PRODUTOS - DIGITAIS'
      },
      { label: 'Formatos e Futuro', value: 'FORMATOS E FUTURO' },
      { label: 'Noticias', value: 'NOTICIAS' },
      { label: 'Esporte', value: 'ESPORTE' },
      { label: 'Entretenimento', value: 'ENTRETENIMENTO' },
      { label: 'Globoplay e Home', value: 'GLOBOPLAY E HOME' },
      { label: 'Branded Content e Social', value: 'BRANDED CONTENT E SOCIAL' }
    ]
  },
  {
    label: 'Preço e Inventário',
    value: 'PREÇO E INVENTÁRIO',
    areas: [
      { label: 'Revenue Management', value: 'REVENUE MANAGEMENT' },
      {
        label: 'Precificação e Gestão de Inventário Digital',
        value: 'PRECIFICAÇÃO E GESTÃO DE INVENTÁRIO DIGITAL'
      },
      {
        label: 'Precificação e Gestão de inventário Linear',
        value: 'PRECIFICAÇÃO E GESTÃO DE INVENTÁRIO LINEAR'
      },
      {
        label: 'Precificação de Produtos Integrados',
        value: 'PRECIFICAÇÃO DE PRODUTOS INTEGRADOS'
      }
    ]
  },
  {
    label: 'Comunicação com o Mercado',
    value: 'COMUNICAÇÃO COM O MERCADO',
    areas: [
      {
        label: 'Atendimento e Planejamento',
        value: 'ATENDIMENTO E PLANEJAMENTO'
      },
      {
        label: 'Soluções de Comunicação B2B',
        value: 'SOLUÇÕES DE COMUNICAÇÃO B2B'
      },
      {
        label: 'Soluções de Relacionamento B2B',
        value: 'SOLUÇÕES DE RELACIONAMENTO B2B'
      },
      { label: 'Gestão CRM', value: 'GESTÃO CRM' }
    ]
  },
  {
    label: 'Dados e Performance em Publicidade',
    value: 'DADOS E PERFORMANCE EM PUBLICIDADE',
    areas: [
      { label: 'Data Analytics', value: 'DATA ANALYTICS' },
      {
        label: 'Performance e Vendas Programáticas',
        value: 'PERFORMANCE E VENDAS PROGRAMÁTICAS'
      },
      {
        label: 'Gestão de Vendas de Mídias Digitais',
        value: 'GESTÃO DE VENDAS DE MÍDIAS DIGITAIS'
      }
    ]
  },
  {
    label: 'Operações Comerciais',
    value: 'OPERAÇÕES COMERCIAIS',
    areas: [
      {
        label: 'Gestão do Inventário e Distribuição comercial',
        value: 'GESTÃO DO INVENTÁRIO E DISTRIBUIÇÃO COMERCIAL'
      },
      {
        label: 'Gestão Operacional de Negócios',
        value: 'GESTÃO OPERACIONAL DE NEGÓCIOS'
      },
      {
        label: 'Desenvolvimento, suporte e Ativos Não Comercializados',
        value: 'DESENVOLVIMENTO, SUPORTE E ATIVOS NÃO COMERCIALIZADOS'
      }
    ]
  }
];

export const currentbaseAudience = [
  {
    label: 'Todas as praças',
    value: 'Todas as praças'
  },
  {
    label: 'SP-BASE-SP',
    value: 'SP-BASE-SP'
  }
];

export const getCentral = (central: string | undefined) =>
  centralsWithAreas.find(
    ({ label, value }) =>
      label?.toUpperCase() === central?.toUpperCase() ||
      value?.toUpperCase() === central?.toUpperCase()
  ) || { label: '', value: '', areas: [] };

export const getCentrals = () =>
  centralsWithAreas.map(({ label, value }) => ({ label, value }));

export const getAreaPerCentral = (
  area: string | undefined,
  central: string | undefined
) =>
  getCentral(central).areas.find(
    ({ label, value }) =>
      label?.toUpperCase() === area?.toUpperCase() ||
      value?.toUpperCase() === area?.toUpperCase()
  ) || { label: '', value: '' };

export const roleOptions = [
  { value: Roles.MASTER.toString(), label: 'Master' },
  { value: Roles.ADMIN.toString(), label: 'Administrador' },
  { value: Roles.EXECUTIVE.toString(), label: 'Executivo' },
  { value: Roles.AGENCY.toString(), label: 'Agência' }
];

export const getRole = (value: string) =>
  roleOptions.find((el) => el.value === value) || { label: '', value: '' };

export const getBaseAudience = (baseAudience: string) =>
  currentbaseAudience.find(
    ({ label, value }) =>
      label?.toUpperCase() === baseAudience?.toUpperCase() ||
      value?.toUpperCase() === baseAudience?.toUpperCase()
  ) || { label: '', value: '' };

export const getAllBaseAudience = () =>
  currentbaseAudience.map(({ label, value }) => ({ label, value }));
