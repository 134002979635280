import { HeaderRendererProps, headerRenderer } from 'react-data-grid';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

export interface GridPaginationHeaderProps
  extends HeaderRendererProps<any, any> {
  title: string;
  subtitle?: string;
  classes?: {
    title?: string;
    subtitle?: string;
  };
  alignment?: 'center' | 'right' | 'left';
  dataMaskTitle?: boolean;
  dataMaskSubtitle?: boolean;
}

const GridPaginationHeader = ({
  title,
  subtitle,
  classes,
  alignment = 'left',
  dataMaskSubtitle = false,
  dataMaskTitle = false,
  ...rest
}: GridPaginationHeaderProps) => {
  const getHeader = () => (
    <span className={`${subtitle ? 'd-flex flex-column grid-header' : ''}`}>
      <span
        className={`grid-header__title ${classes?.title || ''}`}
        {...attributeMaskOrUnmask(dataMaskTitle)}
      >
        {title}
      </span>
      {subtitle ? (
        <span
          className={`grid-header__subtitle ${classes?.subtitle || ''}`}
          {...attributeMaskOrUnmask(dataMaskSubtitle)}
        >
          {subtitle}
        </span>
      ) : null}
    </span>
  );

  return rest.column.sortable ? (
    <div className={`grid-header__align--${alignment}`}>
      {headerRenderer({
        ...rest,
        column: { ...rest.column, name: getHeader() }
      })}
    </div>
  ) : (
    <div className={`grid-header__align--${alignment} d-flex`}>
      <span className="flex-grow-1">{getHeader()}</span>
    </div>
  );
};

GridPaginationHeader.defaultProps = {
  alignment: undefined,
  classes: { title: '', subtitle: '' },
  dataMaskSubtitle: 'False',
  dataMaskTitle: 'False',
  subtitle: undefined
};

export default GridPaginationHeader;
