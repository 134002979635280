import { SortColumn } from 'react-data-grid';
import { ApiResponse } from '../../../services/shared/Api.service';

export enum UsersTypes {
  LOAD_USERS_REQUEST = '@users/LOAD_USERS_REQUEST',
  LOAD_USERS_SUCCESS = '@users/LOAD_USERS_SUCCESS',
  LOAD_USERS_FAILURE = '@users/LOAD_USERS_FAILURE',
  SAVE_USER_REQUEST = '@users/SAVE_USER_REQUEST',
  SAVE_USER_SUCCESS = '@users/SAVE_USER_SUCCESS',
  SAVE_OR_UPDATE_USER_FAILURE = '@users/SAVE_OR_UPDATE_USER_FAILURE',
  DELETE_USERS_REQUEST = '@users/DELETE_USERS_REQUEST',
  DELETE_USERS_SUCCESS = '@users/DELETE_USERS_SUCCESS',
  DELETE_USERS_FAILURE = '@users/DELETE_USERS_FAILURE',
  UPDATE_USER_SUCCESS = '@users/UPDATE_USER_SUCCESS',
  SET_USERS_PAGE = '@users/SET_USERS_PAGE',
  SET_USERS_ROWS_PER_PAGE = '@users/SET_USERS_ROWS_PER_PAGE',
  SET_USERS_SORT_COLUMNS = '@users/SET_USERS_SORT_COLUMNS',
  SET_USERS_SEARCH = '@users/SET_USERS_SEARCH'
}

export enum Roles {
  ADMIN = 'ADMIN',
  EXECUTIVE = 'EXECUTIVE',
  AGENCY = 'AGENCY',
  MASTER = 'MASTER'
}

export interface User {
  id: number | null;
  name: string;
  email: string;
  role: Roles.MASTER | Roles.ADMIN | Roles.EXECUTIVE | Roles.AGENCY;
  deleted: boolean;
  central: string | undefined;
  area: string | undefined;
  baseAudience: string;
  updatedAt: string;
}

export interface UserAction {
  type: UsersTypes;
  payload: {
    users: User[];
    count?: number;
    message: string;
    page: number;
    rowsPerPage: number;
    sortColumns: SortColumn[];
    search: string;
  };
}
export interface UsersFindAll {
  limit?: number;
  offset?: number;
  orderDir?: string;
  orderBy?: string;
  search?: string;
}
export interface UsersState {
  readonly data: User[];
  readonly loading: boolean;
  readonly error: boolean;
  readonly reload: boolean;
  readonly message: string;
  readonly count: number;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly sortColumns: SortColumn[];
  readonly search: string;
}

export interface UsersResponse {
  data: {
    rows: User[];
    count: number;
  };
  status: number;
  msg: string;
}

export type UserResponse = ApiResponse<{
  email: string;
  role: string;
  id: number;
}>;
