import { Reducer } from 'redux';
import {
  NotificationsState,
  NotificationAction,
  NotificationsTypes
} from './Notifications.types';

const notificationInitialState: NotificationsState = {
  openDrawer: false
};

const reducer: Reducer<NotificationsState, NotificationAction> = (
  state = notificationInitialState,
  action
): NotificationsState => {
  switch (action.type) {
    case NotificationsTypes.OPEN_NOTIFICATIONS_DRAWER: {
      return {
        ...state,
        openDrawer: true
      };
    }
    case NotificationsTypes.CLOSE_NOTIFICATIONS_DRAWER: {
      return {
        ...state,
        openDrawer: false
      };
    }
    default:
      return state;
  }
};

export default reducer;
