import React from 'react';
import './Loading.scss';

interface LoadingProps {
  children?: React.ReactNode;
  classes?: { container?: string };
}
const Loading: React.FC<LoadingProps> = ({ classes, children }) => {
  return (
    <div className={`loading-container ${classes?.container || ''}`}>
      <div className="wrapper">
        <svg height="27" viewBox="0 0 26.666 27" width="26.666">
          <defs>
            <linearGradient
              gradientUnits="objectBoundingBox"
              id="linear-gradient-loading"
              y1="1"
              y2="1"
            >
              <stop offset="0" stopColor="#05A6FF" />
              <stop offset="1" stopColor="#8800F8" />
            </linearGradient>
          </defs>
          <path
            d="M-77.858-269.837a12.929,12.929,0,0,0-12.826,12.993,12.932,12.932,0,0,0,12.826,13.007,12.935,12.935,0,0,0,12.84-13.007,12.931,12.931,0,0,0-12.84-12.993Zm-1.087,6.509q.547,0,1.087,0a78.822,78.822,0,0,1,8.757.361c.812.083,1.237.278,1.334,1.168.15,1.614.165,3.269.165,4.952s-.015,3.353-.165,4.966c-.1.89-.522,1.085-1.334,1.169a82.54,82.54,0,0,1-8.757.362,82.444,82.444,0,0,1-8.744-.362c-.811-.083-1.237-.278-1.333-1.169-.151-1.614-.165-3.269-.165-4.966s.014-3.339.165-4.952c.1-.89.522-1.085,1.333-1.168A73.814,73.814,0,0,1-78.945-263.328Zm1.087,1.225a5.236,5.236,0,0,0-5.21,5.259,5.227,5.227,0,0,0,5.21,5.259,5.229,5.229,0,0,0,5.224-5.259A5.238,5.238,0,0,0-77.858-262.1Z"
            data-name="Path 18"
            fill="url(#linear-gradient-loading)"
            fillRule="evenodd"
            id="Path_18"
            stroke="rgba(0,0,0,0)"
            strokeWidth="1"
            transform="translate(91.185 270.337)"
          />
        </svg>
      </div>
      {children}
    </div>
  );
};

Loading.defaultProps = {
  children: undefined,
  classes: undefined
};
export default Loading;
