import { apiV1 } from '../../../shared/Api.service';
import { WeekDayResponse } from '../../../../store/modules/AudienceModule/OpenTv/WeekDays/Audience.weekDays.types';

const path = '/audience/open-tv/regular-plazas/week-days';

export default async function getOpenTvRegularPlazasWeekDays(): Promise<WeekDayResponse> {
  const { data } = await apiV1.get<WeekDayResponse>(path);

  return data;
}
