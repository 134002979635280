import React from 'react';
import './SubHeader.scss';

type Props = {
  children: React.ReactNode;
};

const SubHeader = ({ children }: Props) => {
  return (
    <div className="subheader">
      <div className="subheader__wrapper subheader__wrapper--space">
        {children}
      </div>
    </div>
  );
};

export default SubHeader;
