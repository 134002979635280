import { DateRange } from '@mui/lab/DateRangePicker';
import { ListValue } from '../../../../components/Competitive/ListChannels/types';
import { OptionsList } from '../../../../components/Form/types';
import { ChannelWithDiscount } from '../Channels/Competitive.channels.types';

export interface Period {
  from: string;
  to: string;
}

export enum AppStatus {
  RUNNING = 'running',
  COMPLETED = 'completed',
  FAILED = 'failed'
}
export interface CompetitiveFormData {
  market?: string;
  sectors?: OptionsList[];
  clients?: OptionsList[];
  channels?: ListValue[];
  target?: string;
  categories?: OptionsList[];
  brands?: OptionsList[];
  commercialTypes?: OptionsList[];
  period: DateRange<Date>;
}

export type ValidCompetitiveFormData = Required<
  Pick<CompetitiveFormData, 'market' | 'channels' | 'target'>
> &
  Omit<CompetitiveFormData, 'market' | 'channels' | 'target' | 'period'> & {
    period: Date[];
  };
export interface CompetitiveData {
  marketId: string;
  sectors: string[];
  clients: string[];
  channels: ChannelWithDiscount[];
  categories: string[];
  brands: string[];
  commercialTypes: string[];
  from: string;
  to: string;
  targetId: string;
}

export interface MetricsParams {
  jobToken: string;
}

export type CompetitiveMetricsJobStatus = {
  data: {
    row: {
      progress: number;
      status: AppStatus;
    };
  };
  status: number;
  msg: string;
};
