import React, { useLayoutEffect, useEffect } from 'react';

import { ToastContainer, ToastClassName } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import { classMaskOrUnmask } from './helpers/Utils';
import loginLocalStorage from './components/Auth/LoginWrapper/Login.LocalStorage';

const { REACT_APP_REDIRECT_HOST_FROM, REACT_APP_REDIRECT_URL_TO } = process.env;

// const DELAY = Number(REACT_APP_VERIFY_AT_MILLISECONDS || 30000);
// const PUSH_IN_MIN = Number(REACT_APP_PUSH_WARN_NOTIFICATION_IN_MINUTES || 2);

const App: React.FC = () => {
  const location = useLocation();
  // const loginStore = loginLocalStorage();
  useLayoutEffect(() => {
    if (
      window.location.hostname === REACT_APP_REDIRECT_HOST_FROM &&
      REACT_APP_REDIRECT_URL_TO
    ) {
      window.location.href = REACT_APP_REDIRECT_URL_TO;
    }
  }, []);

  useEffect(() => {
    if (
      typeof window.gtag === 'function' &&
      loginLocalStorage().authenticated.get()
    ) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);

  // const warnExpireSessionOpened = useRef(false);
  // const toastId = useRef<string | number>('');
  // useInterval(() => {
  //   const exp = loginStore.expires.get();
  //   const today = new Date();

  //   if (exp) {
  //     const diff = differenceInMinutes(stringToDate(exp), today);

  //     if (!warnExpireSessionOpened.current && diff <= PUSH_IN_MIN) {
  //       toastId.current = toast.warn(
  //         diff < 0
  //           ? 'Sua sessão expirou'
  //           : `Atenção sua sessão vai expirar em menos de ${PUSH_IN_MIN} minutos.`,
  //         {
  //           hideProgressBar: true,
  //           autoClose: false
  //         }
  //       );

  //       warnExpireSessionOpened.current = true;
  //     }

  //     // if (diff < 0 && warnExpireSessionOpened.current) {
  //     //   toast.update(toastId.current, {
  //     //     render: 'Sua sessão expirou.',
  //     //     autoClose: 2000,
  //     //     hideProgressBar: false,
  //     //     onClose: () => {
  //     //       loginStore.authenticated.remove();
  //     //       loginStore.expires.remove();
  //     //       eventEmitter.dispatch(Events.LOGIN_RELOAD);
  //     //     }
  //     //   });
  //     //   warnExpireSessionOpened.current = false;
  //     // }
  //   }
  // }, DELAY);

  const progressClassName = (): ToastClassName => (params) =>
    params && params.type === 'info'
      ? `${params.defaultClassName} plim-bg-gradient`
      : params?.defaultClassName || '';

  return (
    <>
      <Outlet />
      <ToastContainer
        autoClose={5000}
        className={classMaskOrUnmask(true)}
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        position="bottom-right"
        progressClassName={progressClassName()}
      />
    </>
  );
};

export default App;
