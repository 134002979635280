import './CollumnChart.scss';
import React from 'react';
import { ResponsiveBar, BarTooltipProps } from '@nivo/bar';
import { BarChartData } from './types';

interface Props {
  data: BarChartData[];
  padding?: number;
  valueFormat?: (value: number) => string;
}
const maxPadding = 1;

const customTooltip =
  (): React.FC<BarTooltipProps<BarChartData>> => (datum) => {
    const { data } = datum;

    return (
      <div className="collumn-chart__tooltip">
        <div className="collumn-chart__tooltip__title">{data.id}</div>
        <div className="collumn-chart__tooltip__text">{data.label}</div>
      </div>
    );
  };

const calcPaddingBars = (quantityBars: number, padding: number) => {
  const res = maxPadding - quantityBars * padding - 0.02;

  return res <= 0 ? 0.1 : res;
};

export default function CollumnChartWithScroll({
  data,
  padding,
  valueFormat
}: Props) {
  return (
    <div style={{ height: '400px', overflowX: 'auto', overflowY: 'hidden' }}>
      <div
        style={{
          position: 'relative',
          height: '400px',
          width: `${55 * data.length}px`,
          minWidth: '100%'
        }}
      >
        <ResponsiveBar
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            legend: '',
            legendPosition: 'middle',
            legendOffset: 32,
            tickValues: 8,
            format: (value) => {
              const barData = data.find((d) => d.id === value);
              if (barData) {
                const idText = barData.id.replace(/\n/g, ' ');
                const maxLength = 6;
                if (idText.length > maxLength) {
                  return `${idText.substring(0, maxLength)}...`;
                }
                return idText;
              }

              return value;
            }
          }}
          axisTop={null}
          colors={{ datum: 'data.color' }}
          data={data}
          enableLabel={false}
          indexBy="id"
          keys={['value']}
          gridYValues={8}
          axisLeft={{
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            tickValues: [0]
          }}
          valueFormat={(value) =>
            valueFormat ? valueFormat(value) : String(value)
          }
          indexScale={{ type: 'band', round: true }}
          margin={{ bottom: 80, left: 30, right: 20, top: 30 }}
          padding={calcPaddingBars(data.length, padding ?? 0.1)}
          role="application"
          tooltip={customTooltip()}
          valueScale={{ type: 'linear', nice: true }}
          enableGridY
          isInteractive
          enableTotals
          theme={{
            labels: {
              text: {
                fontSize: 12,
                fontFamily: 'inherit',
                fontWeight: 400,
                fill: '#5A6066'
              }
            },
            grid: {
              line: {
                stroke: '#e0e0e0',
                strokeDasharray: '4 4'
              }
            }
          }}
        />
      </div>
    </div>
  );
}

CollumnChartWithScroll.defaultProps = {
  padding: 0.1,
  valueFormat: undefined
};
