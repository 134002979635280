import {
  MetricsParams,
  CompetitiveMetricsJobStatus,
  CompetitiveData
} from '../../store/modules/Competitive/Shared/Competitive.shared.types';
import {
  CompetitiveConsolidatedTokenResponse,
  ConsolidatedMetricsResponse
} from '../../store/modules/Competitive/ConsolidatedMetrics/Competitive.consolidated.types';
import { apiV1 } from '../shared/Api.service';

const path = '/sectoral/metrics';

export async function runConsolidatedMetricsJob(
  params: CompetitiveData
): Promise<CompetitiveConsolidatedTokenResponse> {
  const { data } = await apiV1.post<CompetitiveConsolidatedTokenResponse>(
    `${path}/run/consolided`,
    params
  );

  return data;
}

export async function getConsolidatedMetricsStatus(
  params: MetricsParams
): Promise<CompetitiveMetricsJobStatus> {
  const { data } = await apiV1.get<CompetitiveMetricsJobStatus>(
    `${path}/status/consolided`,
    {
      params,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
    }
  );

  return data;
}

export async function getCompetitiveMetricsResult(
  params: MetricsParams
): Promise<ConsolidatedMetricsResponse> {
  const { data } = await apiV1.get<ConsolidatedMetricsResponse>(
    `${path}/result/consolided`,
    {
      params
    }
  );

  return data;
}
