import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import AppSVG from '../SVG/AppSVG';

interface Props extends IconButtonProps {
  icon: string;
  info?: string;
  loading?: boolean;
  svgClassName?: string;
}

const ButtonIcon = ({
  loading,
  icon,
  info,
  id,
  disabled,
  svgClassName,
  ...rest
}: Props) => {
  return (
    <Tooltip placement="top" title={info || ''}>
      <IconButton disabled={loading || disabled} {...rest}>
        {loading ? (
          <CircularProgress size={15} />
        ) : (
          <AppSVG
            className={`no-pointer-events ${svgClassName || ''} ${
              disabled ? 'btn--disabled' : ''
            }`}
            id={id}
            loader={<Skeleton height={24} width={24} />}
            src={icon}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

ButtonIcon.defaultProps = {
  info: undefined,
  loading: false,
  svgClassName: undefined
};

export default ButtonIcon;
