/* eslint-disable camelcase */
import { toast } from 'react-toastify';
import {
  AudienceFilterRangeAvaragesAction,
  AudienceFiltersRangeAvaragesType,
  RangeAvarageResponse
} from './Audience.rangeAvarages.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../../../../components/Audience/AudiencePanels/PayTv/Channels/types';
import { AppThunk } from '../../../../../..';
import getPayTvRangeAvaragesByChannels from '../../../../../../../services/Audience/PayTv/Audience.payTv.rangeAvarages.service';

const LoadAudienceFilterRangeAvaragesSuccess = (
  response: RangeAvarageResponse
) => {
  const payload: AudienceFilterRangeAvaragesAction = {
    type: AudienceFiltersRangeAvaragesType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadAudienceFilterRangeAvaragesError = (error: string) => {
  const payload: AudienceFilterRangeAvaragesAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: AudienceFiltersRangeAvaragesType.ERROR
  };

  return payload;
};

const LoadingAudienceRangeAvaragesFilter = () => ({
  type: AudienceFiltersRangeAvaragesType.REQUEST,
  payload: {}
});

export const ClearAudienceRangeAvaragesData = () => ({
  type: AudienceFiltersRangeAvaragesType.CLEAR,
  payload: {}
});

export const LoadPayTvAudienceFilterRangeAvaragesRequestByChannels =
  ({
    target,
    timeRanges,
    weekDays,
    programmingGenders,
    programmers,
    channelGeneres,
    channels,
    channelsNotIn,
    year
  }: IAudienceByChannelsComparativeFilterParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingAudienceRangeAvaragesFilter());
      const response = await getPayTvRangeAvaragesByChannels({
        target,
        programmers,
        channelGeneres,
        channels,
        timeRanges,
        weekDays,
        programmingGenders,
        year,
        fields: ['pc_alcance', 'nm_emissora'],
        table: 'anual',
        channelsNotIn
      });

      dispatch(LoadAudienceFilterRangeAvaragesSuccess(response));
    } catch (error) {
      dispatch(
        LoadAudienceFilterRangeAvaragesError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error(
        'Ops, houve um problema ao tentar carregar os gêneros de programação.'
      );
    }
  };
