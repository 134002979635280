import { toast } from 'react-toastify';
import {
  CompetitiveFilterTargetsAction,
  CompetitiveFiltersTargetsType,
  TargetsQueryParams,
  TargetResponse
} from './Competitive.targets.types';
import { getTargets } from '../../../../services/MediaSimulator/Competitive.service';
import { AppThunk } from '../../..';

const LoadCompetitiveFilterTargetsSuccess = (response: TargetResponse) => {
  const payload: CompetitiveFilterTargetsAction = {
    type: CompetitiveFiltersTargetsType.SUCESS,
    payload: response
  };

  return payload;
};

const LoadCompetitiveFilterTargetsError = (error: string) => {
  const payload: CompetitiveFilterTargetsAction = {
    payload: {
      data: {
        count: 0,
        rows: []
      },
      msg: error
    },
    type: CompetitiveFiltersTargetsType.ERROR
  };

  return payload;
};

const LoadingCompetitiveTargetsFilter = () => ({
  type: CompetitiveFiltersTargetsType.REQUEST,
  payload: {}
});

const LoadCompetitiveFilterTargetsRequest =
  (request?: TargetsQueryParams): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(LoadingCompetitiveTargetsFilter());
      const response = await getTargets(request);

      dispatch(LoadCompetitiveFilterTargetsSuccess(response));
    } catch (error) {
      dispatch(
        LoadCompetitiveFilterTargetsError(
          error instanceof Error ? error.message : ''
        )
      );
      toast.error('Ops, houve um problema ao tentar carregar os targets.');
    }
  };

export default LoadCompetitiveFilterTargetsRequest;
