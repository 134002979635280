import { useNavigate } from 'react-router-dom';
import './SubHeaderTabs.scss';
import StyledTabs, { StyledTab } from '../../../StyledTabs';

interface TabItem {
  id: string;
  label: string;
  index: number;
  path: string;
  className?: string;
}

interface Props {
  items: TabItem[];
  activeTab: number;
}

const SubHeaderTabs = ({ items, activeTab }: Props) => {
  const navigate = useNavigate();
  const WIDTH_TAB = 125;
  const WIDTH_BUTTON_ADD = 35;
  const WIDTH_BUTTON_ARROWS = 40;
  const WIDTH_ALL_BUTTONS = WIDTH_BUTTON_ADD + WIDTH_BUTTON_ARROWS;

  const handleClickTab = (value: number) => {
    navigate(items[value].path);
  };

  return (
    <div className="tabs">
      <StyledTabs
        allowScrollButtonsMobile
        onChange={(_, value: number) => handleClickTab(value)}
        scrollButtons="auto"
        value={activeTab}
        variant={
          items.length <
          Math.floor((window.innerWidth - WIDTH_ALL_BUTTONS) / WIDTH_TAB)
            ? 'standard'
            : 'scrollable'
        }
      >
        {items.map((item) => (
          <StyledTab
            key={item.label}
            id={item.id}
            index={item.index}
            label={item.label}
            className={item.className}
          />
        ))}
      </StyledTabs>
    </div>
  );
};

export default SubHeaderTabs;
