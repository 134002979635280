import { isArray } from 'lodash';

import { apiV1 } from '../../../shared/Api.service';
import { PeriodResponse } from '../../../../store/modules/AudienceModule/OpenTv/Periods/Audience.periods.types';
import { IAudienceByChannelsComparativeFilterParams } from '../../../../components/Audience/AudiencePanels/OpenTv/Channels/types';

const path = '/audience/open-tv/regular-plazas/periods';

export default async function getOpenTvRegularPlazasPeriods({
  plaza,
  channels,
  programmingGenders,
  target,
  timeRange,
  weekDays,
  channelsNotIn,
  program
}: IAudienceByChannelsComparativeFilterParams): Promise<PeriodResponse> {
  const { data } = await apiV1.get<PeriodResponse>(path, {
    params: {
      plaza: plaza?.value,
      channels: channels?.map((channel) => channel.value),
      programmingGenders: isArray(programmingGenders)
        ? programmingGenders?.map(
            (programmingGender) => programmingGender.value
          )
        : programmingGenders?.value,
      target: target?.value,
      timeRange: timeRange?.value,
      weekDays: weekDays?.value ?? 'TODOS',
      channelsNotIn,
      program: program?.value ?? 'TODOS'
    }
  });
  return data;
}
