import Keycloak from 'keycloak-js';

interface KeycloakWithDidInitialize extends Keycloak {
  didInitialize?: boolean;
}

type Security = KeycloakWithDidInitialize;

const initOptions = {
  url: `${process.env.REACT_APP_KEYCLOAK_URL}/auth`,
  realm: 'globoads',
  clientId: 'login-globoads',
  checkLoginIframe: true,
  KeycloakResponseType: 'code'
};

const security: Security = new Keycloak(initOptions);

export default security;
