import { CompetitiveData } from '../Shared/Competitive.shared.types';
import {
  CompetitiveNavAction,
  CompetitiveNavTypes
} from './Competitive.navigation.types';

const ChangeTab = (tab: number) => {
  const payload: CompetitiveNavAction = {
    type: CompetitiveNavTypes.COMPETITIVE_CHANGE_TAB,
    payload: {
      tab
    }
  };

  return payload;
};

export const SaveFiltersTab = (filters: CompetitiveData | undefined) => {
  const payload: CompetitiveNavAction = {
    type: CompetitiveNavTypes.COMPETITIVE_SAVE_FILTERS_TAB,
    payload: {
      filters
    }
  };

  return payload;
};

export default ChangeTab;
