export interface Year {
  id: string;
  name: string;
}
export interface AudienceFilterYearsState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: Year[];
  readonly count: number | null;
}

export interface YearResponse {
  data: {
    rows: Year[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersYearsType {
  REQUEST = '@audience/openTv/years/load',
  SUCESS = '@audience/openTv/years/success',
  ERROR = '@audience/openTv/years/error',
  CLEAR = '@audience/openTv/years/clear'
}

export interface AudienceFilterYearsAction {
  type: AudienceFiltersYearsType;
  payload: Omit<YearResponse, 'status'>;
}
