import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AudienceByProgramsRanking from './Ranking/AudienceOpenTvRegularPlazasProgramsRankingPanel';
import { OptionsList } from '../../../../Form/types';
import AudienceByProgramsContainer from './AudienceOpenTvRegularPlazasProgramsContainer';
import AudienceByProgramsRadiography from './Radiography/AudienceOpenTvRegularPlazasProgramsRadiographyPanel';

const AudienceByPrograms: React.FC = () => {
  const [target, setTarget] = React.useState<OptionsList | undefined>();
  const [timeRange, setTimeRange] = React.useState<OptionsList | undefined>();
  const [newFilter, setNewFilter] = React.useState<boolean>(false);

  const params = useParams() as {
    tab: 'programs';
    subtab: 'ranking' | 'radiography';
  };

  const subtab = useMemo(() => {
    if (params.tab === 'programs') {
      return params.subtab || 'ranking';
    }

    return params.subtab;
  }, [params.tab, params.subtab]);

  const globalProps = {
    target,
    timeRange,
    newFilter,
    setNewFilter
  };

  const additionalProps = {
    ...globalProps,
    setTarget,
    setTimeRange,
    showTimeRange: params.subtab === 'ranking'
  };

  return (
    <AudienceByProgramsContainer {...additionalProps}>
      {subtab === 'ranking' ? (
        <AudienceByProgramsRanking {...globalProps} />
      ) : (
        <AudienceByProgramsRadiography {...globalProps} />
      )}
    </AudienceByProgramsContainer>
  );
};

export default AudienceByPrograms;
