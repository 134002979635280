import { TypeTV, apiV1 } from '../shared/Api.service';
import { BaseAudienceResponse } from '../../store/modules/BaseAudience/BaseAudience.types';

const getBaseAudience = async (typeTV: TypeTV) => {
  const { data } = await apiV1.get<BaseAudienceResponse>(`/audience/limits`, {
    params: { typeTV }
  });
  return data;
};

export default getBaseAudience;
