import { apiV1 } from '../shared/Api.service';
import {
  CompetitiveCategoriesQueryParams,
  CompetitiveCategoriesResponse
} from '../../store/modules/Competitive/Categories/Competitive.categories.types';

const path = '/sectoral/categories';

export default async function getCompetitiveCategories(
  params: CompetitiveCategoriesQueryParams
): Promise<CompetitiveCategoriesResponse> {
  const { data } = await apiV1.get<CompetitiveCategoriesResponse>(path, {
    params
  });

  return data;
}
