import './BottomBar.scss';
import React from 'react';
import ButtonApp from '../ButtonApp';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

interface BottomBarProps {
  buttons: {
    id: number;
    classes?: {
      button?: string;
      loading?: string;
      tagManager?: string;
    };
    title: string;
    info?: string;
    disabled?: boolean;
    visible: boolean;
    loading?: boolean;
    alwaysOpenInfo?: boolean;
    infoVisibleOnHover?: boolean;
    onClick(): void;
  }[];
}

const BottomBar: React.FC<BottomBarProps> = ({ buttons }) => {
  return buttons.length ? (
    <div className="bottom-bar" {...attributeMaskOrUnmask(false)}>
      <div className="bottom-bar__wrapper">
        {buttons.map((button) => (
          <div key={button.id} className="btn-wrapper">
            <ButtonApp
              {...button}
              classes={{
                ...button.classes,
                button: `btn--med btn--transparent ${
                  button.classes?.button || ''
                }`
              }}
            />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};

export default BottomBar;
