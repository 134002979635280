import {
  User,
  UsersFindAll,
  UsersResponse,
  UserResponse
} from '../../store/modules/Users/Users.types';
import { apiV1, ApiErrorResponse } from '../shared/Api.service';

type QueryParamsUser = Omit<UsersFindAll, 'orderBy'> & Partial<User>;
interface GetAllUsersQueryParams extends QueryParamsUser {
  attributes?: string;
  order?: string;
}

const path = '/users';

export async function getAllUsers({
  search,
  limit = 10,
  offset = 0,
  orderDir,
  orderBy
}: UsersFindAll): Promise<UsersResponse> {
  try {
    const params: GetAllUsersQueryParams = {
      deleted: false,
      attributes:
        'id,email,name,deleted,role,central,area,baseAudience,updatedAt',
      limit,
      offset
    };

    if (search) {
      params.search = search;
    }
    if (orderBy) {
      params.order = `${orderBy}:${orderDir}`;
    }

    const { data } = await apiV1.get<UsersResponse>(path, { params });

    return data;
  } catch (error) {
    return error as UsersResponse;
  }
}

export async function saveUser(user: User): Promise<UserResponse> {
  try {
    const { data } = await apiV1.post<UserResponse>(`${path}`, user);

    return data;
  } catch (error) {
    return error as UserResponse;
  }
}

export async function updateUser({
  email,
  id,
  name,
  role,
  central,
  area,
  baseAudience,
  deleted
}: User): Promise<UserResponse> {
  try {
    const { data } = await apiV1.put<UserResponse>(`${path}/${id}`, {
      email,
      name,
      role,
      central,
      area,
      baseAudience,
      deleted
    });

    return data;
  } catch (error) {
    return error as UserResponse;
  }
}

export async function deleteUser(
  id: number
): Promise<UserResponse | ApiErrorResponse> {
  try {
    const { data } = await apiV1.delete<UserResponse>(path, {
      data: {
        ids: [id]
      }
    });

    return data;
  } catch (error) {
    return error as ApiErrorResponse;
  }
}
