import { apiV1 } from '../shared/Api.service';
import {
  CompetitiveCommercialQueryParams,
  CompetitiveCommercialResponse
} from '../../store/modules/Competitive/CommercialTypes/Competitive.commercial.types';

const path = '/sectoral/commercial-types';

export default async function getCompetitiveCommercialTypes(
  params: CompetitiveCommercialQueryParams
): Promise<CompetitiveCommercialResponse> {
  const { data } = await apiV1.get<CompetitiveCommercialResponse>(path, {
    params
  });

  return data;
}
