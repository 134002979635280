import '../AudienceOpenTvRegularPlazasProgramsFilters.scss';
import React, { useRef, useEffect, useMemo } from 'react';
import { FormHandles } from '@unform/core';
import { checkboxClasses, Grid } from '@mui/material';

import { IAudienceByProgramsRankingFilterProps, TypePeriod } from '../types';
import { useAppDispatch, useAppSelector } from '../../../../../../hooks';
import { OptionsList } from '../../../../../Form/types';
import {
  chipProps,
  listboxProps,
  listboxSigleProps
} from '../../../../../Competitive/CompetitiveFilter/constants';
import FormContainer from '../../../../../Form/FormContainer';
import AutocompleteInput from '../../../../../Form/AutocompleteInput';
import {
  ClearAudienceChannelsData,
  LoadAudienceFilterChannelsRequest
} from '../../../../../../store/modules/AudienceModule/OpenTv/Channels/Audience.channels.actions';
import {
  ClearAudiencePeriodsData,
  LoadAudienceFilterPeriodsRequest
} from '../../../../../../store/modules/AudienceModule/OpenTv/Periods/Audience.periods.actions';
import {
  ClearAudienceProgrammingGendersData,
  LoadAudienceFilterProgrammingGendersRequest
} from '../../../../../../store/modules/AudienceModule/OpenTv/ProgrammingGenders/Audience.programmingGenders.actions';

const AudienceByProgramsRankingFilter: React.FC<
  IAudienceByProgramsRankingFilterProps
> = ({
  channels,
  plaza,
  period,
  programmingGenders,
  setChannels,
  setPlaza,
  setPeriod,
  setProgrammingGenders,
  newFilter,
  setNewFilter,
  target,
  timeRange
}) => {
  const formRef = useRef<FormHandles>(null);
  const FIXED_VALUE = useMemo(() => ({ label: 'Todas', value: 'all' }), []);
  const PROGRAMMING_GENDERS_FIXED_VALUE = useMemo(
    () => ({ label: 'Todos', value: 'TODOS' }),
    []
  );
  const inputsConfig = useMemo(
    () => ({
      channels: {
        name: 'channels',
        defaultValue: [FIXED_VALUE]
      },
      plaza: {
        name: 'plaza',
        defaultValue: 'PNT'
      },
      period: {
        name: 'period',
        defaultValue: '2024'
      },
      programmingGenders: {
        name: 'programmingGenders',
        defaultValue: [PROGRAMMING_GENDERS_FIXED_VALUE]
      }
    }),
    [FIXED_VALUE, PROGRAMMING_GENDERS_FIXED_VALUE]
  );
  const dispatch = useAppDispatch();
  const { audience } = useAppSelector((state) => state);
  const [inputPlazaKey, setInputPlazaKey] = React.useState(0);

  useEffect(() => {
    if (
      plaza &&
      audience?.openTv?.plazas.count !== 0 &&
      audience?.openTv?.plazas.count !== null &&
      audience?.openTv?.plazas.loading === false &&
      target &&
      timeRange &&
      audience?.openTv?.channels.count === null &&
      !audience?.openTv?.channels.error &&
      !audience?.openTv?.channels.loading
    ) {
      dispatch(
        LoadAudienceFilterChannelsRequest({
          plaza,
          target,
          timeRange,
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL']
        })
      );
    }
  }, [
    dispatch,
    plaza,
    target,
    timeRange,
    audience?.openTv?.channels,
    audience?.openTv?.plazas
  ]);

  useEffect(() => {
    if (
      plaza &&
      channels?.length !== 0 &&
      audience?.openTv?.channels.loading === false &&
      audience?.openTv?.channels.count !== 0 &&
      audience?.openTv?.channels.count !== null &&
      target &&
      timeRange &&
      audience?.openTv?.programmingGenders.count === null &&
      audience?.openTv?.programmingGenders.loading === false &&
      !audience?.openTv?.programmingGenders.error
    ) {
      dispatch(
        LoadAudienceFilterProgrammingGendersRequest({
          target,
          timeRange,
          channels,
          plaza,
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL']
        })
      );
    }
  }, [
    dispatch,
    plaza,
    channels,
    target,
    timeRange,
    programmingGenders,
    audience?.openTv?.channels,
    audience?.openTv?.programmingGenders
  ]);

  useEffect(() => {
    if (
      plaza &&
      channels?.length !== 0 &&
      programmingGenders?.length !== 0 &&
      audience?.openTv?.programmingGenders.loading === false &&
      audience?.openTv?.programmingGenders.count !== 0 &&
      audience?.openTv?.programmingGenders.count !== null &&
      target &&
      timeRange &&
      audience?.openTv?.periods.count === null &&
      audience?.openTv?.periods.loading === false &&
      !audience?.openTv?.periods.error
    ) {
      dispatch(
        LoadAudienceFilterPeriodsRequest({
          plaza,
          channels,
          target,
          timeRange,
          programmingGenders,
          channelsNotIn: ['NI', 'TOTAL LIGADOS ESPECIAL']
        })
      );
    }
  }, [
    dispatch,
    plaza,
    channels,
    target,
    timeRange,
    programmingGenders,
    audience?.openTv?.programmingGenders,
    audience?.openTv?.periods
  ]);

  const clearInputs = (names: string[]) => {
    names.forEach((name) => {
      formRef.current?.clearField(name);
    });
  };

  const handlePlazaChange = (option: OptionsList) => {
    dispatch(ClearAudienceChannelsData());
    clearInputs([
      inputsConfig.channels?.name,
      inputsConfig?.programmingGenders?.name,
      inputsConfig.period?.name
    ]);

    setPlaza(option);
  };

  const handleChannelChange = (option: OptionsList[]) => {
    dispatch(ClearAudienceProgrammingGendersData());
    clearInputs([
      inputsConfig?.programmingGenders?.name,
      inputsConfig.period?.name
    ]);

    setChannels(option);
  };

  const handleProgrammingGendersChange = (option: OptionsList[]) => {
    dispatch(ClearAudiencePeriodsData());
    clearInputs([inputsConfig.period?.name]);

    setProgrammingGenders(option);
  };

  const handlePeriodsChange = (option: TypePeriod) => {
    setPeriod(option);
  };

  const handleSendClick = () => {
    return;
  };

  const plazasOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.plazas.count) {
      const options = audience?.openTv?.plazas.data
        .filter((plazaOption) => plazaOption?.name)
        .map((plazaOption) => ({
          label: plazaOption?.name,
          value: plazaOption?.name
        }))
        .sort((a, b) => {
          if (a?.value === 'PNT') {
            return -1;
          }

          if (b?.value === 'PNT') {
            return 1;
          }

          return a?.value?.localeCompare(b?.value);
        });

      setInputPlazaKey((prev) => prev + 1);

      return options;
    }

    setInputPlazaKey((prev) => prev + 1);

    return [];
  }, [audience?.openTv?.plazas.data, audience.openTv?.plazas.count]);

  useEffect(() => {
    if (!plaza) {
      setPlaza(
        plazasOptions.find(
          (option) => option.value === inputsConfig.plaza.defaultValue
        )
      );

      clearInputs([inputsConfig.channels.name, inputsConfig.period.name]);
    }
  }, [
    inputsConfig.plaza,
    plazasOptions,
    plaza,
    setPlaza,
    inputsConfig.channels.name,
    inputsConfig.period.name
  ]);

  const channelsOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.channels.count) {
      return audience?.openTv?.channels.data
        .filter((channelOption) => channelOption.name)
        .map((channelOption) => ({
          label: channelOption.name,
          value: channelOption.name
        }));
    }

    return [];
  }, [audience?.openTv?.channels.data, audience?.openTv?.channels.count]);

  useEffect(() => {
    if (
      (!channels?.length && channelsOptions.length) ||
      channels?.length === 0
    ) {
      setChannels(inputsConfig.channels.defaultValue);
      clearInputs([inputsConfig.programmingGenders.name]);
    }
  }, [
    channelsOptions,
    channels,
    setChannels,
    inputsConfig.programmingGenders,
    inputsConfig.channels
  ]);

  const programmingGendersOptions = useMemo<OptionsList[]>(() => {
    if (audience?.openTv?.programmingGenders.count) {
      return audience?.openTv?.programmingGenders.data
        .filter((programmingGenderOption) => programmingGenderOption.name)
        .map((programmingGenderOption) => ({
          label: programmingGenderOption.name,
          value: programmingGenderOption.name
        }))
        .sort((a, b) => a?.label?.localeCompare(b?.label));
    }

    return [];
  }, [
    audience?.openTv?.programmingGenders.data,
    audience?.openTv?.programmingGenders.count
  ]);

  useEffect(() => {
    if (!programmingGenders) {
      setProgrammingGenders(inputsConfig.programmingGenders.defaultValue);
      clearInputs([inputsConfig.period.name]);
    }
  }, [
    programmingGendersOptions,
    programmingGenders,
    setProgrammingGenders,
    inputsConfig.programmingGenders,
    inputsConfig.period
  ]);

  const periodsOptions = useMemo<TypePeriod[]>(() => {
    if (audience?.openTv?.periods.count) {
      return audience?.openTv?.periods.data
        .filter((timeRangeOption) => timeRangeOption.name)
        .map((timeRangeOption) => ({
          label: timeRangeOption.name,
          value: timeRangeOption.name,
          tp_dado: timeRangeOption.tp_dado,
          nr_ano: timeRangeOption.nr_ano
        }))
        .sort((a, b) => {
          if (a?.value?.includes('SEM') && !b?.value?.includes('SEM')) {
            return 1;
          }

          if (!a?.value?.includes('SEM') && b?.value?.includes('SEM')) {
            return -1;
          }

          if (a?.value?.includes('TRI') && !b?.value?.includes('TRI')) {
            return 1;
          }

          if (!a?.value?.includes('TRI') && b?.value?.includes('TRI')) {
            return -1;
          }

          return a?.value?.localeCompare(b?.value);
        });
    }

    return [];
  }, [audience.openTv?.periods.data, audience.openTv?.periods.count]);

  useEffect(() => {
    if (!period && periodsOptions.length) {
      setPeriod(
        periodsOptions.find(
          (option) => option.value === inputsConfig.period.defaultValue
        )
      );
    }
  }, [inputsConfig.period, periodsOptions, period, setPeriod]);

  useEffect(() => {
    if (newFilter === true) {
      clearInputs([
        inputsConfig.plaza.name,
        inputsConfig.channels.name,
        inputsConfig.period.name
      ]);

      setPlaza(undefined);
      setChannels(undefined);
      setPeriod(undefined);

      if (setNewFilter) {
        setNewFilter(false);
      }
    }
  }, [newFilter, setNewFilter, setPlaza, setChannels, setPeriod, inputsConfig]);

  return (
    <Grid
      container
      padding={5}
      paddingTop={0}
      paddingLeft={0}
      spacing={0}
      columnGap={1}
      rowGap={1}
    >
      <FormContainer
        formRef={formRef}
        onSubmit={handleSendClick}
        fullWidth
        className="formContainer__gap"
        formProps={{
          className: 'formContainer__fullWidth'
        }}
        sx={{
          width: '100%',
          flexDirection: 'row'
        }}
      >
        <AutocompleteInput
          /* classes={} */
          label="Mercado"
          key={inputPlazaKey}
          name={inputsConfig.plaza?.name}
          data-testid="mercado"
          disabled={
            audience?.openTv?.plazas.loading ||
            audience?.openTv?.plazas.count === 0
          }
          loading={audience?.openTv?.plazas.loading}
          ListboxProps={listboxSigleProps}
          options={plazasOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={plazasOptions.find(
            (option) => option.value === inputsConfig.plaza.defaultValue
          )}
          onChange={handlePlazaChange}
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channels)}
          /* classes={} */
          data-testid="channels"
          disableCloseOnSelect
          disabled={
            audience?.openTv?.channels.loading ||
            audience?.openTv?.channels.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={FIXED_VALUE}
          label="Emissoras"
          limitTags={5}
          maxItems={6}
          ListboxProps={listboxProps}
          loading={audience?.openTv?.channels.loading}
          multiple
          name={inputsConfig.channels?.name}
          onChange={handleChannelChange}
          options={channelsOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          checkboxClasses={checkboxClasses}
          ChipProps={chipProps(channels)}
          /* classes={} */
          data-testid="programmingGenders"
          disableCloseOnSelect
          disabled={
            audience?.openTv?.programmingGenders.loading ||
            audience?.openTv?.programmingGenders.count === 0
          }
          disableValidateValInOpts
          filterOptions={(x) => x}
          fixedValue={PROGRAMMING_GENDERS_FIXED_VALUE}
          label="Gêneros de Programação"
          limitTags={5}
          maxItems={10}
          ListboxProps={listboxProps}
          loading={audience?.openTv?.programmingGenders.loading}
          multiple
          name={inputsConfig.programmingGenders?.name}
          onChange={handleProgrammingGendersChange}
          options={programmingGendersOptions}
          required
          returnObject
          dataClarityMask
          sx={{
            width: '20%'
          }}
        />
        <AutocompleteInput
          /* classes={} */
          label="Período"
          name={inputsConfig.period?.name}
          data-testid="period"
          disabled={
            audience?.openTv?.periods.loading ||
            audience?.openTv?.periods.data?.length === 0
          }
          loading={audience?.openTv?.periods.loading}
          ListboxProps={listboxSigleProps}
          options={periodsOptions}
          disableValidateValInOpts
          required
          dataClarityMask
          defaultValue={periodsOptions.find(
            (option) => option.label === inputsConfig.period.defaultValue
          )}
          onChange={handlePeriodsChange}
          sx={{
            width: '20%',
            marginLeft: 'auto',
            marginRight: '-70px'
          }}
        />
      </FormContainer>
    </Grid>
  );
};

export default AudienceByProgramsRankingFilter;
