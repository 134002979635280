import { useMemo } from 'react';
import Skeleton from '@mui/material/Skeleton';
import IconExpand from '../../assets/images/expand.svg';
import { getUuid } from '../../helpers/UUID';
import AppSVG from '../SVG/AppSVG';

interface Props {
  expanded: boolean;
  icon?: string;
  text?: string;
  disabled?: boolean;
  onCellExpandClick?: () => void;
  classes?: {
    root?: string;
    icon?: string;
    expandedLessIcon?: string;
    expandedMoreIcon?: string;
    text?: string;
    tagManager?: string;
  };
}

const GridPaginationCellExpanded = ({
  expanded,
  onCellExpandClick,
  text,
  classes,
  icon,
  disabled = false
}: Props) => {
  const handleClick = () => {
    if (onCellExpandClick && !disabled) {
      onCellExpandClick();
    }
  };

  const getClasses = useMemo(
    () => ({
      root: `${!disabled ? 'cursor-pointer' : ''} d-flex align-center ${
        classes?.root || ''
      } ${classes?.tagManager || ''}`.trim(),
      expandedMoreIcon: `grid-icon__expanded-more ${
        classes?.expandedMoreIcon || ''
      }`.trim(),
      expandedLessIcon: `grid-icon__expanded-less ${
        classes?.expandedLessIcon || ''
      }`.trim(),
      icon: `d-flex ${classes?.icon || ''}`,
      text: `no-pointer-events ${classes?.text || ''}`.trim()
    }),
    [classes, disabled]
  );
  return (
    <div className={getClasses.root} onClick={handleClick} role="cell">
      <span className="no-pointer-events">
        <span className="d-flex" id={`btn_summary_expand_${getUuid()}`}>
          <AppSVG
            className={`no-pointer-events ${getClasses?.icon} ${
              expanded
                ? getClasses?.expandedMoreIcon
                : getClasses?.expandedLessIcon
            } ${disabled ? 'btn--disabled' : ''}`}
            loader={<Skeleton className="mr-1" height={24} width={24} />}
            src={icon || IconExpand}
          />
        </span>
      </span>
      {text ? <span className={getClasses?.text}>{text}</span> : null}
    </div>
  );
};

GridPaginationCellExpanded.defaultProps = {
  classes: undefined,
  disabled: false,
  icon: undefined,
  onCellExpandClick: undefined,
  text: ''
};

export default GridPaginationCellExpanded;
