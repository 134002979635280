import { AppStatus } from '../Shared/Competitive.shared.types';

export enum CompetitiveComparativeTypes {
  COMPARATIVE_METRICS_ERROR = '@competitive/comparative_metrics_job/error',
  CLEAR_COMPARATIVE_METRICS = '@competitive/comparative_metrics/clear',
  RUN_COMPARATIVE_METRICS_REQUEST = '@competitive/comparative_metrics_run/request',
  STATUS_COMPARATIVE_METRICS_REQUEST = '@competitive/comparative_metrics_status/request',
  STATUS_COMPARATIVE_METRICS_SUCCESS = '@competitive/comparative_metrics_status/success',
  RESULT_COMPARATIVE_METRICS_REQUEST = '@competitive/comparative_metrics_result/request',
  RESULT_COMPARATIVE_METRICS_SUCCESS = '@competitive/comparative_metrics_result/success'
}
export interface CompetitiveComparativeTokenResponse {
  data: {
    row: { jobToken: string };
  };
  status: number;
  msg: string;
}

export interface MetricsByChannels {
  impacts: number;
  absoluteCoverage: number;
  percentageCoverage: number;
  cpm: number;
  avgFrequency: number;
  affinity: number;
  targetId: string;
  channelMnemonic: string;
  channelName: string;
}

export interface ComparativeMetricsResponse {
  data: { row: { metricsByChannels: MetricsByChannels[] } };
  status: number;
  msg: string;
}

export interface ComparativeMetricsAction {
  type: CompetitiveComparativeTypes;
  payload: {
    metrics?: ComparativeMetricsResponse;
    token: string;
    progress: number;
    message: string;
  };
}

export interface ComparativeMetricsState {
  readonly data?: MetricsByChannels[];
  readonly progress: number;
  readonly loading: boolean;
  readonly error: boolean;
}

export type CompetitiveMetricsJobStatus = {
  data: {
    row: {
      progress: number;
      status: AppStatus;
    };
  };
  status: number;
  msg: string;
};

export interface ComparativeMetricsParams {
  jobToken: string;
}
