export enum CompetitiveConsolidatedTypes {
  CONSOLIDATED_METRICS_ERROR = '@competitive/consolidated_metrics_job/error',
  CLEAR_CONSOLIDATED_METRICS = '@competitive/consolidated_metrics/clear',
  RUN_CONSOLIDATED_METRICS_REQUEST = '@competitive/consolidated_metrics_run/request',
  STATUS_CONSOLIDATED_METRICS_REQUEST = '@competitive/consolidated_metrics_status/request',
  STATUS_CONSOLIDATED_METRICS_SUCCESS = '@competitive/consolidated_metrics_status/success',
  RESULT_CONSOLIDATED_METRICS_REQUEST = '@competitive/consolidated_metrics_result/request',
  RESULT_CONSOLIDATED_METRICS_SUCCESS = '@competitive/consolidated_metrics_result/success'
}

export type TypeRange =
  | 'earlyMorning'
  | 'morning'
  | 'afternoon'
  | 'nightOne'
  | 'nightTwo'
  | 'nightThree';

export interface CompetitiveConsolidatedTokenResponse {
  data: {
    row: { jobToken: string };
  };
  status: number;
  msg: string;
}

export interface MetricsByTarget {
  affinity: number;
  impacts: any;
  cpm: number;
  percentageCoverage: number;
  absoluteCoverage: number;
  avgFrequency: number;
  targetId: string;
  universe: number;
  grpOrTrp: number;
}

export interface MetricsByChannels {
  channelMnemonic: string;
  channelName: string;
  insertions: number;
  insertionsPercentage: number;
  investment: number;
  investmentPercentage: number;
  grpOrTrp: number;
  grpOrTrpPercentage: number;
}

export interface MetricsByPeriod {
  type: TypeRange;
  timeBand: string;
  insertions: number;
  insertionsPercentage: number;
  investment: number;
  investmentPercentage: number;
  grpOrTrp: number;
  grpOrTrpPercentage: number;
}

export interface ConsolidatedMetrics {
  metricsByChannels: MetricsByChannels[];
  metricsByTargets: MetricsByTarget[];
  metricsByPeriods: MetricsByPeriod[];
  totalLiquidInvestment: number;
  totalInvestment: number;
  totalInsertions: number;
  totalGrpOrTrp: number;
}

export interface ConsolidatedMetricsResponse {
  data: { row: { consolidatedMetrics: ConsolidatedMetrics } };
  status: number;
  msg: string;
}

export interface ConsolidatedMetricsAction {
  type: CompetitiveConsolidatedTypes;
  payload: {
    metrics?: ConsolidatedMetricsResponse;
    token: string;
    progress: number;
    message: string;
  };
}

export interface ConsolidatedMetricsState {
  readonly data?: ConsolidatedMetrics;
  readonly progress: number;
  readonly loading: boolean;
  readonly error: boolean;
}
