import { Reducer } from 'redux';
import {
  CompetitiveCommercialState,
  CompetitiveCommercialAction,
  CompetitiveCommercialTypes
} from './Competitive.commercial.types';

const targetInitialState: CompetitiveCommercialState = {
  data: [],
  loading: false,
  error: false,
  count: 0
};

const reducer: Reducer<
  CompetitiveCommercialState,
  CompetitiveCommercialAction
> = (state = targetInitialState, action): CompetitiveCommercialState => {
  switch (action.type) {
    case CompetitiveCommercialTypes.LOAD_COMMERCIAL_SUCCESS: {
      return {
        ...state,
        data: action.payload.rows,
        count: action.payload.count,
        loading: false,
        error: false
      };
    }
    case CompetitiveCommercialTypes.LOAD_COMMERCIAL_ERROR: {
      return {
        ...state,
        data: [],
        count: 0,
        loading: false,
        error: true
      };
    }
    case CompetitiveCommercialTypes.LOAD_COMMERCIAL_REQUEST: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    default: {
      return state;
    }
  }
};
export default reducer;
