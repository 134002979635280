export interface AvarageAudienceByGender {
  pc_adh: number;
  nm_target: string;
}
export interface AudienceFilterAvarageAudienceByGendersState {
  readonly loading: boolean;
  readonly error: boolean;
  readonly data: AvarageAudienceByGender[];
  readonly count: number | null;
}

export interface AvarageAudienceByGenderResponse {
  data: {
    rows: AvarageAudienceByGender[];
    count: number;
  };
  status: number;
  msg: string;
}

export enum AudienceFiltersAvarageAudienceByGendersType {
  REQUEST = '@audience/payTv/byPrograms/avarageAudienceByGenders/load',
  SUCESS = '@audience/payTv/byPrograms/avarageAudienceByGenders/success',
  ERROR = '@audience/payTv/byPrograms/avarageAudienceByGenders/error',
  CLEAR = '@audience/payTv/byPrograms/avarageAudienceByGenders/clear'
}

export interface AudienceFilterAvarageAudienceByGendersAction {
  type: AudienceFiltersAvarageAudienceByGendersType;
  payload: Omit<AvarageAudienceByGenderResponse, 'status'>;
}
