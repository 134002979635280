import './ButtonApp.scss';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { attributeMaskOrUnmask } from '../../helpers/Utils';

export interface ButtonAppProps {
  classes?: {
    button?: string;
    loading?: string;
    tagManager?: string;
  };
  title: string;
  info?: string;
  disabled?: boolean;
  visible?: boolean;
  loading?: boolean;
  alwaysOpenInfo?: boolean;
  infoVisibleOnHover?: boolean;
  onClick(): void;
}

const ButtonApp = ({
  classes,
  title,
  info,
  disabled,
  visible = true,
  loading,
  alwaysOpenInfo,
  infoVisibleOnHover,
  onClick
}: ButtonAppProps) => {
  const [open, setOpen] = useState(alwaysOpenInfo && !!info);
  // verifica se o mouse está em cima do botão
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (info && alwaysOpenInfo) {
      setOpen(true);
    }
  }, [info, alwaysOpenInfo]);

  const handleClose = () => {
    if (!alwaysOpenInfo) {
      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return visible ? (
    <div
      onMouseLeave={() => setHover(false)}
      onPointerLeave={() => setHover(false)}
      onClickCapture={() => setHover(true)}
    >
      <Tooltip
        arrow
        onClose={handleClose}
        onOpen={handleOpen}
        open={open || (infoVisibleOnHover && hover)}
        title={info || ''}
        {...attributeMaskOrUnmask(false)}
      >
        <button
          className={`btn ${classes?.button || ''} ${
            classes?.tagManager || ''
          } ${
            // eslint-disable-next-line no-nested-ternary
            disabled || loading
              ? title === 'exportar mapa para compra'
                ? 'btn--disabled btn-export-disabled'
                : 'btn--disabled'
              : ''
          }`}
          disabled={disabled || loading}
          onClick={() => onClick()}
          type="button"
        >
          {loading ? (
            <CircularProgress
              classes={{ indeterminate: 'button-app__loading' }}
              className={classes?.loading || 'plim-violet'}
              size={20}
            />
          ) : (
            title
          )}
        </button>
      </Tooltip>
    </div>
  ) : null;
};

ButtonApp.defaultProps = {
  alwaysOpenInfo: false,
  infoVisibleOnHover: false,
  classes: undefined,
  disabled: false,
  info: undefined,
  loading: false,
  visible: true
};

export default ButtonApp;
